import { BASEURL } from "../../API/apiData";

const shareNewSuggestion = async (profile_id, suggestion_id) => {
    const data = {
        shared_suggestions: {
              profile_id,
             suggestion_id
        }
    };
    const token = localStorage.getItem("token");
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
  
    const response = await fetch(
      `${BASEURL}shared_suggestions`,
      requestOptions
    );
    const results = await response.json();
    return results;
};

export default shareNewSuggestion