import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Divider } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const AppReusableDisplayUpvotedProfiles = ({
  profiles,
  votersToggleDrawer,
  setVotersToggleDrawer,
  drawerTitle,
  setDrawerTitle,
  setVotersProfiles
}) => {
const [open, setOpen] = useState(false);
  const history = useHistory()

  useEffect(() => {
    if (votersToggleDrawer === true) {
      setOpen(true);
      return;
    }
    setOpen(false);
  }, [votersToggleDrawer]);

  const handleClose = () => {
    setOpen(false);
    setVotersToggleDrawer(false);
    setDrawerTitle("");
    setVotersProfiles([])
  };

  const navigateToProfile = (profileId) => {
    history.push({
      pathname: `/profile/${profileId}`,
    });
  }

  const displayUpvotedProfiles = () => {
    if (profiles.length < 1) return "Loading profiles...";

    const results = profiles.map((value, index) => {
      return (
        <div className="profiles-likes-dis" key={index}>
          <ListItem  alignItems="flex-start" onClick={() => navigateToProfile(value.profile.id)}>
            <ListItemAvatar>
              <Avatar alt={`${value.profile.first_name}`} src={value.profile.profile_avatar.url ? value.profile.profile_avatar.url : "/static/images/avatar/1.jpg"} />
            </ListItemAvatar>
            <ListItemText
              primary={`${value.profile.first_name} ${value.profile.last_name}`}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                   Role
                  </Typography>
                  {` — ${value.profile.role ? value.profile.role : "Not assigned a role yet"}`}
                </React.Fragment>
              }
            />
          </ListItem>
        </div>
      );
    });

    return results;
  };
  return (
    <div>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{drawerTitle}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {displayUpvotedProfiles()}
          </List>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  </div>
  )
};

export default AppReusableDisplayUpvotedProfiles;
