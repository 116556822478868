import React from "react";
import Modal from "react-bootstrap/Modal";

const ConfirmEmailModal = ({ isOpen, closeModal, title, message }) => {
  return (
    <>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ "backgroundColor": "#ff9b00" }}
          closeButton
        >
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{message}</h6>
          <hr />
          <div className="form-group text-center">
            <button onClick={closeModal} className="add-btn rounded" type="button">
              OK
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmEmailModal;
