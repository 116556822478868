import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { CREATE_SUGGESTION_FAILURE, CREATE_SUGGESTION_REQUEST, CREATE_SUGGESTION_SUCCESS, EDIT_SUGGESTION_FAILURE, EDIT_SUGGESTION_REQUEST, EDIT_SUGGESTION_SUCCESS, 
  FETCH_SUGGESTIONS_FAILURE, FETCH_SUGGESTIONS_REQUEST, FETCH_SUGGESTIONS_SUCCESS,
  FETCH_SUGGESTION_FAILURE, FETCH_SUGGESTION_REQUEST, FETCH_SUGGESTION_SUCCESS } from "./suggestionTypes";

export const fetchSuggestionsRequest = () => ({
  type: FETCH_SUGGESTIONS_REQUEST,
});

export const fetchSuggestionsSuccess = (response) => ({
  type: FETCH_SUGGESTIONS_SUCCESS,
  payload: response,
});

export const fetchSuggestionsFailure = (error) => ({
  type: FETCH_SUGGESTIONS_FAILURE,
  payload: error,
});

export const fetchSuggestionRequest = () => ({
  type: FETCH_SUGGESTION_REQUEST,
});

export const fetchSuggestionSuccess = (response) => ({
  type: FETCH_SUGGESTION_SUCCESS,
  payload: response,
});

export const fetchSuggestionFailure = (error) => ({
  type: FETCH_SUGGESTION_FAILURE,
  payload: error,
});

export const createSuggestionRequest = () => ({
  type: CREATE_SUGGESTION_REQUEST,
});

export const createSuggestionSuccess = (response) => ({
  type: CREATE_SUGGESTION_SUCCESS,
  payload: response,
});

export const createSuggestionFailure = (error) => ({
  type: CREATE_SUGGESTION_FAILURE,
  payload: error,
});

export const editSuggestionRequest = () => ({
  type: EDIT_SUGGESTION_REQUEST,
});

export const editSuggestionSuccess = (response) => ({
  type: EDIT_SUGGESTION_SUCCESS,
  payload: response,
});

export const editSuggestionFailure = (error) => ({
  type: EDIT_SUGGESTION_FAILURE,
  payload: error,
});

export const createSuggestion = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(createSuggestionRequest());
  return axios
    .post(`${BASEURL}suggestions`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(createSuggestionSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(createSuggestionFailure(error));
    });
};

export const fetchSuggestions = () => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchSuggestionsRequest());
  return axios
    .get(`${BASEURL}suggestions`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchSuggestionsSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchSuggestionsFailure(error));
    });
};

export const fetchSuggestion = (id) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchSuggestionRequest());
  return axios
    .get(`${BASEURL}suggestions/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchSuggestionSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchSuggestionFailure(error));
    });
};

export const editSuggestion = (id,data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(editSuggestionRequest());
  return axios
    .put(`${BASEURL}suggestions/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(editSuggestionSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(editSuggestionFailure(error));
    });
};
