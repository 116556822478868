import React, { Fragment, useEffect, useState } from "react";
import { displayDate, displayTime } from "../../helpers/timeModule";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { values } from "lodash";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import BorderColorTwoToneIcon from "@mui/icons-material/BorderColorTwoTone";
import Chip from "@mui/material/Chip";
import ReplyTwoToneIcon from "@mui/icons-material/ReplyTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";

import { Avatar, Divider, Paper } from "@mui/material";
import { createSuggestionResponse, fetchSuggestionResponse } from "../../redux";
import { createVote } from "../../services/votes/voteService";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import BottomDrawerMobile from "./BottomDrawerMobile";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import editSuggestionComment from "../../services/suggestions/suggestions";
import {
  createSuggestionCommentReply,
  editSuggestionCommentReply,
} from "../../services/suggestions/suggestionCommentReplies";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewCommentMobileDrawer = ({
  newComment,
  setNewComment,
  numberOfComments,
  suggestion,
  sugID,
  fetchSugResponse,
  createSugResponse,
}) => {
  const [open, setOpen] = useState(false);
  const [suggestionResponses, setResponses] = useState([]);
  const [bottomDrawer, setBottomDrawer] = useState(false);
  const [suggestionID, setSuggestionID] = useState(null);
  const [commentReply, setCommentReply] = useState("");
  const [commentResponsereplyEdit, setCommentResponsereplyEdit] = useState({
    form: false,
    formcontent: "",
    replyResponseId: null,
  });

  // <==== Reply and edit form state ====>
  const [editReplyState, setEditReplyState] = useState({
    editbutton: false,
    replyButton: false,
    editContent: null,
  });

  useEffect(() => {
    if (newComment === true) return setOpen(true);
    setOpen(false);
  }, [newComment]);

  useEffect(() => {
    fetchSuggestionComments();
    // eslint-disable-next-line
  }, []);

  // ==== THIS FUNCTION GET ALL THE SUGGESTION COMMENTS ====
  const fetchSuggestionComments = async () => {
    try {
      const results = await fetchSugResponse(sugID);
      const { result } = results.payload;
      setResponses(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setNewComment(false);
    setOpen(false);
  };
  // ==== THIS METHOD IS FOR VOTING ====
  const sendVote = async (data) => {
    try {
      const res = await createVote(data);
      res.message === "Thanks for your vote"
        ? toast.success(res.message)
        : toast.error(res.message);

      fetchSuggestionComments();
    } catch (error) {
      console.error(error);
    }
  };

  const voteEntry = (modelName, model, likes) => {
    const xData = {
      likeable_type: modelName,
      likeable_id: model.id,
    };
    sendVote(xData);
  };

  // ==================================>

  const handleCommentEdit = (value, valueSuggestion) => {
    if (value === suggestionID) {
      setEditReplyState({
        editbutton: true,
        replyButton: false,
        editContent: valueSuggestion.response_text,
      });
      return;
    }
    if (value !== suggestionID) {
      setEditReplyState({
        editbutton: false,
        replyButton: false,
        editContent: null,
      });
    }
  };

  const handleCloseEditComment = (value) => {
    if (value === suggestionID) {
      setEditReplyState({
        editbutton: false,
        replyButton: false,
        editContent: null,
      });
    }
  };

  const editReplyForm = (selection) => {
    const { edit, reply } = selection;
    if (edit === true)
      return (
        <>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={"row"}
          >
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                data={editReplyState.editContent}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditReplyState({
                    ...editReplyState,
                    editContent: data,
                  });
                }}
              />
            </Grid>
          </Grid>
        </>
      );

    if (reply === true) {
      return (
        <>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={"row"}
          >
            <div className="close-reply-comment">
              <CancelTwoToneIcon
                fontSize="large"
                onClick={() => {
                  setEditReplyState({
                    ...editReplyState,
                    replyButton: false,
                  });
                }}
              />
            </div>
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                data={commentReply}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setCommentReply(data);
                }}
              />
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const handleReplyForm = (value) => {
    if (value === suggestionID) {
      setEditReplyState({
        editbutton: false,
        replyButton: true,
        editContent: null,
      });

      editReplyForm({
        edit: false,
        reply: true,
      });

      return;
    }

    if (value !== suggestionID) {
      setEditReplyState({
        editbutton: false,
        replyButton: false,
        editContent: null,
      });

      editReplyForm({
        edit: false,
        reply: false,
      });
    }
  };

  const displayReplyButtons = (commentID) => {
    const { replyButton } = editReplyState;
    if (replyButton === true && commentID === suggestionID)
      return (
        <Chip
          label="Reply"
          onClick={() => {
            handleCommentReply(commentID);
            setSuggestionID(commentID);
            handleReplyForm(commentID);
          }}
          icon={<SendTwoToneIcon />}
        />
      );

    return (
      <Chip
        label="Reply"
        onClick={() => {
          setSuggestionID(commentID);
          handleReplyForm(commentID);
        }}
        icon={<ReplyTwoToneIcon />}
      />
    );
  };

  // ==== THIS FUNCTION HANDLES COMMENT EDIT ====

  const handleEditComment = async (commentID) => {
    const { editContent } = editReplyState;
    try {
      const { message } = await editSuggestionComment(
        sugID,
        commentID,
        editContent
      );
      if (message === "Response updated successfully") {
        fetchSuggestionComments();
        handleCloseEditComment(commentID);
        toast("Your Comment has been edited successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(`Something went wrong ${error.message}`);
    }
  };

  // ==== THIS FUNCTION HANDLE COMMENT REPLY ====
  const handleCommentReply = async (commentID) => {
    try {
      await createSuggestionCommentReply(sugID, commentID, commentReply);

      setEditReplyState({
        ...editReplyState,
        replyButton: false,
      });

      fetchSuggestionComments();
      setCommentReply("");

      toast("Your reply has been created successfully", {
        position: "top-right",
      });
    } catch (error) {
      toast.error(`Something went wrong,${error.message}`);
    }
  };

  // ==== THIS FUNCTION IS HANDINLING RESPONSE EDIT ====
  const handleEditReplyResponse = async (
    suggestionID,
    suggestionCommentID,
    id
  ) => {
    const { formcontent } = commentResponsereplyEdit;
    try {
      await editSuggestionCommentReply(
        suggestionID,
        suggestionCommentID,
        id,
        formcontent
      );
      fetchSuggestionComments();
      toast("Your reply has been Edited successfully", {
        position: "top-right",
      });
    } catch (error) {
      toast.error(`Something went wrong,${error.message}`);
    }
  };

  const editReplyResponseForm = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          direction={"row"}
        >
          <Grid item xs={12}>
            <CKEditor
              editor={ClassicEditor}
              data={commentResponsereplyEdit.formcontent}
              onChange={(event, editor) => {
                const data = editor.getData();
                setCommentResponsereplyEdit({
                  ...commentResponsereplyEdit,
                  formcontent: data,
                });
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const displayCommentReplies = (value) => {
    const { suggestion_comment_replies, id: suggestionCommentID } = value;
    if (suggestion_comment_replies.length < 1) return null;

    const result = suggestion_comment_replies.map((value, index) => {
      return (
        <Fragment key={index}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}>
              {value.profile !== null ? (
                <Avatar
                  alt="profile avatar"
                  src={
                    value.profile.profile_avatar.url &&
                    value.profile.profile_avatar.url
                  }
                  sx={{ width: 40, height: 40 }}
                  className="mr-3"
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              {suggestion_comment_replies.length < 2 ||
              value.id ===
                suggestion_comment_replies[
                  suggestion_comment_replies.length - 1
                ].id ? null : (
                <div className="vertical-bar-comment-box-sub-mobile"></div>
              )}
            </Grid>

            <Grid item xs={10}>
              <h4
                style={{ margin: 0, textAlign: "left" }}
                className="creator-of-comment"
              >
                {value.profile !== null && value.profile.first_name}
                {value.profile !== null && value.profile.last_name}
              </h4>
              <p
                style={{ textAlign: "left", color: "gray" }}
                className="comment-date-posted"
              >
                Posted &nbsp;
                {displayDate(value.created_at).value < 1
                  ? "Just now"
                  : displayDate(value.created_at).value}{" "}
                {displayDate(value.created_at).value < 1
                  ? null
                  : displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}{" "}
              </p>
              <div className="main-sb-comment-mobile-version">
                {commentResponsereplyEdit.form === true &&
                value.id === commentResponsereplyEdit.replyResponseId ? (
                  <div
                    className="cancel-btn-cont-display"
                    onClick={() => {
                      setCommentResponsereplyEdit({
                        formcontent: "",
                        replyResponseId: null,
                        form: false,
                      });
                    }}
                  >
                    <CancelTwoToneIcon fontSize="medium" />
                  </div>
                ) : null}
                {commentResponsereplyEdit.form === true &&
                value.id === commentResponsereplyEdit.replyResponseId ? (
                  editReplyResponseForm()
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: value.comment }} />
                )}

                <div className="reply-of-reply-like-container">
                  <div className="reply-of-reply-Like-button">
                    {
                      value.user_likes === false ? (
                        <Chip
                      label={`Upvote(${value.votes})`}
                      onClick={() =>  voteEntry("SuggestionCommentReply", value, values.votes)}
                      icon={<ThumbUpAltTwoToneIcon color= "disabled" />}
                    />
                      ) : (
                        <Chip
                        label={`Upvote(${value.votes})`}
                        onClick={() =>  voteEntry("SuggestionCommentReply", value, values.votes)}
                        icon={<ThumbUpAltTwoToneIcon color="success" />}
                        
                      />
                      )
                    }
                    
                  </div>

                  <div className="reply-of-reply-edit-button">
                    {commentResponsereplyEdit.form === true &&
                    value.id === commentResponsereplyEdit.replyResponseId ? (
                      <Chip
                        label="Edit"
                        onClick={() => {
                          setCommentResponsereplyEdit({
                            form: false,
                            formcontent: "",
                            replyResponseId: null,
                          });
                          handleEditReplyResponse(
                            value.suggestion_id,
                            suggestionCommentID,
                            value.id
                          );
                        }}
                        icon={<SendTwoToneIcon />}
                      />
                    ) : (
                      <Chip
                        label="Edit"
                        onClick={() => {
                          setCommentResponsereplyEdit({
                            form: true,
                            formcontent: value.comment,
                            replyResponseId: value.id,
                          });
                        }}
                        icon={<BorderColorTwoToneIcon />}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Fragment>
      );
    });
    return result;
  };

  const displayComments = () => {
    const { editbutton, replyButton } = editReplyState;
    if (suggestionResponses.length < 1)
      return (
        <div className="No-comments-yet">
          No comments yet... Be the first to comment!{" "}
        </div>
      );
    const results = suggestionResponses.map((value, index) => {
      return (
        <Fragment key={index}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={2}>
              {value.profile !== null ? (
                <Avatar
                  alt="profile avatar"
                  src={
                    value.profile.profile_avatar.url &&
                    value.profile.profile_avatar.url
                  }
                  sx={{ width: 40, height: 40 }}
                  className="mr-3"
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              {suggestionResponses.length < 2 ||
              value.id ===
                suggestionResponses[suggestionResponses.length - 1]
                  .id ? null : (
                <div className="vertical-bar-comment-box-mobile-version"></div>
              )}
            </Grid>

            <Grid item xs={10}>
              <h4
                style={{ margin: 0, textAlign: "left" }}
                className="creator-of-comment"
              >
                {value.profile !== null && value.profile.first_name}
                {value.profile !== null && value.profile.last_name}
              </h4>
              <p
                style={{ textAlign: "left", color: "gray" }}
                className="comment-date-posted"
              >
                Posted &nbsp;
                {displayDate(value.created_at).value < 1
                  ? "Just now"
                  : displayDate(value.created_at).value}{" "}
                {displayDate(value.created_at).value < 1
                  ? null
                  : displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}{" "}
              </p>

              <div className="main-sb-comment-mobile-version">
                {editbutton === true && value.id === suggestionID ? (
                  editReplyForm({
                    edit: editbutton,
                    reply: false,
                  })
                ) : (
                  <div
                    dangerouslySetInnerHTML={{ __html: value.response_text }}
                  />
                )}

                {replyButton === true && value.id === suggestionID
                  ? editReplyForm({
                      edit: false,
                      reply: replyButton,
                    })
                  : null}

                <div className="edit-reply-mobile-container">
                  <div className="reply-of-reply-Like-button">
                    {value.user_likes ? (
                      <Chip
                        label={`Upvote(${value.votes})`}
                        onClick={() =>
                          voteEntry("SuggestionResponse", value, values.votes)
                        }
                        icon={<ThumbUpAltTwoToneIcon color="success" />}
                      />
                    ) : (
                      <Chip
                        label={`Upvote(${value.votes})`}
                        onClick={() =>
                          voteEntry("SuggestionResponse", value, values.votes)
                        }
                        icon={<ThumbUpAltTwoToneIcon color="disabled" />}
                      />
                    )}
                  </div>
                  <div className="edit-cont-button">
                    {editbutton === true &&
                    value.id === suggestionID &&
                    replyButton === false ? (
                      <Chip
                        label="Edit"
                        onClick={() => {
                          handleEditComment(value.id);
                          setSuggestionID(value.id);
                        }}
                        icon={<SendTwoToneIcon />}
                      />
                    ) : (
                      <Chip
                        label="Edit"
                        onClick={() => {
                          setSuggestionID(value.id);
                          handleCommentEdit(value.id, value);
                        }}
                        icon={<BorderColorTwoToneIcon />}
                      />
                    )}
                  </div>
                  <div className="reply-cont-button">
                    {editbutton === true &&
                    suggestionID === value.id &&
                    replyButton === false ? (
                      <Chip
                        label="Cancel"
                        onClick={() => {
                          setSuggestionID(null);
                          setEditReplyState({
                            ...editReplyState,
                            editbutton: false,
                            editContent: null,
                          });
                        }}
                        icon={<HighlightOffTwoToneIcon />}
                      />
                    ) : (
                      displayReplyButtons(value.id)
                    )}
                  </div>
                </div>
              </div>
              {displayCommentReplies(value)}
              <Divider />
            </Grid>
          </Grid>
        </Fragment>
      );
    });
    return results;
  };
  return (
    <>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <BottomDrawerMobile
            bottomDrawer={bottomDrawer}
            setBottomDrawer={setBottomDrawer}
            createSugResponse={createSugResponse}
            fetchSuggestionComments={fetchSuggestionComments}
            suggestionID={sugID}
          />
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Comments{" "}
                <span className="number-of-comments-drawer">
                  ({numberOfComments})
                </span>
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className="comments-mobile-version-container">
            {displayComments()}
          </div>

          <div className="mobile-comments-editor">
            <Paper elevation={6} />
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => {
                setBottomDrawer(true);
              }}
            >
              New
            </Button>
            <Paper />
          </div>
        </Dialog>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchSugResponse: (sId) => dispatch(fetchSuggestionResponse(sId)),
  createSugResponse: (sId, data) =>
    dispatch(createSuggestionResponse(sId, data)),
});

export default connect(null, mapDispatchToProps)(NewCommentMobileDrawer);
