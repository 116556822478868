import React, { useState } from "react";
import _ from "lodash";
import parse from "html-react-parser";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

function ListingPage({
  listItems,
  listLoader,
  setListingShow,
  setlistingEditShow,
  setListEditInfo,
  setItemID,
  tabDetails,
}) {
  const [state, setState] = useState(false);

  const displayLoader = () => {
    return (
      <>
        <div className="loader-items-sort text-center loader-bmc-list">
          <span
            className="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Loading...</span>
        </div>
      </>
    );
  };

  const noListItems = () => {
    return listLoader ? (
      displayLoader()
    ) : (
      <>
        <div className="col-md-12 mt-2">
          <div className="create-new-swort">
            <Button
              variant="contained"
              onClick={() => setListingShow(true)}
              className="mt-4 mb-3"
              color="secondary"
              startIcon={<AddBoxIcon />}
            >
              Add new
            </Button>
          </div>
          <div className="not-found-statement container">
            <div className="alert alert-danger" role="alert">
              <i className="fa fa-frown-o" aria-hidden="true"></i>{" "}
              <h3>There are no list of items</h3>
            </div>
          </div>
        </div>
      </>
    );
  };

  const displayListItems = () => {
    const results = listItems.map((value, idx) => {
      return (
        <div className="card list-card-container" key={value.id}>
          <div className="card-body list-card">
            <span className="list-number">{idx + 1}</span>{" "}
            <div className="list-item-stength"> {parse(value.item_name)}</div>
            {/* <div
              className="edit-button-list mr-4"
              onClick={() => {
                console.log("clicked");
              }}
            >
              <ThumbUpOutlinedIcon />
            </div> */}
            <div
              className="edit-button-list"
              onClick={() => {
                setListEditInfo(value.item_name);
                setItemID(value.id);

                setTimeout(() => {
                  setlistingEditShow(true);
                }, 1500);
              }}
            >
              <i className="fa fa-pencil-square" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      );
    });

    return listLoader ? (
      displayLoader()
    ) : (
      <div className="col-md-12 mt-2">
        <div className="create-new-swort">
          <Button
            variant="contained"
            onClick={() => setListingShow(true)}
            className="mt-4 mb-3"
            color="secondary"
            startIcon={<AddBoxIcon />}
          >
            Add new
          </Button>

          <PsychologyAltIcon
            className={state ? "question-bmc-active" : "question-bmc"}
            onClick={() => setState(!state)}
          />
        </div>
        {state && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {tabDetails}
          </Alert>
        )}

        <>{results}</>
      </div>
    );
  };

  return _.isEmpty(listItems) ? noListItems() : displayListItems();
}

export default ListingPage;
