import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { UPDATE_PWD_FAILURE, UPDATE_PWD_REQUEST, UPDATE_PWD_SUCCESS, RESET_PWD_REQUEST, RESET_PWD_SUCCESS, RESET_PWD_FAILURE } from './passwordTypes';

export const updatepwdRequest = () => ({
  type: UPDATE_PWD_REQUEST,
});

export const updatepwdSuccess = response => ({
  type: UPDATE_PWD_SUCCESS,
  payload: response,
});

export const updatepwdFailure = error => ({
  type: UPDATE_PWD_FAILURE,
  payload: error,
});

export const resetpwdRequest = () => ({
  type: RESET_PWD_REQUEST,
});

export const resetpwdSuccess = response => ({
  type: RESET_PWD_SUCCESS,
  payload: response,
});

export const resetpwdFailure = error => ({
  type: RESET_PWD_FAILURE,
  payload: error,
});

export const updatePwd = data => dispatch => {
  dispatch(updatepwdRequest());
  return axios.put(`${BASEURL}users/password`, data)
    .then(response => {
      return dispatch(updatepwdSuccess(response.data));
    }).catch(error => {
      return dispatch(updatepwdFailure(error));
    });
};

export const requestPwdReset = data => dispatch => {
  dispatch(resetpwdRequest());
  return axios.post(`${BASEURL}users/password`, data)
    .then(response => {
      return dispatch(resetpwdSuccess(response.data));
    }).catch(error => {
      return dispatch(resetpwdFailure(error));
    });
};