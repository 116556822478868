import axios from "axios";
import { BASEURL } from "../../API/apiData";
import {FETCH_LEGENDCATEGORIES_REQUEST,FETCH_LEGENDCATEGORIES_SUCCESS, FETCH_LEGENDCATEGORIES_FAILURE} from "./legendCategoryTypes";

export const fetchLegendCategoriesRequest = () => ({
  type: FETCH_LEGENDCATEGORIES_REQUEST,
});

export const fetchLegendCategoriesSuccess = (response) => ({
  type: FETCH_LEGENDCATEGORIES_SUCCESS,
  payload: response,
});

export const fetchLegendCategoriesFailure = (error) => ({
  type: FETCH_LEGENDCATEGORIES_FAILURE,
  payload: error,
});


export const fetchLegendCategories = () => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchLegendCategoriesRequest());
  return axios
    .get(`${BASEURL}legend_categories`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchLegendCategoriesSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchLegendCategoriesFailure(error));
    });
};
