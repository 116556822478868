/*eslint-disable */
import React, { useState } from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";

const LibraryModal = ({
  data,
  category,
  categories= [],
  isOpen,
  closeModal,
  handleSubmit,
  master,
}) => {
  const [name, setName] = useState(data == null ? "" : data.list_name);
  const [description, setDescription] = useState(
    data == null ? "" : data.list_description
  );
  const [isPrivate, setIsPrivate] = useState(
    data == null ? false : data.is_private
  );
  const [categoryId, setCategoryId] = useState(
    category ? category : data.tuc_inventory_category_id
  );

  const displayCategories = () => {
    if(!_.isEmpty(categories)) {
      return (
        <>
          {categories.map(cat => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
        </>
      )
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const xData = {
      tuc_inventory: {
        list_name: name,
        list_description: description,
        tuc_inventory_category_id: categoryId,
        is_private: isPrivate,
      },
    };
    console.log(xData)
    handleSubmit(xData);
    if (data == null) {
      setName("");
      setDescription("");
    }
    closeModal();
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>{data == null ? "Add New List" : "Edit List"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={name}
              place="category name here"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </div>
          {master ? null : (
            <>
              <div className="form-group">
                <label htmlFor="category">
                  Select Category
                </label>
                &nbsp;
                <select className="form-control" name="category" defaultValue={category} onChange={e => setCategory(e.target.value)}>
                  {
                    displayCategories()
                  }
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="privacy">
                  Do you want to make this list private?
                </label>
                &nbsp;
                <input
                  type="checkbox"
                  name="privacy"
                  onChange={(e) => setIsPrivate(e.target.checked)}
                />
              </div>
            </>
          )}
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              {data == null ? "Add" : "Edit"}
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
};

export default LibraryModal;
