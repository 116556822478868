import React from "react";
import { Formik } from "formik";

import { passwordResetSchema } from "./schema";
import AppLabel from "./AppLabel";
import AppInputField from "./AppInputField";

function AppPasswordResetForm({ resetPassword }) {
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={(value) => resetPassword(value)}
        validationSchema={passwordResetSchema}
      >
        {({ handleSubmit }) => {
          return (
            <form className="w-75 mx-auto mt-3" onSubmit={handleSubmit}>
              <div className="form-group">
                <AppLabel>Email</AppLabel>
                <AppInputField
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  classname="form-control bg-light"
                />
              </div>
              <div className="text-center">
                <button className="auth-btn-one mt-3" type="submit">
                  SUBMIT
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default AppPasswordResetForm;
