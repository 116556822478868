import React from "react";
import AppBar from "@mui/material/AppBar";
import styles from "../cssModules/topBar.module.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import SummarizeIcon from "@mui/icons-material/Summarize";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const TopBar = ({ selection }) => {
  return (
    <AppBar
      position="static"
      color="transparent"
      className={styles.top_bar_container}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div role="presentation" className={styles.bread_navigation}>
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  component="a"
                  onClick={() => console.log("home")}
                  label="DashBoard"
                  icon={<HomeIcon fontSize="small" />}
                  className={styles.bread_project_name_one}
                />
                <StyledBreadcrumb
                  component="a"
                  icon={<SummarizeIcon fontSize="small" />}
                  onClick={() => console.log("refresh the page")}
                  label="Release notes"
                  className={styles.bread_project_name_one}
                />
                <StyledBreadcrumb
                  label={`${selection.name}`}
                  className={styles.bread_project_name_active}
                  icon={<CheckCircleIcon fontSize="small" color="#000" />}
                />
              </Breadcrumbs>
            </div>
          </div>
        </div>
      </div>
    </AppBar>
  );
};

export default TopBar;
