import { BASEURL } from "../../API/apiData";

const upvoteProjectAssumption = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}upvotes`, requestOptions);
  const results = await response.json();
  return results;
};

const unvoteProjectAssumption = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}unvote`, requestOptions);
  const results = await response.json();
  return results;
};

const createProjectAssumptionComment = async (data) => {
  const { project_id, project_assumption_id, commentData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_assumptions/${project_assumption_id}/project_assumption_comments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const fetchProjectAssumptionComment = async (data) => {
  const { project_id, project_assumption_id } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_assumptions/${project_assumption_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectAssumptionComment = async (data) => {
  const { project_id, project_assumption_id, id, commentData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_assumptions/${project_assumption_id}/project_assumption_comments/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectAssumptionCommentResponses = async (data) => {
  const {
    project_id,
    project_assumption_id,
    project_assumption_comment_id,
    commentData,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_assumptions/${project_assumption_id}/project_assumption_comments/${project_assumption_comment_id}/project_assumption_comment_replies`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectAssumptionCommentResponses = async (data) => {
  const {
    project_id,
    project_assumption_id,
    project_assumption_comment_id,
    commentData,
    id,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_assumptions/${project_assumption_id}/project_assumption_comments/${project_assumption_comment_id}/project_assumption_comment_replies/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  upvoteProjectAssumption,
  unvoteProjectAssumption,
  createProjectAssumptionComment,
  fetchProjectAssumptionComment,
  editProjectAssumptionComment,
  createProjectAssumptionCommentResponses,
  editProjectAssumptionCommentResponses,
};
