import React, {useState} from 'react'

const CoreValueForm = ({handleSubmit}) => {

  const [coreValue, setCoreValue] = useState('')

  const handleFormSubmit  = (e) => {
    e.preventDefault()
    const profileCoreValue = {
      name: coreValue,
    }
    handleSubmit(profileCoreValue)
    setCoreValue('')
  }

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <div className="d-flex flex-row">
          <input className="form-control" id="corevalue" type="text" name="corevalue" value={coreValue} placeholder="Add new Core value" onChange={e => setCoreValue(e.target.value)}/>
          <button className="add-btn text-white rounded"><span className="add-word">Add New</span><i className="fas fa-plus plus-icon"></i></button>
        </div>
      </form>
    </>
  )
}

export default CoreValueForm
