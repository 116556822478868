import React from "react";

const SuggestionFilterBar = () => {
  return (
    <div className="tt-filter-bar container-fluid">
      <div className="tt-filter-bar-mobile row">
        <div className="col-md-4 col-sm-4 col-4">
          <div className="dropdown">
            <div
              className="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="drop-title">Show:</span>{" "}
              <span className="drop-title-category">All</span>
            </div>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="/">
                Action
              </a>
              <a className="dropdown-item" href="/">
                Another action
              </a>
              <a className="dropdown-item" href="/">
                Something else here
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-4 col-4">
          <div className="dropdown">
            <div
              className="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="drop-title">Sort by:</span>{" "}
              <span className="drop-title-category">Date posted</span>
            </div>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" href="/">
                Action
              </a>
              <a className="dropdown-item" href="/">
                Another action
              </a>
              <a className="dropdown-item" href="/">
                Something else here
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-4 col-4 text-right suggestion-design-change">
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div>
      {/* === Display For Desktop === */}
      <div className="tt-filter-bar-desktop">
        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <a className="nav-link" href="/">
                  All <span className="sr-only">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Latest
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Ratings
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Popular
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Oldest
                </a>
              </li>
            </ul>
            {/* <div className="navbar-text text-right filter-icons">
                          <span>View: </span> <i className="fa fa-bars" aria-hidden="true"></i> <i className="fa fa-bars" aria-hidden="true"></i>
                        </div> */}
          </div>
        </nav>
      </div>

      <hr />
    </div>
  );
}

export default SuggestionFilterBar;
