const bmcMainRoutes = {
    revenue: "revenue_streams",
    cost: "cost_structures",
    channel: "channels",
    resources: "key_resources",
    relationships: "customer_relationships",
    propositions: "value_propostions",
    activities: "key_activities",
    partners: "key_partners",
    segments: "customer_segments"
}

const bmcListsRoutes = {
    revenueItems: "revenue_stream_items",
    costItems: "cost_structure_items",
    channelItems: "channel_items",
    resourcesItems: "key_resource_items",
    relationshipsItems: "customer_relationship_items",
    propositionsItems: "value_propostion_items",
    activitiesItems: "key_activity_item",
    partnersItems: "key_partner_items",
    segmentsItems: "customer_segment_items"
}


export {bmcMainRoutes, bmcListsRoutes}