import React from 'react'
import ReactPaginate from 'react-paginate';

function Paginate({handlePageClick, pageCount}) {
    return (
        <div>
        < ReactPaginate
        previousLabel={'<<'}
        nextLabel={'>>'}
        pageCount={pageCount}
        marginPagesDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName='pagination justify-content-center'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        previousClassName='page-item'
        previousLinkClassName='page-link'
        nextClassName='page-item'
        nextLinkClassName='page-link'
        />
        </div>
    )
}

export default Paginate
