import React, { useState, useEffect } from "react";
import editSuggestionComment from "../../services/suggestions/suggestions";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// react params
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function SuggestionReplyEditForm({
  suggestionReplyID,
  suggestionComment,
  fetchSuggestionComments,
  setCommentEditForm,
}) {
  const [reply, setReply] = useState("");
  const { id } = useParams();

  useEffect(() => {
    setReply(suggestionComment);
  }, [suggestionComment]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const { status } = await editSuggestionComment(
        id,
        suggestionReplyID,
        reply
      );
      if (status === 201) {
        fetchSuggestionComments();
        setCommentEditForm(false);
        return;
      }
    } catch (error) {
      toast.error("something went wrong");
      setCommentEditForm(false);
    }
  };

  return (
    <div>
      <div className="container comment-reply-form">
        <CKEditor
          editor={ClassicEditor}
          data={suggestionComment}
          onChange={(event, editor) => {
            const data = editor.getData();
            setReply(data);
          }}
        />

        <button
          type="submit"
          className="btn btn-success"
          onClick={(e) => handleFormSubmit(e)}
        >
          Edit
        </button>
        <hr/>
      </div>
    </div>
  );
}

export default SuggestionReplyEditForm;
