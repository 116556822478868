import React from "react";
import LandingBtn from "./button";
const Contact = () => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("clicked");
  };

  return (
    <div className="contact-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h3 className="mb-3 mt-5 font-weight-bold">Contact Us</h3>
            <h6 className="mb-4">
              JOIN US in building THE ULTIMATE COLLABORATION (TUC), the world’s
              largest experiment, and help all Good Ideas reach their full
              potential.
            </h6>
            <h6 className="subtitle font-weight-bold ">
              Efficiency and Happiness Guaranteed!
            </h6>
          </div>
        </div>
      </div>
      <form>
        <div className="form-row">
          <div className="form-group col-md-6">
            <input
              type="email"
              className="form-control"
              placeholder="Full Name"
            />
          </div>
          <div className="form-group col-md-6">
            <input
              type="password"
              className="form-control"
              placeholder="Email"
            />
          </div>
        </div>
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Subject" />
        </div>
        <div className="form-group">
          <textarea
            type="text"
            className="form-control"
            placeholder="Message"
          ></textarea>
        </div>
        <div className="text-center">
          <LandingBtn name="Submit" clicked={(e) => handleFormSubmit(e)} />
        </div>
      </form>
    </div>
  );
};

export default Contact;
