import React from 'react'

const ProfileRecents = ({title,children}) => {
  return (
    <div className="profile-recents">
      <div className="profile-recents-header">
        <h3>{title}</h3>
      </div>
      <div>
        {children}
      </div>
    </div>
  )
}

export default ProfileRecents
