import React, { useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';

const EditProfile = ({ isOpen, closeModal, currentUser, updateProfile }) => {
  const [state, setState] = useState({
    role: currentUser.role,
    vision: currentUser.vision,
    mission: currentUser.mission,
    address: currentUser.address,
    contact_no: currentUser.contact_no,
    country: currentUser.country,
    region: currentUser.region
  });

  const [profileAvatar, setProfileAvatar] = useState(null);
  const [privacy, setPrivacy] = useState(currentUser.is_private,)

  const handleFormSubmit = (e) => {
    const profileData = new FormData();
    profileData.append("profile[first_name]", currentUser.first_name);
    profileData.append("profile[last_name]", currentUser.last_name);
    profileData.append("profile[role]", state.role);
    profileData.append("profile[vision]", state.vision);
    profileData.append("profile[mission]", state.mission);
    profileData.append("profile[address]", state.address);
    profileData.append("profile[country]", state.country);
    profileData.append("profile[region]", state.region);
    profileData.append("profile[contact_no]", state.contact_no);
    profileData.append("profile[is_private]", privacy);
    if (profileAvatar !== null) {
      profileData.append("profile[profile_avatar]", profileAvatar);
    }
    updateProfile(currentUser.id, profileData);
    closeModal();
    e.preventDefault();
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  const handleChange = (e) => {
    let values = { ...state };
    values[e.target.name] = e.target.value;
    setState(values);
  };

  return (
    <>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C" }}
          closeButton
        >
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <Fragment>
              <div className="form-group mt-2 mb-2">
                <label htmlFor="profile_avatar">Upload Avatar</label>
                <input
                  type="file"
                  className="form-control border-0"
                  name="profile_avatar"
                  onChange={(event) => setProfileAvatar(event.target.files[0])}
                />
              </div>
              <div className="form-group mt-2 mb-2">
                <label htmlFor="role">Role</label>
                <input
                  className="form-control"
                  type="text"
                  name="role"
                  defaultValue={currentUser.role}
                  onChange={(event) => handleChange(event)}
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label htmlFor="vision">Vision</label>
                <textarea
                  cols="50"
                  className="form-control"
                  type="text"
                  defaultValue={currentUser.vision}
                  name="vision"
                  onChange={(event) => handleChange(event)}
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label htmlFor="mission">Mission</label>
                <textarea
                  cols="50"
                  className="form-control"
                  type="text"
                  name="mission"
                  defaultValue={currentUser.mission}
                  onChange={(event) => handleChange(event)}
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label htmlFor="address">Address</label>
                <textarea
                  cols="50"
                  className="form-control"
                  type="text"
                  name="address"
                  defaultValue={currentUser.address}
                  onChange={(event) => handleChange(event)}
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label htmlFor="contact_no">contact Number</label>
                <input
                  className="form-control"
                  type="number"
                  name="contact_no"
                  defaultValue={currentUser.contact_no}
                  onChange={(event) => handleChange(event)}
                />
              </div>

              <div className="form-group mt-2 mb-2">
                <label htmlFor="contact_no">Country</label>
                <CountryDropdown
                 selected=""
                 className="form-control"
                 value={state.country}
                 onChange={(val) => setState({...state, country: val})}
               />
              </div>

              <div className="form-group mt-2 mb-2">
                <label htmlFor="contact_no">Region</label>
                <RegionDropdown 
                selected=""
                 className="form-control"
                 country={state.country}
                 value={state.region}
                 onChange={(val) => setState({...state, region: val})}
               />
              </div>

              <div className="form-group">
                <label htmlFor="privacy">Do you want to your profile to be private?</label>
                &nbsp;&nbsp;
                <input
                  type="checkbox"
                  name="privacy"
                  defaultChecked={currentUser.is_private}
                  onChange={(e) => setPrivacy(e.target.checked)}
                />
              </div>
            </Fragment>
            <div className="form-group float-right">
              <button className="add-btn rounded text-white" type="submit">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditProfile;
