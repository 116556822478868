/*eslint-disable */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import projectImage from "../assets/projectmain.png";

import ProjectDescription from "../components/singleProject/ProjectDescription";
import ProjectDiscussion from "../components/singleProject/ProjectDiscussion";
import ProjectGallery from "../components/singleProject/ProjectGallery";
import ProjectGoals from "../components/singleProject/ProjectGoals";
import ProjectNav from "../components/singleProject/ProjectNav";
import ProjectStatistics from "../components/singleProject/ProjectStatistics";
import ProjectSummary from "../components/singleProject/ProjectSummary";
import ProjectAssumptions from "../components/singleProject/ProjectAssumptions";
import ProjectNeeds from "../components/singleProject/ProjectNeeds";
import ProjectTop10Main from "../components/singleProject/project-top-ten/ProjectTop10Main";
import ProjectSuggestion from "../components/singleProject/project-suggestion-box/ProjectSuggestion";
import PrivacyWarning from "../components/reusablecomponents/PrivacyWarning";
import "../stylesheets/singleProject.css";
import "../stylesheets/profiles.css";
import "../stylesheets/projectmodal.css";
import "../stylesheets/projects.css";
import "../stylesheets/suggestion.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  createProjectComments,
  createProjectGoals,
  fetchProjectCategory,
  fetchProjectComments,
  fetchSingleProject,
  updateProject,
  createCollaboration,
  createQuickLink,
  fetchProjectsNeeds,
  createProjectNeeds,
  fetchProjectsAssumptions,
  createProjectAssumption,
  createProjectDiscussion,
  fetchProjectDiscussion,
  createProject,
  fetchProjects,
  fetchProjectTopTens,
  fetchProjectSuggestions,
  fetchAllProfile,
  createProjectTopTen,
  createProjectSuggestion,
} from "../redux";
import _ from "lodash";
import Navbar from "../components/navbar/Navbar";
import EditProjectModal from "../components/project/EditProjectModal";
import ProjectCollabModal from "../components/singleProject/ProjectCollabModal";
import ProjectQuickLinkModal from "../components/singleProject/ProjectQuickLinkModal";
import { getProjectSdgs, showProjectSdgs } from "../services/Sdgs/projectSdgs";
import getAllSdgs from "../services/Sdgs/allSdgs";
import SubProject from "../components/singleProject/subProjects/SubProject";
import { getAllSubProjects } from "../services/subProjects/subProjectService";
import SubProjectModal from "../components/singleProject/subProjects/SubProjectModal";
import ProjectSingleForm from "../components/singleProject/ProjectSingleForm";
import SwotContainer from "./SwotContainer";
import BmcContainer from "./BmcContainer";
import {
  getAllSwot,
  createNewSwot,
} from "../services/swotAnalysis/projectSwot";
import { getAllBmc, createNewBmc } from "../services/bmc/projectBmc";
import EditSdgs from "../components/singleProject/sdg/EditSdgs";
import ProjectInviteModal from "../components/singleProject/projectInvite/ProjectInviteModal";
import AppBottomNavigation from "../components/reusablecomponents/AppBottomNavigation";
import AppRightDrawer from "../components/singleProject/MobileDesign/AppRightDrawer";
import SideBar from "../components/singleProject/globals/SideBar";
import { follow, unFollow } from "../services/favorites/favoriteService";
import TopProjectBar from "../components/singleProject/globals/TopProjectBar";
import MenuSpeedDial from "../components/singleProject/globals/MenuSpeedDial";
import useSpeedDialButtons from "../hooks/project/useSpeedDialButtons";
import ProjectGoalsModal from "../components/singleProject/ProjectGoalsModal";
import CreateAssumption from "../components/singleProject/projectAssumptions/CreateAssumption";
import NeedModal from "../components/masterNeeds/NeedModal";
import {
  createMasterNeed,
  fetchMasterNeedsCategories,
} from "../services/masterNeeds/masterNeedsService";
import { fetchProjectNeeds } from "../services/projects/projectsService";
import ProjectTopTenModal from "../components/singleProject/project-top-ten/ProjectTopTenModal";
import CreateDiscussion from "../components/singleProject/projectDiscussion/CreateDiscussion";
import ProjectSuggestionModal from "../components/singleProject/project-suggestion-box/ProjectSuggestionModal";
import ProjectCalender from "../components/singleProject/calender/ProjectCalender";
import { getAllProjectOpenItems } from "../services/openItem/projectOpenItemService";
import ProjectQuickLinks from "../components/singleProject/ProjectQuickLinks";
import AppMobileBottomNavigation from "../components/singleProject/globals/AppMobileBottomNavigation";
import AppSideBarProject from "../components/singleProject/globals/AppSideBarProject";
import ProjectEditOfQuickLinkModal from "../components/singleProject/ProjectEditOfQuickLinkModal";

const SingleProjectContainer = ({
  singleProject,
  fetchSingleProject,
  createProjectGoal,
  fetchProjectComments,
  createProjectComment,
  fetchProjectCategory,
  updateProject,
  createCollaboration,
  createQuickLink,
  fetchProjectsAssumptions,
  createProjectAssumption,
  createProjectDiscussion,
  fetchProjectDiscussion,
  fetchProjects,
  fetchProjectTopTens,
  fetchSuggestions,
  allProfiles,
  createProjectTopTen,
  createSuggestion,
}) => {
  // ==== Include speeddial context ====
  const speeddial = useSpeedDialButtons();
  const { id } = useParams();
  const [tab, setTab] = useState("sum");
  const [sProject, setSProject] = useState({});
  const [goals, setGoals] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [quickLinks, setQuickLinks] = useState([]);
  const [comments, setComments] = useState([]);
  const [categories, setCategories] = useState({});
  const [ShowSubProject, setShowSubProject] = useState(false);
  const [showCollab, setShowCollab] = useState(false);
  const [showQuickLink, setShowQuickLink] = useState(false);
  const [projectNeeds, setProjectNeeds] = useState([]);
  const [editState, setEditState] = useState({
    project_goal: "",
    inputFields: [],
  });
  const [projectAssumption, setProjectAssumption] = useState([]);
  const [projectDiscussion, setProjectDiscussion] = useState([]);
  const [projectSdgs, setProjectSdgs] = useState([]);
  const [allSdgs, setAllSdgs] = useState([]);
  const [subProjects, setSubProjects] = useState([]);
  const [displayProjectformSub, setDisplayProjectformSub] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [navSummation, setNavSummation] = useState({});
  const [top10Count, setTop10Count] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [swotLength, setSwotLength] = useState(0);
  const [bmcLength, setBmcLength] = useState(0);
  const [showEditSdg, setShowEditSdg] = useState(false);
  const [editSdgs, setEditSdgs] = useState([]);
  const [inviteModal, setInviteModal] = useState(false);
  const [allEmailAdresses, setAllEmailAdresses] = useState([]);
  const [projectGoalKpis, setProjectGoalKpis] = useState([]);
  // ==== Right Drawer ====
  const [rightDrawer, setRightDrawer] = useState(false);
  // ==== Project Top Drawer ====
  const [topProjectDrawer, setTopProjectDrawer] = useState(false);
  const [mobileChoice, setMobileChoice] = useState("");
  const [masteNeedCategories, setMasterNeedCategories] = useState([]);
  const [needs, setNeeds] = useState({
    category: null,
    open_needs: [],
    closed_needs: [],
  });
  const [topTens, setTopTens] = useState([]);
  const [filtered10List, setFiltered10List] = useState([]);

  const [currentEvents, setCurrentEvents] = useState([]);
  const [mobileMenu, setMobileMenu] = useState({
    editProject: false,
    projectMenu: false,
  });

  // ==== quick links edit modal ===
  const [quicklinkEdit, setQuicklinkEdit] = useState(false);
  const [editLink, setEditLinkInfo] = useState({});

  // ==== SET COUNT STATE ====
  useEffect(() => {
    setNavSummation({
      goals: goals.length,
      assumptions: projectAssumption.length,
      needs: projectNeeds.length,
      gallery: 0,
      discussion: projectDiscussion.length,
      top10: top10Count.length,
      suggestion_Box: suggestionList.length,
      sub_projects: subProjects.length,
      swot: swotLength,
      bmc: bmcLength,
    });
  }, [
    goals,
    projectAssumption,
    projectNeeds,
    projectDiscussion,
    subProjects,
    top10Count,
    suggestionList,
    swotLength,
    bmcLength,
  ]);

  useEffect(() => {
    fetchSingleProject(id)
      .then((res) => {
        setGoals(res.payload.result.project_goals);
        setCollaborators(res.payload.result.project_collaborators);
        setQuickLinks(res.payload.result.project_quick_links);
        setAllUsers(res.payload.result.users);
        setSProject(res.payload.result.project);
        setProjectGoalKpis(res.payload.result.project_goal_kpis);
      })
      .catch((err) => console.log(err));
    fetchProjectDiscussion(id)
      .then(({ payload }) => {
        setProjectDiscussion(payload.result);
      })
      .catch((err) => {
        NotificationManager.error(
          "Something went wrong kindly check your network connection and refresh"
        );
      });
    fetchProjectCategory()
      .then((res) => {
        setCategories(res.payload.result);
      })
      .catch((err) => console.log("Internal Server Error"));
  }, [fetchSingleProject, id, fetchProjectComments, fetchProjectCategory]);

  // ==== MASTER NEED CATEGORIES ====
  const getCategories = async () => {
    try {
      const data = await fetchMasterNeedsCategories();
      setMasterNeedCategories(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getNeeds = async () => {
    try {
      const data = await fetchProjectNeeds(id);
      setNeeds(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const createNeed = async (data) => {
    try {
      await createMasterNeed(data);
      getNeeds();
      toast.success("Need created successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNeed = (data) => {
    createNeed(data);
  };

  useEffect(() => {
    getNeeds();
    getCategories();
  }, []);

  // ==== CHECK IF THERE ARE ANY TOP10'S ====
  const fetchTop10s = async () => {
    const results = await fetchProjectTopTens(id);
    setTop10Count(results.payload.result);
  };

  // ==== CHECK IF THERE ARE ANY SUGGESTIONS ====
  const projectSuggestions = async () => {
    try {
      const results = await fetchSuggestions(id);
      setSuggestionList(results.payload.result);
    } catch (error) {
      console.log(
        error,
        "Something went wrong fetching all suggestions in the single project container"
      );
    }
  };

  // ==== Create New Swot
  const createSwot = async () => {
    const myresults = await fetchSingleProject(id);
    const { payload } = myresults;
    const { result } = payload;
    const { project } = result;

    const message = await createNewSwot(id, project.name);
    if (message === "Swot created successfuly") {
      setSwotLength(1);
      return;
    }
  };
  // ==== CHECK IF THERE IS SWOT ====

  const projectSwotCheck = async () => {
    const { message } = await getAllSwot(id);
    message === "Swot found successfully" ? setSwotLength(1) : createSwot();
    return;
  };

  // ==== Create New BMC

  const createNewProjectBmc = async (bmcDescription = "") => {
    const myresults = await fetchSingleProject(id);
    const { payload } = myresults;
    const { result } = payload;
    const { project } = result;

    try {
      const { message } = await createNewBmc(id, project.name, bmcDescription);
      if (message === "BCM created successfuly") {
        setBmcLength(1);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ==== CHECK IF THERE IS BMC ====

  const projectBmcCheck = async () => {
    const { message } = await getAllBmc(id);
    message === "BCM found successfully"
      ? setBmcLength(1)
      : createNewProjectBmc();
    return;
  };

  useEffect(() => {
    fetchTop10s();
    projectSuggestions();
    projectSwotCheck();
    projectBmcCheck();
  }, []);

  // ===== FETCH PROJECT NEEDS END =====

  // ==== FETCH PROJECT ASSUMPTIONS =====
  const fetchingProjectAssumptions = async () => {
    try {
      const { payload } = await fetchProjectsAssumptions(id);
      setProjectAssumption(payload.result);
    } catch (error) {
      // do proper error handling
      NotificationManager.error(
        "Something went wrong kindly check your network connection and refresh"
      );
    }
  };

  useEffect(() => {
    fetchingProjectAssumptions();
  }, []);

  // ==== FETCH PROJECT ASSUMPTIONS END =====

  // ==== FETCH ALL PROJECTS =====
  const fetchAllProjects = async () => {
    const { payload } = await fetchProjects();
    if (payload.message !== "Projects fetched successfully")
      return NotificationManager.error(
        "Something went wrong kinldy refresh your browser"
      );

    setAllProjects(payload.result);
  };

  useEffect(() => {
    fetchAllProjects();
  }, []);

  // ===== FETCH ALL PROJECTS ====

  // ==== FETCH PROJECT SDGS =====
  const fetchProjectSdgs = async () => {
    const { result } = await getProjectSdgs(id);

    if (!result.project_sdg)
      return NotificationManager.error(
        "Something went wrong kindly check your network connection and refresh"
      );

    setProjectSdgs(result.project_sdg);
  };

  useEffect(() => {
    fetchProjectSdgs();
  }, []);

  // ==== FETCH PROJECT SDGS  END=====

  const showCollabModal = () => {
    setShowCollab(!showCollab);
  };

  const showQuickLinkModal = () => {
    setShowQuickLink(!showQuickLink);
  };

  const showEditModal = () => {
    setShowEdit(!showEdit);
  };

  // ==== Edit Sdg Project

  // ==== CLOSE MODAL SUBPROJECT
  const closeSubProject = () => {
    setShowSubProject(false);
  };

  // ==== ALL SDGS ======
  const fetchAllSdgs = async () => {
    const { result } = await getAllSdgs();
    if (result) return setAllSdgs(result);
  };

  useEffect(() => {
    fetchAllSdgs();
  }, []);

  // ==== END OF SDGS ===

  // ==== Fetch all subProjects ===
  const fetchAllSubProjects = async (projectID) => {
    const { result } = await getAllSubProjects(projectID);
    setSubProjects(result);
  };

  useEffect(() => {
    fetchAllSubProjects(id);
  }, [id]);

  // ==== Fetch all subProjects End ===

  // ==== FETCH ALL PROJECTS OPEN ITEMS ====
  const fetchAllProjectOpenItems = async () => {
    try {
      const data = await getAllProjectOpenItems(id);
      if (data.message === "Project open items fetched successfully") {
        setCurrentEvents([...data.result]);
      }
    } catch (error) {
      NotificationManager.error(
        "Something went wrong kindly check your internet connection and refresh"
      );
    }
  };

  useEffect(() => {
    fetchAllProjectOpenItems();
  }, []);

  const handleCollaborators = (data) => {
    createCollaboration(id, data)
      .then((res) => {
        if (res.payload.message == "This user is already a collaborator") {
          toast.warn(res.payload.message);
        } else {
          fetchSingleProject(id)
            .then((res) => {
              setCollaborators(res.payload.result.project_collaborators);
            })
            .catch((err) => console.log("Internal Server Error"));
        }
      })
      .catch((error) => {
        console.log("Internal Server error");
      });
  };

  const handleQuickLinks = (data) => {
    createQuickLink(id, data)
      .then((res) => {
        fetchSingleProject(id)
          .then((res) => {
            setQuickLinks(res.payload.result.project_quick_links);
          })
          .catch((err) => console.log("Internal Server Error"));
      })
      .catch((error) => {
        console.log("Internal Server error");
      });
  };

  const handleGoal = (goal) => {
    createProjectGoal(id, goal).then((res) => {
      fetchSingleProject(id).then((res) => {
        setGoals(res.payload.result.project_goals);
      });
    });
  };

  const handleComments = (comment) => {
    createProjectComment(id, comment).then((res) => {
      fetchProjectComments(id).then((res) => {
        setComments(res.payload.result);
      });
    });
  };

  const handleUpdate = (formData) => {
    updateProject(id, formData);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const handleSDG = async (e) => {
    e.preventDefault();
    const { message, result } = await showProjectSdgs(id);
    if (message === "Sdgs fetched sucessfully") {
      setShowEditSdg(true);
      setEditSdgs(result);
      return;
    }

    //  handle error handling well
  };

  // ==== Close  quick links edit modal ====
  const handleCloseQuickLinkEdit = () => {
    setQuicklinkEdit(false);
    setEditLinkInfo({});
  };

  const setEditLink = (link) => {
    setEditLinkInfo({ ...link });
  };

  // ==== Invitation modal control ====

  const invitationModalControl = async () => {
    try {
      const { type, payload } = await allProfiles();
      type === "FETCH_ALL_PROFILES_SUCCESS"
        ? setAllEmailAdresses(payload)
        : NotificationManager.error(
            "Something went wrong kindly check your network connection and refresh"
          );
      setInviteModal(true);
    } catch (error) {
      NotificationManager.error(
        "Something went wrong kindly check your network connection and refresh"
      );
    }
  };

  // <==== HANDLE FOLLOW ACTION ====>
  const handleFollowAction = () => {
    follow(id)
      .then(() => {
        fetchSingleProject(id)
          .then((response) => {
            setSProject(response.payload.result.project);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ==== HANDLE UNFOLLOW ACTION ====
  const handleUnfollowAction = async () => {
    unFollow(id)
      .then(() => {
        fetchSingleProject(id)
          .then((response) => {
            setSProject(response.payload.result.project);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showTabSelection = () => {
    if (!_.isEmpty(singleProject.data)) {
      const res = singleProject.data.result;
      switch (tab) {
        case "sum":
          return (
            <ProjectSummary
              project={res.project}
              tags={res.tags}
              creator={res.created_by}
              users={collaborators}
              date={res.project.created_at}
              links={quickLinks}
              showCollabModal={showCollabModal}
              showQuickLinkModal={showQuickLinkModal}
              projectSdgs={projectSdgs}
              handleSDG={handleSDG}
            />
          );
        case "quick":
          return (
            <ProjectQuickLinks
              id={id}
              fetchSingleProject={fetchSingleProject}
              links={quickLinks}
              showModal={showQuickLinkModal}
              setQuicklinkEdit={setQuicklinkEdit}
              setEditLink={setEditLink}
            />
          );
        case "goa":
          return (
            <ProjectGoals
              id={id}
              goals={goals}
              handleGoalSubmit={handleGoal}
              editState={editState}
              setEditState={setEditState}
              fetchSingleProject={fetchSingleProject}
              setGoals={setGoals}
              project={res.project}
              projectGoalKpis={projectGoalKpis}
              setProjectGoalKpis={setProjectGoalKpis}
              speedDialState={speeddial}
            />
          );
        case "ass":
          return (
            <ProjectAssumptions
              projectAssumption={projectAssumption}
              setProjectAssumption={setProjectAssumption}
              projectID={id}
              createProjectAssumption={createProjectAssumption}
              fetchProjectsAssumptions={fetchProjectsAssumptions}
              project={res.project}
              allProfiles={allProfiles}
              speedDialState={speeddial}
            />
          );
        case "nee":
          return (
            <ProjectNeeds
              id={id}
              project={res.project}
              speedDialState={speeddial}
            />
          );
        case "gal":
          return <ProjectGallery project={res.project} />;
        case "dis":
          return (
            <ProjectDiscussion
              projectDiscussion={
                projectDiscussion === null ? [] : projectDiscussion
              }
              projectID={id}
              createProjectDiscussion={createProjectDiscussion}
              setProjectDiscussion={setProjectDiscussion}
              singleProject={singleProject}
              fetchProjectDiscussion={fetchProjectDiscussion}
              project={res.project}
              allProfiles={allProfiles}
              speedDialState={speeddial}
            />
          );
        case "top":
          return (
            <ProjectTop10Main
              id={id}
              project={res.project}
              topTens={topTens}
              setTopTens={setTopTens}
              filteredList={filtered10List}
              setFilteredList={setFiltered10List}
              speedDialState={speeddial}
            />
          );
        case "sug":
          return (
            <ProjectSuggestion
              id={id}
              suggestions={suggestions}
              setSuggestions={setSuggestions}
              project={res.project}
              allProfiles={allProfiles}
              speedDialState={speeddial}
            />
          );
        case "sub":
          return (
            <SubProject
              subProjects={subProjects}
              setShowSubProject={setShowSubProject}
              setDisplayProjectformSub={setDisplayProjectformSub}
              categories={categories}
              sdgs={allSdgs}
              fetchAllSubProjects={fetchAllSubProjects}
              project={res.project}
              followAction={handleFollowAction}
              unfollowAction={handleUnfollowAction}
              speedDialState={speeddial}
            />
          );
        case "swo":
          return (
            <SwotContainer
              projectID={id}
              singleProject={singleProject}
              setSwotLength={setSwotLength}
              project={res.project}
            />
          );
        case "bmc":
          return (
            <BmcContainer
              projectID={id}
              singleProject={singleProject}
              setBmcLength={setBmcLength}
              project={res.project}
            />
          );

        case "cal":
          return (
            <ProjectCalender
              allProfiles={allProfiles}
              currentEvents={currentEvents}
              setCurrentEvents={setCurrentEvents}
              fetchAllProjectOpenItems={fetchAllProjectOpenItems}
            />
          );
        default:
          return (
            <ProjectSummary
              project={res.project}
              creator={res.created_by}
              users={collaborators}
              date={res.project.created_at}
              links={quickLinks}
              showCollabModal={showCollabModal}
              showQuickLinkModal={showQuickLinkModal}
              projectSdgs={projectSdgs}
              handleSDG={handleSDG}
            />
          );
      }
    }
  };

  // <==== THIS IS FOR CLOSING THE EDIT MODAL ====>

  const closeEditModal = () => {
    speeddial.toggleProjectSpeedDialMenu("editproject", false);
  };

  const renderEditModal = () => {
    if (!_.isEmpty(singleProject.data)) {
      const res = singleProject.data.result;
      return (
        <>
          {speeddial.buttons.editProject ? (
            <EditProjectModal
              categories={categories}
              project={res.project}
              proTags={res.tags}
              handleSubmit={handleUpdate}
              closeModal={closeEditModal}
              sdgs={projectSdgs}
              allSdgs={allSdgs}
            />
          ) : null}
        </>
      );
    }
  };

  const renderCollabModal = () => {
    if (!_.isEmpty(singleProject.data)) {
      const res = singleProject.data.result;
      return (
        <ProjectCollabModal
          isOpen={showCollab}
          closeModal={showCollabModal}
          users={allUsers}
          handleSubmit={handleCollaborators}
        />
      );
    }
  };

  const renderQuickLinkModal = () => {
    if (!_.isEmpty(singleProject.data)) {
      return (
        <>
          <ProjectQuickLinkModal
            isOpen={showQuickLink}
            closeModal={showQuickLinkModal}
            handleSubmit={handleQuickLinks}
          />
        </>
      );
    }
  };

  const showLink = (res) => {
    if (res.top_ten_entry.entry == null && res.suggestion == null) {
      return null;
    } else if (res.top_ten_entry.entry !== null && res.suggestion === null) {
      return (
        <>
          <i className="fa fa-link" aria-hidden="true"></i>&nbsp;&nbsp;
          <span>Link</span>&nbsp;&nbsp;
          <i className="fas fa-chevron-right"></i>&nbsp;&nbsp;
          <Link
            to={`/categories/${res.top_ten_entry.category}/topten/${res.top_ten_entry.entry.top_ten_id}/entries`}
          >
            <span className="text-primary">
              (Top10 Entry) {res.top_ten_entry.entry.properties["Name"]}
            </span>
          </Link>
        </>
      );
    } else if (res.top_ten_entry.entry === null && res.suggestion !== null) {
      return (
        <Link to={`/suggestion`}>
          <span className="text-primary">
            (Suggestion) {res.suggestion.idea}
          </span>
        </Link>
      );
    }
  };

  const showProject = (res) => {
    return (
      <>
        <div className="main-body-area-single-project">
          <SideBar singleProject={singleProject} setTab={setTab} tab={tab} />
          <div className="project-static-area-space">
            <TopProjectBar
              singleProject={singleProject}
              showTabSelection={showTabSelection}
              tab={tab}
              users={singleProject.data.result.project_collaborators}
              showCollabModal={showCollabModal}
              handleFollowAction={handleFollowAction}
              handleUnfollowAction={handleUnfollowAction}
              speedDialState={speeddial}
            />
            {/* This is the plus button <===== We shall add it back please dont delete this line of code =====>*/}
            {/* <MenuSpeedDial speedDialState={speeddial} /> */}
          </div>
        </div>
        {/* <==== Mobile Design Point ====> */}
        <div className="main-mobile-body-single-project-area">
          <div className="project-static-area-space">
            <TopProjectBar
              singleProject={singleProject}
              showTabSelection={showTabSelection}
              tab={tab}
              users={singleProject.data.result.project_collaborators}
              showCollabModal={showCollabModal}
              handleFollowAction={handleFollowAction}
              handleUnfollowAction={handleUnfollowAction}
              speedDialState={speeddial}
            />
          </div>
          <AppSideBarProject
            mobileMenu={mobileMenu}
            setTab={setTab}
            tab={tab}
            setMobileMenu={setMobileMenu}
          />
          <AppMobileBottomNavigation
            mobileMenu={mobileMenu}
            setMobileMenu={setMobileMenu}
            speedDialState={speeddial}
          />
        </div>
      </>
    );
  };

  const showData = () => {
    if (!_.isEmpty(singleProject.data)) {
      const res = singleProject.data.result;
      const isPrivate = res.project.is_private;
      if (
        isPrivate &&
        res.project.user_id !== res.current_user &&
        !res.collaborator_ids.includes(res.current_user)
      ) {
        return <PrivacyWarning title="Project" />;
      } else {
        return showProject(res);
      }
    }
  };

  // ==== CREATE NEW TOP10 ====
  const handleCreatTop10 = (title, description, fields_attributes) => {
    const data = {
      title: title,
      description: description,
      project_fields_attributes: fields_attributes,
    };
    createProjectTopTen(id, data)
      .then((res) => {
        toast.success("Project Top10 created successfully");
        fetchProjectTopTens(id)
          .then((res) => {
            setTopTens(res.payload.result);
            setFiltered10List(res.payload.result);
          })
          .catch((error) => console.log("Internal Server Error"));
      })
      .catch((error) => console.log("Internal Server Error"));
  };

  // ==== CREATE SUGGESTION ====
  const handleCreateSuggestion = (data) => {
    createSuggestion(id, data)
      .then((res) => {
        toast.success(res.payload.message);
        fetchSuggestions(id)
          .then((res) => setSuggestions(res.payload.result))
          .catch((e) => console.log("Internal Server Error"));
      })
      .catch((e) => console.log("Internal Server Error"));
  };

  const displayProjectFormClose = () => {
    setDisplayProjectformSub(false);
  };

  // ==== THIS IS FOR CLOSING MODAL ====
  const closeGoalModal = () => {
    speeddial.toggleProjectSpeedDialMenu("goal", false);
  };
  // ==== THIS IS FOR CREATING ASSUMPTIONS MODAL ====
  const closeAssumptionModal = () => {
    speeddial.toggleProjectSpeedDialMenu("assumptions", false);
  };

  // ==== THIS IS FOR CLOSING NEEDS MODAL ====
  const closeNeedsModal = () => {
    speeddial.toggleProjectSpeedDialMenu("need", false);
  };

  // ==== THIS IS FOR CLOSING TOP10 MODAL ====
  const closeTop10Modal = () => {
    speeddial.toggleProjectSpeedDialMenu("top10", false);
  };

  // ==== THIS IS FOR CLOSING THE DISCUSSION MODAL ====
  const closeDiscussionModal = () => {
    speeddial.toggleProjectSpeedDialMenu("discussion", false);
  };

  // ==== THIS IS FOR CLOSING THE SUGGESTION BOX MODAL ====
  const closeSuggestionBoxModal = () => {
    speeddial.toggleProjectSpeedDialMenu("suggestion", false);
  };

  // ==== THIS IS FOR CLOSING THE SUBPROJECT MODAL ====
  const closeSubprojectModal = () => {
    speeddial.toggleProjectSpeedDialMenu("subproject", false);
  };

  return (
    <>
      <AppRightDrawer
        rightDrawer={rightDrawer}
        setRightDrawer={setRightDrawer}
        setTopProjectDrawer={setTopProjectDrawer}
        setMobileChoice={setMobileChoice}
      />
      <NotificationContainer />
      <EditSdgs
        showEditSdg={showEditSdg}
        setShowEditSdg={setShowEditSdg}
        editSdgs={editSdgs}
        projectID={id}
        setEditSdgs={setEditSdgs}
        setProjectSdgs={setProjectSdgs}
      />
      <ProjectInviteModal
        inviteModal={inviteModal}
        setInviteModal={setInviteModal}
        allEmailAdresses={allEmailAdresses}
        singleProject={singleProject}
      />
      {/* ==== THIS IS FOR CREATING NEW GOAL ==== */}
      <ProjectGoalsModal
        closeModal={closeGoalModal}
        id={id}
        handleSubmit={handleGoal}
        speedDialState={speeddial}
      />
      {/* ==== THIS IS FOR CREATING NEW ASSUMPTION ==== */}
      <CreateAssumption
        projectID={id}
        createProjectAssumption={createProjectAssumption}
        fetchProjectsAssumptions={fetchProjectsAssumptions}
        setProjectAssumption={setProjectAssumption}
        projectAssumption={projectAssumption}
        isOpen={speeddial.buttons.newAssumption}
        closeModal={closeAssumptionModal}
      />

      {/* ==== THIS IS FOR CREATING NEW NEED ==== */}
      <NeedModal
        categories={masteNeedCategories}
        category={null}
        isOpen={speeddial.buttons.newNeed}
        closeModal={closeNeedsModal}
        handleSubmit={handleCreateNeed}
        proId={id}
      />

      {/* ==== CREATE NEW TOP 10 ==== */}
      <ProjectTopTenModal
        closeModal={closeTop10Modal}
        isOpen={speeddial.buttons.newTop10}
        handleSubmit={handleCreatTop10}
      />

      {/* ==== CREATE NEW DISCUSSION ==== */}
      <CreateDiscussion
        isOpen={speeddial.buttons.newDiscussion}
        closeModal={closeDiscussionModal}
        projectID={id}
        createProjectDiscussion={createProjectDiscussion}
        setProjectDiscussion={setProjectDiscussion}
        projectDiscussion={projectDiscussion}
      />

      {/* ==== CREATE NEW SUGGESTION BOX ==== */}
      <ProjectSuggestionModal
        closeModal={closeSuggestionBoxModal}
        isOpen={speeddial.buttons.newSuggestion}
        handleSubmit={handleCreateSuggestion}
      />

      {/* ==== Edit Project Modal ==== */}
      {renderEditModal()}
      {/*==== THIS RENDERS COLLABORATION MODAL ==== */}
      {renderCollabModal()}
      {/*==== THIS RENDERS QUICK LINK MODAL ==== */}
      {renderQuickLinkModal()}
      {/* ==== Edit of quick links ====  */}
      <ProjectEditOfQuickLinkModal
        onClose={handleCloseQuickLinkEdit}
        isOpen={quicklinkEdit}
        editLink={editLink}
        projectId={id}
        setQuickLinks={setQuickLinks}
      />
      <ToastContainer />
      <Navbar />
      <Modal
        show={displayProjectformSub}
        onHide={displayProjectFormClose}
        size="xl"
      >
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C", marginBottom: "50px" }}
          closeButton
        >
          <Modal.Title>Create new project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProjectSingleForm
            categories={categories}
            sdgs={allSdgs}
            closeModal={displayProjectFormClose}
            createProject={createProject}
            setSubProjects={setSubProjects}
          />
        </Modal.Body>
      </Modal>

      {/* ==== SUB PROJECT MODAL ==== */}
      <SubProjectModal
        ShowSubProject={ShowSubProject}
        closeSubProject={closeSubProject}
        allProjects={allProjects}
        singleProjectID={id}
        setSubProjects={setSubProjects}
        setShowSubProject={setShowSubProject}
        isOpen={speeddial.buttons.newSubProject}
        closeModal={closeSubprojectModal}
      />

      {showData()}
    </>
  );
};

const mapStateToProps = (state) => ({
  singleProject: state.singleProject,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(fetchProjects()),
  fetchSingleProject: (id) => dispatch(fetchSingleProject(id)),
  createProjectGoal: (projectID, data) =>
    dispatch(createProjectGoals(projectID, data)),
  createProjectComment: (id, data) => dispatch(createProjectComments(id, data)),
  fetchProjectComments: (id) => dispatch(fetchProjectComments(id)),
  fetchProjectCategory: () => dispatch(fetchProjectCategory()),
  updateProject: (id, data) => dispatch(updateProject(id, data)),
  createCollaboration: (id, data) => dispatch(createCollaboration(id, data)),
  createQuickLink: (id, data) => dispatch(createQuickLink(id, data)),
  fetchProjectsNeeds: (projectID) => dispatch(fetchProjectsNeeds(projectID)),
  createProjectNeeds: (projectID, data) =>
    dispatch(createProjectNeeds(projectID, data)),
  fetchProjectsAssumptions: (projectID) =>
    dispatch(fetchProjectsAssumptions(projectID)),
  createProjectAssumption: (projectID, data) =>
    dispatch(createProjectAssumption(projectID, data)),
  createProjectDiscussion: (projectID, data) =>
    dispatch(createProjectDiscussion(projectID, data)),
  fetchProjectDiscussion: (projectID) =>
    dispatch(fetchProjectDiscussion(projectID)),
  fetchProjectTopTens: (id) => dispatch(fetchProjectTopTens(id)),
  fetchSuggestions: (id) => dispatch(fetchProjectSuggestions(id)),
  createSuggestion: (id, data) => dispatch(createProjectSuggestion(id, data)),
  allProfiles: () => dispatch(fetchAllProfile()),
  createProjectTopTen: (id, data) => dispatch(createProjectTopTen(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleProjectContainer);

/*eslint-enable */
