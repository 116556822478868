import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_SUGGESTION_CATEGORIES_SUCCESS, FETCH_SUGGESTION_CATEGORIES_REQUEST, FETCH_SUGGESTION_CATEGORIES_FAILURE} from './suggestionCategoryTypes'

export const fetchSuggestionCategoriesRequest = () =>({
  type: FETCH_SUGGESTION_CATEGORIES_REQUEST,
})

export const fetchSuggestionCategoriesSuccess = (response) =>({
  type: FETCH_SUGGESTION_CATEGORIES_SUCCESS,
  payload: response
})

export const fetchSuggestionCategoriesFailure = (error) =>({
  type: FETCH_SUGGESTION_CATEGORIES_FAILURE,
  payload: error
})

export const fetchSuggestionCategories = () => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchSuggestionCategoriesRequest());
  return axios.get(`${BASEURL}suggestion_categories`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchSuggestionCategoriesSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchSuggestionCategoriesFailure(error));
    });
}