import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_PROJECTTOPTENFIELDS_SUCCESS, FETCH_PROJECTTOPTENFIELDS_REQUEST, FETCH_PROJECTTOPTENFIELDS_FAILURE} from './projectTopTenFieldTypes'

export const fetchProjectTopTenFieldsRequest = () =>({
  type: FETCH_PROJECTTOPTENFIELDS_REQUEST,
})

export const fetchProjectTopTenFieldsSuccess = (response) =>({
  type: FETCH_PROJECTTOPTENFIELDS_SUCCESS,
  payload: response
})

export const fetchProjectTopTenFieldsFailure = (error) =>({
  type: FETCH_PROJECTTOPTENFIELDS_FAILURE,
  payload: error
})

export const fetchProjectTopTenFields = (pId,pttId) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchProjectTopTenFieldsRequest());
  return axios.get(`${BASEURL}projects/${pId}/project_top_tens/${pttId}/project_top_ten_fields`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchProjectTopTenFieldsSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchProjectTopTenFieldsFailure(error));
    });
};