import { BASEURL } from "../../API/apiData";

const createSuggestionCommentReply = async (suggestion_id, suggestion_response_id, commentData) => {
    const data = {
      suggestion_comment_replies: {
        comment: commentData
        }
    };
    const token = localStorage.getItem("token");
  
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };
  
    const response = await fetch(
      `${BASEURL}suggestions/${suggestion_id}/suggestion_responses/${suggestion_response_id}/suggestion_comment_replies`,
      requestOptions
    );
    const results = await response.json();
    return results;
  };


const editSuggestionCommentReply = async (suggestion_id, suggestion_response_id, id, commentData) => {
  const data = {
    suggestion_comment_replies: {
      comment: commentData
      }
  };
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}suggestions/${suggestion_id}/suggestion_responses/${suggestion_response_id}/suggestion_comment_replies/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};


  export {createSuggestionCommentReply, editSuggestionCommentReply}