import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "./AppSideBarProject.module.css";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import CollectionsIcon from "@mui/icons-material/Collections";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ForumIcon from "@mui/icons-material/Forum";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LinkIcon from "@mui/icons-material/Link";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const menuData = [
  {
    name: "Summary",
    icon: <SummarizeIcon />,
    tab: "sum",
  },
  {
    name: "Quick Links",
    icon: <LinkIcon />,
    tab: "quick",
  },
  {
    name: "Goals",
    icon: <FlagCircleIcon />,
    tab: "goa",
  },
  {
    name: "Assumptions",
    icon: <TipsAndUpdatesIcon />,
    tab: "ass",
  },
  {
    name: "Inventory",
    icon: <VolunteerActivismIcon />,
    tab: "nee",
  },
  {
    name: "Gallery",
    icon: <CollectionsIcon />,
    tab: "gal",
  },
  {
    name: "Top10",
    icon: <FormatListNumberedIcon />,
    tab: "top",
  },
  {
    name: "Discussion",
    icon: <ForumIcon />,
    tab: "dis",
  },
  {
    name: "Suggestion Box",
    icon: <IntegrationInstructionsIcon />,
    tab: "sug",
  },
  {
    name: "Sub Projects",
    icon: <AccountTreeIcon />,
    tab: "sub",
  },
  {
    name: "SWOT Analysis",
    icon: <AssessmentIcon />,
    tab: "swo",
  },
  {
    name: "BMC",
    icon: <LeaderboardIcon />,
    tab: "bmc",
  },
  {
    name: "Calender",
    icon: <CalendarMonthIcon />,
    tab: "cal",
  },
];

const AppSideBarProject = ({ mobileMenu, tab, setTab, setMobileMenu }) => {
  const { projectMenu } = mobileMenu;

  const [state, setState] = useState({
    right: projectMenu,
  });

  useEffect(() => {
    setState({
      right: projectMenu,
    });
  }, [projectMenu]);

  const displayMenuList = () => {
    const result = menuData.map((value) => {
      return (
        <ListItem
          key={value.name}
          disablePadding
          onClick={() => setTab(value.tab)}
          className={tab === value.tab ? styles.active_tab : styles.menu_item}
        >
          <ListItemButton>
            <ListItemIcon>{value.icon}</ListItemIcon>
            <ListItemText primary={value.name} />
          </ListItemButton>
        </ListItem>
      );
    });

    return result;
  };

  const closeSideBar = () => {
    setMobileMenu({
      editProject: false,
      projectMenu: false,
    });
  };
  const openSideBar = () => {};

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={closeSideBar}
    >
      <div className={styles.menu_top}>
        <div className={styles.close_menu_button}>
          <ArrowCircleLeftIcon fontSize="large" color="secondary" />
        </div>
        <div className={styles.menu_title}>
          <h5>Close Menu</h5>
        </div>
      </div>
      <List>{displayMenuList()}</List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={closeSideBar}
          onOpen={openSideBar}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};

export default AppSideBarProject;
