import React from "react";
import complete from "../../assets/comprog.png";
import progress from "../../assets/inprog.png";
import nostart from "../../assets/nsprog.png";

const StatisticsCard = ({ heading, status, bgc }) => {
  const showProgress = (status) => {
    switch (status) {
      case "Not Started":
        return <img src={nostart} alt={status} />;
      case "In Progress":
        return <img src={progress} alt={status} />;
      case "Completed":
        return <img src={complete} alt={status} />;
      default:
        return <img src={nostart} alt={status} />;
    }
  };

  return (
    <div className={`statistics-card ${bgc} shadow`}>
      <h5>{heading}</h5>
      <br />
      <h6>Task Progress</h6>
      <span>{status}</span>
      <br />
      {showProgress(status)}
    </div>
  );
};

export default StatisticsCard;
