import React,{ useState }from 'react'
import ProjectCard from './ProjectCard'
import ProjectModal from '../project/ProjectModal';
import nocores from '../../assets/nocorevalues.png'

const ProfileProjects = ({projects, privacy, handleCreateProject,projectCategories}) => {

  const [openProjectMoal, setOpenProjectModal] = useState(false);

   const showProjectModal = () => {
     setOpenProjectModal(!openProjectMoal);
   };

  return (
    <>
      {openProjectMoal ? (
        <ProjectModal
          sugId={""}
          ttId={""}
          categories={projectCategories}
          closeModal={showProjectModal}
          createProject={handleCreateProject}
        />
      ) : null}
      <div className="profile-core-values">
        <div className="project-sb-header">
          <h2 className="m-3">Projects ({projects.length})</h2>
          {privacy ? (
            <button type="button" onClick={showProjectModal}>
              Create Project
            </button>
          ) : null}
        </div>
      </div>
      <div>
        <div className="profile-project-container">
          {projects.length === 0 ? (
            <div className="w-100 text-center">
              <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
              <h4 className="text-success">No projects available</h4>
            </div>
          ) : (
            projects.map((project) => (
              <ProjectCard key={project.id} project={project} />
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileProjects