import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

export const fetchLibraryCategories = async () => {
  try {
    const result = await axios.get(
      `${BASEURL}tuc_inventory_categories`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const createLibraryCategory = async (data) => {
  try {
    const result = await axios.post(
      `${BASEURL}tuc_inventory_categories`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchMasterInventory = async (id) => {
  try {
    const result = await axios.get(
      `${BASEURL}tuc_inventory_categories/${id}`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const createMasterInventory = async (data) => {
  try {
    const result = await axios.post(
      `${BASEURL}tuc_inventories`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateMasterInventory = async (id,data) => {
  try {
    const result = await axios.put(
      `${BASEURL}tuc_inventories/${id}`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const createMasterInventoryItem = async (invId,data) => {
  try {
    const result = await axios.post(
      `${BASEURL}tuc_inventories/${invId}/tuc_inventory_items`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchInventoryItems = async (id) => {
  try {
    const result = await axios.get(
      `${BASEURL}tuc_inventories/${id}/tuc_inventory_items`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateInventoryItem = async (invId,itemId, data) => {
  try {
    const result = await axios.put(
      `${BASEURL}tuc_inventories/${invId}/tuc_inventory_items/${itemId}`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};