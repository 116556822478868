import axios from "axios";
import { BASEURL } from "../../API/apiData";
import {
  CREATE_PROJECTASSUMPTIONS_REQUEST,
  CREATE_PROJECTASSUMPTIONS_SUCCESS,
  CREATE_PROJECTASSUMPTIONS_FAILURE,
  FETCH_PROJECTASSUMPTIONS_REQUEST,
  FETCH_PROJECTASSUMPTIONS_SUCCESS,
  FETCH_PROJECTASSUMPTIONS_FAILURE
} from "./ProjectAssumptionsTypes";

// ==== Fetch Project Assumptions

export const fetchProjectAssumptionsRequest = () => ({
  type: FETCH_PROJECTASSUMPTIONS_REQUEST,
});

export const fetchProjectAssumptionsSuccess = (response) => ({
  type: FETCH_PROJECTASSUMPTIONS_SUCCESS,
  payload: response,
});

export const fetchProjectAssumptionsFailure = (error) => ({
  type: FETCH_PROJECTASSUMPTIONS_FAILURE,
  payload: error,
});

//   ==== Create Project Assumptions

export const createProjectAssumptionsRequest = () => ({
  type: CREATE_PROJECTASSUMPTIONS_REQUEST,
});

export const createProjectAssumptionsSuccess = (response) => ({
  type: CREATE_PROJECTASSUMPTIONS_SUCCESS,
  payload: response,
});

export const createProjectAssumptionsFailure = (error) => ({
  type: CREATE_PROJECTASSUMPTIONS_FAILURE,
  payload: error,
});

// ==== Create Project Assumptions ====

export const createProjectAssumption = (projectID, data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(createProjectAssumptionsRequest());
  return axios
    .post(`${BASEURL}projects/${projectID}/project_assumptions`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(createProjectAssumptionsSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(createProjectAssumptionsFailure(error));
    });
};

// ==== Fetch Project Assumptions ====

export const fetchProjectsAssumptions = (projectID) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchProjectAssumptionsRequest());
  return axios
    .get(`${BASEURL}projects/${projectID}/project_assumptions`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchProjectAssumptionsSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchProjectAssumptionsFailure(error));
    });
};
