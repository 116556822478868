import { CREATE_PROJECTNEEDS_FAILURE, CREATE_PROJECTNEEDS_SUCCESS, CREATE_PROJECTNEEDS_REQUEST } from "./projectNeedsTypes";


const initialState = {
  loading: true,
  data: [],
  errorMsg: '',
};

const projectNeedsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROJECTNEEDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PROJECTNEEDS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: '',
      };
    case CREATE_PROJECTNEEDS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default projectNeedsReducer