import React from "react";

function AppAuthScreen() {
  return (
    <div className="col-md-5 register-screen">
      <div className="tuc-logo-signup">Tuc</div>
      <div className="statement-register">
        <h1>
          " The world’s largest experiment,help all Good Ideas reach their full
          potential. "
        </h1>
      </div>
    </div>
  );
}

export default AppAuthScreen;
