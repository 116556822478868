import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal';
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function CreateNewBmcModal({handleClose, show, projectName, createNewProjectBmc, setBmcLength}) {
  const [formData, setFormData] = useState({
    title: `${projectName} business model canvas`,
    description: ""
  })

  const saveBmcData = (e) => {
    e.preventDefault()
     createNewProjectBmc(formData.description)
     setBmcLength(1)
     handleClose()
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create new Business Model Canvas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
              <div className="form-group">
                <label>Title</label>
                <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formData.title}
              onChange={(event, editor) => {
                const data = editor.getData();
               setFormData({...formData, title: data});
              }}
              required
            />
              </div>
              <div className="form-group">
                <label>Description</label>
                <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formData.description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormData({
                  ...formData, description: data
                })
              }}
              required
            />
              </div>
          </form>
          </Modal.Body>
        <Modal.Footer>
          <button variant="secondary" onClick={handleClose}>
            Close
          </button>
          <button variant="primary" onClick={(e) => saveBmcData(e)}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateNewBmcModal