import _ from "lodash";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchLibraryCategories,
  createLibraryCategory,
} from "../services/library/libraryService";
import LibraryCategoryCard from "../components/library/LibraryCategoryCard";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/masterNeeds.css";
import LibraryCategoryModal from "../components/library/LibraryCategoryModal";


const LibraryCategoryContainer = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);

  const getCategories = async () => {
    try {
      const data = await fetchLibraryCategories();
      setCategories(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const createCategory = async (data) => {
    try {
      await createLibraryCategory(data);
      toast.success("Category added successfully");
      getCategories();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleCreateCategory = (data) => {
    createCategory(data);
  };

  const displayCategories = () => {
    if (!_.isEmpty(categories)) {
      return (
        <>
          {categories.map((cat) => (
            <LibraryCategoryCard key={cat.id} category={cat} />
          ))}
        </>
      );
    } else {
      return (
        <h2 className="text-center text-danger">No Categories Available</h2>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <LibraryCategoryModal
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleCreateCategory}
      />
      <div className="master-need-container">
        <div className="master-needs-header">
          <h2 className="app-head-title ml-1">&nbsp;Categories</h2>
          <button type="button" onClick={openModal}>
            Add New Category
          </button>
        </div>
        <div className="master-need-categories">{displayCategories()}</div>
      </div>
    </>
  );
};

export default LibraryCategoryContainer;
