import React, { Fragment, useState } from "react";
import _ from "lodash";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import FormControlLabel from "@mui/material/FormControlLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";


import { toast } from "react-toastify";
// ==== service ==
import { linkSuggestionToProject } from "../../services/singleProject/singleProject";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const filter = createFilterOptions();

function LinkProjectModal({
  handleClose,
  open,
  projects,
  selectedSuggestionID,
  fetchSuggestion,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState([]);
  const [searchState, setSearchState] = useState(null);

  const handleSubmit = () => {
    setLoading(true);
    selectedProject.forEach(async (value) => {
      let data = {
        suggetion_projects: {
          suggestion_id: selectedSuggestionID,
          project_id: value,
        },
      };
      try {
        await linkSuggestionToProject(data);
      } catch (error) {
        toast.error("Something went wrong please try again");
        setLoading(false);
      }
    });
    toast.success("suggestions linked successfully");
    setSelectedProject([]);
    handleClose();
    setSearchState(null)
    fetchSuggestion();
    setLoading(false);
  };

  const displayProjectRadioGroups = () => {
    if (_.isEmpty(projects)) return <p>loading...</p>;

    if (searchState !== null) {
      return (
        <FormControlLabel
          value={searchState.id}
          control={<Checkbox />}
          label={searchState.name}
          onChange={(e) => {
            if (e.target.checked) {
              const results = [...selectedProject, e.target.value];
              const array = _.uniq(results);
              setSelectedProject(array);
            } else {
              setSelectedProject(
                [...selectedProject].filter((value) => value !== e.target.value)
              );
            }
          }}
        />
      );
    }

    const results = projects.map((project, idx) => {
      return (
        <Fragment key={idx}>
          <FormControlLabel
            value={project.id}
            control={<Checkbox />}
            label={project.name}
            onChange={(e) => {
              if (e.target.checked) {
                const results = [...selectedProject, e.target.value];
                const array = _.uniq(results);
                setSelectedProject(array);
              } else {
                setSelectedProject(
                  [...selectedProject].filter(
                    (value) => value !== e.target.value
                  )
                );
              }
            }}
          />
        </Fragment>
      );
    });
    return results;
  };

  const SearchBar = () => {
    return (
      <Stack spacing={2} sx={{ width: 300 }}>
        <Autocomplete
          value={searchState}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setSearchState({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setSearchState({
                name: newValue.inputValue,
              });
            } else {
              setSearchState(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="project-search-bar"
          options={projects}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Search Project" />
          )}
        />
      </Stack>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Link projects"}</DialogTitle>
        <div className="search-linking-projects-cont">
          <SearchBar />
        </div>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <FormGroup>
              {loading ? (
                <CircularProgress color="success" />
              ) : (
                displayProjectRadioGroups()
              )}
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            onClick={handleSubmit}
            color="success"
          >
            submit
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<CancelIcon />}
            onClick={ () => {
              
              handleClose()
              setSearchState(null)
              setSelectedProject([])
            }}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LinkProjectModal;
