import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import { createProjectCategory } from "../../../services/projects/ProjectCategoryService";

function CreateNewCategoryModal({
  show,
  handleClose,
  setCategories,
  fetchProjectCategory,
  showProgress,
  setShowProgress,
}) {
  const [name, setName] = useState("");

  const createCategory = async (data) => {
    try {
      // ==== CREATE NEW CATEGORY ===
      const { message } = await createProjectCategory(data);
      if (message !== "Project category created successfully") {
        setShowProgress(false);
        handleClose();
        return NotificationManager.error(
          "Something went wrong kindly check your internet connection and try again"
        );
      }
      // ==== FETCH ALL CATEGORY ===
      const { payload } = await fetchProjectCategory();
      if (payload.message !== "Project categories fetched successfully") {
        setShowProgress(false);
        handleClose();
        return NotificationManager.error(
          "Something went wrong kindly check your internet connection and try again"
        );
      }

      // === POPULATE CATEGORIES STATE ===
      setShowProgress(false);
      handleClose();
      setCategories(payload.result);
    } catch (error) {
      //  === HANDLE ERROR ===
      NotificationManager.error(
        "Something went wrong kindly check your internet connection and try again"
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowProgress(true);
    const data = {
      name: name,
    };
    createCategory(data);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Create New Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="Category Name"
              placeholder="Enter Category Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <button className="add-btn rounded text-white m-3">
            {showProgress === true ? (
              <div className="spinner-border text-dark" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Create"
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateNewCategoryModal;
