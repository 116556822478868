import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import styles from "../cssModules/sideBar.module.css";

const SideBar = ({
  menuItems,
  state,
  setState,
  toggleDrawer,
  setMenuSelection,
  menuSelection,
}) => {
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div className={styles.list_container}>
          <ArrowBackIosNewIcon
            fontSize="large"
            sx={{
              background: "purple",
              color: "white",
              borderRadius: "50%",
              padding: "8px",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer("right", false)}
          />
        </div>
        {menuItems.map((value) => (
          <ListItem
            style={{
              background:
                menuSelection.name === value.name ? "purple" : "white",
              color: menuSelection.name === value.name ? "white" : "black",
            }}
            key={value.id}
            disablePadding
            sx={{
              "&:hover": {
                color: "black",
              },
            }}
          >
            <ListItemButton
              onClick={(e) =>
                setMenuSelection({
                  name: value.name,
                })
              }
            >
              <ListItemIcon>{value.icon}</ListItemIcon>
              <ListItemText
                primary={value.name}
                fontSize="large"
                sx={{
                  fontFamily: "Roboto, sans-serif",
                  textTransform: "capitalize",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default SideBar;
