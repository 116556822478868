import React, { Fragment, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import { deepOrange } from "@mui/material/colors";
import styles from "../../stylesheets/taskModules/TaskMenuBar.module.css";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import Tooltip from "@mui/material/Tooltip";
import { displayFullDate } from "../../helpers/timeModule";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

function TaskCard3({
  selectedTabItems,
  allowDrop,
  drop,
  drag,
  setDisplayProfiles,
  setSelectedTask,
  setDisplayViewTask,
  handleSingleOpenItem,
}) {
  const [sortRow, setSortRow] = useState(false);
  // ==== PRIORITY CHECK FUNCTION ====
  const checkPriority = (priority) => {
    if (priority === 1)
      return (
        <Chip
          icon={<AddTaskIcon />}
          label="OI 1"
          color="error"
          variant="filled"
        />
      );
    if (priority === 2)
      return (
        <Chip
          icon={<AddTaskIcon />}
          label="OI 2"
          color="secondary"
          variant="filled"
        />
      );
    if (priority === 3)
      return (
        <Chip
          icon={<AddTaskIcon />}
          label="OI 3"
          color="primary"
          variant="filled"
        />
      );
    if (priority === 4)
      return (
        <Chip
          icon={<AddTaskIcon />}
          label="OI 4"
          color="success"
          variant="filled"
        />
      );
    if (priority === 5)
      return <Chip icon={<AddTaskIcon />} label="OI 5" variant="outlined" />;
    if (priority === 6) return <Chip icon={<AddTaskIcon />} label="OI 6" />;
  };

  const displayItem = () => {
    // eslint-disable-next-line array-callback-return
    const displayListsToolTips = (profile) => {
      return profile.map((value, index) => {
        return (
          <Tooltip
            title={`${value.first_name} ${value.last_name}`}
            placement="top-start"
            key={index}
          >
            {value.profile_avatar.url ? (
              <Avatar
                alt={`${value.first_name} ${value.last_name}`}
                src={value.profile_avatar.url}
                sx={{ bgcolor: deepOrange[500] }}
              />
            ) : (
              <Avatar
                alt={`${value.first_name} ${value.last_name}`}
                src="/static/images/avatar/1.jpg"
                sx={{ bgcolor: deepOrange[500] }}
              />
            )}
          </Tooltip>
        );
      });
    };
    // eslint-disable-next-line array-callback-return
    const result = selectedTabItems().map((item, idx) => {
      if (item.status === "In review") {
        return (
          <Fragment key={idx}>
            {/* main card start */}
            <div
              className={`card ${styles.card_sections}`}
              draggable="true"
              onDragStart={(e) => drag(e, item.id)}
            >
              <div className="card-body">
                <p className={styles.card_text}>{item.title}</p>
                {checkPriority(item.priority)}
                <div className={styles.assigned_to}>
                  <span>Assigned to:</span>

                  <AvatarGroup
                    max={3}
                    onClick={() => {
                      setSelectedTask({ ...item });
                      setDisplayProfiles(true);
                    }}
                  >
                    {displayListsToolTips(item.collaborators)}
                  </AvatarGroup>
                </div>
                <div className={styles.ms}>
                  <span>
                    <CalendarMonthIcon />
                    {displayFullDate(item.assigned_on)}
                  </span>
                  <span className={styles.ntf}>
                    <ModeCommentOutlinedIcon /> {item.comments.length}
                  </span>
                </div>
              </div>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<VisibilityIcon />}
                onClick={() => {
                  setDisplayViewTask(true);
                  handleSingleOpenItem(item.open_item_id);
                }}
              >
                VIEW
              </Button>
            </div>
            {/* end of main card */}
          </Fragment>
        );
      }
    });
    return result;
  };

  const displayCount = () => {
    let counter = 0;
    selectedTabItems().forEach((element) => {
      if (element.status === "In review") {
        counter += 1;
      }
    });
    return counter;
  };

  return (
    <div className={styles.over_holder_todo_3}>
      <div className={styles.to_do_board}>
        <div className={styles.board_title}>
          <h6 className={styles.h6_board_title}>
            In review{" "}
            <span className="badge badge-info ml-1">{displayCount()}</span>
          </h6>
          <div className="dropright">
            <MoreVertIcon
              className={`dropdown-toggle ${styles.toggle_button}`}
              data-toggle="dropdown"
              aria-expanded="false"
              fontSize="large"
              aria-haspopup="true"
              edge="right"
            />
            <div className={`dropdown-menu ${styles.drop_down_menu_show}`}>
              <ol>
                <li
                  className={`dropdown-item ${styles.drop_item_menu} ${
                    sortRow === false ? styles.active_tab : null
                  }`}
                  onClick={() => setSortRow(false)}
                >
                  <SwapVerticalCircleIcon /> top to bottom
                </li>
                <li
                  className={`dropdown-item ${styles.drop_item_menu} ${
                    sortRow === true ? styles.active_tab : null
                  }`}
                  onClick={() => setSortRow(true)}
                >
                  <SwapVerticalCircleIcon /> bottom to top
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div
          className={styles.card_main_container}
          onDrop={(e) => drop(e, "In review")}
          onDragOver={(e) => allowDrop(e)}
        >
          <div className={`card ${styles.card_sections}`} draggable="true">
            <div className="card-body" id={styles.add_items}>
              <div className={`media ${styles.align_items_center}`}>
                <AddIcon fontSize="medium" className={styles.add_plus} />
              </div>
            </div>
          </div>
          {displayItem()}
        </div>
      </div>
    </div>
  );
}

export default TaskCard3;
