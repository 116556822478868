import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DocumentScannerRoundedIcon from "@mui/icons-material/DocumentScannerRounded";
import ProjectForm from "../../ProjectForm";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectFormModal = ({
  open,
  handleClose,
  categories,
  createNewProject,
  sdgs,
  sdgLoading,
  sdgError,
  projectCategoriesError,
  projectCategoriesIsLoading,
  ProjectCreationMutation,
}) => {
  return (
    <Dialog
      width={"lg"}
      maxWidth={"lg"}
      minWidth={"lg"}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        minWidth: "100%",
        width: "100%",
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {" "}
        <DocumentScannerRoundedIcon
          sx={{
            fontSize: 40,
            color: "#000",
          }}
        />{" "}
        <h5
          style={{
            marginTop: "8px",
            marginLeft: "15px",
            color: "#000",
            textTransform: "uppercase",
            fontWeight: "700",
          }}
        >
          Create a new project.
        </h5>
        {ProjectCreationMutation.isError && (
          <Alert variant="filled" severity="error">
            Something went wrong while creating the project
          </Alert>
        )}
        <div
          className="text-right ml-5"
          style={{
            marginTop: "-6px",
          }}
        >
          <IconButton onClick={handleClose}>
            <CancelIcon fontSize="large" color="error" />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <ProjectForm
          categories={categories}
          createNewProject={createNewProject}
          sdgs={sdgs}
          sdgLoading={sdgLoading}
          sdgError={sdgError}
          projectCategoriesError={projectCategoriesError}
          projectCategoriesIsLoading={projectCategoriesIsLoading}
          ProjectCreationMutation={ProjectCreationMutation}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProjectFormModal;
