import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

export const fetchTags = async () => {
  try {
    const result = await axios.get(`${BASEURL}tags`, fetchRequestOptions);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTag = async (data) => {
  try {
    const result = await axios.post(
      `${BASEURL}tags/show_module_items`,data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
