import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {  CREATE_PROJECTGOALS_FAILURE, CREATE_PROJECTGOALS_SUCCESS, CREATE_PROJECTGOALS_REQUEST } from "./projectGoalsTypes";

  export const createProjectGoalsRequest = () =>({
    type: CREATE_PROJECTGOALS_REQUEST,
  })
  
  export const createProjectGoalsSuccess = (response) =>({
    type: CREATE_PROJECTGOALS_SUCCESS,
    payload: response
  })
  
  export const createProjectGoalsFailure = (error) =>({
    type: CREATE_PROJECTGOALS_FAILURE,
    payload: error
  })

  // ==== Create Project Goals ====
  
export const createProjectGoals = (id, data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createProjectGoalsRequest());
    return axios.post(`${BASEURL}projects/${id}/project_goals`, data ,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(createProjectGoalsSuccess(response.data));
      }).catch(error => {
        return dispatch(createProjectGoalsFailure(error));
      });
}
