import { BASEURL } from "../../API/apiData";

const sendAnInvite = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(`${BASEURL}invitation_systems`, requestOptions);
  const results = await response.json();
  return results;
};
const getAllInvites = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(`${BASEURL}invitation_systems`, requestOptions);
  const results = await response.json();
  return results;
};

const destroyAnInvite = async (inviteID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}invitation_systems/${inviteID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const acceptInvitation = async (token) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${BASEURL}invitations/use/${token}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export { sendAnInvite, getAllInvites, acceptInvitation, destroyAnInvite };
