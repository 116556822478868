import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import styles from "../cssModules/ListUpdate.module.css";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Badge from "@mui/material/Badge";
import MessageIcon from "@mui/icons-material/Message";
import EditIcon from "@mui/icons-material/Edit";
import { CardActionArea } from "@mui/material";
import { displayFullDate } from "../../../helpers/timeModule";

const displayUpvotedProfiles = () => {
  return (
    <AvatarGroup max={2}>
      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
      <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
      <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
      <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
      <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
    </AvatarGroup>
  );
};

const ListUpdates = ({
  update,
  setCommentModal,
  setEditModal,
  setEditData,
}) => {
  return (
    <Card
      sx={{ minWidth: 275, minHeight: "305px" }}
      variant="outlined"
      className="mt-3 mb-2"
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          className={styles.list_top_bar}
        >
          {update.is_complete ? (
            <Chip label="Completed" color="success" />
          ) : (
            <Chip label="Not Completed" color="error" />
          )}

          <div className={styles.date_container}>
            {displayFullDate(update.created_at)}
          </div>
        </Typography>
        <Typography variant="h6" className={styles.update_name}>
          <ReadMoreReusable>{update.update_title}</ReadMoreReusable>
        </Typography>
      </CardContent>
      <CardActionArea className={styles.card_action_area}>
        <CardActions className={styles.buttons_container}>
          {displayUpvotedProfiles()}
          &nbsp;
          <IconButton aria-label="upvote">
            <Badge badgeContent={4} color="secondary">
              <FavoriteBorderIcon
                fontSize="large"
                sx={{
                  color: "black",
                }}
              />
            </Badge>
          </IconButton>
          &nbsp;
          <IconButton
            aria-label="messages"
            onClick={() => setCommentModal(true)}
          >
            <Badge badgeContent={4} color="secondary">
              <MessageIcon
                fontSize="large"
                sx={{
                  color: "black",
                }}
              />
            </Badge>
          </IconButton>
          &nbsp;
          <IconButton
            aria-label="Edit"
            onClick={() => {
              setEditData({
                id: update.id,
                title: update.update_title,
                details: update.update_details,
                is_complete: update.is_complete,
              });
              setEditModal(true);
            }}
          >
            <EditIcon
              fontSize="large"
              sx={{
                color: "black",
              }}
            />
          </IconButton>
          &nbsp;
          <Button
            size="small"
            variant="contained"
            onClick={() => setCommentModal(true)}
          >
            Details
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default ListUpdates;
