/*eslint-disable */
import React from "react";
import _ from "lodash";
import projectImage from "../assets/nocorevalues.png";
import parse from "html-react-parser";
import { useHistory } from "react-router-dom";

const DisplayProjects = ({
  projects,
  categories,
  sdgs,
  icon,
  handleDelete,
  followAction,
  unfollowAction,
}) => {
  let history = useHistory();
  const navigate = (id) => {
    history.push({
      pathname: `/projects/${id}`,
      state: {
        allProjects: projects,
        categories,
        sdgs,
      },
    });
  };

  const displayProjects = () => {
    if (!_.isEmpty(projects)) {
      let results = projects.map((value, index) => {
        if (value !== null) {
          return (
            <div
              className="col-md-6 col-sm-6 col-12 col-lg-4 col-xl-4 card-container-display"
              key={index}
            >
              {icon && (
                <div className="remove-sub-project">
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    onClick={() => handleDelete(value.id)}
                  ></i>
                </div>
              )}
              <div className="card">
                <img
                  className="card-img-top"
                  src={projectImage}
                  alt={value.name}
                />
                <div className="card-body">
                  <h5 className="card-title">{value.name}</h5>
                  <div className="project-idea-container">
                    {parse(value.idea)}
                  </div>
                  <div className="project-card-buttons">
                    <button
                      onClick={() => navigate(value.id)}
                      className="btn view-project-btn"
                    >
                      Enter Project
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });
      return results;
    } else {
      return (
        <div className="jumbotron jumbotron-fluid container">
          <div className="container">
            <h1 className="display-4">There no projects</h1>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="tt-project-display-entry container-fluid">
      <div className="row">{displayProjects()}</div>
    </div>
  );
};

export default DisplayProjects;
