import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_FAILURE} from './categoryTypes'

export const fetchCategoriesRequest = () =>({
  type: FETCH_CATEGORIES_REQUEST,
})

export const fetchCategoriesSuccess = (response) =>({
  type: FETCH_CATEGORIES_SUCCESS,
  payload: response
})

export const fetchCategoriesFailure = (error) =>({
  type: FETCH_CATEGORIES_FAILURE,
  payload: error
})

export const fetchCategories = () => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchCategoriesRequest());
  return axios.get(`${BASEURL}categories`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchCategoriesSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchCategoriesFailure(error));
    });
}