import { FETCH_SUGGESTIONS_FAILURE, FETCH_SUGGESTIONS_REQUEST, FETCH_SUGGESTIONS_SUCCESS } from "./suggestionTypes";

const initialState = {
  loading: true,
  data: [],
  errorMsg: "",
};

const suggestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUGGESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case FETCH_SUGGESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default suggestionReducer;
