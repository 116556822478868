import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import _ from "lodash";
import SuggestionCard from "../suggestionbox/SuggestionCard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function ProfileSuggestions({ profile }) {
  const { my_suggestions, shared_suggestions } = profile;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const displayMySuggestions = () => {
    if (_.isEmpty(my_suggestions))
      return <h1>You dont have any suggestions</h1>;

    const results = my_suggestions.map((value, index) => {
      return (
          <div key={index} className="suggestion-card-share-cont">
            <SuggestionCard suggestion={value} />
          </div>
      );
    });
    return results;
  };

  const displayRecievedSuggestions = () => {
    if (_.isEmpty(shared_suggestions))
    return <h1>You dont have any suggestions</h1>;

  const results = shared_suggestions.map((value, index) => {
    return (
        <div key={index} className="suggestion-card-share-cont">
          <SuggestionCard suggestion={value} />
        </div>
    );
  });
  return results;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="toggle-bar-container">
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100vh",
              width: "400px"
            }}
          >
            <div className="display-value">
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab label="my suggestions" {...a11yProps(0)} />
              <Tab label="recieved suggestions" {...a11yProps(1)} />
            </Tabs>

            </div>
           

            <TabPanel value={value} index={0}>
              <div className="display-my-suggetions-on-prfl">{displayMySuggestions()}</div>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <div className="display-my-suggetions-on-prfl">
                {displayRecievedSuggestions()}
            </div>
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}
