import React from "react";
import nature from "../../images/val.png";
import "../../stylesheets/landingPage.css";

const AboutUs = () => {
  return (
    <div className="card border-0">
      <div className="row no-gutters">
        <div className="col-lg-6">
          <img src={nature} alt="" className="img-fluid abt-img" />
        </div>
        <div className="col-lg-6">
          <div className="card-body abt-card-text">
            <h2 className="card-title mb-5 text-warning font-weight-bold">
              This Is What The Future Looks Like!
            </h2>
            <h5 className="card-title ">Top10 System</h5>
            <p className="card-text">
              A method for increasing the quality, quantity and efficacy of
              collective engagement for developing and distributing good Ideas.
              Soon you will be able to click here to find out more.
            </p>
            <h5 className="card-title">Ambassador program</h5>
            <p className="card-text">
              Our Objective is to find one Ambassador for every community before
              launching. Soon you will be able to click here to find out more.
            </p>
            <h5 className="card-title">WhoWeAre</h5>
            <p className="card-text">
              An inclusive, online community for making the world a better place
              and helping good ideas reach their full potential. Soon you will
              be able to click here to find out more.
            </p>
            <h5 className="card-title">
              The World's Most Connected Suggestion Box
            </h5>
            <p className="card-text">
              One small change can have an enormous impact. One small act of
              kindness can save a persons life. Soon you will be able to click
              here to find out more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
