import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

export const fetchProfileInventory = async (id) => {
  try {
    const result = await axios.get(`${BASEURL}profile_inventories/${id}`, fetchRequestOptions);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};