import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const AppMobileBottomNavigation = ({
  mobileMenu,
  setMobileMenu,
  speedDialState,
}) => {
  const history = useHistory();
  const { projectMenu } = mobileMenu;
  const { toggleProjectSpeedDialMenu } = speedDialState;

  const [value, setValue] = useState(null);

  const navigateToProjects = () => {
    history.push({
      pathname: `/projects`,
    });
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          if (newValue === 0) {
            navigateToProjects();
            setValue(0);
          }
          if (newValue === 1) {
            setMobileMenu({
              projectMenu: false,
              editProject: true,
            });
            toggleProjectSpeedDialMenu("editproject", true);
            setValue(1);
            return;
          }
          if (newValue === 2) {
            setMobileMenu({
              editProject: false,
              projectMenu: true,
            });
            setValue(2);
            return;
          }
        }}
      >
        <BottomNavigationAction label="Back" icon={<KeyboardBackspaceIcon />} />
        <BottomNavigationAction label="Edit Project" icon={<EditIcon />} />
        <BottomNavigationAction
          label="Menu"
          icon={projectMenu === false ? <MenuIcon /> : <CloseIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default AppMobileBottomNavigation;
