/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  createProjectTopTenEntry,
  fetchProjectTopTenEntries,
  fetchProjectTopTenFields,
  updateProjectTopTenEntry,
} from "../../../redux";
import { createVote } from "../../../services/votes/voteService";
import ProjectTopTenEntryCard from "./ProjectTop10EntryCard";
import ProjectTopTenEntryModal from "./ProjectTopTenEntryModal";
import { Button } from "@mui/material";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AppProfilesList from "../globals/AppProfilesList";
import parse from "html-react-parser";

const ProjectTop10Single = ({
  pId,
  ttId,
  setViewNo,
  fetchTopTens,
  fetchFields,
  createTopTenEntry,
  updateTopTenEntry,
}) => {
  const [inputFields, setInputFields] = useState([]);
  const [entries, setEntries] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [open, setOpen] = useState(false);
  // LIST THE PEOPLE WHO HAVE LIKED THE ENTRIES
  const [likesProfiles, setLikesProfiles] = useState([]);
  const [openProfilesModal, setOpenProfilesModal] = useState(false);

  const fetchEntries = async () => {
    try {
      const res = await fetchTopTens(pId, ttId);
      setEntries(res.payload.result);
      setTitle(res.payload.top_ten_title);
      setDescription(res.payload.top_ten_description);
    } catch (error) {
      console.log(error, "Cant fetch project top10 entries");
    }
  };

  useEffect(() => {
    fetchFields(pId, ttId)
      .then((res) => setInputFields(res.payload.result))
      .catch((e) => console.log("Internal Server Error"));
    fetchEntries();
  }, [fetchTopTens, pId, ttId, fetchFields]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleProfilesModal = () => {
    setOpenProfilesModal(false);
  };

  const sendVote = async (data) => {
    try {
      const res = await createVote(data);
      res.message == "Thanks for your vote"
        ? toast.success(res.message)
        : toast.error(res.message);
      fetchEntries();
    } catch (error) {
      console.log(error);
    }
  };

  const handleVote = (data) => {
    sendVote(data);
  };

  const handleSubmit = (data) => {
    createTopTenEntry(pId, ttId, data)
      .then((res) => {
        toast.success("Entry Created successfully");
        fetchTopTens(pId, ttId)
          .then((res) => {
            setEntries(res.payload.result);
            setTitle(res.payload.top_ten_title);
          })
          .catch((e) => console.log("Internal Server Error"));
      })
      .catch((err) => "Internal Server Error");
  };

  const handleUpdate = (eId, data) => {
    updateTopTenEntry(pId, ttId, eId, data)
      .then((res) => {
        toast.success("Entry Updated successfully");
        fetchTopTens(pId, ttId)
          .then((res) => {
            setEntries(res.payload.result);
            setTitle(res.payload.top_ten_title);
          })
          .catch((e) => console.log("Internal Server Error"));
      })
      .catch((err) => console.log("Internal Server Error"));
  };

  const showProfiles = (profiles) => {
    setLikesProfiles([...profiles]);
    setOpenProfilesModal(true);
  };

  const showEntries = () => {
    if (entries.length === 0) {
      return <h2 className="text-center text-success">No entries available</h2>;
    } else if (entries.length > 0) {
      return (
        <>
          {entries.map((e, idx) => (
            <ProjectTopTenEntryCard
              key={e.id}
              idx={idx}
              data={e}
              handleSubmit={handleUpdate}
              handleVote={handleVote}
              showProfiles={showProfiles}
              projectID={pId}
              top10ID={ttId}
              fetchEntries={fetchEntries}
            />
          ))}
        </>
      );
    }
  };

  const DisplayTop10Banner = () => {
    return (
      <div className="display-top-10-banner">
        <div className="banner-header-top10">
          <FormatListNumberedIcon fontSize="large" color="black" />{" "}
          <span className="top-10-decription-title">Top10 Description:</span>
          <span className="top-10-name-display"> {parse(title)}</span>
          <Button
            variant="contained"
            onClick={openModal}
            color="secondary"
            edge="end"
            className="entry-add-button"
            startIcon={<AddCircleIcon />}
          >
            Add Entry
          </Button>
        </div>
        <p className="top-10-actual-description">{parse(description)}</p>
      </div>
    );
  };

  return (
    <>
      <ToastContainer />

      <AppProfilesList
        profiles={likesProfiles}
        open={openProfilesModal}
        handleClose={handleProfilesModal}
      />
      <ProjectTopTenEntryModal
        closeModal={closeModal}
        isOpen={open}
        handleSubmit={handleSubmit}
        inputFields={inputFields}
      />

      <Button
        color="secondary"
        variant="contained"
        onClick={() => setViewNo(1)}
        className="back-to-t0p-10s mt-4 mb-4"
        startIcon={<ArrowBackIosNewIcon />}
      >
        Back to all All Project Top10's
      </Button>

      <DisplayTop10Banner />
      {showEntries()}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchTopTens: (pId, pttId) => dispatch(fetchProjectTopTenEntries(pId, pttId)),
  createTopTenEntry: (pId, pttId, data) =>
    dispatch(createProjectTopTenEntry(pId, pttId, data)),
  fetchFields: (pId, ttId) => dispatch(fetchProjectTopTenFields(pId, ttId)),
  updateTopTenEntry: (pId, pttId, tteId, data) =>
    dispatch(updateProjectTopTenEntry(pId, pttId, tteId, data)),
});

export default connect(null, mapDispatchToProps)(ProjectTop10Single);
