export const FETCH_SUGGESTIONS_REQUEST = 'FETCH_SUGGESTIONS_REQUEST'
export const FETCH_SUGGESTIONS_SUCCESS = 'FETCH_SUGGESTIONS_SUCCESS'
export const FETCH_SUGGESTIONS_FAILURE = 'FETCH_SUGGESTIONS_FAILURE'
export const CREATE_SUGGESTION_REQUEST = 'CREATE_SUGGESTION_REQUEST'
export const CREATE_SUGGESTION_SUCCESS = 'CREATE_SUGGESTION_SUCCESS'
export const CREATE_SUGGESTION_FAILURE = 'CREATE_SUGGESTION_FAILURE'
export const FETCH_SUGGESTION_REQUEST = 'FETCH_SUGGESTION_REQUEST'
export const FETCH_SUGGESTION_SUCCESS = 'FETCH_SUGGESTION_SUCCESS'
export const FETCH_SUGGESTION_FAILURE = 'FETCH_SUGGESTION_FAILURE'
export const EDIT_SUGGESTION_REQUEST = 'EDIT_SUGGESTION_REQUEST'
export const EDIT_SUGGESTION_SUCCESS = 'EDIT_SUGGESTION_SUCCESS'
export const EDIT_SUGGESTION_FAILURE = 'EDIT_SUGGESTION_FAILURE'

