/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, Fragment, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

var Airtable = require("airtable");

function FinancialForm({
  state,
  setState,
  show,
  handleClose,
  financialDepartments,
  employees,
  editState,
  editAction,
}) {
  // ==== Airtable
  let base = new Airtable({ apiKey: "keyGl0sEEEcvT6Zqw" }).base(
    "appHeHlLFYZKqPWl5"
  );
  // === Checking if the employee or company expense is new
  const [newEmployee, setNewEmployee] = useState(false);
  // ==== Checking for any arreas
  const [openArrears, setOpenArrears] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // ===== Diplaying the Departments
  const displayDepartment = () => {
    const results = financialDepartments.map((value, index) => {
      return (
        <Fragment key={index}>
          <option value={value}>{value}</option>
        </Fragment>
      );
    });
    return results;
  };
  // ==== Displaying the employees
  const displayEmployees = () => {
    const results = employees.map((value, index) => {
      return (
        <Fragment key={index}>
          <option value={value}>{value}</option>
        </Fragment>
      );
    });
    return results;
  };

  // ==== Saving the record
  const saveRecord = () => {
    const info = {
      fields: {
        EmployeeName: state.name,
        Total: parseInt(state.amount),
        Notes: state.notes,
        recievedOn: state.paidOn,
        Department: state.department,
        WhoPaid: "Jeff J Mack",
        RemainingArrears: parseInt(state.remainingArrears),
        Paidarrears: parseInt(state.paidArrears),
      },
    };
    base("Receipt Log").create([info], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
        window.location.reload();
      });
    });
  };

  // ==== Set edit action to the state.
  useEffect(() => {
    if (editAction === true) {
      setState(editState);
    } else {
      setState({
        name: "",
        amount: 0,
        notes: "",
        paidOn: "",
        department: "",
        paidArrears: 0,
        remainingArrears: 0,
      });
    }
  }, [editState]);

  // === Editing a particular record
  const editRecord = () => {
    const editInfo = {
      id: state.id,
      fields: {
        EmployeeName: state.name,
        Total: parseInt(state.amount),
        Notes: state.notes,
        recievedOn: state.paidOn,
        Department: state.department,
        WhoPaid: "Jeff J Mack",
        RemainingArrears: parseInt(state.remainingArrears),
        Paidarrears: parseInt(state.paidArrears),
      },
    };

    base("Receipt Log").update([editInfo], function (err, records) {
      if (err) {
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.get("Department"));
        window.location.reload();
      });
    });
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editAction === true
              ? "Edit financial record"
              : "Create New Financial Record"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Employee Name/Company Expense</label>
              <select
                className="custom-select my-1 mr-sm-2"
                id="inlineFormCustomSelectPref"
                value={state.name}
                name="name"
                onChange={handleChange}
              >
                <option value="">Choose...</option>
                {displayEmployees()}
              </select>
              <div className="check-new-employee">
                <small id="employee help" className="form-text text-muted">
                  Is this a new employee or a new company expense?
                </small>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    onChange={() => setNewEmployee(!newEmployee)}
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              {newEmployee === true ? (
                <div className="newEmployee-input-field">
                  <hr />
                  <input
                    type="text"
                    className="form-control"
                    id="employee Name"
                    placeholder="Enter employee name/expense name"
                    value={state.name}
                    name="name"
                    onChange={handleChange}
                  />
                </div>
              ) : null}
            </div>
            <hr />
            <div className="form-group">
              <label>Amount</label>
              <input
                type="number"
                className="form-control"
                name="amount"
                value={state.amount}
                onChange={handleChange}
                placeholder="Enter amount in dollars"
              />
            </div>
            <hr />
            <div className="form-group">
              <label>More information about this record</label>
              <textarea
                className="form-control"
                name="notes"
                value={state.notes}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>
            <hr />
            <div className="form-group">
              <label>Paid on</label>
              <input
                type="date"
                className="form-control"
                name="paidOn"
                value={state.paidOn}
                onChange={handleChange}
                placeholder="Enter amount in dollars"
              />
            </div>

            <hr />
            <div className="form-group">
              <label>Department</label>
              <select
                className="custom-select my-1 mr-sm-2"
                name="department"
                value={state.department}
                onChange={handleChange}
              >
                <option value="">Choose...</option>
                {displayDepartment()}
              </select>
            </div>
            <hr />
            <div className="form-group">
              <div className="check-new-employee">
                <small id="employee help" className="form-text text-muted">
                  Are there any remaining or paid arreas?
                </small>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck"
                    onChange={() => setOpenArrears(!openArrears)}
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              {openArrears === true ? (
                <div className="newEmployee-input-field">
                  <hr />
                  <label>Paid Arrears</label>
                  <input
                    type="number"
                    className="form-control"
                    id="employee Name"
                    name="paidArrears"
                    value={state.paidArrears}
                    onChange={handleChange}
                    placeholder="enter amount of paid arreas if any"
                  />
                  <hr />
                  <label>Remaining Arrears</label>
                  <input
                    type="number"
                    className="form-control"
                    name="remainingArrears"
                    value={state.remainingArrears}
                    onChange={handleChange}
                    id="employee Name"
                    placeholder="enter amount of remaining arreas if any"
                  />
                </div>
              ) : null}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-danger"
          >
            Close
          </button>
          {editAction === true ? (
            <button
              variant="primary"
              onClick={editRecord}
              className="btn btn-success"
            >
              Edit Record
            </button>
          ) : (
            <button
              variant="primary"
              onClick={saveRecord}
              className="btn btn-success"
            >
              Save Record
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FinancialForm;
/*eslint-enable react-hooks/exhaustive-deps*/