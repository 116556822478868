import React from "react";

function AppLabel({ children }) {
  return (
    <label htmlFor={children} className="custom-font-b app-label">
      {children}
    </label>
  );
}

export default AppLabel;
