import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
// MATERIAL UI CHIP
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

// This should be categories mobile component not trending(Naming error)
const SuggestionBoxTrendingMobile = ({
  suggestionCategories,
  chosenMobileCategory,
}) => {
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    chosenMobileCategory(newValue);
  };

  const displaySuggestionCategories = () => {
    return suggestionCategories.map((item, index) => {
      return (
        <Tab
          key={index}
          label={
            <Chip
              avatar={<Avatar alt={item.category.name} src={item.category.image} />}
              label={item.category.name}
              variant="outlined"
            />
          }
          value={item.category.id}
        />
      );
    });
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        width: "100%",
      }}
    >
      <Paper
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          right: 0,
          marginBottom: 0,
        }}
        elevation={3}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          <Tab
            label={<Chip avatar={<Avatar>A</Avatar>} label="All" />}
            value={null}
          />
          {displaySuggestionCategories()}
        </Tabs>
      </Paper>
    </Box>
  );
};

export default SuggestionBoxTrendingMobile;
