import { BASEURL } from "../../API/apiData";

const getSpecificSwotDetail = async (projectID, swotID, route) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${swotID}/${route}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createSpecificSwotDetail = async (projectID, swotID, route, data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${swotID}/${route}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editSpecificSwotDetail = async (
  projectID,
  swotID,
  route,
  data,
  detailID
) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${swotID}/${route}/${detailID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const showSpecificSwotDetail = async (projectID, swotID, route, detailID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${swotID}/${route}/${detailID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  getSpecificSwotDetail,
  createSpecificSwotDetail,
  editSpecificSwotDetail,
  showSpecificSwotDetail,
};
