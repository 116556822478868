/*eslint-disable */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { fetchProjectNeeds } from "../../services/projects/projectsService";
import {
  createMasterNeed,
  updateMasterNeed,
  fetchMasterNeedsCategories,
} from "../../services/masterNeeds/masterNeedsService";
import NeedCard from "../masterNeeds/NeedCard";
import { toast } from "react-toastify";
import NeedModal from "../masterNeeds/NeedModal";
import { Button } from "@mui/material";

const ProjectNeeds = ({ id, speedDialState }) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [categories, setCategories] = useState([]);
  const [needs, setNeeds] = useState({
    category: null,
    open_needs: [],
    closed_needs: [],
  });
  const [tabNo, setTabNo] = useState(0);
  const [open, setOpen] = useState(false);

  const getNeeds = async () => {
    try {
      const data = await fetchProjectNeeds(id);
      setNeeds(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const data = await fetchMasterNeedsCategories();
      setCategories(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNeeds();
    getCategories();
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const createNeed = async (data) => {
    try {
      await createMasterNeed(data);
      getNeeds();
      toast.success("Need created successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const updateNeed = async (nId, data) => {
    try {
      await updateMasterNeed(nId, data);
      getNeeds();
      if (data.need.is_procured) {
        toast.warning("Need Closed");
      } else {
        toast.warning("Need updated successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateNeed = (data) => {
    createNeed(data);
  };

  const handleUpdateNeed = (nId, data) => {
    updateNeed(nId, data);
  };

  const showNeeds = () => {
    switch (tabNo) {
      case 0:
        return showData(needs.open_needs);
      case 1:
        return showData(needs.closed_needs);
      default:
        break;
    }
  };

  const showData = (data) => {
    if (_.isEmpty(data)) {
      return (
        <h4 className="text-danger text-center">
          <i className="fa fa-frown-o" aria-hidden="true"></i>
          &nbsp; Sorry No Needs Available
        </h4>
      );
    } else {
      return data.map((need, idx) => (
        <NeedCard
          key={need.id}
          idx={idx}
          data={need}
          master={false}
          projects={[]}
          categories={categories}
          handleSubmit={handleUpdateNeed}
        />
      ));
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("need", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Need
        </Button>
      </div>
      <NeedModal
        categories={categories}
        category={null}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleCreateNeed}
        proId={id}
      />
      <div>
        <div className="p-1">
          <button
            className="app-add-btn m-2"
            type="button"
            onClick={() => setTabNo(0)}
          >
            <i className="fa fa-folder-open text-success"></i>
            &nbsp; Open
          </button>
          <button
            className="app-add-btn m-2"
            type="button"
            onClick={() => setTabNo(1)}
          >
            {" "}
            <i className="fas fa-times-circle text-danger"></i>
            &nbsp; Closed
          </button>
        </div>
        <div className="p-3">{showNeeds()}</div>
      </div>
    </>
  );
};

export default ProjectNeeds;
