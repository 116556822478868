import React from "react";
import { displayDate } from "../../../helpers/timeModule";
import { displayTime } from "../../../helpers/timeModule";

// === MATERIAL UI ===
import Stack from "@mui/material/Stack";
import AvatarGroup from "@mui/material/AvatarGroup";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import ArticleIcon from "@mui/icons-material/Article";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
import comment from "../../../images/comment.png";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import ideaImg from "../../../images/idea.png";
import styles from "./appReusableCard.module.css";
import { Badge } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";

//==== THIS IS REUSABLE CARD THAT IS USED IN THE PROJECT DISCUSSION, SUGGESTION BOX AND ASSUMPTIONS =====
// This are the props definations
// eslint-disable-next-line no-lone-blocks
{
  /*
 1. actionItems: is a boolen that turns the share like and comment buttons ON or OFF
 2. appData: this is an object that contains all the data needed to render the card
 3. 

*/
}

const AppReusableCard = ({ idea, purpose, ...otherProps }) => {
  const {
    actionItems,
    inputField,
    inputFieldName,
    userLiked,
    votes,
    creatorImage,
    creatorFirstName,
    creatorLastName,
    createdAt,
    editAction,
    openCommentModal,
    likeAction,
    unLikeAction,
    upvoted_by,
    setVotersProfiles,
    setVotersToggleDrawer,
    setDrawerTitle,
    id,
    comments,
    data,
    likeButton,
  } = otherProps;

  const displayCreatorAvator = () => {
    if (creatorImage)
      return (
        <Avatar
          alt={`${creatorFirstName} ${creatorLastName}`}
          src={`${creatorImage}`}
        />
      );

    return <Avatar src="/broken-image.jpg" />;
  };

  const displayVotersProfiles = () => {
    const result = upvoted_by.map((value, idx) => {
      return (
        <Avatar
          key={idx}
          alt="profile avatar"
          src={`${value.profile.profile_avatar.url}`}
          className="sb-shared-icons-avatars"
        />
      );
    });

    return result;
  };

  return (
    <div className={`card tt-suggestion-card ${styles.main_container}`}>
      <div className="card-body">
        {/* === DETAILS === */}

        <div className={`suggest-idea ${styles.item_height}`}>
          {" "}
          {inputField !== "" ? (
            <div className="suggestion-idea-title">
              <ArticleIcon /> <span> {inputFieldName} </span>
            </div>
          ) : null}
          {inputField !== null ? (
            <ReadMoreReusable>{inputField}</ReadMoreReusable>
          ) : null}
        </div>

        <div className="tt-suggestion-card-title row">
          {/* === IDEA === */}
          <div
            className={`col-md-12 col-sm-12 col-12 suggest-idea ${styles.item_height}`}
          >
            {idea === null ? null : (
              <div className="suggestion-idea-title">
                <img src={ideaImg} alt="thumbnail" /> <span> IDEA</span>
              </div>
            )}

            <div
              onClick={() => console.log("clicked")}
              className="suggestion-purpose"
            >
              {idea !== null ? (
                <ReadMoreReusable>{idea}</ReadMoreReusable>
              ) : null}
            </div>
          </div>
        </div>
        {/* === PURPOSE === */}
        <div className={`suggest-idea ${styles.item_height}`}>
          {" "}
          {purpose !== "" ? (
            <div className="suggestion-idea-title">
              <FlagCircleIcon /> <span> PURPOSE</span>
            </div>
          ) : null}
          {purpose !== null ? (
            <ReadMoreReusable>{purpose}</ReadMoreReusable>
          ) : null}{" "}
        </div>
        {/* ==== This is where we are going to show the share once its back ==== */}
        {actionItems && (
          <div className="show-like-share-container likes-cont-ex">
            {/*  ==== DISPLAY LIKED USERS AND SHARED USERS ==== */}
            {/* === REMOVED THE SHARE DUE TO UNRESONABLE ERROR ==== */}
            {/* {shared_with.length < 1 ? null : (
              <div className="shared-with-container">
                <div className="suggestions-shared-profiles">
                  <Chip
                    label="Shared with:"
                    color="secondary"
                    variant="filled"
                    className="shared-with-chip mt-2 mr-2"
                    onClick={() => {
                      setVotersProfiles([...shared_with]);
                      setDrawerTitle("Shared with:");
                      setVotersToggleDrawer(true);
                    }}
                    size="small"
                  />

                  <AvatarGroup
                    max={2}
                    onClick={() => {
                      setVotersProfiles([...shared_with]);
                      setDrawerTitle("Shared with:");
                      setVotersToggleDrawer(true);
                    }}
                  >
                    {displaySharedProfiles()}
                  </AvatarGroup>
                </div>
              </div>
            )} */}
          </div>
        )}

        {actionItems && <Divider />}
        {/* === COMMENTS SHARE LIKE AND VIEW BUTTONS === */}
        {actionItems && (
          <div className={`sb-like-comment-btn ${styles.buttons_container}`}>
            {upvoted_by.length < 1 ? null : (
              <div className="liked-by-container">
                <div className="suggestions-shared-profiles">
                  <AvatarGroup
                    max={2}
                    onClick={() => {
                      setVotersProfiles([...upvoted_by]);
                      setDrawerTitle("Upvoted by:");
                      setVotersToggleDrawer(true);
                    }}
                  >
                    {displayVotersProfiles()}
                  </AvatarGroup>
                </div>
              </div>
            )}
            <button type="button">
              {userLiked ? (
                <IconButton
                  onClick={unLikeAction}
                  disabled={likeButton}
                  className={styles.love_button}
                >
                  <Badge color="secondary" badgeContent={votes}>
                    <FavoriteIcon style={{ color: "red" }} fontSize="large" />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  onClick={likeAction}
                  disabled={likeButton}
                  className={styles.love_button}
                >
                  <Badge color="secondary" badgeContent={votes}>
                    <FavoriteBorderIcon
                      fontSize="large"
                      style={{ color: "black" }}
                    />
                  </Badge>
                </IconButton>
              )}{" "}
            </button>
            <button onClick={() => openCommentModal(id, comments, data)}>
              <Badge
                badgeContent={comments.length}
                color="secondary"
                className={styles.badges}
              >
                <img src={comment} alt="sb-like-btn" />
              </Badge>
            </button>
            <button onClick={editAction}>
              <DriveFileRenameOutlineTwoToneIcon style={{ fontSize: "30px" }} />
              <span>Edit</span>
            </button>
            {/* WE CAN ADD THE SHARE BUTTON LATER */}
            {/* === SHARE BUTTON === */}
            {/* <button onClick={openShareModal}>
              <Badge badgeContent={shared_with.length} color="secondary">
                <ShareIcon style={{ fontSize: "30px" }} />
              </Badge>

              <span> share </span>
            </button> */}
          </div>
        )}
      </div>
      {actionItems && <Divider />}
      <div className="suggestion-creator-avatar">
        <Stack direction="row" spacing={3}>
          {displayCreatorAvator()}
          <h6>
            {creatorFirstName}
            {creatorLastName}
          </h6>
        </Stack>

        <h6 className="date-of-suggestion-creation">
          {inputFieldName} created: {displayDate(createdAt).value}{" "}
          {displayTime(
            displayDate(createdAt).unit,
            displayDate(createdAt).value
          )}
        </h6>
      </div>
    </div>
  );
};

export default AppReusableCard;
