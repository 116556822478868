import { UPDATE_PWD_FAILURE, UPDATE_PWD_REQUEST, UPDATE_PWD_SUCCESS } from './passwordTypes';

const initialState = {
    loading: true,
    data: [],
    errorMsg: '',
  };
  
  const passwordsReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_PWD_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_PWD_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          errorMsg: '',
        };
      case UPDATE_PWD_FAILURE:
        return {
          ...state,
          loading: false,
          data: [],
          errorMsg: action.payload,
        };
      
      default:
        return state;
    }
  };
  
  export default passwordsReducer;