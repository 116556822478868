/*eslint-disable */
import React, { useState } from "react";
import "../stylesheets/tags.css";
import Navbar from "../components/navbar/Navbar";
import _ from "lodash";

import { fetchTag } from "../services/tags/tagService";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";

const SingleTagContainer = ({ match }) => {
  const tagId = match.params.id;

  const [tagName, setTagName] = useState("NO-TAG");
  const [list, setList] = useState({});

  const fetchList = async (data) => {
    const result = await fetchTag(data);
    setTagName(result.result.tag_name);
    setList(result);
  };

  const fetchProjects = () => {
    const xData = {
      tag_id: tagId,
      project: true,
    };
    fetchList(xData);
  };

  const fetchSuggestions = () => {
    const xData = {
      tag_id: tagId,
      suggestion: true,
    };
    fetchList(xData);
  };

  const showList = () => {
    if (_.isEmpty(list)) {
      return (
        <div className="tags-nil">
          <i className="fa fa-eye fa-5x" aria-hidden="true"></i>
          <p>Select content and view it here</p>
        </div>
      );
    } else if (list.result.data == "no") {
      return (
        <div className="tags-nil">
          <i className="fa fa-ban fa-5x text-danger" aria-hidden="true"></i>
          <p>{list.message}</p>
        </div>
      );
    } else if (_.isEmpty(list.result)) {
      return (
        <div className="tags-nil">
          <i className="fa fa-ban fa-5x text-danger" aria-hidden="true"></i>
          <p>{list.message}</p>
        </div>
      );
    } else if (!_.isEmpty(list.result) && list.result.data == "yes") {
      const res = list.result;
      if (res.info == "project") {
        return (
          <ul className="list-group">
            {res.projects.map((proj, idx) => (
              <Link key={proj.id} to={`/projects/${proj.id}`}>
                <li className="list-group-item d-flex align-items-center">
                  <span className="badge badge-primary badge-pill">
                    {idx + 1}
                  </span>
                  &nbsp;&nbsp;
                  {capitalize(proj.name)}
                </li>
              </Link>
            ))}
          </ul>
        );
      } else if (res.info == "suggestion") {
        return (
          <ul className="list-group">
            {res.suggestions.map((sug, idx) => (
              <Link key={sug.id} to={`/suggestion`}>
                <li className="list-group-item d-flex align-items-center">
                  <span className="badge badge-primary badge-pill">{idx + 1}</span>
                  &nbsp;&nbsp;
                  {capitalize(sug.idea)}
                </li>
              </Link>
            ))}
          </ul>
        );
      }
    }
  };

  return (
    <>
      <Navbar />
      <h2 className="tag-header">#{capitalize(tagName)}</h2>
      <div className="tag-container w-100 d-flex flex-column">
        <div className="d-flex w-100 p-1">
          <button className="btn w-50 tag-btn" onClick={fetchProjects}>
            Projects
          </button>
          <button className="btn w-50 tag-btn" onClick={fetchSuggestions}>
            Suggestions
          </button>
        </div>
        {showList()}
      </div>
    </>
  );
};

export default SingleTagContainer;
