import React from "react";

const MyTasks = () => {
  return (
    <div className="alert alert-primary" role="alert">
      This feature is still under development and will be shipped soon, kindly
      use the assigned tasks for now. Thanks for your patience.
    </div>
  );
};

export default MyTasks;
