/*eslint-disable */
import React, { useState } from "react";
import "../../stylesheets/navbar.css";
import "../../stylesheets/notifications.css";
import logo from "../../assets/img/logo.png";
import ToggleNav from "./ToggleNav";
import { useHistory } from "react-router-dom";
import Notifications from "../notifications/Notifications";
import UserToggleNav from "./UserToggleNav";

const Navbar = () => {
  const history = useHistory();

  const [showToggleNav, setShowToggleNav] = useState(false);
  const [showUserToggleNav, setShowUserToggleNav] = useState(false);
  const [showHam, setShowHam] = useState(true);
  const [showClose, setShowClose] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const showNotificationBox = () => {
    setShowNotifications(!showNotifications);
  };

  const showToggleNavbar = () => {
    setShowToggleNav(!showToggleNav);
    setShowHam(!showHam);
    setShowClose(!showClose);
    if (showUserToggleNav == true) {
      setShowUserToggleNav(false);
    }
  };

  const showUserToggleNavbar = () => {
    if (showToggleNav == true) {
      setShowToggleNav(false);
      setShowHam(!showHam);
      setShowClose(!showClose);
    }
    setShowUserToggleNav(!showUserToggleNav);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setTimeout(() => {
      history.push("/signin");
      window.location.reload(true);
    }, 1000);
  };

  const handleFavorite = () => {
    history.push("/favorites");
  };

  return (
    <>
      {showToggleNav ? <ToggleNav showToggleNavbar={showToggleNavbar} /> : null}
      {showNotifications ? <Notifications /> : null}
      {showUserToggleNav ? (
        <UserToggleNav
          handleLogout={handleLogout}
          showUserToggleNav={showUserToggleNavbar}
        />
      ) : null}
      <nav className="top-nav">
        <div className="top-nav-link-menu">
          {showClose ? (
            <button className="top-nav-ham" onClick={showToggleNavbar}>
              <i className="fas fa-times fa-2x"></i>
            </button>
          ) : null}
          {showHam ? (
            <button className="top-nav-ham" onClick={showToggleNavbar}>
              <i className="fas fa-bars fa-2x"></i>
            </button>
          ) : null}
          <img className="tuc-logo" src={logo} alt="nPmRzQ.png" border="0" />
        </div>

        <div className="top-nav-icon-cont" onClick={handleFavorite}>
          <i className="fa fa-star favorite-nav-btn" aria-hidden="true"></i>
          <span>Favorites</span>
        </div>

        <div className="top-nav-icon-cont">
          <i
            className="fas fa-bell favorite-nav-btn"
            onClick={showNotificationBox}
          ></i>
          <span>Notifications</span>
        </div>

        <div className="top-nav-icon-cont-profile">
          <i
            className="fas fa-user-circle text-warning favorite-nav-btn"
            onClick={showUserToggleNavbar}
          ></i>
          <span>Account</span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
