import React from "react";
import CoreValueCard from "./CoreValueCard";
import _ from "lodash";
import nocores from "../../assets/nocorevalues.png";
import CoreValueForm from "./CoreValueForm";

const ProfileCoreValues = ({ data, handleCoreValueSumbit, privacy = false }) => {
  const showData = () => {
    if (!_.isEmpty(data.result)) {
      return data.result.map((val, idx) => (
        <CoreValueCard key={`cv${idx}`} value={val.name} privacy={privacy}/>
      ));
    } else if (_.isEmpty(data.result)) {
      return (
        <div className="w-100 text-center">
          <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
          <h4 className="text-dark">{data.message}</h4>
        </div>
      );
    }
  };

  const handleSubmit = (coreValue) => {
    handleCoreValueSumbit(coreValue);
  };

  return (
    <>
      <div className="profile-core-values">
        <h1>Core Values</h1>
        {privacy ? (
          <div className="core-value-form-container">
            <CoreValueForm handleSubmit={handleSubmit} />
          </div>
        ) : null}
        {showData()}
      </div>
    </>
  );
};

export default ProfileCoreValues;
