import React, {useState, useEffect} from 'react'
import Modal from "react-bootstrap/Modal";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function EditListModal({
  listingEditShow, 
  setlistingEditShow, 
  listEditInfo,
  projectID, 
  bmcID,
  fetchlistingFunction, 
  items, 
  setListItems,
  editListItem,
  itemID,
  blockInfo
  }) {
const [name, setName] = useState(listEditInfo);

const handleSave = async (e) => {
    e.preventDefault();
    let { editList } = editListItem
    const {fetchRoute} = fetchlistingFunction
    const { id } = blockInfo;

    try {
      await editList(projectID, bmcID, id, name, itemID)
      const { result } = await fetchRoute(projectID, bmcID);
      let response = result[0];
      setListItems(response[items]);

      setlistingEditShow(false)
      setName("")
      
    } catch (error) {
      console.log(error)
    }

};

 useEffect(() => {
    setName(listEditInfo)
 }, [listEditInfo]);

  return (
    <Modal show={listingEditShow} onHide={() => setlistingEditShow(false)} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>Edit item</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <form>
        <div className="form-group">
          <label>Name</label>
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={name}
            onChange={(event, editor) => {
              const data = editor.getData();
              setName(data);
            }}
            required
          />
        </div>
      </form>
    </Modal.Body>
    <Modal.Footer>
      <button variant="primary" className='create-project-btn m-3 btn' onClick={(e) => handleSave(e)}>
        Save Changes
      </button>
    </Modal.Footer>
  </Modal>
  )
}

export default EditListModal