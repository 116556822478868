import React from "react";

const AssociationFilter = ({ associations, filter }) => {

  const handleChange = (e) => {
    filter(e.target.value)
  };

  return (
    <>
      <div className="form-group w-50 p-2">
      <label htmlFor="association">Choose Association</label>&nbsp;
      <select className="w-25 mr-3 form-control w-100" name="association" onChange={e => handleChange(e)}>
        <option value="">All</option>
        {associations.map((item) => (
          <option key={item.associations.name} value={item.associations.id}>
            {item.associations.name}
          </option>
        ))}
      </select>
      </div>
    </>
  );
};

export default AssociationFilter;
