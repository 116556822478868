import React, { useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
// From text, to editor ====
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const ProjectTopTenModal = ({ closeModal, isOpen, handleSubmit }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [inputFields, setInputFields] = useState([
    { field_name: "", field_type: "" },
  ]);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const project_fields_attributes = addNameField();
    handleSubmit(title, description, project_fields_attributes);
    closeModal();
    setTitle("");
    setDescription("");
    setInputFields([{ field_name: "", field_type: "" }]);
  };

  const addNameField = () => {
    const values = [...inputFields];
    values.unshift({ field_name: "Name", field_type: "text" });

    return values;
  };

  const handleChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "field_name") {
      values[index].field_name = event.target.value;
    } else {
      values[index].field_type = event.target.value;
    }

    setInputFields(values);
  };

  const handleAddField = () => {
    const values = [...inputFields];
    if (values.length < 5) {
      values.push({ field_name: "", field_type: "" });
    } else {
      alert("You cannot create more than 6 fields");
    }
    setInputFields(values);
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>Create New Project Top10</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={title}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTitle(data);
              }}
              label="title"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
              label="description"
              required
            />
          </div>
          <div>
            <div className="form-group mt-2 d-flex justify-content-between">
              <h5 className="pt-2">Add Fields</h5>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => handleAddField()}
              >
                +
              </button>
            </div>
            <div className="row mx-auto mb-2  disabled-class-tt">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value="Name"
                  disabled
                />
              </div>
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  id="type"
                  name="type"
                  value="text"
                  disabled
                />
              </div>
            </div>
            <div className="row mx-auto">
              {inputFields.map((field, idx) => (
                <Fragment key={idx}>
                  <div className="form-group col-md-6 mt-2">
                    <input
                      type="text"
                      className="form-control"
                      id="field_name"
                      name="field_name"
                      value={field.field_name}
                      placeholder="field name"
                      onChange={(event) => handleChange(idx, event)}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4 mt-2">
                    <select
                      className="form-control"
                      name="field_type"
                      id="field_type"
                      defaultValue="DEFAULT"
                      onChange={(event) => handleChange(idx, event)}
                    >
                      <option value="DEFAULT" disabled>
                        -- field type --
                      </option>
                      <option value="text">text</option>
                      <option value="number">number</option>
                      <option value="url">link</option>
                      <option value="date">date</option>
                    </select>
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      className="btn btn-danger mt-2"
                      type="button"
                      onClick={() => handleRemoveField(idx)}
                    >
                      -
                    </button>
                  </div>
                </Fragment>
              ))}
            </div>
            <hr />
            <div className="form-group float-right">
              <button
                className="add-btn rounded text-white"
                type="submit"
                onClick={handleFormSubmit}
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectTopTenModal;
