import {FETCH_SINGLEPROJECT_SUCCESS, FETCH_SINGLEPROJECT_REQUEST, FETCH_SINGLEPROJECT_FAILURE} from './singleProjectTypes'

const initialState = {
  loading: true,
  data: [],
  errorMsg: '',
};

const singleProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SINGLEPROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SINGLEPROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: '',
      };
    case FETCH_SINGLEPROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default singleProjectReducer