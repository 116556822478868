import {CREATE_INVENTORY_SUCCESS, CREATE_INVENTORY_REQUEST, CREATE_INVENTORY_FAILURE} from './inventoryTypes'

const initialState = {
  loading: true,
  data: [],
  errorMsg: '',
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: '',
      };
    case CREATE_INVENTORY_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default inventoryReducer