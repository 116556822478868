import React from "react";
import { Link } from "react-router-dom";

const LibraryCategoryCard = ({ category }) => {
  return (
    <>
      <Link to={`library/${category.id}`}>
        <div
          className="master-need-category-card"
          style={{ background: category.indicator }}
        >
          {category.name}
        </div>
      </Link>
    </>
  );
};

export default LibraryCategoryCard;
