import React from "react";
import nocores from "../../assets/nocorevalues.png";

const ProjectGallery = () => {
  return (
    <div className="profile-core-values container-m-well mt-4 mb-2">
      <div className="w-100 text-center mb-4">
        <h4 className="text-dark mt-4" style={{ fontWeight: "bold" }}>
          This feature is under construction
        </h4>
        <img className="no-cores-thumbnail mb-4" src={nocores} alt="no-cv" />
      </div>
    </div>
  );
};

export default ProjectGallery;
