import {FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE} from './categoryTypes'

const initialState = {
    loading: true,
    data: [],
    errorMsg: '',
  };
  
  const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CATEGORIES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_CATEGORIES_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          errorMsg: '',
        };
      case FETCH_CATEGORIES_FAILURE:
        return {
          ...state,
          loading: false,
          data: [],
          errorMsg: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default categoriesReducer;