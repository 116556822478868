import React  from 'react'

const SuggestionCategoriesCard = ({value, setSelectedCategory}) => {
  const handleClick = () => {
    setSelectedCategory(value.category.id)
  }

  return (
    <>
       <li className="list-group-item" onClick={handleClick}>
          <img src={value.category.image} className="img-thumbnail" alt={`thumb-${value.category.name}`} />{" "}
          <span>{value.category.name}</span>{" "}
          <span className="text-right cat-value-number">({value.count})</span>
        </li>
    </>
  )
}

export default SuggestionCategoriesCard
