import React, {useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";

const ProjectTopTenEntryEditModal = ({id,isOpen, closeModal, object, handleSubmit}) => {
  let arrayEntry = []

  for (const property in object) {
    let obj = {};
    obj[property] = object[property];
    arrayEntry.push(obj)
  }
  const [state, setState] = useState(object)

  const handleChange = (e) => {
    let values = {...state}
    values[e.target.name] = e.target.value
    setState(values)
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(id,state)
    closeModal();
  };

  return (
    <>
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header className="text-white" style={{ backgroundColor: "#309897" }} closeButton>
        <Modal.Title>Edit Entry</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          {arrayEntry.map((field, idx) => {  
            const name = Object.keys(field)[0]
            const val = arrayEntry[idx][name]
            return (
              <Fragment key={idx}>
                <div className="form-group mt-2 mb-2">
                  <label htmlFor={name}>
                    {name}
                  </label>
                  {name === "Name" ? <input
                    className="form-control"
                    type="text"
                    name={name}
                    defaultValue={val || ''}
                    onChange={(event) => handleChange(event)}
                    required
                  /> : <textarea
                    className="form-control"
                    type="text"
                    name={name}
                    defaultValue={val || ''}
                    onChange={(event) => handleChange(event)}
                    required
                  />}
                </div>
              </Fragment>
            )
          })}
           <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              Edit
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default ProjectTopTenEntryEditModal