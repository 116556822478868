/* eslint-disable */
import React, { Fragment, useState } from "react";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import {
  deleteProjectSdgs,
  postProjectSdg,
} from "../../../services/Sdgs/projectSdgs";

const EditSdgs = ({
  showEditSdg,
  setShowEditSdg,
  editSdgs,
  projectID,
  setEditSdgs,
  setProjectSdgs,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const onChangeValue = (e) => {
    if (e.target.checked === true) {
      setSelectedValues([...selectedValues, e.target.value]);
    }
  };

  const persistNewSdgs = async (sdgID) => {
    try {
      await postProjectSdg(projectID, sdgID);
    } catch (error) {
      console.log("Something went wrong");
    }

    return;
  };

  const saveNewEdits = (e) => {
    e.preventDefault();
    let result = _.uniq(selectedValues);
    result.forEach((value) => {
      let integer = parseInt(value);
      persistNewSdgs(integer);
    });
    window.location.reload();
  };

  const handleRemove = async (e, sdgID) => {
    e.preventDefault();
    let initialState = editSdgs;

    const results = editSdgs.filter((value) => {
      if (value.id !== sdgID) return value;
    });
    setEditSdgs([...results]);

    try {
      await deleteProjectSdgs(projectID, sdgID);
    } catch (error) {
      console.log("something went wrong", error);
      setEditSdgs([...initialState]);
    }
  };

  const displaySdgs = () => {
    let results = editSdgs.map((value, idx) => {
      if (value.selected === false) {
        return (
          <Fragment key={idx}>
            <div className="form-check">
              <label className="form-check-label">
                <img
                  src={value.imageUrl}
                  alt={value.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                />
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                value={value.id}
                id="flexCheckDefault"
                defaultChecked={value.selected}
                onChange={(e) => onChangeValue(e)}
              />
            </div>
          </Fragment>
        );
      } else if (value.selected === true) {
        return (
          <Fragment key={idx}>
            <div className="form-check">
              <div
                onClick={(e) => handleRemove(e, value.id)}
                className="remove-sdg-button"
              >
                <i className="fa fa-times-circle" aria-hidden="true"></i>
              </div>
              <label className="form-check-label">
                <img
                  src={value.imageUrl}
                  alt={value.name}
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "10px",
                  }}
                />
              </label>
            </div>
          </Fragment>
        );
      }
    });

    return results;
  };

  return (
    <Modal show={showEditSdg} onHide={() => setShowEditSdg(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit sdgs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sdg-images-edit">{displaySdgs()}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          className="create-project-btn m-3 btn"
          onClick={(e) => saveNewEdits(e)}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSdgs;
