import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";

const DisplayLinkedProjects = ({
  displayLinkedProjects,
  setDisplayLinkedProjects,
  listSpecificSuggestionProjects,
  setlistSpecificSuggestionProjects,
}) => {
  const [state, setState] = useState({ right: false });
  const { suggestionProjects } = listSpecificSuggestionProjects;
  const history = useHistory()

  useEffect(() => {
    if (displayLinkedProjects === true) {
      setState({ right: true });
    }
  }, [displayLinkedProjects]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = (anchor) => {
    toggleDrawer(anchor, false);
  };

  const handlePageNavigation = (id) => {
    history.push({
      pathname: `/projects/${id}`,
    });
  };

  const displayProjects = () => {
    if (suggestionProjects.length < 1)
      return (
        <Alert severity="info">
          <AlertTitle>Empty</AlertTitle>
          <strong>No linked projects</strong>
        </Alert>
      );

    const results = suggestionProjects.map((value, index) => {
      return (
        <Fragment key={index}>
          <ListItem
            alignItems="flex-start"
            disableGutters
            onClick={() => handlePageNavigation(value.id)}
            className="project-linked-list"
          >
            <ListItemAvatar>
              {value.image.url !== null ? (
                <Avatar
                  alt={`${value.name}`}
                  src={`${value.image.url}`}
                  variant="square"
                />
              ) : (
                <Avatar
                  alt="Project"
                  src="/static/images/avatar/1.jpg"
                  variant="square"
                />
              )}
            </ListItemAvatar>
            <ListItemText
              primary={`${value.name}`}
              secondary={<React.Fragment></React.Fragment>}
            />
          </ListItem>
          <Divider />
        </Fragment>
      );
    });

    return results;
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => handleClick(anchor)}
      onKeyDown={() => handleClick(anchor)}
    >
      <List
        sx={{ bgcolor: "background.paper", width: "100%" }}
        subheader={
          <>
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              color="primary"
            >
              {" "}
              LINKED PROJECTS
              <IconButton
                variant="outlined"
                className="linked-project-close"
                onClick={() => {
                  setState(false);
                  setDisplayLinkedProjects(false);
                  setlistSpecificSuggestionProjects({
                    selectedID: null,
                    suggestionProjects: [],
                  });
                }}
              >
                <HighlightOffTwoToneIcon fontSize="large" color="primary" />
              </IconButton>
            </ListSubheader>
            <Divider />
          </>
        }
      >
        {displayProjects()}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={state["right"]}>
        {list("right")}
      </Drawer>
    </div>
  );
};

export default DisplayLinkedProjects;
