/* eslint-disable eqeqeq */
import React,{ useState } from 'react'
import Modal from "react-bootstrap/Modal";

const LegendModal = ({isOpen,closeModal, categories, associations,handleLegendSubmit}) => {
  
  const [indicator, setIndicator] = useState('')
  const [basicMeaning, setBasicMeaning] = useState('')
  const [category, setCategory] = useState('')
  const [association, setAssociation] = useState('')
  const [purpose, setPurpose] = useState('')
  const [googleDOCName, setGoogleDOCName] = useState('')
  const [googleDOCLink, setGoogleDOCLink] = useState('')

  const handleSumbit = (e) => {
    e.preventDefault()
    const data = {
      indicator: indicator,
      basic_meaning: basicMeaning,
      legend_category_id: category == '' ? 1 : category,
      legend_association_id: association == '' ? 1 : association,
      purpose: purpose,
      google_doc_name: googleDOCName,
      google_doc_link: googleDOCLink
    }
    handleLegendSubmit(data)
    setIndicator('')
    setBasicMeaning('')
    setPurpose('')
    setGoogleDOCName('')
    setGoogleDOCLink('')
    closeModal()
  }

  return (
    <>
       <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header className="text-white" style={{"backgroundColor": "#047C7C"}} closeButton>
          <Modal.Title>Add New Legend Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSumbit}>
            <div className="form-group">
              <label htmlFor="category">Select Category</label>
              <select className='form-control' name="category" id="category" onChange={e => setCategory(e.target.value)}>
                {
                  categories.map(cat => <option key={cat.name} value={cat.id}>{cat.name}</option>)
                }
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="category">Select Association</label>
              <select className='form-control' name="association" id="association" onChange={e => setAssociation(e.target.value)}>
                {
                  associations.map(item => <option key={item.associations.name} value={item.associations.id}>{item.associations.name}</option>)
                }
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="indicator">Indicator</label>
              <input
                className="form-control"
                id="indicator"
                type="text"
                name="indicator"
                value={indicator}
                onChange={(e) => setIndicator(e.target.value)}
                placeholder="Enter Indicator"
              />
            </div>
            <div className="form-group">
              <label htmlFor="basic-meaning">Basic Meaning</label>
              <textarea
                className="form-control"
                id="basic-meaning"
                type="text"
                name="basic-meaning"
                value={basicMeaning}
                onChange={(e) => setBasicMeaning(e.target.value)}
                placeholder="Type basic meaning here"
              />
            </div>
            <div className="form-group">
              <label htmlFor="purpose">Purpose</label>
              <textarea
                className="form-control"
                id="purpose"
                type="text"
                name="purpose"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                placeholder="Type purpose here"
              />
            </div>
            <div className="form-group">
              <label htmlFor="google_doc_name">Google DOC Name</label>
              <input
                className="form-control"
                id="google_doc_name"
                type="text"
                name="google_doc_name"
                value={googleDOCName}
                onChange={(e) => setGoogleDOCName(e.target.value)}
                placeholder="Type google doc name here"
              />
            </div>
            <div className="form-group">
              <label htmlFor="google_doc_link">Link</label>
              <input
                className="form-control"
                id="google_doc_link"
                type="url"
                name="google_doc_link"
                value={googleDOCLink}
                onChange={(e) => setGoogleDOCLink(e.target.value)}
                placeholder="Type link to google doc here"
              />
            </div>
            <hr />
            <div className="form-group float-right">
              <button className="add-btn rounded" type="submit">
                Add New
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
       </Modal>
    </>
  )
}

export default React.memo(LegendModal)
/* eslint-enable eqeqeq */