import React from "react";
import parse from "html-react-parser";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Button } from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";

const ProjectDescription = ({
  description,
  projectName,
  toggleProjectSpeedDialMenu,
}) => {
  return (
    <>
      <div className="project-dec-title-bar-ss">
        <SummarizeIcon /> <span>Project Description</span>{" "}
        <Button
          variant="contained"
          color="secondary"
          className="ml-5 project-edit-button"
          startIcon={<EditTwoToneIcon color="primary" />}
          onClick={() => toggleProjectSpeedDialMenu("editproject", true)}
        >
          Edit project
        </Button>
      </div>
      <div className="project-description-container-ss">
        <div className="actual-description">{parse(description)}</div>
      </div>
    </>
  );
};

export default ProjectDescription;
