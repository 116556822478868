import axios from "axios";
import { BASEURL } from "../../API/apiData";
import SUGGESTION_RESPONSE from "./suggestionResponseTypes";

export const fetchSuggestionResponseRequest = () => ({
  type: SUGGESTION_RESPONSE.FETCH_REQUEST,
});

export const fetchSuggestionResponseSuccess = (response) => ({
  type: SUGGESTION_RESPONSE.FETCH_SUCCESS,
  payload: response,
});

export const fetchSuggestionResponseFailure = (error) => ({
  type: SUGGESTION_RESPONSE.FETCH_FAILURE,
  payload: error,
});

export const createSuggestionResponseRequest = () => ({
  type: SUGGESTION_RESPONSE.CREATE_REQUEST,
});

export const createSuggestionResponseSuccess = (response) => ({
  type: SUGGESTION_RESPONSE.CREATE_SUCCESS,
  payload: response,
});

export const createSuggestionResponseFailure = (error) => ({
  type: SUGGESTION_RESPONSE.CREATE_FAILURE,
  payload: error,
});

export const fetchSuggestionResponse = (sId) => (dispatch) => {
  const token = localStorage.getItem("token");

  dispatch(fetchSuggestionResponseRequest());
  return axios
    .get(`${BASEURL}suggestions/${sId}/suggestion_responses`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchSuggestionResponseSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchSuggestionResponseFailure(error));
    });
};

export const createSuggestionResponse = (sId, data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(createSuggestionResponseRequest());
  return axios
    .post(`${BASEURL}suggestions/${sId}/suggestion_responses`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(createSuggestionResponseSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(createSuggestionResponseFailure(error));
    });
};
