import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { FETCH_PROFILE_FAILURE, FETCH_PROFILE_REQUEST, FETCH_PROFILE_SUCCESS,
   UPDATE_PROFILE_FAILURE, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, FETCH_ALL_PROFILES, FETCH_ALL_PROFILES_SUCCESS, FETCH_ALL_PROFILES_FAILURE } from './profileTypes';

export const fetchProfileRequest = () =>({
  type: FETCH_PROFILE_REQUEST,
})

export const fetchProfileSuccess = (response) =>({
  type: FETCH_PROFILE_SUCCESS,
  payload: response
})

export const fetchProfileFailure = (error) =>({
  type: FETCH_PROFILE_FAILURE,
  payload: error
})

export const updateProfileRequest = () =>({
  type: UPDATE_PROFILE_REQUEST,
})

export const updateProfileSuccess = (response) =>({
  type: UPDATE_PROFILE_SUCCESS,
  payload: response
})

export const updateProfileFailure = (error) =>({
  type: UPDATE_PROFILE_FAILURE,
  payload: error
})


export const fetchAllProfileRequest = () =>({
  type: FETCH_ALL_PROFILES,
})

export const fetchAllProfileSuccess = (response) =>({
  type: FETCH_ALL_PROFILES_SUCCESS,
  payload: response
})

export const fetchAllProfileFailure = (error) =>({
  type: FETCH_ALL_PROFILES_FAILURE,
  payload: error
})

export const fetchAllProfile = () => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchAllProfileRequest());
  return axios.get(`${BASEURL}profiles`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchAllProfileSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchAllProfileFailure(error));
    });
};



export const fetchProfile = (id) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchProfileRequest());
  return axios.get(`${BASEURL}profiles/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchProfileSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchProfileFailure(error));
    });
};


export const updateProfile = (id,data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(updateProfileRequest());
  return axios.put(`${BASEURL}profiles/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(updateProfileSuccess(response.data));
    }).catch(error => {
      return dispatch(updateProfileFailure(error));
    });
};