import React, {Fragment} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";

function SuggestionRightDrawer({setToggleDrawer, stateToggleDrawer, drawerProfiles}) {
  const history = useHistory();
  const navigateToProfile = (profile) => {
    const {id} = profile
    history.push({
      pathname: `/profile/${id}`,
    });
  } 

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setToggleDrawer(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {drawerProfiles.map((text, index) => (
          <Fragment key={index}>
          <ListItem onClick={() => navigateToProfile(text)}>
            <ListItemButton>
              <Avatar
              alt="profile avatar"
              src={`${text.profile_avatar.url}`}
              />
              &nbsp;
              &nbsp;
              <ListItemText primary={`${text.first_name} ${text.last_name}`} />
            </ListItemButton>
          </ListItem>
          <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  );

  return(
      <Fragment>
        <Drawer
          anchor={"right"}
          open={stateToggleDrawer}
          onClose={() => setToggleDrawer(false)}
        >
          {list("right")}
        </Drawer>
      </Fragment>
  )
}

export default SuggestionRightDrawer;
