/*eslint-disable */
import React, { useState } from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import { toast } from "react-toastify";

const NeedEditModal = ({
  isOpen,
  closeModal,
  projects = [],
  categories,
  data,
  handleSubmit,
}) => {
  const [name, setName] = useState(data.name);
  const [description, setDescription] = useState(data.description);
  const [categoryId, setCategoryId] = useState(data.category_id);
  const [projectId, setProjectId] = useState(data.project_id);

  const displayProjects = () => {
    if (!_.isEmpty(projects)) {
      return (
        <>
          {projects.map((pro) => (
            <option key={pro.id} value={pro.id}>
              {pro.name}
            </option>
          ))}
        </>
      );
    }
  };


  const displayCategories = () => {
    if (!_.isEmpty(categories)) {
      return (
        <>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </>
      );
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault()
    if (projectId == "") {
      toast.error("Please select a project from list");
    } else {
      const xData = {
        need: {
          name,
          description,
          need_category_id: categoryId,
          project_id: projectId,
        },
      };
      handleSubmit(data.id, xData);
      closeModal();
    }
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>Edit Need</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="name">Need</label>
            <input
              className="form-control"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </div>
          {_.isEmpty(projects) ? (
            <>
              {" "}
              <label htmlFor="project">Select Category</label>
              <select
                className="form-control"
                name="category"
                id="category-list"
                defaultValue={data.category_id}
                onChange={(e) => setCategoryId(e.target.value)}
              >
                <option value={""}></option>
                {displayCategories()}
              </select>
            </>
          ) : (
            <div className="form-group">
              <label htmlFor="project">Select Project</label>
              <select
                className="form-control"
                name="project"
                id="project-list"
                defaultValue={categoryId}
                onChange={(e) => setProjectId(e.target.value)}
              >
                <option value={""}></option>
                {displayProjects()}
              </select>
            </div>
          )}
          <div className="form-group float-right mt-2">
            <button className="add-btn rounded text-white" type="submit">
              Edit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default NeedEditModal;
