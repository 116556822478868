import React, { useState, Fragment } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "./ProjectCalender.module.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Chip from "@mui/material/Chip";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PeopleIcon from "@mui/icons-material/People";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import { Divider, Grid, Paper } from "@material-ui/core";
import DialogActions from "@mui/material/DialogActions";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import LinearProgress from "@mui/material/LinearProgress";
import ReactHtmlParser from "html-react-parser";
import DOMPurify from "dompurify";
import AddLinkIcon from "@mui/icons-material/AddLink";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import { useHistory } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import {
  createNewOpenitemComment,
  openItemProfileAssignment,
} from "../../../services/openItem/openItemService";
import { NotificationManager } from "react-notifications";
import EditOpenItem from "./EditOpenItem";
import Checkbox from "@mui/material/Checkbox";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

// ==== BOOTSTRAP APPLICATION IN MATERIAL UI ====
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// ==== BREAD CRUMBS COMPONENT ====
const DisplayBreadCrumbs = ({ creatorProfile }) => {
  const history = useHistory();

  const handlePageNavigation = (id) => {
    history.push({
      pathname: `/profile/${id}`,
    });
  };

  const imgLink = creatorProfile.profile_avatar
    ? creatorProfile.profile_avatar.url
    : null;
  return (
    <div role="presentation">
      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        <ListItem onClick={() => handlePageNavigation(creatorProfile.id)}>
          <ListItemButton>
            <ListItemAvatar>
              <Avatar alt={"profile image"} src={imgLink} />
            </ListItemAvatar>
            <ListItemText
              primary={`${creatorProfile.first_name} ${creatorProfile.last_name}`}
              className={styles.creator_name}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

// ==== DESCRIPTION COMPONENT ====
const Description = ({ description }) => {
  const sanitizedData = DOMPurify.sanitize(description);
  return (
    <div className={styles.open_item_description_container}>
      {" "}
      {ReactHtmlParser(sanitizedData)}
    </div>
  );
};

// ==== COMMENTS COMPONENT =====
const Comments = ({ openItemComments, handleSingleOpenItem, id }) => {
  const [OpenCommentForm, setOpenCommentForm] = useState(false);
  const [comment, setComment] = useState("");

  const displayCommentForm = () => {
    if (OpenCommentForm === false) return null;

    const handleComment = async () => {
      const data = {
        open_item_comment: {
          open_item_id: id,
          comment: comment,
        },
      };

      try {
        const result = await createNewOpenitemComment(data);
        if (result.message === "Open item Comment Created Succesfully") {
          setComment("");
          handleSingleOpenItem(id);
          setOpenCommentForm(false);
        }
      } catch (error) {
        NotificationManager.error(
          "Something went wrong creating the comment kindly try again"
        );
      }
    };

    return (
      <div className="form-group">
        <CKEditor
          className="form-control"
          editor={ClassEditor}
          data={comment}
          onChange={(event, editor) => {
            const data = editor.getData();
            setComment(data);
          }}
        />
        <Button
          variant="contained"
          onClick={handleComment}
          color="secondary"
          endIcon={<SendIcon />}
          className="mt-3 mb-2"
        >
          Send Comment
        </Button>
      </div>
    );
  };

  const displayComments = () => {
    // If there are no comments to be displayed, show a message saying so:
    if (openItemComments.length < 1) {
      return (
        <Grid container wrap="nowrap" spacing={2}>
          <Grid justifyContent="left" item xs zeroMinWidth>
            <h6>No comments yet! Be the first to comment...</h6>
          </Grid>
        </Grid>
      );
    }

    const results = openItemComments.map((item, idx) => {
      const sanitizedData = DOMPurify.sanitize(item.comment);
      return (
        <Fragment key={idx}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar
                alt={item.profile.first_name}
                src={
                  item.profile.profile_avatar
                    ? item.profile.profile_avatar.url
                    : null
                }
              />
            </Grid>
            <Grid justifyContent="left" item xs zeroMinWidth>
              <h4
                style={{ margin: 0, textAlign: "left" }}
              >{`${item.profile.first_name} ${item.profile.last_name}`}</h4>
              <p style={{ textAlign: "left" }}>
                {ReactHtmlParser(sanitizedData)}
              </p>
              <p style={{ textAlign: "left", color: "gray" }}>
                posted on {item.created_at}
              </p>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
        </Fragment>
      );
    });

    return results;
  };

  return (
    <div style={{ padding: 14 }} className="App">
      <div className={styles.comments_form_container}>
        <Chip
          variant="outlined"
          className={styles.add_comment_btn}
          label={OpenCommentForm ? "Close Form" : "New Comment"}
          onClick={() => setOpenCommentForm(!OpenCommentForm)}
          icon={<AddCircleIcon />}
          color="secondary"
        />
        <div className={styles.comment_form_display}>
          {displayCommentForm()}
        </div>
      </div>
      <Paper style={{ padding: "40px 20px" }}>{displayComments()}</Paper>
    </div>
  );
};

// ==== COMFIRM DELETION COMPONENT ====
const ConfirmDeletion = ({
  openConfirmation,
  closeConfirmation,
  setDeleteOpenItem,
}) => {
  return (
    <Fragment>
      <Dialog
        open={openConfirmation}
        onClose={closeConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "red",
            textTransform: "uppercase",
            fontFamily: "Roboto, sans-serif",
          }}
        >
          {"Are You sure you want to delete this open Item?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteOpenItem(false)} variant="contained">
            Disagree
          </Button>
          <Button
            onClick={() => setDeleteOpenItem(false)}
            autoFocus
            variant="contained"
            color="error"
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

// ==== PROFILE COMPONENT ====
const DisplayProfile = ({
  profiles,
  open,
  setOpenProfile,
  creatorProfile,
  id,
  assignedProfiles,
  handleSingleOpenItem,
  fetchAllProjectOpenItems,
}) => {
  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const checkedProfiles = () => {
    let values = [];

    profiles.forEach((element) => {
      if (element.id !== creatorProfile.id) {
        values.push(element);
      }
    });

    function isEqual(obj1, obj2) {
      return obj1.id === obj2.id;
    }

    const uniqueValuesArray1 = values.filter(
      (obj1) => !assignedProfiles.some((obj2) => isEqual(obj1, obj2))
    );
    return uniqueValuesArray1;
  };

  const handleClose = () => {
    setOpenProfile(!open);
  };

  // ==== NB: fix this assigment logic its too heavy for many users ====
  const handleCreateAssignments = async () => {
    // Create assignments for each selected profile with the same question as in the creators profile
    checked.forEach(async (value) => {
      const assignmentData = {
        open_item_assigment: {
          open_item_id: id,
          profile_id: value.id,
        },
      };
      try {
        await openItemProfileAssignment(assignmentData);
        fetchAllProjectOpenItems();
      } catch (error) {
        NotificationManager.error("Something went wrong with the assignments");
      }
    });
    NotificationManager.success("Assigments Successful");
    handleClose();
    setChecked([]);
    handleSingleOpenItem(id);
    fetchAllProjectOpenItems();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        SELECT USERS
        <Button
          edge="end"
          variant="outlined"
          endIcon={<CloseIcon />}
          className="ml-5"
          onClick={() => setOpenProfile(!open)}
        >
          close
        </Button>
      </DialogTitle>

      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        {checkedProfiles().map((value, index) => {
          return (
            <Fragment key={index}>
              <ListItem
                key={value.id}
                secondaryAction={
                  <Checkbox edge="end" onChange={handleToggle(value)} />
                }
                disablePadding
              >
                <ListItemButton>
                  <ListItemAvatar>
                    <Avatar
                      alt={`${value.first_name} ${value.last_name}`}
                      src={
                        value.profile_avatar
                          ? `${value.profile_avatar.url}`
                          : null
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={value.id}
                    primary={`${value.first_name} ${value.last_name}`}
                  />
                </ListItemButton>
              </ListItem>
            </Fragment>
          );
        })}
      </List>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCreateAssignments}
          className="mt-5 mb-2"
          variant="contained"
          color="secondary"
          endIcon={<AssignmentTurnedInIcon />}
          disabled={checked.length < 1 && true}
        >
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// ===== PARENT COMPONENT ====
const OpenItemDisplay = ({
  handleClose,
  open,
  loaderSingleItem,
  singleOpenItemDetails,
  creatorProfile,
  openItemComments,
  handleSingleOpenItem,
  fetchAllProjectOpenItems,
  profiles,
  assignedProfiles,
}) => {
  const { detail, end, priority, start, title, id, allDay } =
    singleOpenItemDetails;

  // ==== STATES ====
  const [value, setValue] = useState("Description");
  const [deleteOpenItem, setDeleteOpenItem] = useState(false);
  const [editView, setEditView] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  // ==== ACTIONS ====
  const handleChange = (e, value) => {
    setValue(value);
  };

  // ==== TAB SELECTION ====
  const displayTabSelection = () => {
    if (value === "Description") return <Description description={detail} />;
    if (value === "Comments")
      return (
        <Comments
          openItemComments={openItemComments}
          handleSingleOpenItem={handleSingleOpenItem}
          id={id}
        />
      );
  };

  // ==== PRIORITY CHECK FUNCTION ====
  const checkPriority = (priority) => {
    if (priority === 1)
      return <Chip icon={<AddTaskIcon />} label="OI 1" color="error" />;
    if (priority === 2)
      return <Chip icon={<AddTaskIcon />} label="OI 2" color="secondary" />;
    if (priority === 3)
      return <Chip icon={<AddTaskIcon />} label="OI 3" color="primary" />;
    if (priority === 4)
      return <Chip icon={<AddTaskIcon />} label="OI 4" color="success" />;
    if (priority === 5)
      return <Chip icon={<AddTaskIcon />} label="OI 5" variant="outlined" />;
    if (priority === 6) return <Chip icon={<AddTaskIcon />} label="OI 6" />;
  };

  // ==== CHECK AND DISPLAY ASSIGNED PROFILES ====

  const displayAssignedProfiles = () => {
    if (assignedProfiles.length < 1) return null;
    let result = assignedProfiles.map((value, idx) => {
      return (
        <Fragment key={idx}>
          <Avatar
            alt={`${value.first_name ? value.first_name : `img${idx}`} `}
            src={value.profile_avatar ? value.profile_avatar.url : null}
          />
        </Fragment>
      );
    });

    return result;
  };

  return (
    <React.Fragment>
      <DisplayProfile
        profiles={profiles}
        open={openProfile}
        setOpenProfile={setOpenProfile}
        creatorProfile={creatorProfile}
        id={id}
        assignedProfiles={assignedProfiles}
        handleSingleOpenItem={handleSingleOpenItem}
        fetchAllProjectOpenItems={fetchAllProjectOpenItems}
      />
      <ConfirmDeletion
        openConfirmation={deleteOpenItem}
        setDeleteOpenItem={setDeleteOpenItem}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth={true}
      >
        {editView ? (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              <Button
                color="secondary"
                variant="contained"
                startIcon={<ArrowBackIosNewIcon />}
                onClick={() => setEditView(false)}
              >
                Back
              </Button>{" "}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setEditView(false);
                handleClose();
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 15,
                color: "secondary",
              }}
              color="secondary"
              fontSize="large"
            >
              <CloseIcon />
            </IconButton>

            <DialogContent dividers>
              {/* ==== This is the edit view ==== */}
              <EditOpenItem
                detail={detail}
                end={end}
                priority={priority}
                start={start}
                title={title}
                id={id}
                allDay={allDay}
                handleSingleOpenItem={handleSingleOpenItem}
                setEditView={setEditView}
                fetchAllProjectOpenItems={fetchAllProjectOpenItems}
              />
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              <Button
                color="secondary"
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => setEditView(true)}
                disabled={loaderSingleItem}
              >
                Edit
              </Button>{" "}
              <Button
                variant="contained"
                color="error"
                endIcon={<DeleteIcon />}
                onClick={() => setDeleteOpenItem(true)}
                disabled={loaderSingleItem}
              >
                Delete
              </Button>
            </DialogTitle>
            <IconButton
              disabled={loaderSingleItem}
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 15,
                color: "secondary",
              }}
              color="secondary"
              fontSize="large"
            >
              <CloseIcon />
            </IconButton>

            <DialogContent dividers>
              {loaderSingleItem === true ? (
                <>
                  <LinearProgress color="secondary" />
                  <div className={styles.loading_text}>
                    <h6>loading ...</h6>
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.bread_crumbs_cont}>
                    <DisplayBreadCrumbs creatorProfile={creatorProfile} />
                  </div>

                  <Typography
                    gutterBottom
                    variant="h4"
                    className={styles.open_item_title_con}
                  >
                    {title}
                  </Typography>
                  <hr />
                  <Typography
                    gutterBottom
                    variant="div"
                    className={styles.open_item_details_cont}
                  >
                    <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                      <ListItem>
                        <ListItemIcon>
                          <PriorityHighIcon color="error" />
                        </ListItemIcon>
                        <ListItemText
                          id="switch-list-label-priority"
                          primary="Priority"
                          className={styles.list_test_name_il}
                        />
                        {checkPriority(priority)}
                      </ListItem>

                      <ListItem>
                        <ListItemIcon>
                          <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText
                          id="switch-list-label-bluetooth"
                          primary="Start Date"
                          className={styles.list_test_name_il}
                        />
                        <Typography
                          className={styles.list_test_name_date}
                          variant="h6"
                        >
                          {" "}
                          {start}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText
                          id="switch-list-label-bluetooth"
                          primary="Due Date"
                          className={styles.list_test_name_il}
                        />
                        <Typography
                          className={styles.list_test_name_date}
                          variant="h6"
                        >
                          {" "}
                          {end}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText
                          id="switch-list-label-bluetooth"
                          primary="Assignee"
                          className={styles.list_test_name_il}
                        />
                        <AvatarGroup max={4}>
                          {displayAssignedProfiles()}
                        </AvatarGroup>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<AddLinkIcon />}
                          size="small"
                          className="ml-2"
                          onClick={() => setOpenProfile(true)}
                        >
                          Assign
                        </Button>
                      </ListItem>
                    </List>
                  </Typography>
                  <hr />
                  <Typography gutterBottom variant="div">
                    <Box sx={{ width: "100%" }}>
                      <Tabs
                        value={value}
                        onChange={(e, value) => handleChange(e, value)}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                      >
                        <Tab value="Description" label="Description" />
                        <Tab
                          value="Comments"
                          label={
                            <Badge
                              badgeContent={openItemComments.length}
                              color="secondary"
                              className={styles.badge_comments}
                            >
                              Comments
                            </Badge>
                          }
                        />
                      </Tabs>
                    </Box>
                    <div className={styles.tabselection_container}>
                      {displayTabSelection()}
                    </div>
                  </Typography>
                </>
              )}
            </DialogContent>
          </>
        )}
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default OpenItemDisplay;
