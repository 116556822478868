import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {  FETCH_PROJECTCATEGORY_FAILURE, FETCH_PROJECTCATEGORY_SUCCESS, FETCH_PROJECTCATEGORY_REQUEST } from "./projectCategoriesTypes";

  export const fetchProjectCategoryRequest = () =>({
    type: FETCH_PROJECTCATEGORY_REQUEST,
  })
  
  export const fetchProjectCategorySuccess = (response) =>({
    type: FETCH_PROJECTCATEGORY_SUCCESS,
    payload: response
  })
  
  export const fetchProjectCategoryFailure = (error) =>({
    type: FETCH_PROJECTCATEGORY_FAILURE,
    payload: error
  })

  // ==== Create Project category ====
  
export const fetchProjectCategory = () => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchProjectCategoryRequest());
    return axios.get(`${BASEURL}project_categories`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchProjectCategorySuccess(response.data));
      }).catch(error => {
        return dispatch(fetchProjectCategoryFailure(error));
      });
  }
