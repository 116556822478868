import { useQuery } from "react-query";
import { fetchProfiles } from "../../services/profiles/profileService";

const useGetProfiles = () => {
  const {
    isLoading: allProfilesLoading,
    isError: allProfilesError,
    data: allProfilesData,
    error: errorOccured,
  } = useQuery("Profiles", fetchProfiles);

  return {
    allProfilesData,
    allProfilesError,
    allProfilesLoading,
    errorOccured,
  };
};

export default useGetProfiles;
