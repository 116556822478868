import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_TOPTENS_SUCCESS, FETCH_TOPTENS_REQUEST, 
  FETCH_TOPTENS_FAILURE, CREATE_TOPTEN_REQUEST, 
  CREATE_TOPTEN_SUCCESS, CREATE_TOPTEN_FAILURE, CREATE_TOPTENUPDATE_REQUEST,
  CREATE_TOPTENUPDATE_SUCCESS,CREATE_TOPTENUPDATE_FAILURE} from './topTenTypes'

export const fetchTopTensRequest = () =>({
  type: FETCH_TOPTENS_REQUEST,
})

export const fetchTopTensSuccess = (response) =>({
  type: FETCH_TOPTENS_SUCCESS,
  payload: response
})

export const fetchTopTensFailure = (error) =>({
  type: FETCH_TOPTENS_FAILURE,
  payload: error
})

export const createTopTenRequest = () =>({
  type: CREATE_TOPTEN_REQUEST,
})

export const createTopTenSuccess = (response) =>({
  type: CREATE_TOPTEN_SUCCESS,
  payload: response
})

export const createTopTenFailure = (error) =>({
  type: CREATE_TOPTEN_FAILURE,
  payload: error
})

export const createTopTenUpdateRequest = () =>({
  type: CREATE_TOPTENUPDATE_REQUEST,
})

export const createTopTenUpdateSuccess = (response) =>({
  type: CREATE_TOPTENUPDATE_SUCCESS,
  payload: response
})

export const createTopTenUpdateFailure = (error) =>({
  type: CREATE_TOPTENUPDATE_FAILURE,
  payload: error
})

export const fetchTopTens = id => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchTopTensRequest());
  return axios.get(`${BASEURL}categories/${id}/top_tens`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchTopTensSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchTopTensFailure(error));
    });
};

export const createTopTen = (id, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createTopTenRequest());
  return axios.post(`${BASEURL}categories/${id}/top_tens`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createTopTenSuccess(response.data));
    }).catch(error => {
      return dispatch(createTopTenFailure(error));
    });
};

export const updateTopTen = (catId,ttId, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createTopTenUpdateRequest());
  return axios.put(`${BASEURL}categories/${catId}/top_tens/${ttId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createTopTenUpdateSuccess(response.data));
    }).catch(error => {
      return dispatch(createTopTenUpdateFailure(error));
    });
};
