/* eslint-disable eqeqeq, no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchLegendCategories,
  fetchLegendAssociations,
  createLegend,
} from "../redux";
import _ from "lodash";
import "../stylesheets/category.css";
import LoadingSpinner from "../components/LoadingSpinner";
import CategoryFilter from "../components/legends/CategoryFilter";
import AssociationFilter from "../components/legends/AssociationFilter";
import "../stylesheets/legend.css";
import LegendTableRow from "../components/legends/LegendTableRow";
import LegendModal from "../components/legends/LegendModal";
import Navbar from "../components/navbar/Navbar";

const LegendContainer = ({
  createItem,
  fetchCategories,
  fetchAssociations,
  legendAssociations,
}) => {
  const [categories, setCategories] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchAssociations()
      .then((res) => setAssociations(res.payload.result))
      .catch((err) => console.log("Internal Server Error"));
    fetchCategories()
      .then((res) => setCategories(res.payload.result))
      .catch((err) => console.log("Internal Server Error"));
  }, [fetchAssociations, fetchCategories]);

  const handleLegendSubmit = (data) => {
    createItem(data)
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => console.log("Internal Server Error"));
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const renderModal = () => {
    if (legendAssociations.data.loggedIn) {
      return (
        <LegendModal
          isOpen={open}
          closeModal={closeModal}
          categories={categories}
          associations={legendAssociations.data.result}
          handleLegendSubmit={handleLegendSubmit}
        />
      );
    }
  };

  const filterAssociations = (value) => {
    if (!_.isEmpty(legendAssociations.data.result) && value !== "") {
      const data = legendAssociations.data.result.filter(
        (item) => item.associations.id == value
      );
      setAssociations(data);
    } else if (value === "") {
      setAssociations(legendAssociations.data.result);
    }
  };

  const filterCategories = (value) => {
    console.log("Pending feature" + value);
  };

  const showLegends = () => {
    if (!_.isEmpty(legendAssociations.data.result)) {
      return (
        <>
          <div className="w-100 p-3 d-flex flex-row justify-content-between">
            <AssociationFilter
              associations={legendAssociations.data.result}
              filter={filterAssociations}
            />
            <button
              className="add-btn rounded tt-btn-2 mt-4"
              onClick={openModal}
            >
              <i className="fas fa-plus"></i> Add New
            </button>
            {/* <CategoryFilter categories={categories} filter={filterCategories}/> */}
          </div>
          <div className="legend-table-container">
            <table>
              <thead>
                <tr>
                  <th>Indicator</th>
                  <th>Basic Meaning</th>
                  <th>Category</th>
                  <th>Purpose</th>
                  <th>Google DOC</th>
                  <th className="text-center">
                    <i className="fas fa-cog"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {associations.map((item, idx) => (
                  <LegendTableRow
                    key={`al${idx}`}
                    association={item.associations}
                    legend={item.legend_items}
                    categories={categories}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
    }

    return (
      <>
        <LoadingSpinner />
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="legend-container container-fluid">
        {renderModal()}
        {showLegends()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  legendAssociations: state.legendAssociations,
});

const mapDispatchToProps = (dispatch) => ({
  createItem: (data) => dispatch(createLegend(data)),
  fetchCategories: () => dispatch(fetchLegendCategories()),
  fetchAssociations: () => dispatch(fetchLegendAssociations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LegendContainer);
/* eslint-enable eqeqeq, no-unused-vars */
