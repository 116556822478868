// ==== THIS FILES HANDLES QUERIES FOR ALL PROJECTS CONTAINER ====
import { useQuery } from "react-query";

// ==== SERVICES PATHS ====
import {
  fetchAllProjectCategories,
  fetchAllProjects,
  fetchMostRecentEnagedProject,
} from "../../services/projects/ProjectService";
import getAllSdgs from "../../services/Sdgs/allSdgs";

// ---- USED TO FETCH ALL PROJECTS ----
const UseFetchProjects = () => {
  const fetchProjects = async () => {
    const { result } = await fetchAllProjects();
    return result;
  };
  return useQuery({
    queryKey: ["allProjects"],
    queryFn: fetchProjects,
  });
};

// ---- USED TO FETCH ALL PROJECT CATEGORIES ----
const UseFetchAllProjectCategories = () => {
  const fetchCategories = async () => {
    const { result } = await fetchAllProjectCategories();
    return result;
  };
  return useQuery({
    queryKey: ["allProjectsCategories"],
    queryFn: fetchCategories,
  });
};

// ---- USED TO FETCH THE MOST ENGAGED PROJECT AND THE LAST 5 RECENT CREATED PROJECTS ----
const UseFetchMostRecentEngaged = () => {
  const fetchRecent = async () => {
    const { result } = await fetchMostRecentEnagedProject();
    return result;
  };

  return useQuery({
    queryKey: ["mostRecentEngagedProjects"],
    queryFn: fetchRecent,
  });
};

// ---- USED TO FETCH SDGS -----
const UseFetchSDGs = () => {
  const fetchSDGs = async () => {
    const { result } = await getAllSdgs();
    return result;
  };

  return useQuery({
    queryKey: ["sdgs"],
    queryFn: fetchSDGs,
    staleTime: 1000 * 60 * 30,
  });
};

export {
  UseFetchProjects,
  UseFetchAllProjectCategories,
  UseFetchMostRecentEngaged,
  UseFetchSDGs,
};
