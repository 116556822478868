import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

export const fetchUserRoles = async () => {
  try {
    const result = await axios.get(
      `${BASEURL}user_sub_roles`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserRole = async (id, data) => {
  try {
    const result = await axios.put(
      `${BASEURL}user_sub_roles/${id}`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
     console.log(error);
  }
};

export const fetchUsers =  async () => {
  try {
    const result = await axios.get(
      `${BASEURL}profiles`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
}


