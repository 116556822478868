import React, { useEffect, useState } from "react";
import DisplayProjects from "../components/DisplayProjects";
import Navbar from "../components/navbar/Navbar";
import {
  getAllFavoritesProjects,
  unFollow,
} from "../services/favorites/favoriteService";
import "../stylesheets/favorites.css";

function FavoriteContainer() {
  const [favorites, setFavorites] = useState([]);

  // === FETCH ALL FAVORITES ===
  const fetchAllFavorites = async () => {
    const { result } = await getAllFavoritesProjects();
    try {
      setFavorites(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllFavorites();
  }, []);

  const handleUnFollow = async (projectID) => {
    await unFollow(projectID);
    fetchAllFavorites();
  };

  return (
    <>
      <Navbar />
      <div className="display-favorites-container">
        <div className="project-container-header-name container-fluid">
          <h3 className="project-categories-header">My Favorites</h3>
          <div className="underline"></div>
        </div>
        <DisplayProjects projects={favorites} unfollowAction={handleUnFollow} />
      </div>
    </>
  );
}

export default FavoriteContainer;
