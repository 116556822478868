import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function CreateNewListingModal({
  listingShow,
  handlelistingModalClose,
  creatingList,
  blockInfo,
  projectID,
  bmcID,
  fetchlistingFunction,
  setListItems,
  items,
  setListingShow,
}) {
  const [name, setName] = useState("");
  const handleSave = async (e) => {
    e.preventDefault();
    const { id } = blockInfo;
    const { createList } = creatingList;
    const { fetchRoute } = fetchlistingFunction;

    try {
      await createList(projectID, bmcID, id, name);
      const { result } = await fetchRoute(projectID, bmcID);
      let response = result[0];
      setListItems(response[items]);

      setListingShow(false);
      setName("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={listingShow} onHide={handlelistingModalClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add new item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label>Name</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={name}
              onChange={(event, editor) => {
                const data = editor.getData();
                setName(data);
              }}
              required
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          className="create-project-btn m-3 btn"
          onClick={(e) => handleSave(e)}
        >
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateNewListingModal;
