import React from "react";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { editSpecificSwotDetail } from "../../../services/swotAnalysis/swotDetails";

function EditSwotModal({
  editname,
  detailID,
  projectID,
  swotID,
  dynamicRoute,
  swotTitleDetail,
  fetchSwotSpecificDetails,
  editState,
  setEditState,
}) {
  const handleClose = () => {
    setEditState({
      editModal: false,
      editname: "",
      detailID: null,
    });
  };
  const editListItem = async (e) => {
    e.preventDefault();
    let titleName = swotTitleDetail.toLowerCase();
    var data = {};
    data[`${titleName}_name`] = editname;

    const response = await editSpecificSwotDetail(
      projectID,
      swotID,
      dynamicRoute,
      data,
      detailID
    );
    if (!_.has(response, "result")) {
      return console.log("somthing went wrong");
    }
    fetchSwotSpecificDetails(dynamicRoute);
    handleClose();
  };

  return (
    <Modal
      show={editState.editModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <label> Title </label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editname}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditState({
                  ...editState,
                  editname: data,
                });
              }}
              required
            />
          </div>
          <button
            className="create-project-btn m-3 btn"
            onClick={(e) => editListItem(e)}
          >
            Update
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EditSwotModal;
