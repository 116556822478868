import React from "react";
import avatar from "../../images/avatar.jpeg";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

const NeedSupportMessage = ({ data }) => {
  return (
    <div className="master-need-support-msg">
      <div className="master-need-support-msg-avatar">
        {data.user.profile_avatar.url ? (
          <Tooltip
            interactive
            className="toolTip"
            title={`${data.user.first_name} ${data.user.last_name}`}
          >
            <Link to={`/profile/${data.user.id}`}>
              <img
                className="profile-avatar-thumb-sm ml-3"
                src={data.user.profile_avatar.url}
                alt="avatar"
              />
            </Link>
          </Tooltip>
        ) : (
          <Tooltip
            interactive
            className="toolTip"
            title={`${data.user.first_name} ${data.user.last_name}`}
          >
            <Link to={`/profile/${data.user.id}`}>
              <img
                className="profile-avatar-thumb-sm ml-3"
                src={avatar}
                alt="avatar"
              />
            </Link>
          </Tooltip>
        )}
      </div>
      <div className="master-need-support-msg-text">
        <p>{data.message}</p>
      </div>
    </div>
  );
};

export default NeedSupportMessage;
