import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { FETCH_NOTIFICATIONS_FAILURE, FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_REQUEST, UPDATE_NOTIFICATIONS_SUCCESS,UPDATE_NOTIFICATIONS_FAILURE} from './notificationTypes';

export const fetchNotificationsRequest = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
});

export const fetchNotificationsSuccess = response => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: response,
});

export const fetchNotificationsFailure = error => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const updateNotificationsRequest = () => ({
  type: UPDATE_NOTIFICATIONS_REQUEST,
});

export const updateNotificationsSuccess = response => ({
  type: UPDATE_NOTIFICATIONS_SUCCESS,
  payload: response,
});

export const updateNotificationsFailure = error => ({
  type: UPDATE_NOTIFICATIONS_FAILURE,
  payload: error,
});


export const fetchNotifications = () => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchNotificationsRequest());
  return axios.get(`${BASEURL}notifications`,{
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  })
    .then(response => {
      return dispatch(fetchNotificationsSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchNotificationsFailure(error));
    });
};

export const updateNotification = (id) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(updateNotificationsRequest());
  return axios.put(`${BASEURL}notifications/${id}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  })
    .then(response => {
      return dispatch(updateNotificationsSuccess(response.data));
    }).catch(error => {
      return dispatch(updateNotificationsFailure(error));
    });
};