import { BASEURL } from "../../API/apiData";

const deleteTop10 = async (projectID, top10ID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_top_tens/${top10ID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectTop10EntryComment = async (data) => {
  const {
    project_id,
    project_top_ten_id,
    project_top_ten_entry_id,
    commentData,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_top_tens/${project_top_ten_id}/project_top_ten_entries/${project_top_ten_entry_id}/project_top_ten_entries_comments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const getOneProjectTop10Entry = async (data) => {
  const { project_id, project_top_ten_id, id } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_top_tens/${project_top_ten_id}/project_top_ten_entries/${id}`,
    requestOptions
  );

  const results = await response.json();
  return results;
};

const editOfProjectTopEntryComment = async (data) => {
  const {
    project_id,
    project_top_ten_id,
    project_top_ten_entry_id,
    id,
    commentData,
  } = data;

  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_top_tens/${project_top_ten_id}/project_top_ten_entries/${project_top_ten_entry_id}/project_top_ten_entries_comments/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewCommentReponseForTop10Entry = async (data) => {
  const {
    project_id,
    project_top_ten_id,
    project_top_ten_entry_id,
    project_top_ten_entries_comment_id,
    commentData,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_top_tens/${project_top_ten_id}/project_top_ten_entries/${project_top_ten_entry_id}/project_top_ten_entries_comments/${project_top_ten_entries_comment_id}/project_top_ten_entries_comment_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editCommentReponseForTop10Entry = async (data) => {
  const {
    project_id,
    project_top_ten_id,
    project_top_ten_entry_id,
    project_top_ten_entries_comment_id,
    id,
    commentData,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_top_tens/${project_top_ten_id}/project_top_ten_entries/${project_top_ten_entry_id}/project_top_ten_entries_comments/${project_top_ten_entries_comment_id}/project_top_ten_entries_comment_responses/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  deleteTop10,
  createProjectTop10EntryComment,
  getOneProjectTop10Entry,
  editOfProjectTopEntryComment,
  createNewCommentReponseForTop10Entry,
  editCommentReponseForTop10Entry,
};
