import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import _ from "lodash";
import { logIn, checkLoggedInStatus } from "../redux";
import LoggedInComp from "../components/LoggedInComp";
import AppSignInForm from "../components/auth/AppSignInForm";
import AppAuthScreen from "../components/auth/AppAuthScreen";

const SignIn = ({ status, history, login, isLoggedIn }) => {
  useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);

  const [loader, setButtonLoader] = useState(false);
  const [disable, setDisableButton] = useState(false);

  const buttonLoader = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const logInUser = async ({ email, password }) => {
    setButtonLoader(true);
    setDisableButton(true);
    const userData = {
      user: {
        email: email,
        password: password,
      },
    };

    login(userData)
      .then((response) => {
        const result = response.payload;
        if (result.user) {
          localStorage.setItem("token", result.user.token);
          toast.success("Signin successful");
          setTimeout(() => {
            history.push("/dashboard");
          }, 3000);
        }
        if (result.response.status === 401) {
          setButtonLoader(false);
          setDisableButton(false);
          toast.error(result.response.data.message);
        }
        if (result.response.status === 422) {
          setButtonLoader(false);
          setDisableButton(false);
          toast.error("Invalid email or password");
        }
      })
      .catch((error) => console.log(error));
  };

  const showData = () => {
    if (!_.isEmpty(status.data)) {
      return (
        <>
          <LoggedInComp username={status.data.user.username} />
          {setTimeout(() => {
            history.push("/dashboard");
          }, 2000)}
        </>
      );
    }
    if (_.isEmpty(status.data) && status.errorMsg) {
      return (
        <>
          <div className="row">
            <AppAuthScreen />
            <div className="col-md-7">
              <ToastContainer />
              <div className="register-content-form p-3">
                <h1 className="text-center mt-5">Log into your account</h1>
                <div className="sign-in-register">
                  <span className="account-quiz">
                    You dont have an account? The application access is Invite
                    only
                  </span>{" "}
                  &#160;
                </div>
                <AppSignInForm
                  logInUser={logInUser}
                  loader={loader}
                  disable={disable}
                  buttonLoader={buttonLoader}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="container-fluid">{showData()}</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(logIn(data)),
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
