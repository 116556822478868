import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_COREVALUE_REQUEST, CREATE_COREVALUE_SUCCESS, CREATE_COREVALUE_FAILURE, FETCH_COREVALUE_FAILURE, FETCH_COREVALUE_SUCCESS, FETCH_COREVALUE_REQUEST } from "./corevalueTypes";

export const createCoreValueRequest = () =>({
    type: CREATE_COREVALUE_REQUEST,
  })
  
  export const createCoreValueSuccess = (response) =>({
    type: CREATE_COREVALUE_SUCCESS,
    payload: response
  })
  
  export const createCoreValueFailure = (error) =>({
    type: CREATE_COREVALUE_FAILURE,
    payload: error
  })

  export const fetchCoreValueRequest = () =>({
    type: FETCH_COREVALUE_REQUEST,
  })
  
  export const fetchCoreValueSuccess = (response) =>({
    type: FETCH_COREVALUE_SUCCESS,
    payload: response
  })
  
  export const fetchCoreValueFailure = (error) =>({
    type: FETCH_COREVALUE_FAILURE,
    payload: error
  })
  
export const fetchCoreValue = (profileID) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchCoreValueRequest());
    return axios.get(`${BASEURL}profiles/${profileID}/core_values`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchCoreValueSuccess(response.data));
      }).catch(error => {
        return dispatch(fetchCoreValueFailure(error));
      });
  }


  export const createCoreValue = (profileID, data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createCoreValueRequest());
    return axios.post(`${BASEURL}profiles/${profileID}/core_values`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    } )
      .then(response => {
        return dispatch(createCoreValueSuccess(response.data));
      }).catch(error => {
        return dispatch(createCoreValueFailure(error));
      });
  };
  