import React from 'react'

function ProfileIdeaCard({idea,date}) {
  return (
    <div className="p-3 profile-idea-card">
      <h5>{idea}</h5>
      <h6>{date}</h6>
    </div>
  )
}

export default ProfileIdeaCard
