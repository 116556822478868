import React from 'react';
import logo from '../../assets/img/logo.png';
import { Link, HashRouter as Router, useHistory } from "react-router-dom";
import _ from "lodash";
const LandingNav = ({status}) => {

  let history = useHistory();

  return (
    <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="landing-navigation">
          <div className="nav-content d-flex justify-content-between">
            <Link className="navbar-brand d-lg-none" to="">
              {" "}
              <img src={logo} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <Link className="navbar-brand d-none d-lg-block" to={"/"}>
              {" "}
              <img src={logo} alt="" />
            </Link>
            <ul className="navbar-nav navigation-bar ml-auto">
              {!_.isEmpty(status.data) ? (
                <li className="nav-item m-auto">
                  <button
                    className="nav-button text-white"
                    onClick={() => history.push("/dashboard")}
                  >
                    DashBoard
                  </button>
                </li>
              ) : (
                <>
                  <li className="nav-item m-auto">
                    <button
                      className="nav-button text-white"
                      onClick={() => history.push("/signin")}
                    >
                      Sign In
                    </button>
                  </li>
                  &nbsp;&nbsp;
                  <li className="nav-item m-auto">
                    <button
                      className="nav-button text-white"
                      onClick={() => history.push("/signup")}
                    >
                      Sign Up
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </Router>
  );
}

export default LandingNav
