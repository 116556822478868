/* eslint-disable eqeqeq */
const monthToWord = (unit) => {
  let miw = "";
  switch (unit) {
    case 1:
      miw = "January";
      break;
    case 2:
      miw = "February";
      break;
    case 3:
      miw = "March";
      break;
    case 4:
      miw = "April";
      break;
    case 5:
      miw = "May";
      break;
    case 6:
      miw = "June";
      break;
    case 7:
      miw = "July";
      break;
    case 8:
      miw = "August";
      break;
    case 9:
      miw = "September";
      break;
    case 10:
      miw = "October";
      break;
    case 11:
      miw = "November";
      break;
    case 12:
      miw = "December";
      break;
    default:
      break;
  }

  return miw
};

export const displayDate = (value) => {
  const timeNow = new Date();
  const parsedNow = Date.parse(timeNow);
  const commentTime = Date.parse(value);
  let createdAt = parsedNow - commentTime;
  let minutes = Math.floor(createdAt / 60000);
  if (minutes > 60 && minutes < 1440) {
    let hours = Math.floor(minutes / 60);
    return {
      value: hours,
      unit: "hr",
    };
  } else if (minutes > 1440) {
    let days = Math.floor(minutes / 1440);
    return {
      value: days,
      unit: "days",
    };
  }
  return {
    value: minutes,
    unit: "min",
  };
};

export const displayTime = (unit, value) => {
  const unitValues = ["hr", "days", "min"];
  if (unit === unitValues[0]) {
    if (value == 1) {
      return "hour ago";
    }
    return "hours ago";
  } else if (unit === unitValues[1]) {
    if (value == 1) {
      return "day ago";
    }
    return "days ago";
  } else {
    if (value == 1) {
      return "minute ago";
    }
    return "minutes ago";
  }
};

export const displayFullDate = (unit) => {
  const day = new Date(unit).getDate();
  const month = new Date(unit).getMonth();
  const year = new Date(unit).getFullYear();

  const date = `${day} ${monthToWord(month + 1)} ${year}`;

  return date;
};

export const displayFirstLetter = (value) => {
  const myArr = value.split("");
  return myArr[0];
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
/*eslint-enable eqeqeq */