/*eslint-disable */
import React, { useEffect, useState } from "react";
import _, { update } from "lodash";
import BugReportIcon from "@mui/icons-material/BugReport";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styles from "./cssModules/main.module.css";
import MobileDisplayUpdates from "./mobile/MobileDisplayUpdates";
import DeskTopDisplayUpdates from "./deskTop/DeskTopDisplayUpdates";
import SideBar from "./mobile/SideBar";
import CommentBox from "../singleProject/globals/CommentBox";
import UpdateModal from "./UpdateModal";
import UpdateEditsModal from "./UpdateEditsModal";

const menuItems = [
  {
    name: "Completed Updates",
    id: 1,
    icon: (
      <CheckCircleIcon
        fontSize="medium"
        sx={{
          color: "black",
        }}
      />
    ),
  },
  {
    name: "Upcoming Updates",
    id: 2,
    icon: (
      <UpcomingIcon
        fontSize="medium"
        sx={{
          color: "black",
        }}
      />
    ),
  },
  {
    name: "Bug Tracker",
    id: 3,
    icon: (
      <BugReportIcon
        fontSize="medium"
        sx={{
          color: "black",
        }}
      />
    ),
  },
];

const UpdateMain = ({
  updates,
  handleEdit,
  location,
  handleCreateUpdate,
  handleUpdateNotice,
}) => {
  let initialState =
    location.state != undefined && !_.isEmpty(updates)
      ? updates.complete.filter((arr) => arr.id == location.state.id)[0]
      : {};
  // ==== STATES ====
  const [updateInfo, setUpdateInfo] = useState({});
  const [editData, setEditData] = useState({
    id: null,
    title: "",
    details: "",
    is_complete: null,
  });
  const [editModal, setEditModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const [menuSelection, setMenuSelection] = useState({
    name: "Completed Updates",
  });
  const [commentModal, setCommentModal] = useState(false);
  // ==== COMMENT BOX ====
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  const [editSubmitButton, setEditSubmitButton] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    setUpdateInfo(initialState);
  }, [updates]);

  // ==== COMMENTS ====
  const refreshTheUpdates = () => {
    console.log("handle refresh");
  };

  const handleCreateComment = () => {
    console.log("comment Created");
  };

  const handleEditOfComment = () => {
    console.log("Edit comment");
  };

  const handleUpvoteOfComment = () => {
    console.log("Upvotes");
  };

  const handleUnvoteOfComment = () => {
    console.log("Unvote");
  };

  const handleCreateOfCommentReponses = () => {
    console.log("responses");
  };

  const handleEditOfCommentResponses = () => {
    console.log("Edit responses");
  };

  const handleUpvoteOfCommentResponses = () => {
    console.log("Upvotes responses");
  };

  const handleUnvoteOfCommentResponses = () => {
    console.log("Unvote responses");
  };

  return (
    <div className={styles.update_notice_main_container}>
      <UpdateEditsModal
        data={editData}
        setEditData={setEditData}
        closeModal={() => setEditModal(false)}
        isOpen={editModal}
        handleEdit={handleUpdateNotice}
      />
      <UpdateModal
        isOpen={open}
        closeModal={() => setOpen(false)}
        handleCreate={handleCreateUpdate}
      />
      <CommentBox
        handleCreateComment={handleCreateComment}
        handleEditOfComment={handleEditOfComment}
        comments={[]}
        open={commentModal}
        setOpen={setCommentModal}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Update"
        labelDetails={""}
        commentBoxIdea={""}
        commentBoxPurpose={""}
        editSubmitButton={editSubmitButton}
        refreshGoals={refreshTheUpdates}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
      />
      <SideBar
        menuItems={menuItems}
        state={state}
        setState={setState}
        toggleDrawer={toggleDrawer}
        setMenuSelection={setMenuSelection}
        menuSelection={menuSelection}
      />
      <MobileDisplayUpdates
        updates={updates}
        updateInfo={updateInfo}
        setUpdateInfo={setUpdateInfo}
        handleEdit={handleEdit}
        toggleDrawer={toggleDrawer}
        setCommentModal={setCommentModal}
        selection={menuSelection}
        createUpdateModalOpen={() => setOpen(true)}
        setEditModal={setEditModal}
        setEditData={setEditData}
      />
      <DeskTopDisplayUpdates
        selection={menuSelection}
        setMenuSelection={setMenuSelection}
        updates={updates}
        updateInfo={updateInfo}
        setUpdateInfo={setUpdateInfo}
        handleEdit={handleEdit}
        setCommentModal={setCommentModal}
        createUpdateModalOpen={() => setOpen(true)}
        setEditModal={setEditModal}
        setEditData={setEditData}
      />
    </div>
  );
};

export default UpdateMain;
