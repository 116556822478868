/*eslint-disable */
import React, { useState } from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import ckeditor, { CKEditor } from "@ckeditor/ckeditor5-react";
import inputFields from "../../API/inputFields";
import { capitalize } from "../../helpers/timeModule";
import _ from "lodash";
import { postProjectSdg } from "../../services/Sdgs/projectSdgs";
import { createNewProject } from "../../services/projects/ProjectService";
import { useParams } from "react-router-dom";
import {
  getAllSubProjects,
  postSubProject,
} from "../../services/subProjects/subProjectService";
import { NotificationManager } from "react-notifications";

function ProjectSingleForm({ categories, sdgs, closeModal, setSubProjects }) {
  const { id } = useParams();
  const [projectPrivacy, setProjectPrivacy] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectIdea, setProjectIdea] = useState("");
  const [projectPurpose, setProjectPurpose] = useState("");
  const [projectMission, setProjectMission] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectCategory, setProjectCategory] = useState("");
  const [projectFields, setProjectFields] = useState(inputFields);
  const [projectImage, setProjectImage] = useState(null);
  const [projectStgs, setProjectStgs] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const handleFieldChange = (e) => {
    let values = { ...projectFields };
    values[e.target.name] = e.target.checked;
    setProjectFields(values);
  };

  const handleStgsChange = (e) => {
    if (e.target.checked === true) {
      setProjectStgs([...projectStgs, e.target.value]);
    }
  };

  const displayCategories = () => {
    let results = categories.map((value, index) => {
      return (
        <option key={`cat${index}`} value={value.id}>
          {value.name}
        </option>
      );
    });
    return results;
  };

  const displaySdgsFields = () => {
    return sdgs.map((item) => {
      return (
        <div key={item.name} className="m-2">
          <label>{capitalize(item.name)}</label>&nbsp;
          <input
            type="checkbox"
            name={item.name}
            defaultChecked={inputFields[item.name]}
            onChange={(e) => handleStgsChange(e)}
            value={item.id}
          />
        </div>
      );
    });
  };

  const displayInputFields = () => {
    return Object.keys(projectFields).map((key) => {
      return (
        <div key={key} className="m-2">
          <label>{capitalize(key)}</label>&nbsp;
          {key !== "summary" ? (
            <input
              type="checkbox"
              name={key}
              defaultChecked={inputFields[key]}
              onChange={(e) => handleFieldChange(e)}
            />
          ) : (
            <input
              type="checkbox"
              name={key}
              defaultChecked={inputFields[key]}
              onChange={(e) => handleFieldChange(e)}
              disabled
            />
          )}
        </div>
      );
    });
  };

  const createProjectStg = (selectionValues, project_id) => {
    const callEndPoint = async (sdg_id) => {
      await postProjectSdg(project_id, sdg_id);
      return;
    };

    selectionValues.forEach((value) => {
      callEndPoint(value);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingSpinner(true);

    let selectionValues = _.uniq(projectStgs);
    const formData = new FormData();
    formData.append("project[name]", projectName);
    formData.append(
      "project[project_category_id]",
      projectCategory === "" ? "1" : projectCategory
    );
    formData.append("project[idea]", projectIdea);
    formData.append("project[purpose]", projectPurpose);
    formData.append("project[description]", projectDescription);
    formData.append("project[mission]", projectMission);
    formData.append("project[input_fields]", JSON.stringify(projectFields));
    formData.append("project[is_private]", projectPrivacy);
    if (projectImage !== null) {
      formData.append("project[image]", projectImage);
    }

    createNewProject(formData)
      .then((results) => {
        const newProjectID = parseInt(results.data.result.id);
        const postData = { subproject_id: newProjectID };

        // ==== CREATE NEW SDGS ======
        createProjectStg(selectionValues, newProjectID);
        // ==== CREATE NEW SUBPROJECT ====
        postSubProject(id, postData).then((value) => {
          const { message } = value;
          if (message === "SubProject created successfully") {
            setLoadingSpinner(false);
            closeModal();

            getAllSubProjects(id).then((response) => {
              setSubProjects(response.result);
              NotificationManager.info("New project created succesfully");
            });
          }
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="tt-project-form-area">
      <form onSubmit={handleSubmit}>
        <div className="form-group m-3 row">
          <div className="add-image-projects">
            <label>Add image:</label>
            <input
              type="file"
              className="form-control"
              name="idea"
              onChange={(e) => setProjectImage(e.target.files[0])}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <label>Project Name:</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <label>Project Category:</label>
            <select
              id="inputCategory"
              onChange={(e) => setProjectCategory(e.target.value)}
              placeholder="Select a person"
              className="form-control"
              name="category"
            >
              {displayCategories()}
            </select>
          </div>
        </div>

        <div className="form-group  row">
          <div className="col-12">
            <label>Idea:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectIdea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectIdea(data);
              }}
            />
          </div>
        </div>
        <div className="form-group  row">
          <div className="col-12">
            <label>Purpose:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectPurpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectPurpose(data);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <label>Mission:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectMission}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectMission(data);
              }}
            />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-12">
            <label>Project Description:</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={projectDescription}
              onChange={(event, editor) => {
                const data = editor.getData();
                setProjectDescription(data);
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <h3>Select Fields</h3>
            <div className="input-fields">{displayInputFields()}</div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-12">
            <h3>Select Associated STG's</h3>
            <div className="input-fields">{displaySdgsFields()}</div>
          </div>
        </div>
        <div className="form-group">
          <label>Do you want to make this project private?</label>
          &nbsp;&nbsp;
          <input
            type="checkbox"
            name="privacy"
            defaultChecked={projectPrivacy}
            onChange={(e) => setProjectPrivacy(e.target.checked)}
          />
        </div>
        <button
          type="submit"
          className="btn create-project-btn m-3"
          id="loading-button-project"
        >
          {loadingSpinner === true ? (
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            "Create Project"
          )}
        </button>
      </form>
    </div>
  );
}

export default ProjectSingleForm;
