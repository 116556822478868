import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

export const fetchProjects = async () => {
  try {
    const result = await axios.get(`${BASEURL}projects`, fetchRequestOptions);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchProjectNeeds = async (id) => {
  try {
    const result = await axios.get(
      `${BASEURL}project_needs/${id}`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSingleProjectForRefresh = async (id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(`${BASEURL}projects/${id}`, requestOptions);
  const results = await response.json();
  return results;
};
