import React from "react";
import Social from "../../assets/Social collab.png";
function Subscribe() {
  return (
    <div className="subscribe-content">
      <div className="d-flex justify-content-around subscribe-main">
        <div className="d-none d-lg-block">
          <img src={Social} alt="" className="img-fluid subscribe-img" />
        </div>
        <div className="mt-auto mb-auto subscribe-title">
          <div className="pb-4">
            <h5 className="display-4">
              Stay up to date with our latest news & ideas... #getaprofile
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
