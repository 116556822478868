import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { checkLoggedInStatus } from "../redux";
import features from "../helpers/features";
import "../stylesheets/dashboard.css";
import Alert from "@mui/material/Alert";
import styles from "../components/dashboard/css/main.module.css";
import DashBoardMainView from "../components/dashboard/DashBoardMainView";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { unFollow } from "../services/favorites/favoriteService";
import { UseFetchAllDashBoardContents } from "../hooks/Dashboard";
import { useMutation, useQueryClient } from "react-query";

const SkeletonLoader = () => {
  return (
    <Stack spacing={3} className="container">
      <div
        style={{
          marginTop: "20px",
          textAlign: "center",
          color: "purple",
          textTransform: "capitalize",
        }}
      >
        <h4>loading .....</h4>
      </div>

      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: "5rem" }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={60} height={60} />
      <Skeleton variant="rectangular" width={"100%"} height={100} />
      <Skeleton variant="rounded" width={"100%"} height={100} />
      <Skeleton variant="rounded" width={"100%"} height={100} />
    </Stack>
  );
};

const DashboardContainer = ({ status, isLoggedIn }) => {
  // ---- REACT QUERY ----
  const queryClient = useQueryClient();
  // ---- MUTATIONS
  const unFollowAction = useMutation({
    mutationFn: (projectId) => unFollow(projectId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["allDashBoardContents"],
      });
    },
  });
  // --- QUERIES ---
  const {
    data: dashboardData,
    isError,
    isLoading,
  } = UseFetchAllDashBoardContents();

  useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);

  const handleUnfollowAction = async (projectID) => {
    unFollowAction.mutate(projectID);
  };

  const renderDashboard = () => {
    if (!_.isEmpty(status.data)) {
      return (
        <>
          <div className={styles.main_dashboard_container}>
            {isLoading ? (
              <SkeletonLoader />
            ) : (
              <DashBoardMainView
                profile={status.data}
                features={features}
                dashboardContent={dashboardData}
                handleUnfollowAction={handleUnfollowAction}
              />
            )}
            {isError && (
              <Alert variant="filled" severity="error">
                Something went wrong while fetching the dashboard contents....
                Check your internet connection and try again...
              </Alert>
            )}
          </div>
        </>
      );
    } else if (_.isEmpty(status.data)) {
      return (
        <Alert variant="filled" severity="success">
          Something went wrong while fetching the dashboard contents.... Check
          your internet connection and try again...P
        </Alert>
      );
    }
  };

  return <>{renderDashboard()}</>;
};

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
