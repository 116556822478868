import React, { useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";

import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const ProjectGoalsModal = ({
  closeModal,
  id,
  handleSubmit,
  speedDialState,
}) => {
  const { buttons } = speedDialState;
  const [inputFields, setInputFields] = useState([
    { name: "", project_id: id },
  ]);

  const [goal, setGoal] = useState("");

  const handleAddField = () => {
    const values = [...inputFields];
    values.push({ name: "", project_id: id });
    setInputFields(values);
  };

  const handleChange = (index, data) => {
    const values = [...inputFields];
    values[index].name = data;
    setInputFields(values);
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleFormSubmit = (e) => {
    const goalData = {
      name: goal,
      project_goal_objectives_attributes: inputFields,
    };
    handleSubmit(goalData);
    // ==== Clear state after submit
    setGoal("");
    e.preventDefault();
    closeModal();
  };

  return (
    <>
      <Modal show={buttons.newGoal} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C" }}
          closeButton
        >
          <Modal.Title>Create Project Goal and Objectives</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="project-goals-tt" onSubmit={handleFormSubmit}>
            <div className="form-group project-goal-field">
              <label htmlFor="title">Goal</label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={goal}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setGoal(data);
                }}
              />
            </div>
            <div className="form-group mt-2 d-flex justify-content-between">
              <h5 className="pt-2  project-goal-field">Add Objectives</h5>
              <button
                className="btn btn-success"
                type="button"
                onClick={() => handleAddField()}
              >
                +
              </button>
            </div>
            <div className="row mx-auto project-goal-field">
              {inputFields.map((field, idx) => (
                <Fragment key={idx}>
                  <div className="form-group col-md-11 mt-2">
                    <CKEditor
                      className="form-control"
                      editor={ClassEditor}
                      data={field.description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChange(idx, data);
                      }}
                      required
                    />
                  </div>
                  <div className="form-group col-md-1 remove-btn">
                    <button
                      className="btn btn-danger mt-2"
                      type="button"
                      onClick={() => handleRemoveField(idx)}
                    >
                      -
                    </button>
                  </div>
                </Fragment>
              ))}
            </div>
            <button type="submit" className="btn  btn-secondary m-4">
              Create Goal
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProjectGoalsModal;
