import React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Divider } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import _ from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddProfilesModal = ({
  open,
  setOpen,
  allProfilesData,
  allProfilesLoading,
  allProfilesError,
  setSelectedProfiles,
  selectedProfiles,
  newNetworkMembers,
  addMembersToNetworkMutation,
}) => {
  const { isLoading, isError } = addMembersToNetworkMutation;
  const handleClose = () => {
    setOpen(false);
    setSelectedProfiles([]);
  };

  const displayProjects = () => {
    if (allProfilesLoading)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      );

    if (allProfilesError)
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert variant="filled" severity="error">
            Something went wrong while fetching the projects...
          </Alert>
        </div>
      );

    if (_.isEmpty(allProfilesData))
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert variant="filled" severity="info">
            There are no profiles at this time...
          </Alert>
        </div>
      );

    const result = allProfilesData.map((value) => {
      return (
        <>
          <List
            dense
            sx={{ width: "100%", bgcolor: "background.paper" }}
            key={value.id}
          >
            <ListItem
              secondaryAction={
                <Checkbox
                  edge="end"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedProfiles([...selectedProfiles, value.user_id]);
                      return;
                    }
                  }}
                />
              }
            >
              <ListItemButton>
                <ListItemAvatar>
                  {value?.profile_avatar?.url === null ? (
                    <Avatar
                      sx={{ bgcolor: red[500] }}
                      aria-label="Profile"
                      alt={`${value?.first_name}`}
                    />
                  ) : (
                    <Avatar
                      alt={`${value?.first_name} ${value?.last_name}`}
                      src={`${value?.profile_avatar?.url}`}
                    />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={`${value?.first_name} ${value?.last_name}`}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </>
      );
    });
    return result;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CloseIcon />}
          onClick={handleClose}
        >
          {" "}
          close
        </Button>
        <div className="text-center">
          <h3
            style={{
              fontSize: "22px",
              color: "#000",
              fontWeight: "800",
              textTransform: "uppercase",
            }}
          >
            <AccountTreeIcon /> Add Members To Network
          </h3>
        </div>
      </DialogTitle>
      {isError && (
        <Alert variant="filled" severity="error">
          Something went wrong while adding Members to the network. Kindly try
          again...
        </Alert>
      )}

      <Divider />
      <DialogContent>
        <FormGroup>{displayProjects()}</FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disabled={isLoading && true}
          onClick={newNetworkMembers}
        >
          {isLoading ? "Adding Members to network..." : "   Add Members"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProfilesModal;
