/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import "../stylesheets/financial.css";
import "../stylesheets/suggestion.css";
import Navbar from "../components/navbar/Navbar";
import TaskMenuBar from "../components/tasks/TaskMenuBar";
import styles from "../stylesheets/taskModules/TaskMenuBar.module.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  getAllOpenItemsAssignment,
  getSpecificOpenItems,
  openItemDrag,
} from "../services/openItem/openItemService";
import TaskCard from "../components/tasks/TaskCard";
import TaskCard2 from "../components/tasks/TaskCard2";
import TaskCard3 from "../components/tasks/TaskCard3";
import TaskCard4 from "../components/tasks/TaskCard4";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import TaskCalender from "../components/tasks/TaskCalender";
import AllAssignedProfiles from "../components/tasks/AllAssignedProfiles";
import OpenItemDisplayModal from "../components/tasks/openItemDisplayModal";
import { NotificationManager } from "react-notifications";
import MyTasks from "../components/tasks/MyTasks";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function TaskContainer() {
  const [openItems, setOpenItems] = useState([]);
  const [draggingId, setDraggingId] = useState(null);
  const [view, setView] = useState(false);
  const [taskSwitch, setTaskSwitch] = useState(true);
  const [tab, setTab] = useState("ALL");
  const [displayProfiles, setDisplayProfiles] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const [displayViewTask, setDisplayViewTask] = useState(false);
  const [loaderSingleItem, setLoaderSingleItem] = useState(false);
  const [singleOpenItemDetails, setSingleOpenItemDetails] = useState({});
  const [creatorProfile, setCreatorProfile] = useState({});
  const [openItemComments, setOpenItemComments] = useState([]);
  const [assignedProfiles, setAssignedProfiles] = useState([]);
  const [assignedProject, setAssignProject] = useState([]);

  const selectedTabItems = () => {
    if (tab === "ALL") return openItems;
    if (tab === "1") return openItems.filter((item) => item.priority === 1);
    if (tab === "2") return openItems.filter((item) => item.priority === 2);
    if (tab === "3") return openItems.filter((item) => item.priority === 3);
    if (tab === "4") return openItems.filter((item) => item.priority === 4);
    if (tab === "5") return openItems.filter((item) => item.priority === 5);
    if (tab === "6") return openItems.filter((item) => item.priority === 6);
  };

  const fetchAllOpenItems = async () => {
    try {
      const data = await getAllOpenItemsAssignment();
      if (data.message === "Assigned Open Items Fetched Successfully") {
        setOpenItems([...data.result]);
      }
    } catch (error) {
      console.log("Something went wrong" + error);
    }
  };

  const handleSingleOpenItem = async (id) => {
    setLoaderSingleItem(true);
    try {
      const data = await getSpecificOpenItems(id);
      if (data.message === "Open item fetched Succesfully") {
        setSingleOpenItemDetails({ ...data.result });
        setCreatorProfile({ ...data.creator });
        setOpenItemComments([...data.open_item_comments]);
        setAssignedProfiles([...data.assigned_profiles]);
        setAssignProject([...data.projects]);
        setLoaderSingleItem(false);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong");
      setLoaderSingleItem(false);
    }
  };

  useEffect(() => {
    fetchAllOpenItems();
  }, []);

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const drop = async (ev, status) => {
    ev.preventDefault();
    const data = {
      id: draggingId,
      status,
    };
    try {
      const result = await openItemDrag(data);
      if (result.message === "Open item dragged succesfully") {
        fetchAllOpenItems();
        setDraggingId(null);
      }
    } catch (error) {
      console.log("Something went wrong" + error);
    }
  };

  const drag = (ev, id) => {
    setDraggingId(id);
  };

  const AppBarOpenItems = () => {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="transparent">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            ></IconButton>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ flexGrow: 1 }}
            >
              <Typography className={styles.switch_name} color="primary">
                MY TASKS
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={taskSwitch}
                    onChange={() => setTaskSwitch(!taskSwitch)}
                  />
                }
              />
              <Typography className={styles.switch_name} color="secondary">
                TEAM TASKS (assigned tasks)
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography className={styles.switch_name} color="primary">
                BOARD
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={view}
                    onChange={() => setView(!view)}
                  />
                }
              />
              <Typography className={styles.switch_name} color="secondary">
                CALENDER
              </Typography>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    );
  };

  return (
    <>
      <AllAssignedProfiles
        displayProfiles={displayProfiles}
        setDisplayProfiles={setDisplayProfiles}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
      />
      <OpenItemDisplayModal
        displayViewTask={displayViewTask}
        setDisplayViewTask={setDisplayViewTask}
        loaderSingleItem={loaderSingleItem}
        singleOpenItemDetails={singleOpenItemDetails}
        creatorProfile={creatorProfile}
        openItemComments={openItemComments}
        handleSingleOpenItem={handleSingleOpenItem}
        assignedProfiles={assignedProfiles}
        fetchAllOpenItems={fetchAllOpenItems}
        assignedProject={assignedProject}
      />
      <Navbar />
      <div className={styles.main_container_open_items}>
        <div className={styles.main_side_bar_container}>
          <TaskMenuBar tab={tab} setTab={setTab} />
        </div>
        <div className={styles.main_area_container}>
          {taskSwitch === false ? <MyTasks /> : null}
          <AppBarOpenItems />
          {view === true ? (
            <div className="container-fluid">
              <TaskCalender selectedTabItems={selectedTabItems} />
            </div>
          ) : (
            <section className="board">
              <div className="container-fluid">
                <div className="row">
                  {/* ==== Display to do board ==== */}
                  <div className="col-md-3 col-sm-6 col-xs-12 col-lg-3 ">
                    <TaskCard
                      selectedTabItems={selectedTabItems}
                      allowDrop={allowDrop}
                      drop={drop}
                      drag={drag}
                      setDisplayProfiles={setDisplayProfiles}
                      setSelectedTask={setSelectedTask}
                      setDisplayViewTask={setDisplayViewTask}
                      handleSingleOpenItem={handleSingleOpenItem}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 col-lg-3 ">
                    <TaskCard2
                      selectedTabItems={selectedTabItems}
                      allowDrop={allowDrop}
                      drop={drop}
                      drag={drag}
                      setDisplayProfiles={setDisplayProfiles}
                      setSelectedTask={setSelectedTask}
                      setDisplayViewTask={setDisplayViewTask}
                      handleSingleOpenItem={handleSingleOpenItem}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 col-lg-3 ">
                    <TaskCard3
                      selectedTabItems={selectedTabItems}
                      allowDrop={allowDrop}
                      drop={drop}
                      drag={drag}
                      setDisplayProfiles={setDisplayProfiles}
                      setSelectedTask={setSelectedTask}
                      setDisplayViewTask={setDisplayViewTask}
                      handleSingleOpenItem={handleSingleOpenItem}
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 col-xs-12 col-lg-3 ">
                    <TaskCard4
                      selectedTabItems={selectedTabItems}
                      allowDrop={allowDrop}
                      drop={drop}
                      drag={drag}
                      setDisplayProfiles={setDisplayProfiles}
                      setSelectedTask={setSelectedTask}
                      setDisplayViewTask={setDisplayViewTask}
                      handleSingleOpenItem={handleSingleOpenItem}
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </div>
    </>
  );
}

export default TaskContainer;
/* eslint-enable react-hooks/exhaustive-deps */
