import { BASEURL } from "../../API/apiData";

const getAllFavoritesProjects = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(`${BASEURL}favorites`, requestOptions);
  const results = await response.json();
  return results;
};

const unFollow = async (projectID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}favorites/${projectID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const follow = async (projectID) => {
  const data = {
    project_id: projectID,
  };

  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(`${BASEURL}favorites`, requestOptions);
  const results = await response.json();
  return results;
};

export { getAllFavoritesProjects, unFollow, follow };
