/*eslint-disable */
import React, { useState } from "react";
import upload from "../../images/upload-img.png";
import parse from "html-react-parser";
import LibraryItemModal from "./LibraryItemModal"

const LibraryItem = ({ data, handleUpdate,priv,owner }) => {
  const [details, setDetails] = useState(false);
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <LibraryItemModal
        invId={null}
        data={data}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleUpdate}
      />
      <div className="library-item">
        <div className="library-item-header">
          <div className="library-item-header-title">
            {data.image.url ? (
              <img className="upload-img" src={data.image.url} alt="img" />
            ) : (
              <img className="upload-img" src={upload} alt="img" />
            )}
            &nbsp;&nbsp;
            <h6>{data.name}</h6>
          </div>
          <div>
            <button onClick={() => setDetails(!details)}>
              <i className="fa fa-chevron-down mt-2"></i>
            </button>
          </div>
        </div>
        {details ? (
          <div className="library-item-body">
            <div className="library-item-body-details">
              <div className="library-item-status mt-2">
                <h6>
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  &nbsp; Specifications
                </h6>
                {data.tuc_inventory_item_status_id == 1 ? (
                  <h6 className="text-success">
                    <i className="fa fa-check-circle" aria-hidden="true"></i>
                    &nbsp;Avalaible
                  </h6>
                ) : (
                  <h6 className="text-danger">
                    <i className="fa fa-stop-circle" aria-hidden="true"></i>{" "}
                    &nbsp;Not Avalaible
                  </h6>
                )}
              </div>
              <div className="library-item-body-info ">
                {data.image.url ? (
                  <img
                    className="library-item-body-info-img"
                    src={data.image.url}
                    alt="upload-img"
                  />
                ) : (
                  <img
                    className="library-item-body-info-img"
                    src={upload}
                    alt="upload-img"
                  />
                )}
                <div className="library-item-body-info-details">
                  {parse(data.specs)}
                </div>
              </div>
              <div className="library-item-status">
                <h6>Location: Coming Soon</h6>
                <div>
                  {owner ? (
                    <>
                      {" "}
                      <button type="button" onClick={openModal}>
                        <i className="fas fa-edit text-primary"></i>
                        &nbsp; Edit
                      </button>
                    </>
                  ) : null}
                  {data.tuc_inventory_item_status_id == 1 ? (
                    <>
                      {owner ? (
                        <button
                          type="button"
                          onClick={() => console.log("Open View")}
                        >
                          <i className="fas fa-share-alt-square text-success"></i>
                          &nbsp; Share
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => console.log("Open View")}
                        >
                          <i className="fas fa-question-circle text-success"></i>
                          &nbsp; Request
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LibraryItem;
