import { BASEURL } from "../../API/apiData";

const postProjectSdg = async (project_id, sdg_id) => {
  const data = { project_id, sdg_id };
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_sdgs`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const getProjectSdgs = async (project_id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const showProjectSdgs = async (project_id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_sdgs`,
    requestOptions
  );
  const results = await response.json();
  return results;
}

const deleteProjectSdgs = async (project_id, sdg_id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_sdgs/${sdg_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
}

export { postProjectSdg, getProjectSdgs, showProjectSdgs, deleteProjectSdgs };
