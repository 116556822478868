import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_GOALS_REQUEST, CREATE_GOALS_SUCCESS, CREATE_GOALS_FAILURE, FETCH_GOALS_FAILURE, FETCH_GOALS_SUCCESS, FETCH_GOALS_REQUEST } from "./goalsTypes";

export const createGoalsRequest = () =>({
    type: CREATE_GOALS_REQUEST,
  })
  
  export const createGoalsSuccess = (response) =>({
    type: CREATE_GOALS_SUCCESS,
    payload: response
  })
  
  export const createGoalsFailure = (error) =>({
    type: CREATE_GOALS_FAILURE,
    payload: error
  })

  export const fetchGoalsRequest = () =>({
    type: FETCH_GOALS_REQUEST,
  })
  
  export const fetchGoalsSuccess = (response) =>({
    type: FETCH_GOALS_SUCCESS,
    payload: response
  })
  
  export const fetchGoalsFailure = (error) =>({
    type: FETCH_GOALS_FAILURE,
    payload: error
  })
  
export const fetchGoals = (profileID) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchGoalsRequest());
    return axios.get(`${BASEURL}profiles/${profileID}/goals`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchGoalsSuccess(response.data));
      }).catch(error => {
        return dispatch(fetchGoalsFailure(error));
      });
  }


  export const createGoals = (profileID, data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createGoalsRequest());
    return axios.post(`${BASEURL}profiles/${profileID}/goals`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    } )
      .then(response => {
        return dispatch(createGoalsSuccess(response.data));
      }).catch(error => {
        return dispatch(createGoalsFailure(error));
      });
  };
  