import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  fetchProfile,
  fetchCoreValue,
  fetchGoals,
  fetchMyNetwork,
} from "../redux";
import _ from "lodash";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/profiles.css";
import ProfileHeadDetails from "../components/profile/ProfileHeadDetails";
import ProfileSummary from "../components/profile/ProfileSummary";
import ProfileCoreValues from "../components/profile/ProfileCoreValues";
import ProfileNetwork from "../components/profile/ProfileNetwork";
import ProfileGoals from "../components/profile/ProfileGoals";
import ProfileBadges from "../components/profile/ProfileBadges";
import ProfileInventories from "../components/profile/ProfileInventories";
import ProfileNav from "../components/profile/ProfileNav";
import PrivacyWarning from "../components/reusablecomponents/PrivacyWarning";
import ProfileProjects from "../components/profile/ProfileProjects";
import ProfileSuggestions from "../components/profile/ProfileSuggestions";

const ProfileShowContainer = ({
  match,
  history,
  fetchProfile,
  profile,
  fetchCoreValue,
  fetchGoals,
  fetchInventories,
  fetchMyNetwork,
}) => {
  const id = match.params.id;

  const [tabNo, setTabNo] = useState(0);
  const [coreValues, setCoreValues] = useState({});
  const [goals, setGoals] = useState({});
  const [myNetwork, setMyNetwork] = useState({});
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetchProfile(id).then((res) => {
      setProjects(res.payload.projects);
      fetchCoreValue(id).then((results) => {
        setCoreValues(results.payload);
      });
      fetchGoals(id).then((results) => {
        setGoals(results.payload);
      });
      fetchMyNetwork(id).then((results) => {
        setMyNetwork(results.payload);
      });
    });
  }, [
    fetchProfile,
    fetchCoreValue,
    fetchGoals,
    fetchInventories,
    fetchMyNetwork,
    id,
  ]);

  const showTab = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;
      switch (tabNo) {
        case 0:
          return <ProfileSummary profile={res} />;
        case 1:
          return <ProfileCoreValues data={coreValues} />;
        case 2:
          return <ProfileNetwork myNetwork={myNetwork} />;
        case 3:
          return <ProfileGoals id={id} goals={goals} />;
        case 4:
          return <ProfileBadges userId={id}/>;
        case 5:
          return <ProfileInventories id={id} privacy={false} />;
        case 6:
          return <ProfileProjects projects={projects} privacy={false} />;
        case 7:
          return <ProfileSuggestions profile={profile.data}/>
        default:
          return <ProfileSummary profile={res} />;
      }
    }
  };

  const showProfile = (profile, userId) => {
    return (
      <>
        <div className="profile-header">
          <div className="profile-header-cont">
            <ProfileHeadDetails
              avatar={profile.profile_avatar.url}
              fName={profile.first_name}
              lName={profile.last_name}
              address={profile.address}
              role={profile.role}
              userId={userId}
              profileUserId={profile.user_id}
            />
          </div>
          {profile.user_id === userId ? (
            <div className="profile-header-cont-2 edit-area">
              <button
                onClick={() => history.push("/profile")}
                className="prof-edit-btn"
              >
                <i className="fas fa-pen"></i>&nbsp;Edit Mode
              </button>
            </div>
          ) : null}
        </div>
        <div className="profile-main">
          <ProfileNav setTabNo={setTabNo} />
          {showTab()}
        </div>
      </>
    );
  };

  const showData = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;
      const isPrivate = res.is_private;
      const userId = profile.data.current_user;

      if (isPrivate && userId !== res.user_id) {
        return <PrivacyWarning title="Profile" />;
      } else {
        return showProfile(res, userId);
      }
    }
  };

  return (
    <>
      <Navbar />
      {showData()}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProfile: (id) => dispatch(fetchProfile(id)),
  fetchCoreValue: (id) => dispatch(fetchCoreValue(id)),
  fetchGoals: (id) => dispatch(fetchGoals(id)),
  fetchMyNetwork: (id) => dispatch(fetchMyNetwork(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileShowContainer);
