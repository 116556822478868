/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FinancialsCard from "../components/financials/FinancialsCard";
import "../stylesheets/financial.css";
import Airtable from "airtable";
import env from "react-dotenv";
import Navbar from "../components/navbar/Navbar";

function FinancialContainer() {
  const [people, setPeople] = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [employees, setEmployees] = useState([]);

  // === Configure airtable
  Airtable.configure({
    endpointUrl: env.AIRTABLE_ENDPOINT,
    apiKey: env.AIRTABLE_API_KEY,
  });
  // === Airtable Base
  const base = Airtable.base(env.AIRTABLE_BASE);

  // === Employee Names
  useEffect(() => {
    base("Receipt Log")
      .select({
        view: "Main View",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          let arrayOfUsers = [];
          records.forEach(function (record) {
            arrayOfUsers.push(record.get("EmployeeName"));
          });
          setPeople(arrayOfUsers);
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  // === Filtred List of Employees
  useEffect(() => {
    let uniqueArray = people.filter((item, pos) => {
      return people.indexOf(item) === pos;
    });
    setEmployees(uniqueArray);
  }, [people]);

  // === All records
  useEffect(() => {
    base("Receipt Log")
      .select({
        view: "Main View",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          let arrayOfRecords = [];
          records.forEach(function (record) {
            arrayOfRecords.push(record.fields);
          });
          setAllRecords(arrayOfRecords);
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  return (
    <>
    <Navbar />
    <div className="container-fluid">
      <FinancialsCard allRecords={allRecords} employees={employees} />
    </div>
    </>
  );
}
export default FinancialContainer;

/* eslint-enable react-hooks/exhaustive-deps */
