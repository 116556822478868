import React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { Paper } from "@mui/material";

const BottomAppBar = ({ value, setValue }) => {
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value.value}
        onChange={(event, newValue) => {
          if (newValue === 0)
            return setValue({
              ...value,
              value: newValue,
              menuPage: true,
              homePage: false,
              notificationPage: false,
            });
          if (newValue === 1)
            return setValue({
              ...value,
              value: newValue,
              menuPage: false,
              homePage: true,
              notificationPage: false,
            });
          if (newValue === 2)
            return setValue({
              ...value,
              value: newValue,
              menuPage: false,
              homePage: false,
              notificationPage: true,
            });
        }}
      >
        <BottomNavigationAction label="Menu" icon={<MenuIcon />} />
        <BottomNavigationAction label="Dashboard" icon={<HomeIcon />} />
        <BottomNavigationAction
          label="Engagements"
          icon={<CircleNotificationsIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomAppBar;
