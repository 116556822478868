import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { FETCH_LEGENDASSOCIATIONS_REQUEST,FETCH_LEGENDASSOCIATIONS_SUCCESS,FETCH_LEGENDASSOCIATIONS_FAILURE } from "./legendAssociationTypes";

export const fetchLegendAssociationsRequest = () => ({
  type: FETCH_LEGENDASSOCIATIONS_REQUEST,
});

export const fetchLegendAssociationsSuccess = (response) => ({
  type: FETCH_LEGENDASSOCIATIONS_SUCCESS,
  payload: response,
});

export const fetchLegendAssociationsFailure = (error) => ({
  type: FETCH_LEGENDASSOCIATIONS_FAILURE,
  payload: error,
});

export const fetchLegendAssociations = () => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchLegendAssociationsRequest());
  return axios
    .get(`${BASEURL}legend_associations`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchLegendAssociationsSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchLegendAssociationsFailure(error));
    });
};
