import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import { BASEURL } from "../../../API/apiData";

import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function EditAssumption({
  closeEditModal,
  openEditModal,
  assumptionID,
  editState,
  projectID,
  setProjectAssumption,
  fetchProjectsAssumptions,
}) {
  //  ==== FORM CONTROL STATE ====
  const [formState, setFormState] = useState({
    assumption: "",
    idea: "",
    purpose: "",
  });
  // === REFRESH THE EDITED AND UPDATED STATE ====

  const updateEditedState = async () => {
    try {
      const { payload } = await fetchProjectsAssumptions(projectID);
      NotificationManager.info("Updated successfully");
      setProjectAssumption(payload.result);
    } catch (error) {
      NotificationManager.error(
        "Somethin went wrong kindly check your internet connection and try again"
      );
    }
  };

  //  ==== SUBMIT THE FORM ====
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formState),
    };

    fetch(
      `${BASEURL}projects/${projectID}/project_assumptions/${assumptionID}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(() => {
        closeEditModal();
        updateEditedState();
      })
      .catch(() => {
        closeEditModal();
        NotificationManager.error(
          "Something went wrong. Kindly check your internet connection and try again"
        );
      });
  };

  // ==== POPULATE THE EDIT FORM ====
  useEffect(() => {
    setFormState({
      assumption: editState.assumption,
      idea: editState.idea,
      purpose: editState.purpose,
    });
  }, [editState]);

  return (
    <Modal show={openEditModal} onHide={closeEditModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Edit Assumption</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
        <div className="form-group">
            <label htmlFor="need">Assumption</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.assumption}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, assumption: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default EditAssumption;
