/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { NotificationManager } from "react-notifications";
import {
  createProjectSuggestion,
  createProjectSuggestionComment,
  fetchProjectSuggestions,
  updateProjectSuggestion,
} from "../../../redux";
import "../../../stylesheets/projectSuggestion.css";
import SuggestionTrending from "../../suggestionbox/SuggestionTrending";
import ProjectSuggestionCard from "./ProjectSuggestionCard";
import ProjectSuggestionModal from "./ProjectSuggestionModal";
import sbanner from "../../../images/sb-av.png";
import _, { result } from "lodash";
import AppReusableCard from "../globals/AppReusableCard";
import ProjectSuggestionEditModal from "./ProjectSuggestionEditModal";
import {
  createProjectSuggestionCommentResponses,
  editProjectSuggestionComment,
  editProjectSuggestionCommentResponses,
  getOneSuggestion,
  unvoteProjectSuggestion,
  upvoteProjectSuggestion,
} from "../../../services/projects/ProjectSuggestionService";
import AppReusableDisplayUpvotedProfiles from "../globals/AppReusableDisplayUpvotedProfiles";
import ShareSuggestionModal from "../globals/ShareSuggestionModal";
import { Button } from "@mui/material";
import CommentBox from "../globals/CommentBox";
import {
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../../services/projects/ProjectAssumptionService";
import AppProjectSearch from "../globals/AppProjectSearch";

const ProjectSuggestion = ({
  id,
  fetchSuggestions,
  createSuggestion,
  updateSuggestion,
  createComment,
  suggestions,
  setSuggestions,
  allProfiles,
  speedDialState,
}) => {
  // === ID, REPRESENTS PROJECT ID ====
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [open, setOpen] = useState(false);
  const [suggestionEdit, setSuggestionEdit] = useState({});
  const [openEdit, setOpenEdit] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  // ==== OPEN UPVOTES PROFILES ====
  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [votersProfiles, setVotersProfiles] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [profiles, setProfiles] = useState([]);
  // ==== DISPLAY THE SUGGESTION CONDITIONALLY ====
  const [oneSuggestion, setOneSuggestion] = useState(null);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  const [suggestionSelection, setSuggestionSelection] = useState({
    idea: "",
    purpose: "",
    suggestion: "",
  });
  const [editSubmitButton, setEditSubmitButton] = useState(false);
  const [suggestionID, setSuggestionID] = useState(null);
  const [suggestionComments, setSuggestionsComments] = useState([]);
  const [suggestionFilter, setSuggestionFilter] = useState([]);
  const [likeButton, setLikeButton] = useState(false);

  useEffect(() => {
    getAllSuggestions();
    fetchAllProfiles();
  }, []);

  // === USE EFFECT TO FILTER THE DATA ===

  useEffect(() => {
    setSuggestionFilter([...suggestions]);
  }, [suggestions]);

  useEffect(() => {
    setSuggestionFilter(filterSuggestions());
  }, []);

  // ==== FILTER SUGGESTIONS BY SEARCH =====
  const filterSuggestions = () => {
    // ==== If the filter is empty ====
    if (suggestionFilter === "") return suggestions;
    if (suggestionFilter === null) {
      return suggestions;
    }
    // ==== If we have a list to filter ====
    let filteredList = suggestions.filter((value) => {
      if (value.suggestion === suggestionFilter.suggestion) return value;
    });
    return filteredList;
  };

  const fetchAllProfiles = async () => {
    const { type, payload } = await allProfiles();
    if (type === "FETCH_ALL_PROFILES_SUCCESS") return setProfiles([...payload]);
  };

  const getAllSuggestions = () => {
    fetchSuggestions(id)
      .then((res) => setSuggestions(res.payload.result))
      .catch((e) => console.log("Internal Server Error"));
  };

  const getSpecificSuggetion = async () => {
    // fetch specific suggestion for fast refresh
    try {
      const { message, result } = await getSpecificSuggetion(id, suggestionID);
      if (message === "Project Suggestion successfully found") {
        console.log(result);
      }
    } catch (error) {
      console.log(error, "Getting one suggestion not successfull");
    }
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
  };

  const handleOpenShareModal = (value) => {
    setOneSuggestion({ ...value });
    setOpenShareModal(true);
  };

  const refreshSuggestionComments = async () => {
    const refreshData = {
      suggestion_id: suggestionID,
    };
    try {
      const res = await getOneSuggestion(refreshData);
      setSuggestionsComments([...res.result.comments]);
    } catch (error) {
      console.log(error, "Failed to refresh the project suggestion comments");
    }
  };

  const handleCreate = (data) => {
    createSuggestion(id, data)
      .then((res) => {
        toast.success(res.payload.message);
        fetchSuggestions(id)
          .then((res) => setSuggestions(res.payload.result))
          .catch((e) => console.log("Internal Server Error"));
      })
      .catch((e) => console.log("Internal Server Error"));
  };

  const handleUpdate = (psId, data) => {
    updateSuggestion(id, psId, data)
      .then((res) => {
        toast.success(res.payload.message);
        fetchSuggestions(id)
          .then((res) => setSuggestions(res.payload.result))
          .catch((e) => console.log("Internal Server Error"));
      })
      .catch((e) => console.log("Internal Server Error"));
  };
  const handleCreateComment = () => {
    setSubmitButton(true);
    const data = {
      project_suggestion_comment: {
        comment_text: commentBoxDetail,
        project_suggestion_id: suggestionID,
      },
    };

    createComment(id, suggestionID, data)
      .then((res) => {
        toast.success(res.payload.message);
        refreshSuggestionComments();
        setCommentBoxDetail("");
        setSubmitButton(false);
      })
      .catch((e) => {
        console.log(e, "Failed to create a comment for the project suggestion");
        NotificationManager.error("Failed to create comment. Kindly try again");
      });
  };

  const refreshSuggestionAfterVote = (action, data) => {
    const currentSuggestion = suggestions.findIndex(
      (suggestion) => suggestion.id === data.id
    );
    const updatedSuggestion = {
      ...suggestions[currentSuggestion],
      likes: {
        upvoted: action === "upvote" ? true : false,
        counter:
          action === "upvote"
            ? suggestions[currentSuggestion].likes.counter + 1
            : suggestions[currentSuggestion].likes.counter - 1,
      },
      upvoted_by: suggestions[currentSuggestion].upvoted_by,
    };
    const newProjectSuggestions = [...suggestions];
    newProjectSuggestions[currentSuggestion] = updatedSuggestion;
    setSuggestions(newProjectSuggestions);
  };

  const handleSuggestionUpvote = async (sug) => {
    setLikeButton(true);
    const initialSuggestions = suggestions;
    refreshSuggestionAfterVote("upvote", sug);
    const data = {
      upvote: {
        upvoteable_id: sug.id,
        upvoteable_type: "ProjectSuggestion",
      },
    };
    const { message } = await upvoteProjectSuggestion(data);
    if (message === "upvote successful") {
      getAllSuggestions();
      setLikeButton(false);
      return;
    }
    NotificationManager.error(
      "Something went wrong. Check the network connection and try again"
    );
    setSuggestions(initialSuggestions);
    setLikeButton(false);
  };

  const handleSuggestionUnvote = async (sug) => {
    setLikeButton(true);
    const initialSuggestions = suggestions;
    refreshSuggestionAfterVote("unvote", sug);
    const data = {
      upvoteable_id: sug.id,
      upvoteable_type: "ProjectSuggestion",
    };
    const { message } = await unvoteProjectSuggestion(data);
    if (message === "Your vote has been removed") {
      getAllSuggestions();
      setLikeButton(false);
      return;
    }
    NotificationManager.error(
      "Something went wrong. Check the network connection and try again"
    );
    setSuggestions(initialSuggestions);
    setLikeButton(false);
  };
  const handleOpenCommentModal = (id, comments, data) => {
    setSuggestionsComments([...comments]);
    setSuggestionSelection({
      idea: data.idea,
      purpose: data.purpose,
      suggestion: data.details,
    });
    setSuggestionID(id);
    setCommentModal(true);
  };

  const handleSugEdit = (value) => {
    setSuggestionEdit({
      ...value,
    });
    setOpenEdit(true);
  };

  const showSuggestions = () => {
    if (_.isEmpty(suggestions)) {
      return (
        <h2 className="text-center text-success">No suggestions available</h2>
      );
    } else if (suggestions.length > 0) {
      return (
        <>
          {suggestions.map((sug) => (
            <AppReusableCard
              id={sug.id}
              key={sug.id}
              idea={sug.idea}
              purpose={sug.purpose}
              inputField={sug.details}
              inputFieldName="Suggestion"
              suggestion={sug}
              handleUpdate={handleUpdate}
              handleCreateComment={handleCreateComment}
              openCommentModal={handleOpenCommentModal}
              creatorImage={sug.avatar}
              creatorFirstName={sug.created_by.first_name}
              creatorLastName={sug.created_by.last_name}
              createdAt={sug.created_at}
              editAction={() => handleSugEdit(sug)}
              actionItems={true}
              upvoted_by={sug.upvoted_by}
              shared_with={sug.shared_with}
              votes={sug.likes.counter}
              userLiked={sug.likes.upvoted}
              likeAction={() => handleSuggestionUpvote(sug)}
              unLikeAction={() => handleSuggestionUnvote(sug)}
              setVotersToggleDrawer={setVotersToggleDrawer}
              setVotersProfiles={setVotersProfiles}
              setDrawerTitle={setDrawerTitle}
              openShareModal={() => handleOpenShareModal(sug)}
              comments={sug.comments}
              likeButton={likeButton}
              data={sug}
            />
          ))}
        </>
      );
    }
  };

  // ==== COMMENTS AND UPVOTES METHODS ====
  const refreshSuggestions = () => {
    getAllSuggestions();
  };

  const handleEditOfComment = async (editState, setEditState) => {
    const { content, itemId } = editState;
    const data = {
      projectID: id,
      suggestionID,
      ID: itemId,
      commentData: {
        comment_text: content,
        project_suggestion_id: suggestionID,
      },
    };

    try {
      const { message } = await editProjectSuggestionComment(data);
      if (message === "Comment updated successfully") {
        refreshSuggestionComments();
        setEditState({
          content: "",
          itemId: null,
          form: false,
        });
      }
    } catch (error) {
      console.log(
        error,
        "Something went wrong while tring to edit the project suggestion comment"
      );
    }
  };

  const handleUpvoteOfComment = async (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "ProjectSuggestionComment",
      },
    };
    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project suggestion comment"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfComment = async (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "ProjectSuggestionComment",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project Suggestion comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const handleCreateOfCommentResponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const data = {
      project_id: id,
      project_suggestion_id: suggestionID,
      project_suggestion_comment_id: itemReplyId,
      commentData: {
        project_suggestion_comment_response: {
          project_suggestion_comment_id: itemReplyId,
          content: replyContent,
        },
      },
    };
    try {
      const { message } = await createProjectSuggestionCommentResponses(data);
      if (message === "Your comment added") {
        NotificationManager.success("Response created!!!");
        refreshSuggestionComments();
        setReplyState({
          display: true,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    replyState
  ) => {
    const { responseID, content } = editCommentResponse;
    const { itemReplyId } = replyState;
    const data = {
      project_id: id,
      project_suggestion_id: suggestionID,
      project_suggestion_comment_id: itemReplyId,
      id: responseID,
      commentData: {
        project_suggestion_comment_response: {
          project_suggestion_comment_id: itemReplyId,
          content: content,
        },
      },
    };

    try {
      const { message } = await editProjectSuggestionCommentResponses(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Edited sucessfully!!!");
        refreshSuggestionComments();
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project suggestion comments response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectSuggestionCommentResponse",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments response"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectSuggestionCommentResponse",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshSuggestionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("suggestion", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Suggestion
        </Button>
        {/* <div className="container">
          <AppProjectSearch
            data={suggestions && suggestions.length > 1 ? [...suggestions] : []}
            moduleType={"suggestion"}
            value={suggestionFilter}
            setValue={setSuggestionFilter}
          />
        </div> */}
      </div>
      <ToastContainer />
      <CommentBox
        open={commentModal}
        setOpen={setCommentModal}
        comments={suggestionComments}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Suggestion"
        labelDetails={suggestionSelection.suggestion}
        idea={suggestionSelection.idea}
        purpose={suggestionSelection.purpose}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        refreshGoals={refreshSuggestions}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentResponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        commentBoxIdea={suggestionSelection.idea}
        commentBoxPurpose={suggestionSelection.purpose}
      />
      <ShareSuggestionModal
        openClose={openShareModal}
        setOpenShareModal={setOpenShareModal}
        profiles={profiles}
        selected={oneSuggestion}
        getAllSuggestions={getAllSuggestions}
      />
      <ProjectSuggestionModal
        closeModal={closeModal}
        isOpen={open}
        handleSubmit={handleCreate}
      />

      <AppReusableDisplayUpvotedProfiles
        votersToggleDrawer={votersToggleDrawer}
        setVotersToggleDrawer={setVotersToggleDrawer}
        profiles={votersProfiles}
        drawerTitle={drawerTitle}
        setDrawerTitle={setDrawerTitle}
        setVotersProfiles={setVotersProfiles}
      />

      <ProjectSuggestionEditModal
        suggestion={suggestionEdit}
        closeModal={closeEditModal}
        isOpen={openEdit}
        handleSubmit={handleUpdate}
      />
      <div className="profile-core-values">
        <div className="project-sug-main">
          <div className="project-sug-body">{showSuggestions()}</div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchSuggestions: (id) => dispatch(fetchProjectSuggestions(id)),
  createSuggestion: (id, data) => dispatch(createProjectSuggestion(id, data)),
  updateSuggestion: (pId, psId, data) =>
    dispatch(updateProjectSuggestion(pId, psId, data)),
  createComment: (pId, psId, data) =>
    dispatch(createProjectSuggestionComment(pId, psId, data)),
});

export default connect(null, mapDispatchToProps)(ProjectSuggestion);
