import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function CreateAssumption({
  isOpen,
  closeModal,
  projectID,
  createProjectAssumption,
  setProjectAssumption,
  projectAssumption,
}) {
  //  ==== FORM CONTROL STATE ====
  const [formState, setFormState] = useState({
    assumption: "",
    idea: "",
    purpose: "",
  });

  //   ==== SAVE THE ASSUMPTION AND HANDLE ANY ERROR TO AVOID APP FROM BREAKING ====
  const saveAssumption = async (data) => {
    try {
      const { payload } = await createProjectAssumption(projectID, data);
      if (typeof payload === "object") {
        NotificationManager.info("Assumption created successfully");
        //   === UPDATE THE STATE WITHOUT RELOADING ===
        setProjectAssumption([payload, ...projectAssumption]);
        setFormState({
          assumption: "",
          idea: "",
          purpose: "",
        });
        return;
      } else {
        NotificationManager.error(
          "Something went wrong kindly check your network connection and try again"
        );
      }
    } catch (error) {
      NotificationManager.error(
        "Something went wrong kindly check your network connection and try again"
      );
    }
  };

  //  ==== SUBMIT THE FORM ====
  const handleFormSubmit = (e) => {
    e.preventDefault();
    saveAssumption(formState);
    setFormState({
      assumption: "",
      idea: "",
      purpose: "",
    });

    closeModal();
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Add Assumption</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
        <div className="form-group">
            <label htmlFor="need">Assumption</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.assumption}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, assumption: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateAssumption;
