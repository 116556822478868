import React from "react";
import "../../stylesheets/landingPage.css";
import ideaImage from "../../images/ideaImage.png";
import top10 from "../../images/top10.png";
import projects from "../../images/projectLanding.png";
import market from "../../images/marketplace.png";

const Features = () => {
  return (
    <div className="container-fluid landing-features-content">
      <div className="row">
        <div className="col-lg-3 mb-5">
          <div className="landing-features mb-5">
            <img src={ideaImage} alt="world connected suggestion box" />
            {/* <h5 className="font-weight-bold"> Feature 1</h5> */}
            <p className="landing-features-para font-weight-bold p-2">
              World’s Most Connected Suggestion Box
            </p>
          </div>
        </div>
        <div className="col-lg-3 mb-5">
          <div className="landing-features mb-5">
            <img src={top10} alt="The top 10 system" />
            {/* <h5 className="font-weight-bold"> Feature 2</h5> */}
            <p className="landing-features-para font-weight-bold  p-2">
              Top10 System
            </p>
          </div>
        </div>
        <div className="col-lg-3 mb-5">
          <div className="landing-features">
            <img src={projects} alt="The top 10 system" />
            {/* <h5 className="font-weight-bold"> Feature 3</h5> */}
            <p className="landing-features-para font-weight-bold p-2">
              Projects
            </p>
          </div>
        </div>
        <div className="col-lg-3 mb-5 marketplace-anchor">
          <a href="https://62b329f4ee030f064d5ce4db--ubiquitous-dolphin-2f92bd.netlify.app/">
            <div className="landing-features">
              <img src={market} alt="The top 10 system" />
              {/* <h5 className="font-weight-bold"> Feature 4</h5> */}
              <p className="landing-features-para font-weight-bold p-2">
                TUC Market Place
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Features;
