import React from "react";

const UserBadge = ({badge}) => {
  return (
    <>
      <div className="user-badge">
        <div className="user-badge-top shadow-lg">
          <img
            src={badge.image}
            alt={badge.id}
          />
        </div>
      </div>
    </>
  );
};

export default UserBadge;
