/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createUser, checkLoggedInStatus } from "../../redux";
import ConfirmEmailModal from "../../components/ConfirmEmailModal";
import { toast } from "react-toastify";
import styles from "./css/main.module.css";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

import Container from "@mui/material/Container";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";

import logo from "../../assets/img/logo.png";
import { Button } from "@mui/material";
import AppSignUpForm from "../auth/AppSignUpForm";
import { acceptInvitation } from "../../services/InviteSystem/inviteSystemService";

function UseInvitation({ history, createUser }) {
  const params = useParams();
  const [invitationStatus, setInvitationStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [loaderSignUp, setButtonLoaderSignUp] = useState(false);
  const [disable, setDisableButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const accept = async () => {
      setLoader(true);
      try {
        const { message } = await acceptInvitation(params.token);
        if (message === "Invitation accepted") {
          NotificationManager.success(`${message}`);
          setInvitationStatus(true);
          setLoader(false);
          return;
        }
        NotificationManager.error(`${message}`);
        setLoader(false);
        setInvitationStatus(false);
      } catch (error) {
        NotificationManager.error(`${error.message}`);
        setLoader(false);
      }
    };

    accept();
  }, []);

  const buttonLoader = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const registerUser = ({
    firstName,
    lastName,
    userName,
    email,
    password,
    passwordConfirmation,
  }) => {
    setButtonLoaderSignUp(true);
    setDisableButton(true);
    const userData = {
      user: {
        first_name: firstName,
        last_name: lastName,
        username: userName,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        user_type_id: 2,
      },
    };
    createUser(userData)
      .then((response) => {
        const result = response.payload;
        if (result.post) {
          setTitle(result.post.title);
          setMessage(result.post.message);
          setOpen(true);
        }
        if (result.response.status && result.response.status === 422) {
          toast.error("Email or username already taken , please try again");
          setButtonLoaderSignUp(false);
          setDisableButton(false);
        }
      })
      .catch((errors) => console.log(errors));
  };

  const closeModal = () => {
    setOpen(false);
    history.push("/signin");
  };

  const checkStatus = () => {
    if (invitationStatus !== true)
      return (
        <div className="container mt-5">
          <Alert variant="filled" severity="error">
            This invitation is invalid or has already expired. Kindly ask for
            another invitation link.
          </Alert>
        </div>
      );

    return (
      <div className="row">
        <div className="col-md-4 mt-5">
          <h1
            className="text-center"
            style={{
              fontFamily: "fantasy",
            }}
          >
            Welcome to The Ultimate Collaboration
          </h1>
          <h3
            style={{
              fontFamily: "serif",
              textAlign: "center",
              color: "grey",
              marginTop: "20px",
            }}
          >
            A global initiative to increase follow through and help Good Ideas
            reach their Full Potential. Even for the most successful
            organizations, full potential is hard to imagine and solutions can
            seem impossible Together we will create the future we need to see
          </h3>

          <Button
            color="secondary"
            variant="contained"
            sx={{
              textAlign: "center",
              margin: "100px",
            }}
          >
            This is an invitation to join our community. Just create an account
            and start enjoying TUC
          </Button>
        </div>

        <div className="col-md-8 mt-5">
          <h1
            className="text-center"
            style={{
              fontFamily: "fantasy",
            }}
          >
            Create New account Here
          </h1>
          <div
            style={{
              border: "1px solid black",
              padding: "20px",
            }}
          >
            <AppSignUpForm
              registerUser={registerUser}
              disable={disable}
              loader={loaderSignUp}
              buttonLoader={buttonLoader}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.use_invitation_container} container-fluid`}>
      <NotificationContainer />
      <ConfirmEmailModal
        isOpen={open}
        closeModal={closeModal}
        title={title}
        message={message}
      />
      <div>
        <AppBar position="static" color="secondary">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <img
                className={styles.tuc_logo}
                src={logo}
                alt="nPmRzQ.png"
                border="0"
              />
              <h6
                style={{
                  color: "white",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  marginLeft: "1rem",
                  textTransform: "uppercase",
                }}
              >
                The ultimate collaboration
              </h6>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
      {loader ? <CircularProgress /> : checkStatus()}
    </div>
  );
}

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(createUser(data)),
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UseInvitation);
