/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styles from "../../stylesheets/taskModules/TaskMenuBar.module.css";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { Divider } from "@mui/material";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import Looks6Icon from "@mui/icons-material/Looks6";

function TaskMenuBar({ tab, setTab }) {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sidebar
      backgroundColor="#fff"
      collapsed={collapsed}
      className={styles.side_bar_container}
    >
      <div className={styles.sidebar_top_bar}>
        <div className={styles.project_creator}></div>
      </div>
      <Divider light={true} className={styles.side_bar_divider} />
      <Menu>
        <MenuItem
          icon={<SummarizeIcon />}
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            "&:active": {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
          }}
          active={tab === "" || tab === "ALL" ? true : false}
          className={
            tab === "" || tab === "ALL" ? `${styles.active_tab}` : null
          }
          onClick={() => setTab("ALL")}
        >
          {" "}
          All Tasks{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
          }}
          icon={<LooksOneIcon />}
          onClick={() => setTab("1")}
          active={tab === "1" ? true : false}
          className={tab === "1" ? `${styles.active_tab}` : null}
        >
          {" "}
          OI 1(today)
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
          }}
          icon={<LooksTwoIcon />}
          onClick={() => setTab("2")}
          active={tab === "2" ? true : false}
          className={tab === "2" ? `${styles.active_tab}` : null}
        >
          {" "}
          OI 2(soon)
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
          }}
          icon={<Looks3Icon />}
          onClick={() => setTab("3")}
          active={tab === "3" ? true : false}
          className={tab === "3" ? `${styles.active_tab}` : null}
        >
          {" "}
          OI 3(weekly batch)
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
          }}
          icon={<Looks4Icon />}
          onClick={() => setTab("4")}
          active={tab === "4" ? true : false}
          className={tab === "4" ? `${styles.active_tab}` : null}
        >
          {" "}
          OI 4(monthly batch)
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
          }}
          icon={<Looks5Icon />}
          onClick={() => setTab("5")}
          active={tab === "5" ? true : false}
          className={tab === "5" ? `${styles.active_tab}` : null}
        >
          {" "}
          OI 5(yearly batch)
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "800",
          }}
          icon={<Looks6Icon />}
          onClick={() => setTab("6")}
          active={tab === "6" ? true : false}
          className={tab === "6" ? `${styles.active_tab}` : null}
        >
          {" "}
          OI 6(someday)
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

export default TaskMenuBar;
