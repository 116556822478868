import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

export const fetchMasterNeedsCategories = async () => {
  try {
    const result = await axios.get(
      `${BASEURL}need_categories`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const createMasterNeedsCategory = async (data) => {
  try {
    const result = await axios.post(
      `${BASEURL}need_categories`,data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchMasterNeeds = async (id) => {
  try {
    const result = await axios.get(
      `${BASEURL}need_categories/${id}`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const createMasterNeed = async (data) => {
  try {
    const result = await axios.post(
      `${BASEURL}needs`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateMasterNeed = async (id,data) => {
  try {
    const result = await axios.put(
      `${BASEURL}needs/${id}`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};


export const fetchNeedSupport = async (id) => {
  try {
    const result = await axios.get(
      `${BASEURL}needs/${id}/need_supports`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
}

export const createNeedSupport = async (id,data) => {
  try {
    const result = await axios.post(
      `${BASEURL}/needs/${id}/need_supports`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};