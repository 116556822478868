import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import styles from "./AppProjectSearch.module.css";
import parse from "html-react-parser";
const filter = createFilterOptions();

const AppProjectSearch = ({ data, moduleType, value, setValue }) => {
  return (
    <div>
      <Autocomplete
        className={styles.search_bar_container}
        style={{
          width: "100%",
        }}
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              moduleType: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              moduleType: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option[moduleType]
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              moduleType: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={`free-solo-with-${moduleType}`}
        options={data}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option[moduleType];
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {parse(option[moduleType])}
          </li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={`Search by ${moduleType} `} />
        )}
      />
    </div>
  );
};

export default AppProjectSearch;
