import axios from "axios";
import { BASEURL } from "../../API/apiData";
import {
  CREATE_PROJECTNEEDS_FAILURE,
  CREATE_PROJECTNEEDS_SUCCESS,
  CREATE_PROJECTNEEDS_REQUEST,
  FETCH_PROJECTNEEDS_REQUEST,
  FETCH_PROJECTNEEDS_SUCCESS,
  FETCH_PROJECTNEEDS_FAILURE,
} from "./projectNeedsTypes";

// ==== Fetch Project Needs

export const fetchProjectsNeedsRequest = () => ({
  type: FETCH_PROJECTNEEDS_REQUEST,
});

export const fetchProjectsNeedsSuccess = (response) => ({
  type: FETCH_PROJECTNEEDS_SUCCESS,
  payload: response,
});

export const fetchProjectsNeedsFailure = (error) => ({
  type: FETCH_PROJECTNEEDS_FAILURE,
  payload: error,
});

//   ==== Create Project Needs

export const createProjectNeedsRequest = () => ({
  type: CREATE_PROJECTNEEDS_REQUEST,
});

export const createProjectNeedsSuccess = (response) => ({
  type: CREATE_PROJECTNEEDS_SUCCESS,
  payload: response,
});

export const createProjectNeedsFailure = (error) => ({
  type: CREATE_PROJECTNEEDS_FAILURE,
  payload: error,
});

// ==== Create Project Needs ====

export const createProjectNeeds = (projectID, data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(createProjectNeedsRequest());
  return axios
    .post(`${BASEURL}projects/${projectID}/project_needs`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(createProjectNeedsSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(createProjectNeedsFailure(error));
    });
};

// ==== Fetch Project Needs ==== 

export const fetchProjectsNeeds = (projectID) =>(dispatch)=> {
    const token = localStorage.getItem('token')
    dispatch(fetchProjectsNeedsRequest());
    return axios.get(`${BASEURL}projects/${projectID}/project_needs`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchProjectsNeedsSuccess(response.data));
      }).catch(error => {
        return dispatch((error));
      });
  }

