/* eslint-disable */
import React, { useState, Fragment, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { sendProjectInvite } from "../../../services/emailJs/projectInviatation";
import { NotificationManager } from "react-notifications";
import _ from "lodash";

const ProjectInviteModal = ({
  inviteModal,
  setInviteModal,
  allEmailAdresses,
  singleProject,
}) => {
  const [loader, setButtonLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    projectName: "",
  });

  useEffect(() => {
    if (!_.isEmpty(singleProject.data)) {
      setFormData({
        ...formData,
        projectName: singleProject.data.result.project.name,
      });
    }
  }, [singleProject]);

  const displaySelections = () => {
    const results = allEmailAdresses.map((value, idx) => {
      return (
        <Fragment key={idx}>
          <option value={value.email}>{value.username}</option>
        </Fragment>
      );
    });
    return results;
  };

  const buttonLoader = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const handleSendInvite = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    setButtonLoader(true);
    try {
      const response = await sendProjectInvite(formData);
      if (response.status === 200) {
        NotificationManager.info(
          `Invitation message to ${formData.email} sent successfully`
        );
        setFormData({
          email: "",
          message: "",
          projectName: "",
        });
        setButtonLoader(false);
        setDisableButton(false);
        setInviteModal(false);
        return;
      } else {
        NotificationManager.error(
          "Something went wrong. Kindly check your internet connection and try again"
        );
      }
    } catch (error) {}
  };
  return (
    <Modal show={inviteModal} onHide={() => setInviteModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Send a new project invite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pop-up invitation-card-ll">
          <div className="content">
            <div className="container">
              <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>

              <div className="subscribe">
                <form>
                  <div className="form-group">
                    <label>Name</label>
                    <select
                      className="form-select form-control"
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                    >
                      <option value="">none</option>
                      {displaySelections()}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Message</label>
                    <CKEditor
                      className="form-control"
                      editor={ClassEditor}
                      data={formData.message}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFormData({
                          ...formData,
                          message: data,
                        });
                      }}
                      required
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          variant="primary"
          className="create-project-btn m-3 btn"
          onClick={(e) => handleSendInvite(e)}
          disabled={disableButton}
        >
          {loader === true ? buttonLoader() : ` Send invite`}{" "}
          <i className="fa fa-paper-plane" aria-hidden="true"></i>
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProjectInviteModal;
