import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {  FETCH_PROJECT_FAILURE, FETCH_PROJECT_SUCCESS, FETCH_PROJECT_REQUEST, 
  CREATE_PROJECT_REQUEST, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE} from "./projectTypes";

  export const fetchProjectsRequest = () =>({
    type: FETCH_PROJECT_REQUEST,
  })
  
  export const fetchProjectsSuccess = (response) =>({
    type: FETCH_PROJECT_SUCCESS,
    payload: response
  })
  
  export const fetchProjectsFailure = (error) =>({
    type: FETCH_PROJECT_FAILURE,
    payload: error
  })

  // ==== Create Project ====
  export const createProjectsRequest = () =>({
    type: CREATE_PROJECT_REQUEST,
  })
  
  export const createProjectsSuccess = (response) =>({
    type: CREATE_PROJECT_SUCCESS,
    payload: response
  })
  
  export const createProjectsFailure = (error) =>({
    type: CREATE_PROJECT_FAILURE,
    payload: error
  })

  export const createProject = (data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createProjectsRequest());
    return axios.post(`${BASEURL}projects`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      }
    } )
      .then(response => {
        return dispatch(createProjectsSuccess(response.data));
      }).catch(error => {
        return dispatch(createProjectsFailure(error));
      });
  };

   
export const fetchProjects = () => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchProjectsRequest());
    return axios.get(`${BASEURL}projects`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchProjectsSuccess(response.data));
      }).catch(error => {
        return dispatch(fetchProjectsFailure(error));
      });
  }
