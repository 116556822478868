import React from "react";
import SuggestionCategoriesCard from "./SuggestionCategoriesCard";


const SuggestionCategories = ({suggestionCategories, setSelectedCategory}) => {

  const displayCategory = () => {
    let results = suggestionCategories.map((value, idx) => {
      return (
        <SuggestionCategoriesCard key={`ssix${idx}`} value={value} setSelectedCategory={setSelectedCategory}/>
      );
    });
    return results;
  };

  return (
    <div className="suggestion-categories-container">
      <div className="card">
        <div className="card-header">Categories</div>
        <ul className="list-group list-group-flush">{displayCategory()}</ul>
      </div>
    </div>
  );
}


export default SuggestionCategories
