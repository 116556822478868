import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import CircularProgress from '@mui/material/CircularProgress';

const BottomDrawerMobile = ({
  bottomDrawer,
  setBottomDrawer,
  fetchSuggestionComments,
  createSugResponse,
  suggestionID,
}) => {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (bottomDrawer === true) return setOpen(true);
  }, [bottomDrawer]);

  const handleClose = () => {
    setBottomDrawer(false);
    setOpen(false);
  };

  const handleCreateComment = async () => {
    // create a new comment for a given post
    setLoader(true);
    const data = {
      suggestion_response: {
        response_text: comment,
      },
    };
    try {
      const { type } = await createSugResponse(suggestionID, data);
      if (type === "CREATE_SUGGESTION_RESPONSE_SUCCESS") {
        setLoader(false);
        handleClose();
        setComment("")
        toast("Your Comment has been added successfully", {
          position: "top-right",
        });
        fetchSuggestionComments();
        return;
      } else {
        setLoader(false)
        toast("Something went wrong...", { position: "top-right" });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const displayFormConditionally = (action = "New comment") => {
    if (action === "New comment") {
      return (
        <>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction={"row"}
          >
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                data={comment}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setComment(data);
                }}
              />
            </Grid>
          </Grid>

          <Grid container className="mobile-comment-button-container">
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="secondary"
                endIcon={<SendIcon />}
                onClick={handleCreateComment}
              >
               {loader ? <CircularProgress color="success"  /> : "comment"}  
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> New Comment</DialogTitle>
        <DialogContent>
          {displayFormConditionally()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BottomDrawerMobile;
