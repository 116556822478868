import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_COLLABORATION_REQUEST,CREATE_COLLABORATION_SUCCESS, CREATE_COLLABORATION_FAILURE,
  UPDATE_COLLABORATION_REQUEST,UPDATE_COLLABORATION_SUCCESS, UPDATE_COLLABORATION_FAILURE
 } from "./collaborationTypes";



export const createCollaborationRequest = () => ({
  type: CREATE_COLLABORATION_REQUEST,
})

export const createCollaborationSuccess = (response) => ({
  type: CREATE_COLLABORATION_SUCCESS,
  payload: response
})

export const createCollaborationFailure = (error) => ({
  type: CREATE_COLLABORATION_FAILURE,
  payload: error
})

export const updateCollaborationRequest = () => ({
  type: UPDATE_COLLABORATION_REQUEST,
})

export const updateCollaborationSuccess = (response) => ({
  type: UPDATE_COLLABORATION_SUCCESS,
  payload: response
})

export const updateCollaborationFailure = (error) => ({
  type: UPDATE_COLLABORATION_FAILURE,
  payload: error
})

export const createCollaboration = (projectID, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createCollaborationRequest());
  return axios.post(`${BASEURL}projects/${projectID}/collaborations`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createCollaborationSuccess(response.data));
    }).catch(error => {
      return dispatch(createCollaborationFailure(error));
    });
};