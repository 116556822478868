import React from "react";
import _ from 'lodash'

const GoalDetailsCard = ({ data,privacy }) => {
  return (
    <>
      <div className="card border-bottom rounded">
        <div className="card-header  d-flex flex-row justify-content-between">
          <div className="d-flex flex-row" id={`headingOne${data.id}`}>
            <i className="fas fa-bullhorn fa-2x text-primary"></i>
            <h2 className="mb-0">
              <button
                className="btn btn-link text-info "
                type="button"
                data-toggle="collapse"
                data-target={`#collapseOne${data.id}`}
                aria-expanded="false"
                aria-controls={`collapseOne${data.id}`}
              >
                <span className="profile-goal">{data.goal}</span>
              </button>
            </h2>
          </div>
        {privacy ? <div className="p-3">
            <i className="fas fa-edit text-info"></i>
            &nbsp;
            <i className="fas fa-trash text-danger"></i>
          </div> : null}
        </div>
        <div
          id={`collapseOne${data.id}`}
          className="collapse"
          aria-labelledby={`headingOne${data.id}`}
          data-parent={`#goalAccordian`}
        >
          <div className="card-body border-bottom">
            <span className="h6">Objectives</span>
            {
              !_.isEmpty(data.objectives) ?<ul>
              {data.objectives.map((obj) => (
                <li className="h6" key={obj.id}>{obj.description}</li>
              ))}
            </ul>: <h4>No Objectives for this goal</h4>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalDetailsCard;
