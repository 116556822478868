import React from 'react'

const ProfileNav = ({setTabNo}) => {
  return (
    <>
      <nav className="profile-nav">
        <ul className="profile-nav-ul">
          <li onClick={() => setTabNo(0)}>Summary</li>
          <li onClick={() => setTabNo(1)}>Core Values</li>
          <li onClick={() => setTabNo(2)}>Network</li>
          <li onClick={() => setTabNo(3)}>Goals</li>
          <li onClick={() => setTabNo(6)}>Projects</li>
          <li onClick={() => setTabNo(4)}>Badges</li>
          <li onClick={() => setTabNo(7)}>Suggestions</li>
          <li onClick={() => setTabNo(5)}>Inventory</li>
        </ul>
      </nav>
    </>
  );
}

export default React.memo(ProfileNav)
