import { FETCH_LEGENDASSOCIATIONS_REQUEST,FETCH_LEGENDASSOCIATIONS_SUCCESS,FETCH_LEGENDASSOCIATIONS_FAILURE } from "./legendAssociationTypes";

const initialState = {
  loading: true,
  data: [],
  errorMsg: "",
};

const legendAssociationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEGENDASSOCIATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LEGENDASSOCIATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case FETCH_LEGENDASSOCIATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default legendAssociationReducer;
