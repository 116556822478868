/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const ErrorFallback = ({ error }) => {
  const [timeoutID, setTimeoutID] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const newTimeoutID = setTimeout(() => {
      goHome()
    }, 100);

    setTimeoutID(newTimeoutID);
  
    return () => {
      clearTimeout(newTimeoutID);
    }
  }, [])
  

  const goHome = () => {
    history.push("/");
  };

  return (
    <div className="p-3 w-100 text-center">
      <h2>Something went wrong!</h2>
      <p>
        Please <a href="mailto:info@theultimatecollaboration.com">contact support</a> if the
        issue persists.
      </p>
      <br />
      <button className="btn btn-danger" onClick={goHome}>Go Back Home</button>
    </div>
  );
};

export default ErrorFallback;
