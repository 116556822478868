import { BASEURL } from "../../API/apiData";

const getAllNetworks = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${BASEURL}networks`, requestOptions);
  const results = await response.json();
  return results;
};

const getShowNetworkPageData = async (pageID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${BASEURL}networks/${pageID}`, requestOptions);
  const results = await response.json();
  return results;
};

const createNewNetwork = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}networks`, requestOptions);
  const results = await response.json();
  return results;
};

const getAllProjectsForNetwork = async (networkID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}network_projects/${networkID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const addProjectToNetwork = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(`${BASEURL}network_projects`, requestOptions);
  const results = await response.json();
  return results;
};

const editNetwork = async (networkData) => {
  const token = localStorage.getItem("token");
  const { networkID, data } = networkData;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}networks/${networkID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const addNewNetworkMember = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}network_teams`, requestOptions);
  const results = await response.json();
  return results;
};

export {
  getAllNetworks,
  createNewNetwork,
  getAllProjectsForNetwork,
  addProjectToNetwork,
  getShowNetworkPageData,
  editNetwork,
  addNewNetworkMember,
};
