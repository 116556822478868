/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  createNewCommentResponse,
  createNewComments,
  editDiscussionCommentResponse,
  editProjectComment,
  getAllComments,
} from "../../services/projects/ProjectCommentsService";
import nocores from "../../assets/nocorevalues.png";
import _ from "lodash";
import AppReusableCard from "./globals/AppReusableCard";
import {
  createNewDiscussionLike,
  deleteNewDiscussionLike,
} from "../../services/projects/ProjectDiscussionLikesService";
import { NotificationManager } from "react-notifications";
import AppReusableDisplayUpvotedProfiles from "./globals/AppReusableDisplayUpvotedProfiles";
import ShareDiscussionModal from "./globals/ShareDiscussionModal";
import { Button } from "@mui/material";
import CommentBox from "./globals/CommentBox";
import {
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../services/projects/ProjectAssumptionService";
// import AppProjectSearch from "./globals/AppProjectSearch";
import EditDiscussion from "./projectDiscussion/EditDiscussion";

const ProjectDiscussion = ({
  projectDiscussion,
  projectID,
  createProjectDiscussion,
  setProjectDiscussion,
  singleProject,
  fetchProjectDiscussion,
  project,
  allProfiles,
  speedDialState,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [discussionEditState, setDiscussionEditState] = useState({});
  // ==== STATE FOR ALL DISCUSSION COMMENTS ====
  const [discussionComments, setDiscussionComments] = useState([]);
  // ==== DISCUSSION ID ====
  // === OPEN EDIT MODAL
  const [isEditOpen, setIsEditOpen] = useState(false);
  const closeEditModal = () => setIsEditOpen(false);
  // ==== DISPLAY THE DISCUSSIONS CONDITIONALLY  THIS SETS THE ID OF THE DISCUSSION ====
  const [oneDiscussion, setOneDiscussion] = useState(null);
  const [oneDiscussionShare, setOneDiscussionShare] = useState({});
  // ==== REPLY COMMMENTS ====
  const [commentModal, setCommentModal] = useState(false);

  // ==== OPEN UPVOTES PROFILES ====
  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [votersProfiles, setVotersProfiles] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  // ==== Share Modal ====
  const [openShareModal, setOpenShareModal] = useState(false);
  const [profiles, setProfiles] = useState([]);
  // ==== COMMENT BOX ====
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  const [discussionSelection, setDiscussionSelection] = useState({
    discussion: "",
    idea: "",
    purpose: "",
  });
  const [editSubmitButton] = useState(false);
  const [discussionFilter] = useState("");
  const [filteredList, setFilteredList] = useState([...projectDiscussion]);
  const [likeButton, setLikeButton] = useState(false);

  useEffect(() => {
    fetchAllProfiles();
  }, []);

  // === USE EFFECT TO FILTER THE DATA ===

  useEffect(() => {
    setFilteredList([...projectDiscussion]);
  }, [projectDiscussion]);

  useEffect(() => {
    setFilteredList(filterDiscussions());
    return () => {
      setFilteredList(projectDiscussion);
    };
  }, [discussionFilter]);

  const fetchAllProfiles = async () => {
    const { type, payload } = await allProfiles();
    if (type === "FETCH_ALL_PROFILES_SUCCESS") return setProfiles([...payload]);
  };

  // ==== GET ALL THE COMMENTS FOR A SPECIFIC DISCUSSION ====
  const populateComments = async (discussionID) => {
    const { result, message } = await getAllComments(projectID, discussionID);
    if (message !== "Successfully retrieved comments")
      return setDiscussionComments([]);
    setDiscussionComments(result);
  };

  // ==== HANLDE UPVOTE ACTION ====

  const refreshDiscussionAfterVote = (action, data) => {
    const currentDiscussion = projectDiscussion.findIndex(
      (discussion) => discussion.id === data.id
    );
    const updatedDiscussions = {
      ...projectDiscussion[currentDiscussion],
      likes: {
        upvoted: action === "upvote" ? true : false,
        counter:
          action === "upvote"
            ? projectDiscussion[currentDiscussion].likes.counter + 1
            : projectDiscussion[currentDiscussion].likes.counter - 1,
      },
      upvoted_by: projectDiscussion[currentDiscussion].upvoted_by,
    };
    const newProjectDiscussions = [...projectDiscussion];
    newProjectDiscussions[currentDiscussion] = updatedDiscussions;
    setProjectDiscussion(newProjectDiscussions);
  };

  const handleLikeDiscussion = async (value) => {
    setLikeButton(true);
    const initialDiscussionState = projectDiscussion;
    refreshDiscussionAfterVote("upvote", value);

    const data = {
      discussion_like: {
        discussion_id: value.id,
      },
    };
    const { message } = await createNewDiscussionLike(
      projectID,
      value.id,
      data
    );
    if (message === "Thanks for your vote") {
      const { payload } = await fetchProjectDiscussion(projectID);
      setProjectDiscussion(payload.result);
      setLikeButton(false);
      return;
    }

    NotificationManager.error(`${message}`);
    setProjectDiscussion(initialDiscussionState);
    setLikeButton(false);
  };

  //  ==== HANDLE DISVOTE ====
  const handleUnlikeDiscussion = async (value) => {
    setLikeButton(true);
    const initialDiscussionState = projectDiscussion;
    refreshDiscussionAfterVote("unvote", value);
    const data = {
      discussion_like: {
        discussion_id: value.id,
      },
    };
    const { message } = await deleteNewDiscussionLike(
      projectID,
      value.id,
      data
    );
    if (message === "Your vote has been removed") {
      const { payload } = await fetchProjectDiscussion(projectID);
      setProjectDiscussion(payload.result);
      setLikeButton(false);
      return;
    }

    NotificationManager.error(`${message}`);
    setProjectDiscussion(initialDiscussionState);
    setLikeButton(false);
  };

  // ==== SHARE MODAL ====
  const handleOpenShareModal = (value) => {
    setOneDiscussionShare({ ...value });
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };

  // ==== EDIT OF THE DISCUSSION ====
  const HandleEditAction = (value) => {
    setDiscussionEditState({ ...value, discussion_id: value.id });
    setIsEditOpen(true);
  };

  const handleOpenCommentModal = (value) => {
    setOneDiscussion(value.id);
    populateComments(value.id);
    setDiscussionSelection({
      idea: value?.idea || "",
      purpose: value?.purpose || "",
      discussion: value?.discussion || "",
    });
    setCommentModal(true);
  };

  const displayDiscussions = () => {
    if (_.isEmpty(projectDiscussion)) {
      return (
        <>
          <div className="w-100 text-center mb-4">
            <h4 className="text-dark mb-4">No discussions.</h4>
            <img
              className="no-cores-thumbnail mb-4"
              src={nocores}
              alt="no-cv"
            />
          </div>
        </>
      );
    }
    const results = filteredList.map((value) => {
      return (
        <div key={value.id} className="assumption-card-cont">
          <AppReusableCard
            idea={value.idea}
            purpose={value.purpose}
            inputFieldName="Discussion"
            inputField={value.discussion}
            creatorFirstName={value.profile_first_name}
            creatorLastName={value.profile_last_name}
            createdAt={value.created_at}
            editAction={() => HandleEditAction(value)}
            openCommentModal={() => handleOpenCommentModal(value)}
            actionItems={true}
            creatorImage={
              value.creator_profile.profile_avatar
                ? value.creator_profile.profile_avatar.url
                : null
            }
            votes={value.likes.counter}
            userLiked={value.likes.upvoted}
            likeAction={() => handleLikeDiscussion(value)}
            unLikeAction={() => handleUnlikeDiscussion(value)}
            upvoted_by={value.upvoted_by}
            shared_with={value.shared_with}
            setVotersToggleDrawer={setVotersToggleDrawer}
            setVotersProfiles={setVotersProfiles}
            openShareModal={() => handleOpenShareModal(value)}
            setDrawerTitle={setDrawerTitle}
            comments={value.comments}
            likeButton={likeButton}
          />
        </div>
      );
    });
    return results;
  };
  // <==== FILTER DISCUSSIONS ====>
  const filterDiscussions = () => {
    // ==== If the filter is empty ====
    if (discussionFilter === "") return projectDiscussion;
    if (discussionFilter === null) {
      return projectDiscussion;
    }
    // ==== If we have a list to filter ====
    let filteredList = projectDiscussion.filter((value) => {
      return value.discussion === discussionFilter.discussion && value;
    });
    return filteredList;
  };

  // <==== METHODS FOR COMMENTS ====>
  const refreshDiscussions = async () => {
    // This is for refreshing the discussions
    const { payload } = await fetchProjectDiscussion(projectID);
    setProjectDiscussion(payload.result);
  };

  const refreshDiscussionComments = async () => {
    const { result } = await getAllComments(projectID, oneDiscussion);
    setDiscussionComments([...result]);
  };

  const handleCreateComment = async () => {
    // this is for creating a new discussion comment
    setSubmitButton(true);
    const data = {
      project_comment: {
        comment_text: commentBoxDetail,
      },
    };
    try {
      const { message } = await createNewComments(
        projectID,
        oneDiscussion,
        data
      );
      if (message === "Comment created succesfully") {
        refreshDiscussionComments();
        setCommentBoxDetail("");
        setSubmitButton(false);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error creating the assumptions comment in projects"
      );
      NotificationManager.error("Error", "Failed to create new comment");
      setSubmitButton(false);
    }
  };

  const handleEditOfComment = async (editState, setEditState) => {
    // this is for the editing of comments  on the discussion card
    const { itemId, content } = editState;
    const data = {
      project_id: projectID,
      project_discussion_id: oneDiscussion,
      id: itemId,
      project_comment: {
        comment_text: content,
      },
    };
    try {
      const { message } = await editProjectComment(data);
      if (message === "Comment was updated successfuly") {
        refreshDiscussionComments();
        setEditState({
          form: false,
          itemId: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error Editing the discussion comment in projects"
      );
      NotificationManager.error("Error", "Failed to edit comment");
      setEditState({
        form: false,
        itemId: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfComment = async (upvoteable_id) => {
    // this is for the upvotes of comments
    const data = {
      upvote: {
        upvoteable_id: upvoteable_id.id,
        upvoteable_type: "ProjectComment",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        refreshDiscussionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfComment = async (upvoteable_id) => {
    // This is for the unvote of comments ====
    const data = {
      upvoteable_id: upvoteable_id.id,
      upvoteable_type: "ProjectComment",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        refreshDiscussionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    //  This is to create a response to a comment
    const { itemReplyId, replyContent } = replyState;

    const data = {
      project_id: projectID,
      discussion_id: oneDiscussion,
      project_comment_id: itemReplyId,
      commentReponse: {
        response_text: replyContent,
      },
    };
    try {
      const { message } = await createNewCommentResponse(data);
      if (message === "Comment created succesfully") {
        refreshDiscussionComments();
        setReplyState({
          display: true,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    replyState
  ) => {
    //  This is for the  edit responses to a comment
    const { responseID, content } = editCommentResponse;
    const { itemReplyId } = replyState;

    const data = {
      project_id: projectID,
      discussion_id: oneDiscussion,
      project_comment_id: itemReplyId,
      id: responseID,
      commentReponse: {
        response_text: content,
      },
    };

    try {
      const { message } = await editDiscussionCommentResponse(data);
      if (message === "Comment was updated successfuly") {
        refreshDiscussionComments();
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project discussion edit of response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    // This is for upvote of comment responses
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectCommentResponse",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        refreshDiscussionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    // This is to unvote comment responses
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectCommentResponse",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        refreshDiscussionComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  console.log(discussionSelection);

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("discussion", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Discussion
        </Button>
      </div>
      <EditDiscussion
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        projectID={projectID}
        closeEditModal={closeEditModal}
        setDiscussionEditState={setDiscussionEditState}
        discussionEditState={discussionEditState}
        fetchProjectDiscussion={fetchProjectDiscussion}
        setProjectDiscussion={setProjectDiscussion}
      />
      <ShareDiscussionModal
        openClose={openShareModal}
        closeCommentModal={handleCloseShareModal}
        singleProject={singleProject}
        profiles={profiles}
        selected={oneDiscussionShare}
        fetchProjectDiscussion={fetchProjectDiscussion}
        setProjectDiscussion={setProjectDiscussion}
        projectID={projectID}
      />
      <AppReusableDisplayUpvotedProfiles
        votersToggleDrawer={votersToggleDrawer}
        setVotersToggleDrawer={setVotersToggleDrawer}
        profiles={votersProfiles}
        drawerTitle={drawerTitle}
        setDrawerTitle={setDrawerTitle}
        setVotersProfiles={setVotersProfiles}
      />
      <CommentBox
        open={commentModal}
        setOpen={setCommentModal}
        comments={discussionComments}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Discussion"
        labelDetails={discussionSelection.discussion}
        idea={discussionSelection.idea}
        purpose={discussionSelection.purpose}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        refreshGoals={refreshDiscussions}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        commentBoxIdea={discussionSelection.idea}
        commentBoxPurpose={discussionSelection.purpose}
      />

      <div className="profile-core-values container-fluid container-m-well">
        <div className="discussion-display-container">
          <div className="container">
            {/* <AppProjectSearch
              data={
                projectDiscussion && projectDiscussion.length > 1
                  ? [...projectDiscussion]
                  : []
              }
              moduleType={"discussion"}
              value={discussionFilter}
              setValue={setDiscussionFilter}
            /> */}
          </div>
          <div className="discussion-card-area">{displayDiscussions()}</div>
        </div>
      </div>
    </>
  );
};

export default ProjectDiscussion;
