import React from "react";
import { Link } from "react-router-dom";
import bulb from "../../images/lightBulb.png";

const SuggestionNavigation = () => {
  return (
    <div className="suggestion-navigation-container">
      <div className="card">
        <div className="card-header">Navigation</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {" "}
            <img src={bulb} alt="thumbnail" />{" "}
            <Link to={"/suggestion"} className="text-dark">
              {" "}
              <span>Back To All Suggestions</span>{" "}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SuggestionNavigation;
