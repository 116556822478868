import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_COMMENT_SUCCESS, CREATE_COMMENT_REQUEST, CREATE_COMMENT_FAILURE, FETCH_COMMENT_REQUEST, FETCH_COMMENT_SUCCESS, FETCH_COMMENT_FAILURE } from "./commentTypes";


export const fetchCommentsRequest = () =>({
  type: FETCH_COMMENT_REQUEST,
})

export const fetchCommentsSuccess = (response) =>({
  type: FETCH_COMMENT_SUCCESS,
  payload: response
})

export const fetchCommentsFailure = (error) =>({
  type: FETCH_COMMENT_FAILURE,
  payload: error
})


export const createCommentRequest = () =>({
    type: CREATE_COMMENT_REQUEST,
  })
  
  export const createCommentSuccess = (response) =>({
    type: CREATE_COMMENT_SUCCESS,
    payload: response
  })
  
  export const createCommentFailure = (error) =>({
    type: CREATE_COMMENT_FAILURE,
    payload: error
  })


  export const createComment = (catId,tId,tteID, data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createCommentRequest());
    return axios.post(`${BASEURL}categories/${catId}/top_tens/${tId}/top_ten_entries/${tteID}/top_ten_entry_comments`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    } )
      .then(response => {
        return dispatch(createCommentSuccess(response.data));
      }).catch(error => {
        return dispatch(createCommentFailure(error));
      });
  };
  
  export const fetchComments = (catId,tId, tteID) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchCommentsRequest());
    return axios.get(`${BASEURL}categories/${catId}/top_tens/${tId}/top_ten_entries/${tteID}/top_ten_entry_comments`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchCommentsSuccess(response.data));
      }).catch(error => {
        return dispatch(fetchCommentsFailure(error));
      });
  };