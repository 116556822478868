/*eslint-disable */
import React, {useState, useEffect, Fragment} from "react";
import { useHistory } from "react-router-dom";
import idea from "../../images/idea.png";
import comment from "../../images/comment.png";
import { fetchSuggestionEngagements } from "../../services/engagement/engagementService";
import _ from "lodash";
import Divider from '@mui/material/Divider';

const SuggestionTrending = () => {
  const history = useHistory()
  const [trendingSuggestion, setTrendingSuggestion] = useState([])

  useEffect(() => {
    getTrendingSuggestion()
  }, []);

  const getTrendingSuggestion = async () => {
    try {
      const results = await fetchSuggestionEngagements()
      setTrendingSuggestion([...results])
    } catch (error) {
      console.log(error)
    }

  }

  const navigateToTrendingSuggestion = (tSuggestion) => {
    const {suggestion_id} = tSuggestion
    history.push(`/suggestion/${suggestion_id}`)

    window.location.reload()
  }

  const displayTrendingSuggestions = () => {
    if(!_.isEmpty(trendingSuggestion)) {
      const results = trendingSuggestion.map((value, index) => {
        return(
          <Fragment key={index}>
             <li className="list-group-item" onClick={() => navigateToTrendingSuggestion(value)}>
            <div className="suggestion-trending-details">
              <img src={idea} alt="idea" />{" "}
              <span>{value.name}</span>{" "}
            </div>
            <div className="suggestion-trending-comments text-right">
              <div className="tt-comments">
                <img src={comment} alt="idea" /> <span>number of engagements {value.count}</span>
                <Divider />
              </div>
         
              </div>
            </li>
          </Fragment>
        );
      })
      return results
    }
  }

  return (
    <div className="suggestion-trending-container">
      <div className="card">
        <div className="card-header">
          <span> Trending Suggestions </span>
        </div>
        <ul className="list-group list-group-flush">
         {displayTrendingSuggestions()}
        </ul>
      </div>
    </div>
  );
}

export default SuggestionTrending;
