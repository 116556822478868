import { BASEURL } from "../../API/apiData";

const getAllBmc = async (projectID) => {
  const token = localStorage.getItem("token");
    let requestOptions = {
        method: "GET",
        headers: {
        "Content-Type": "application/json",
         Authorization: `Bearer ${token}`,
        },
    };
    try {
      const response = await fetch(
        `${BASEURL}projects/${projectID}/business_model_canvas`,
        requestOptions
      );
      const results = await response.json();
      return results;

    } catch (error) {
      console.log(error)
    }
  
}

const createNewBmc = async (projectID, projectName, bmcDescription) => {
  const token = localStorage.getItem("token");
    const data = {
        title: `${projectName} Business Model Canvas`,
        description: bmcDescription
    }

    let requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      };

    const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas`,
    requestOptions
    );
    const results = await response.json();
    return results;
} 

export{getAllBmc, createNewBmc }