/*eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { fetchAllProfile } from "../../redux";
import "../../stylesheets/users.css";
import avatar from "../../images/avatar.jpeg";
import SearchComponent from "../reusablecomponents/SearchComponent";
import Paginate from "../reusablecomponents/Paginate";
import Navbar from "../navbar/Navbar";
import { Link } from "react-router-dom";

const AllUsersComponent = ({ allProfiles }) => {
  const [allUsers, setAllUsers] = useState([]);

  const [items, setItems] = useState([]);
  const [dataSet, setDataSet] = useState({ page_size: 12, page_number: 1 });
  const [pageCount, setPageCount] = useState(0);
  const [filterItems, setFilterItems] = useState([]);

  const handleSearch = (apiRes, fieldValue, event) => {
    event.preventDefault();
    let results = apiRes;
    let pages = 0;

    if (fieldValue !== "") {
      try {
        results = apiRes.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(fieldValue.toLowerCase());
        });
      } catch {
        results = apiRes;
      }
      setFilterItems(results);
    } else {
      setFilterItems(apiRes);
      pages = Math.ceil(apiRes.length / dataSet["page_size"]);
    }
    pages = Math.ceil(results.length / dataSet["page_size"]);
    setPageCount(pages);
    setItems(getPage(results, 1));
  };

  const getsearch = (e) => {
    const fieldValue = e.target.value;
    handleSearch(allUsers, fieldValue, e);
  };

  const getPage = (set, currentPage) => {
    const firstRec = (currentPage - 1) * dataSet["page_size"];
    const page_to_show = set.slice(firstRec, firstRec + dataSet["page_size"]);
    return page_to_show;
  };

  const fetchNewPage = (currentPage) => {
    const page_to_show = getPage(filterItems, currentPage);
    return page_to_show;
  };

  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    let getfetch = fetchNewPage(currentPage);
    setItems(getfetch);
  };

  useEffect(() => {
    allProfiles()
      .then((response) => {
        setAllUsers(response.payload);
      })
      .catch((err) => console.log("Internal Server Error"));
  }, [allProfiles]);

  useEffect(() => {
    let pages = pageCount;
    if (allUsers.length > 0) {
      pages = Math.ceil(allUsers.length / dataSet["page_size"]);
      setPageCount(pages);
      setFilterItems(allUsers);
      setItems(getPage(allUsers, 1));
      setDataSet({ page_size: 12, page_number: 1 });
    }
  }, [allUsers]);

  const displayAllUsers = () => {
    let results = items.map((value, idx) => {
      return (
        <Fragment key={idx}>
          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <Link to={`/profile/${value.id}`}>
              <div className="container d-flex justify-content-center align-items-center">
                <div className="card">
                  <div className="upper">
                    {" "}
                    <img
                      src="https://i.imgur.com/Qtrsrk5.jpg"
                      className="img-fluid"
                      alt="background"
                    />{" "}
                  </div>
                  <div className="card_body_users">
                    <div className="user text-center">
                      <div className="profile">
                        {" "}
                        <img
                          src={
                            value.profile_avatar.url === null
                              ? avatar
                              : value.profile_avatar.url
                          }
                          className="rounded-circle"
                          alt="profile"
                          width="80"
                        />{" "}
                      </div>
                    </div>
                    <div className="mt-5 text-center">
                      <h4 className="mb-0 user-name">
                        {value.first_name} {value.last_name}
                      </h4>{" "}
                      <span className="d-block mb-2 user-living-place">
                        {value.region !== null ? value.region : "" } , {value.country !== null ? value.country : ""}
                      </span>
                      <div className="user-position text-center">
                        {value.role}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </Fragment>
      );
    });
    return results;
  };

  return (
    <>
      <Navbar />
      <br /> <br /> <br /> <br />
      <div className="container-fluid">
        <div className="all-users-title container-fluid">
          <h3>Search User</h3>
          <div className="d-flex justify-content-between">
            <SearchComponent
              placeholder="Search by name"
              getsearch={getsearch}
            />
          </div>
        </div>
        <div className="container-fluid">
          <hr />
          <div className="pagination-desktop">
            <div className="number_of_users">{allUsers.length} users</div>
            <div className="desk-paginate"></div>
          </div>
          <div className="row all-users-card">{displayAllUsers()}</div>
          <div className="row pagination-real-styles m-3">
            <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  allProfiles: () => dispatch(fetchAllProfile()),
});

export default connect(null, mapDispatchToProps)(AllUsersComponent);
/*eslint-enable */
