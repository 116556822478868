import React from "react";
import parse from "html-react-parser";
import styles from "../css/styles.module.css";

const DetailsCard = ({ title, details, icon }) => {
  return (
    <div className={styles.details_card_container}>
      <div className={styles.details_card_header}>
        <h4 className={styles.card_header_title}>
          {" "}
          {icon} {title}{" "}
        </h4>
      </div>
      <div className={styles.details_card_body}>{parse(details)}</div>
    </div>
  );
};

export default DetailsCard;
