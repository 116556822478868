import React from "react";
// --- material ui ----
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Divider } from "@mui/material";
import PolylineIcon from "@mui/icons-material/Polyline";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

// --- ck editor ---
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditNetworkModal = ({
  setOpen,
  open,
  networkData,
  setNetworkData,
  editNetwork,
  createEditNetworkMutation,
}) => {
  //   ---- ACTIONS ---
  const handleClose = () => {
    setOpen(false);
  };

  const handleFormSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const data = {
      network: {
        ...networkData,
      },
    };

    editNetwork(data);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<CloseIcon />}
          onClick={handleClose}
        >
          {" "}
          close
        </Button>
        <div className="text-center">
          <h3
            style={{
              fontSize: "22px",
              color: "#000",
              fontWeight: "800",
              textTransform: "uppercase",
            }}
          >
            <AccountTreeIcon /> Create New Network
          </h3>
        </div>
      </DialogTitle>
      {createEditNetworkMutation.isError && (
        <Alert variant="filled" severity="error">
          Something went wrong while Editing the network. Kindly try again...
        </Alert>
      )}

      <Divider />
      <DialogContent>
        <Box
          noValidate
          component={"form"}
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "auto",
            width: "fit-content",
          }}
        >
          <div className="form-group row">
            <div className="col-12">
              <label
                style={{
                  fontSize: "17px",
                  fontWeight: "800",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                Network Name:
              </label>
              <input
                type="text"
                className="form-control"
                required
                value={networkData.name}
                onChange={(e) =>
                  setNetworkData({ ...networkData, name: e.target.value })
                }
              />
            </div>
          </div>

          <div className="form-group  row">
            <div className="col-12">
              <label
                style={{
                  fontSize: "17px",
                  fontWeight: "800",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                Network Idea:
              </label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={networkData.idea}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setNetworkData({ ...networkData, idea: data });
                }}
              />
            </div>
          </div>
          <div className="form-group  row">
            <div className="col-12">
              <label
                style={{
                  fontSize: "17px",
                  fontWeight: "800",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                Network Purpose:
              </label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={networkData.purpose}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setNetworkData({ ...networkData, purpose: data });
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: "800",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                Network Mission:
              </label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={networkData.mission}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setNetworkData({
                    ...networkData,
                    mission: data,
                  });
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12">
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: "800",
                  textTransform: "capitalize",
                  color: "#000",
                }}
              >
                Network Vision
              </label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={networkData.vision}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setNetworkData({
                    ...networkData,
                    vision: data,
                  });
                }}
              />
            </div>
          </div>

          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<PolylineIcon />}
              onClick={(e) => handleFormSubmit(e)}
              disabled={createEditNetworkMutation.isLoading && true}
            >
              {createEditNetworkMutation.isLoading
                ? "Editing network..."
                : "Edit Network"}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditNetworkModal;
