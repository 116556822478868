import React from 'react'

const  CoreValueCard = ({value,privacy = false}) => {
  return (
    <>
      <div className="core-value-card">
        <div >
          <i className="fas fa-bullseye fa-2x"></i>
          &nbsp;
          <span>{value}</span>
        </div>
       { privacy ? <div>
          <i className="fas fa-edit"></i>
          &nbsp;
          <i className="fas fa-trash text-danger"></i>
        </div> : null}
      </div>
    </>
  )
}

export default CoreValueCard
