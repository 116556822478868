import React from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import styles from "../css/main.module.css";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { deepOrange } from "@mui/material/colors";

const LatestProject = ({ title, icon, project_activity }) => {
  const history = useHistory();

  const navigateToProject = (id) => {
    history.push({
      pathname: `/projects/${id}`,
    });
  };

  const navigateToProfile = (profile) => {
    history.push({
      pathname: `/profile/${profile}`,
    });
  };

  const displayProjectInfo = () => {
    if (_.isEmpty(project_activity.project)) return null;

    return (
      <div
        className={styles.card_info_body_container}
        onClick={() => navigateToProject(project_activity.project.id)}
      >
        <div className={styles.project_name_body}>
          <ReadMoreReusable>
            {`${project_activity.project.name}`}
          </ReadMoreReusable>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.info_card_container}>
      <div className={styles.info_card_header}>
        {" "}
        {icon}
        <h4 className={styles.info_card_header_title}>{title}</h4>
      </div>
      <div className={styles.info_card_body}>
        <div className={styles.card_info_header}>
          <div className={styles.number_value_container}>
            <h2 className={styles.number_value}>{project_activity.count}</h2>
            <h5 className={styles.name_value}>Engagements</h5>
          </div>
          <div className={styles.progress_circular}>
            <ListItem
              alignItems="flex-start"
              onClick={() => navigateToProfile(project_activity.creator.id)}
              sx={{
                cursor: "pointer",
              }}
            >
              <ListItemAvatar>
                {project_activity.creator.profile_avatar &&
                project_activity.creator.profile_avatar.url ? (
                  <Avatar
                    alt={`${project_activity.creator.first_name} ${project_activity.creator.last_name}`}
                    src={`${project_activity.creator.profile_avatar.url}`}
                  />
                ) : (
                  <Avatar
                    sx={{ bgcolor: deepOrange[500] }}
                    src="/broken-image.jpg"
                    alt={`${project_activity.creator.first_name}`}
                  />
                )}
              </ListItemAvatar>
              <ListItemText
                primary="Created by:"
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {`${project_activity.creator.first_name} ${project_activity.creator.last_name}`}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </div>
        </div>
        <div className={styles.card_info_body}>{displayProjectInfo()}</div>
      </div>
    </div>
  );
};

export default LatestProject;
