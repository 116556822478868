/*eslint-disable react-hooks/exhaustive-deps, array-callback-return*/
import React, { Fragment, useState, useEffect } from "react";
import EmployeeCard from "./EmployeeCard";
import _ from "lodash";
import FinancialForm from "./FinancialForm";

function SoartingFinancialForm({ employees, allRecords }) {
  // ==== State for the form
  // === Making the form a controlled form
  const [state, setState] = useState({
    name: "",
    amount: 0,
    notes: "",
    paidOn: "",
    department: "",
    paidArrears: 0,
    remainingArrears: 0,
  });
  const [sortValue, setSortValue] = useState("");
  const [amountRecieved, setAmountRecieved] = useState([]);
  const [financialDepartments, setFinancialDepartments] = useState([]);
  const [editState, setEditState] = useState({});
  const [editAction, setEditAction] = useState(false);
  // ==== Used to show the modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setEditAction(false);
  };

  const handleShow = () => {
    setState({
      name: "",
      amount: 0,
      notes: "",
      paidOn: "",
      department: "",
      paidArrears: 0,
      remainingArrears: 0,
    });
    setEditAction(false);
    setShow(true);
  };

  const handleShowEdit = () => {
    setShow(true);
  };

  //    ==== used to get the total amount of money
  useEffect(() => {
    let total = [];
    allRecords.forEach((element) => {
      total.push(element.Total);
    });
    setAmountRecieved(total);
  }, [allRecords]);

  const filterData = () => {
    const results = allRecords.filter((value) => {
      if (value.EmployeeName === sortValue) {
        return value;
      }
    });
    return results;
  };

  // === Check for change in sort value in the state so that to update the total amount displayed
  useEffect(() => {
    let total = [];
    if (sortValue !== "All records") {
      filterData().forEach((element) => {
        total.push(element.Total);
      });
      setAmountRecieved(total);
    } else if (sortValue === "All records") {
      allRecords.forEach((element) => {
        total.push(element.Total);
      });
      setAmountRecieved(total);
    }
  }, [sortValue]);

  // === Sum Up the total amount of money given
  const totalAmountSummation = () => {
    if (!_.isEmpty(amountRecieved)) {
      const reducer = (previousValue, currentValue) =>
        previousValue + currentValue;
      return amountRecieved.reduce(reducer);
    }
  };

  const displayEmployees = () => {
    let results = employees.map((value, index) => {
      return (
        <Fragment key={index}>
          <option value={value}>{value}</option>
        </Fragment>
      );
    });
    return results;
  };

  // ==== Get the edit state from employee card child component
  const getEditState = (editInformation) => {
    setEditState(editInformation);
  };

  const displayEmployersCard = () => {
    const results = filterData().map((value, index) => {
      return (
        <Fragment key={index}>
          <EmployeeCard
            state={state}
            setState={setState}
            value={value}
            getEditState={getEditState}
            handleShowEdit={handleShowEdit}
            editAction={editAction}
            setEditAction={setEditAction}
          />
        </Fragment>
      );
    });
    return results;
  };

  const displayAllFinancials = () => {
    const results = allRecords.map((value, index) => {
      return (
        <Fragment key={index}>
          <EmployeeCard
            state={state}
            setState={setState}
            value={value}
            getEditState={getEditState}
            handleShowEdit={handleShowEdit}
            editAction={editAction}
            setEditAction={setEditAction}
          />
        </Fragment>
      );
    });
    return results;
  };

  // ==== Creating all the departments
  const structureDepartments = () => {
    const departments = [];
    allRecords.forEach((item) => {
      departments.push(item.Department);
    });
    if (!_.isEmpty(departments)) {
      const unique = [...new Set(departments)];
      setFinancialDepartments(unique);
    }
  };

  useEffect(() => {
    structureDepartments();
  }, [allRecords]);

  return (
    <div className="financ-container-all">
      <div>
        {/* It displays the form for creating a new record */}
        <FinancialForm
          state={state}
          setState={setState}
          show={show}
          handleClose={handleClose}
          employees={employees}
          financialDepartments={financialDepartments}
          editState={editState}
          editAction={editAction}
        />
        <div className="financial-form container-fluid">
          <div className="row">
            <div className="col-md-4 col-xm-12 text-center">
              <form className="form-inline sort-financials">
                <select
                  className="custom-select my-1 mr-sm-2"
                  id="inlineFormCustomSelectPref"
                  value={sortValue}
                  onChange={(event) => setSortValue(event.target.value)}
                >
                  <option>All records</option>
                  {displayEmployees()}
                </select>
              </form>
            </div>
            <div className="col-md-4 col-xm-12">
              <div className="financials-amount-used text-center">
                <span> Total Amount:</span> {totalAmountSummation()}$
              </div>
            </div>

            <div className="col-md-4 col-xm-12">
              <div className="text-left create-new-financial-record m-1">
                <button className="btn" onClick={handleShow}>
                  Create a new record
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            {sortValue === "All records" || sortValue === ""
              ? displayAllFinancials()
              : displayEmployersCard()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SoartingFinancialForm;
/*eslint-enable react-hooks/exhaustive-deps, array-callback-return*/
