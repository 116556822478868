import React from "react";

function ProfileMVCard({ icon, title, details }) {
  return (
    <>
      <div className="profile-mv-card">
        <div className="profile-mv-card-icon">
          <img src={icon} alt="icon" />
        </div>
        <div className="profile-mv-card-details">
          <h3 className="profile-mv-card-details-title">{title}</h3>
          <p>
            {details}
          </p>
        </div>
      </div>
    </>
  );
}

export default ProfileMVCard;
