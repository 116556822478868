import React, { useState, Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { fetchFields, updateTopTen } from "../redux";

const TTEditModal = ({
  catId,
  id,
  isOpen,
  closeEditModal,
  name,
  desc,
  fetchFields,
  updateTopTen,
  currentUser
}) => {
  const [title, setTitle] = useState(name);
  const [description, setDescription] = useState(desc);
  const [inputFields, setInputFields] = useState([]); 

  useEffect(() => {
    fetchFields(catId, id)
      .then((response) => {
        const fetched = response.payload.result;
        setInputFields(fetched);
      })
      .catch((error) => console.log("Internal Application Error"));
  }, [fetchFields, catId, id]);

  const handleChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "field_name") {
      values[index].field_name = event.target.value;
    } else {
      values[index].field_type = event.target.value;
    }

    setInputFields(values);
  };

  const handleAddField = () => {
    let values = [...inputFields];
    if (values.length < 5) {
      values.push({ field_name: "", field_type: "", user_id: currentUser });
    } else {
      alert("You cannot create more than 6 fields");
    }

    setInputFields(values)
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const combineFields = () => {
    const values = [...inputFields];
    return values;
  };

  const handleFormSubmit = (event) => {
    const fields_attributes = combineFields();
    const updateData =  {
      title: title,
      description: description,
      fields_attributes: fields_attributes,
    };
    updateTopTen(catId,id,updateData)
   
    closeEditModal();
    setTitle("");
    setDescription("");
    event.preventDefault();
    setTimeout(() => {
      window.location.reload(true)
    }, 1000);
  };

  const showModalData = () => {
    return (
      <Modal show={isOpen} onHide={closeEditModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#309897" }}
          closeButton
        >
          <Modal.Title>Edit Top10</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                className="form-control"
                id="title"
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                id="description"
                type="text"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="3"
                placeholder="Enter your description"
                required
              />
            </div>
            <div>
              <div className="form-group mt-2 d-flex justify-content-between">
                <h5 className="pt-2">Edit Fields</h5>
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => handleAddField()}
                >
                  +
                </button>
              </div>
              <div className="row mx-auto">
                {inputFields.map((field, idx) => (
                  <Fragment key={`frag-${idx}`}>
                    <div className="form-group col-md-6 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        id="field_name"
                        name="field_name"
                        value={field.field_name}
                        placeholder="field name"
                        onChange={(event) => handleChange(idx, event)}
                        required
                        disabled={field.field_name === "Name"}
                      />
                    </div>
                    <div className="form-group col-md-4 mt-2">
                      <select
                        className="form-control"
                        name="field_type"
                        id="field_type"
                        value={field.field_type}
                        onChange={(event) => handleChange(idx, event)}
                        disabled={field.field_name === "Name"}
                      >
                        <option value="DEFAULT" disabled>
                          -- field type --
                        </option>
                        <option value="text">text</option>
                        <option value="number">number</option>
                        <option value="url">link</option>
                        <option value="date">date</option>
                      </select>
                    </div>
                    <div className="form-group col-md-2">
                      <button
                        className="btn btn-danger mt-2"
                        type="button"
                        onClick={() => handleRemoveField(idx)}
                        disabled={field.field_name === "Name"}
                      >
                        -
                      </button>
                    </div>
                  </Fragment>
                ))}
              </div>
              <hr />
              <div className="form-group float-right">
                <button className="add-btn rounded" type="submit">
                  Edit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{showModalData()}</>;
};

const mapDispatchToProps = (dispatch) => ({
  fetchFields: (catId, id) => dispatch(fetchFields(catId, id)),
  updateTopTen: (catId,id,data) => dispatch(updateTopTen(catId,id,data))
});

export default connect(null, mapDispatchToProps)(TTEditModal);
