/* eslint-disable eqeqeq */
import React, {Fragment, useState} from "react";
import _ from "lodash";
import LegendEditModal from "./LegendEditModal";
import { connect } from "react-redux";
import { editLegend, fetchLegendAssociations, fetchSLegend } from "../../redux";

const LegendTableRow = ({ association, legend, categories,fetchLegend,fetchAssociations,editLegend }) => {

  const [openEdit, setOpenEdit] = useState(false)
  const [modalData, setModalData] = useState({})
  const [modalAssociations, setModalAssociations] = useState([])


  const openEditModal = (id) => {
    fetchAssociations().then(res => setModalAssociations(res.payload.result))
    .catch(err => console.log("Internal Server Error"))
    fetchLegend(id).then(res => setModalData(res.payload.result))
    .catch(err => console.log("Internal Data Error"))
    setTimeout(() => {
      setOpenEdit(true);
    }, 1500);
  }

  const handleSubmit = (id,data) => {
    editLegend(id,data)
    setTimeout(() => {
      window.location.reload(true)
    }, 1000);
  }

  const closeModal = () => {
    setOpenEdit(false)
  }
  
  const displayCategory = (id) => {
    let name = ''
    for(let i = 0; i < categories.length; i++){
      if(categories[i].id == id)
      name = categories[i].name
    }

    return name
  }

  const showEdit = () => {
    if(openEdit) {
      return (
        <LegendEditModal data={modalData} isOpen={openEdit} closeModal={closeModal} associations={modalAssociations} categories={categories} handleSubmit={handleSubmit}/>
      )
    }
  }
  
  return (
    <>
      {showEdit()}
      <tr className="table-hr">
        <td colSpan="6"></td>
      </tr>
      <tr>
        <td colSpan="6" className="legend-association">
          {association.name || ''}
        </td>
      </tr>
      {!_.isEmpty(legend) ? (
        legend.map((leg,idx) => {
          return (
            <Fragment key={`ss-${leg.indicator}-${idx}`}>
              <tr>
                <td>{leg.indicator}</td>
                <td>{leg.basic_meaning}</td>
                <td>{displayCategory(leg.legend_category_id)}</td>
                <td>{leg.purpose}</td>
                <td>{leg.google_doc_name && leg.google_doc_link  ? <a href={leg.google_doc_link} target="_blank" rel="noreferrer">{leg.google_doc_name.toUpperCase()}</a> : "No Google DOC Available".toUpperCase()}</td>
                <td><button className="border-0 bg-light rounded" onClick={() => openEditModal(leg.id)}><i className="fas fa-edit"></i></button></td>
              </tr>
            </Fragment>
          );
        })
      ) : (
        <tr>
          <td colSpan="6" className='text-center'>No Data Available for this Association</td>
        </tr>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchLegend: (id) => dispatch(fetchSLegend(id)),
  fetchAssociations: () => dispatch(fetchLegendAssociations()),
  editLegend: (id,data) => dispatch(editLegend(id,data))
})

export default connect(null,mapDispatchToProps)(LegendTableRow);
/* eslint-enable eqeqeq */