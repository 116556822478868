import React from 'react'
import { Link } from "react-router-dom";

const CategoryCard = ({ catId, imgUrl, name}) => {
  return (
    <div className="category-crd shadow-lg">
      <div className="category-img-cont">
        <img src={imgUrl} 
        alt="thumbnail" />
      </div>
      <br />
      <span className="category-crd-title">{name}</span>
      <br />
      <Link to={`/categories/${catId}`} className="category-crd-btn">Explore</Link>
      
    </div>
  )
}

export default CategoryCard
