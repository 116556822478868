import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";

const editSuggestionComment = async (
  suggestion_id,
  comment_id,
  commentData
) => {
  const data = {
    suggestion_response: {
      response_text: commentData,
    },
  };
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}suggestions/${suggestion_id}/suggestion_responses/${comment_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export const createSuggestionRemix = async (suggestion_id,data) => {
   try {
     const result = await axios.post(
       `${BASEURL}suggestions/${suggestion_id}/create_remix`,
       data,
       fetchRequestOptions
     );
     return result.data;
   } catch (error) {
     console.log(error);
   }
};

export default editSuggestionComment;
