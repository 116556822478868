/*eslint-disable react-hooks/exhaustive-deps*/
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import anonymus from "../../images/anonymus.jpeg";
import like from "../../images/like.png";
import pencil from "../../images/pencil.png";

import { fetchComments } from "../../redux/comments/commentActions";

function TopTenCommentsBody({
  categoryID,
  topTenID,
  entry,
  fetchComment,
  numberOfComments,
}) {
  let [comment, setComment] = useState({});

  useEffect(() => {
    fetchComment(categoryID, topTenID, entry)
      .then((results) => {
        const { result } = results.payload;
        setComment(result);
      })
      .catch((err) => {
        console.log("Interal Server Error");
      });
  }, [fetchComment, categoryID, topTenID, entry]);

  useEffect(() => {
    let number = comment.length;
    numberOfComments(number);
  }, [comment]);

  const displayResponses = () => {
    if (!_.isEmpty(comment)) {
      const results = comment.map((value, idx) => {
        return (
          <Fragment key={idx}>
            <div className="comment-container-tt-v">
              <img src={anonymus} alt="anonymus" />
              <div className="tt-display-idea-purpose">
                <p className="suggestion-purpose">
                  {" "}
                  <span>Idea:</span>
                  {value.idea}
                </p>

                <p className="suggestion-purpose">
                  {" "}
                  <span>Purpose:</span>
                  {value.purpose}
                </p>

                <p className="suggestion-purpose">
                  {" "}
                  <span>comment:</span>
                  {value.comment_text}
                </p>
              </div>
            </div>

            <div className="suggestion-comments-icons-v container">
              <div className="sugget-like">
                <img src={like} alt="like button" /> <span>0</span>
              </div>

              <div className="comments-edit">
                <img src={pencil} alt="pencil" /> <span>Edit</span>
              </div>

              <div className="comments-reply">
                <i className="fa fa-reply-all" aria-hidden="true"></i>{" "}
                <span>Reply All</span>
              </div>
            </div>
          </Fragment>
        );
      });
      return results;
    } else {
      return (
        <p className="suggestion-purpose text-center">
          <span> No Comments</span>
        </p>
      );
    }
  };

  return <>{displayResponses()}</>;
}

const mapDispatchToProps = (dispatch) => ({
  fetchComment: (categoryID, topTenID, id) =>
    dispatch(fetchComments(categoryID, topTenID, id)),
});

export default connect(null, mapDispatchToProps)(TopTenCommentsBody);
/*eslint-enable react-hooks/exhaustive-deps*/
