import { BASEURL } from "../../API/apiData";

const getAllSwot = async (projectID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewSwot = async (projectID, projectName) => {
  const token = localStorage.getItem("token");
  const data = {
    title: `${projectName} swot analysis`,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewSwotComment = async (
  projectID,
  project_swot_id,
  swot_item_id,
  block,
  comment
) => {
  const token = localStorage.getItem("token");
  const { route, commentRoute } = block;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(comment),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${project_swot_id}/${route}/${swot_item_id}/${commentRoute}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editSwotComment = async (
  projectID,
  project_swot_id,
  swot_item_id,
  block,
  comment,
  itemID
) => {
  const token = localStorage.getItem("token");
  const { route, commentRoute } = block;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(comment),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${project_swot_id}/${route}/${swot_item_id}/${commentRoute}/${itemID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createSwotCommentResponses = async (
  projectID,
  project_swot_id,
  swot_item_id,
  block,
  comment,
  itemID
) => {
  const token = localStorage.getItem("token");
  const { route, commentRoute, commentReponseRoute } = block;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(comment),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${project_swot_id}/${route}/${swot_item_id}/${commentRoute}/${itemID}/${commentReponseRoute}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editSwotCommentResponses = async (
  projectID,
  project_swot_id,
  swot_item_id,
  block,
  comment,
  itemID,
  ID
) => {
  const token = localStorage.getItem("token");
  const { route, commentRoute, commentReponseRoute } = block;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(comment),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_swots/${project_swot_id}/${route}/${swot_item_id}/${commentRoute}/${itemID}/${commentReponseRoute}/${ID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  getAllSwot,
  createNewSwot,
  createNewSwotComment,
  editSwotComment,
  createSwotCommentResponses,
  editSwotCommentResponses,
};
