import React from "react";

const SuggestionSearches = ({recentSearch, mobileSearchSuggestion}) => {
  return (
    <div className="suggestion-searches-container">
      <div className="card  mb-3">
        <div className="card-header">Your Recent Serches</div>
        <div className="card-body">
          {mobileSearchSuggestion !== null ? recentSearch() : <div className="recent-search-desktop" ><strong> NO SEARCH DONE YET </strong> </div>}
        </div>
      </div>
    </div>
  );
};

export default SuggestionSearches;
