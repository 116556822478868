import React from "react";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import nocores from "../../../assets/nocorevalues.png";
import { removeSubProject } from "../../../services/subProjects/subProjectService";
import DisplayProjects from "../../DisplayProjects";
import { Button } from "@mui/material";

function SubProject({
  subProjects,
  categories,
  sdgs,
  fetchAllSubProjects,
  followAction,
  unfollowAction,
  speedDialState,
}) {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const { id } = useParams();
  // === DELETE A SUBPROJECT ===
  const handleDelete = async (subProjectID) => {
    const { message } = await removeSubProject(id, subProjectID);
    if (message !== "SubProject deleted successfully")
      return NotificationManager.error(
        "Something went wrong subproject not removed"
      );
    NotificationManager.info("Subproject removed");
    fetchAllSubProjects(id);
  };

  // === FINISH DELETE SUBPROJECT==

  const displaySubProjects = () => {
    if (subProjects.length > 0) {
      return (
        <div className="sub-project-cont">
          <DisplayProjects
            categories={categories}
            sdgs={sdgs}
            projects={subProjects}
            icon={true}
            handleDelete={handleDelete}
            followAction={followAction}
            unfollowAction={unfollowAction}
          />
        </div>
      );
    }
    return (
      <div className="w-100 text-center">
        <h4 className="text-dark mt-3 mb-3"> No sub projects</h4>
        <img className="no-cores-thumbnail mb-5" src={nocores} alt="no-cv" />
      </div>
    );
  };

  return (
    <div className="container-fluid container-m-well">
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("subproject", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button mt-4"
        >
          Add Sub Project
        </Button>
      </div>
      {displaySubProjects()}
    </div>
  );
}

export default SubProject;
