/*eslint-disable */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";

const filter = createFilterOptions();

function SuggestionShareModal({
  openShareModal,
  handleClose,
  allProfilesData,
  setSelectedProfiles,
  selectedProfiles,
  shareSuggestion,
  chosenSuggestion,
}) {
  const [value, setValue] = useState(null);
  const [filteredProfiles, setFilteredProfiles] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(chosenSuggestion)) {
      const { shared_Profiles } = chosenSuggestion;
      function findDifferentObjects(
        array1 = allProfilesData,
        array2 = shared_Profiles
      ) {
        let differences = [];
        // Loop through the first array of objects
        for (let i = 0; i < array1.length; i++) {
          // Get the 'id' value of the current object
          let id = array1[i].id;
          // Check if the 'id' value of the current object in the second array matches
          let match = array2.find((obj) => obj.id === id);
          // If there is no match, add the object to the differences array
          if (!match) {
            differences.push(array1[i]);
          }
        }
        // Return the differences array
        return differences;
      }
      const result = findDifferentObjects();
      setFilteredProfiles(result);
    }
  }, [chosenSuggestion, allProfilesData]);


  const searchBar = () => {
    return (
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              first_name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              first_name: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.first_name
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              first_name: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={ filteredProfiles}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.first_name;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            {option.first_name} {option.last_name}{" "}
          </li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Search by first name" />
        )}
      />
    );
  };

  const displayAllProfiles = () => {
    if (value !== null)
      return (
        <FormControlLabel
          control={<Checkbox 
            onChange={(event) => {
              if (event.target.checked) {
                setSelectedProfiles([...selectedProfiles, value.id]);
                return;
              }

              if (!event.target.checked) {
                const result = selectedProfiles.filter((item) => {
                  return item !== value.id
                });

                setSelectedProfiles([...result]);
                return;
              }
            }}
          
          />}
          label={
            <div className="share-display-container-sub">
              <Avatar
                alt={`${value.first_name} ${value.last_name}`}
                src={`${value.profile_avatar.url}`}
              />
              &nbsp;
              <span>{value.first_name}</span>
              &nbsp;
              <span>{value.last_name}</span>
            </div>
          }
        />
      );
    const result = filteredProfiles.map((profile, idx) => {
      return (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedProfiles([...selectedProfiles, profile.id]);
                  return;
                }

                if (!event.target.checked) {
                  const result = selectedProfiles.filter((item) => {
                    return item !== profile.id;
                  });

                  setSelectedProfiles([...result]);
                  return;
                }
              }}
            />
          }
          label={
            <div className="share-display-container-sub">
              <Avatar
                alt={`${profile.first_name} ${profile.last_name}`}
                src={`${profile.profile_avatar.url}`}
              />
              &nbsp;
              <span>{profile.first_name}</span>
              &nbsp;
              <span>{profile.last_name}</span>
            </div>
          }
          key={idx}
        />
      );
    });
    return result;
  };

  return (
    <div>
      <Dialog
        open={openShareModal}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          SHARE SUGGESTION WITH:
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            {searchBar()}
            <FormGroup>{displayAllProfiles()}</FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => shareSuggestion()}>share</Button>
          <Button onClick={() => handleClose()}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SuggestionShareModal;
