import React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import Backdrop from "@mui/material/Backdrop";

const actions = [
  { icon: <BorderColorIcon />, name: "ADD" },
  { icon: <ContentPasteSearchIcon />, name: "SEARCH" },
];

const SuggestionBoxPlusButton = ({
  setDesktopPlusBtnState,
  desktopPlusBtnState,
  setSearchButton,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleClick = (action) => {
    if (action === "ADD") {
      setDesktopPlusBtnState({
        ...desktopPlusBtnState,
        addSuggestion: true,
      });
      handleClose();
      return;
    }

    if (action === "SEARCH") {
      setSearchButton(true);
      handleClose();
      return;
    }
  };

  return (
    <Box sx={{  width:400, height: 200, transform: "translateZ(0px)", flexGrow: 1 }}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: "fixed", bottom: 0, right: 200 }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleClick(action.name)}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default SuggestionBoxPlusButton;
