import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { BASEURL } from "../../../API/apiData";
import { NotificationManager } from "react-notifications";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function EditDiscussion({
  isEditOpen,
  projectID,
  setIsEditOpen,
  closeEditModal,
  discussionEditState,
  fetchProjectDiscussion,
  setProjectDiscussion,
}) {
  // === MAKE THE FORM CONTROLLED ===
  const [formState, setFormState] = useState({
    discussion: "",
    idea: "",
    purpose: "",
    discussionID: null,
  });

  // === LOADING COMPONENT STATE ===
  const [loading, setLoadingComponent] = useState(false);

  //  ==== EDIT AND POPULATE THE STATE WITHOUT REFRESHING. ALSO ERROR HANDLING ====

  const populateDisplayState = async () => {
    try {
      const { payload } = await fetchProjectDiscussion(projectID);
      setProjectDiscussion(payload.result);
    } catch (error) {
      NotificationManager.error(
        "Something went wrong. Kindly check your internet connection and try again"
      );
    }
  };

  // === LOADING COMPONENTS ===
  const loadingComponent = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const handleEdit = async () => {
    const editData = {
      discussionData: {
        ...formState,
      },
    };
    //   ==== GET TOKEN FOR AUTHENTICATION ===
    const token = localStorage.getItem("token");

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(editData),
    };

    try {
      const { status } = await fetch(
        `${BASEURL}projects/${projectID}/discussion/${formState.discussionID}`,
        requestOptions
      );
      if (status !== 200)
        return NotificationManager.error(
          "Something went wrong, kindly check internet connection and try again"
        );
      populateDisplayState();
      closeEditModal();
      setLoadingComponent(false);
      NotificationManager.info("updated successfully!");
    } catch (error) {
      console.log(error);
      setLoadingComponent(false);
    }
  };

  // === SUBMIT THE FORM ===
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoadingComponent(true);
    handleEdit();
  };

  // ==== POPULATE THE EDIT FORM WHEN THE FORM APPEARS ===

  useEffect(() => {
    setFormState({
      discussion: discussionEditState.discussion,
      idea: discussionEditState.idea,
      purpose: discussionEditState.purpose,
      discussionID: discussionEditState.discussion_id,
    });
  }, [discussionEditState]);

  return (
    <Modal show={isEditOpen} onHide={closeEditModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Edit Discussion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>

        <div className="form-group">
            <label htmlFor="need">Title of discussion</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={discussionEditState.discussion}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, discussion: data });
              }}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={discussionEditState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={discussionEditState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>

     
          <div className="form-group float-right">
            <button
              className="add-btn rounded text-white"
              type="submit"
              disabled={loading}
            >
              {loading ? loadingComponent() : "Submit"}
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default EditDiscussion;
