/* eslint-disable no-unused-vars */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./ProjectCalender.module.css";
import OpenItemPriority from "./PriorityModule";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const CreateNewOpenItem = ({
  isOpen,
  handleClose,
  formState,
  setFormState,
  handleCreateOpenItem,
  loading,
}) => {
  const handlePrioritySelection = (e) => {
    if (e.target.checked)
      return setFormState({ ...formState, priority: e.target.value });
  };

  const displayPriorityList = () => {
    const results = OpenItemPriority.map((value, index) => {
      return (
        <FormControlLabel
          value={value.level}
          control={<Radio />}
          label={value.name}
          key={index}
          onChange={(e) => handlePrioritySelection(e)}
        />
      );
    });

    return results;
  };

  const OpenItemForm = () => {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="need" className={styles.form_detail_cont}>
            Task Name
          </label>
          <TextField
            type="text"
            className={styles.task_name_field}
            placeholder="Task name of open item(required)"
            multiline
            rows={1}
            defaultValue={formState.title}
            label="Required"
            required
            onChange={(e) => {
              setFormState({
                ...formState,
                title: e.target.value,
              });
            }}
          />
        </div>

        <div className="form-group">
          <label htmlFor="idea" className={styles.form_detail_cont}>
            Task Detail
          </label>
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={formState.details}
            onChange={(event, editor) => {
              const data = editor.getData();
              setFormState({ ...formState, details: data });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="purpose" className={styles.form_detail_cont}>
            Priority
          </label>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            {displayPriorityList()}
          </RadioGroup>
        </div>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle className={styles.form_detail_cont_title}>
        New Open Item
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add an Open Item (Task) that needs to be accomplished on/by this date.
          Also add the priority level(Argency) for that particular Open item
        </DialogContentText>
        <hr />
        {OpenItemForm()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCreateOpenItem}
          variant="contained"
          color="secondary"
          startIcon={<AddTaskIcon />}
          disabled={loading}
        >
          {" "}
          {loading ? (
            <CircularProgress color="success" />
          ) : (
            " Create new open Item"
          )}
        </Button>

        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          endIcon={<CancelIcon />}
          disabled={loading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewOpenItem;
