import React, { useState } from 'react'
import NetworkContactCard from './NetworkContactCard'
import ProfileNetworkForm from './ProfileNetworkForm'
import _ from "lodash";
import nocores from '../../assets/nocorevalues.png'

const ProfileNetwork = ({myNetwork,handleNetworkSubmit, privacy=false}) => {

  const [showForm, setShowForm] = useState(false)

  const showNetworkForm = () => {
    setShowForm(!showForm)
  }

  const handleSubmit = (contact) => {
    handleNetworkSubmit(contact)
  }

  const showData = () => {
    if(!_.isEmpty(myNetwork.result)){
      return myNetwork.result.map((obj,idx) => <NetworkContactCard key={`nn${idx}`} obj={obj}/>)
    }else if(_.isEmpty(myNetwork.result)) {
      return (
        <div className="w-100 text-center">
          <img className="no-cores-thumbnail"src={nocores} alt="no-cv" />
          <h4 className="text-dark">{myNetwork.message}</h4>
        </div>
      )
    }
  }

  
  return (
    <>
      <div className="profile-core-values">
       { privacy ? <div className="d-flex flex-row justify-content-between p-3">
          <h1>My Network</h1>
          <button className="add-btn rounded text-white" onClick={showNetworkForm}>Add New</button>
        </div>: null}
        <div className="profile-net-form">
          {
            showForm ? <ProfileNetworkForm showForm={showNetworkForm} handleSubmit={handleSubmit}/> : null
          }
        </div>
        {showData()}
      </div>
    </>
  )
}

export default ProfileNetwork
