/*eslint-disable react-hooks/exhaustive-deps*/
import _ from 'lodash';
import React, {useEffect } from 'react'
import avatar from "../../images/avatar.jpeg";
import moment from 'moment'

function EmployeeCard({ state, setState, value, getEditState, handleShowEdit, setEditAction, editAction }) {
  const { recievedOn, Department, EmployeeName, Notes, Total, RemainingArrears, WhoPaid, record_id, Paidarrears } = value;

  const displayDate = (value) => {
    const timeNow = new Date();
    const parsedNow = Date.parse(timeNow);
    const commentTime = Date.parse(value);
    let createdAt = parsedNow - commentTime;
    let minutes = Math.floor(createdAt / 60000);
    if (minutes > 60 && minutes < 1440) {
      let hours = Math.floor(minutes / 60);
      return {
        value: hours,
        unit: "hr",
      };
    } else if (minutes > 1440) {
      let days = Math.floor(minutes / 1440);
      return {
        value: days,
        unit: "days",
      };
    }
    return {
      value: minutes,
      unit: "min",
    };
  };

  //  ==== Display Profile Photos

  const displayPhoto = () => {
    if (!_.isEmpty(value.ProfilePhoto) && value.ProfilePhoto !== undefined) {
      return (
        <img src={value.ProfilePhoto[0].thumbnails.large.url} alt="profilefinances" className="profile-image-financials" />
      )
    } else {
      return (
        <img src={avatar} className="profile-image-financials" alt="profilefinances"/>
      )
    }
  }

  // ==== Handling the Edits
  const handleEdit = () => {
    let formattedDate = moment(recievedOn).format('YYYY-MM-DD');
    setState({
      id: record_id,
      name: EmployeeName,
      amount: Total,
      notes: Notes,
      paidOn: formattedDate,
      department: Department,
      paidArrears: Paidarrears,
      remainingArrears: RemainingArrears
    })
    setEditAction(true)
    handleShowEdit();
  }

  useEffect(() => {
    getEditState(state)
  }, [])


  return (
    <div className="col-md-6 col-sm-12 col-lg-4 col-xl-3">
      <div className="card">
        <div className="card-header">
          {EmployeeName} <span className="department badge-warning">{Department}</span>
        </div>
        <div className="card-body">
          <div className="amount-recieved">
            {displayPhoto()}
            <div className="card-title">
              <span className="badge-light info-bg"> Stipend Recieved: </span>  {Total}$
              <div className="remaining-amount">
                <span className="badge-light info-bg"> remaining Arrears: </span>  {RemainingArrears}$
              </div>
              <div className="remaining-amount">
                <span className="badge-light info-bg"> PaidBy: </span>  {WhoPaid}
              </div>
            </div>
          </div>
          <div>
          </div>
          <span className="card-text financial-notes">{Notes}</span>
          <div className="date-display">
            <span className="badge-light info-bg"> The stipend was paid: </span> {displayDate(recievedOn).value} {displayDate(recievedOn).unit} ago
          </div>
        </div>
        <button className="edit-open-item" onClick={handleEdit}>Edit Financial Record</button>
      </div>

    </div>
  )
}

export default EmployeeCard
/*eslint-enable react-hooks/exhaustive-deps*/