import React, { useState } from "react";
import { toast } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// === MATERIAL UI ===

import { createSuggestionCommentReply } from "../../services/suggestions/suggestionCommentReplies";

function SuggestionCommentReply({
  setCommentReplyForm,
  commentReplyForm,
  fetchSuggestionComments,
  suggestionResponse,
  suggestionID,
}) {
  //    === APP STATE ===
  const [reply, setReply] = useState("");
  // === ID OF A PARTICULAR COMMENT
  const { id } = suggestionResponse;
  // === CREATING NEW COMMENT REPLY
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await createSuggestionCommentReply(suggestionID, id, reply);
      toast.success("Replied successfully");
      fetchSuggestionComments();
      setCommentReplyForm(false);
    } catch (error) {
      toast.error("Something went wrong please try again!");
    }
  };
  return (
    <div>
      <div className="container comment-reply-form">
        <CKEditor
          editor={ClassicEditor}
          data={reply}
          onChange={(event, editor) => {
            const data = editor.getData();
            setReply(data);
          }}
        />

        <button
          type="submit"
          className="btn btn-success"
          onClick={(e) => handleFormSubmit(e)}
        >
          Reply
        </button>
        <hr/>
      </div>
    </div>
  );
}

export default SuggestionCommentReply;
