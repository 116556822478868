import React from "react";
import styles from "./css/ProjectContainer.module.css";
import Alert from "@mui/material/Alert";
const MobileDisplay = (props) => {
  return (
    <>
      <div className={styles.MobileDisplay}>
        <Alert severity="info">
          Mobile and tablet display is in developement
        </Alert>
      </div>
    </>
  );
};

export default MobileDisplay;
