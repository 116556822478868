/*eslint-disable*/
import React, { useState } from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import inputFields from "../../API/inputFields";
import { capitalize } from "../../helpers/timeModule";
import TagBadge from "../reusablecomponents/TagBadge";

const ProjectModal = ({
  sugId,
  ttId,
  categories,
  closeModal,
  createProject,
}) => {
  const [projectPrivacy, setProjectPrivacy] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectIdea, setProjectIdea] = useState("");
  const [projectPurpose, setProjectPurpose] = useState("");
  const [projectMission, setProjectMission] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectCategory, setProjectCategory] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [projectFields, setProjectFields] = useState(inputFields);
  const [projectImage, setProjectImage] = useState(null);

  const displayCategories = () => {
    let results = categories.map((value, index) => {
      return (
        <option key={`cat${index}`} value={value.id}>
          {value.name}
        </option>
      );
    });
    return results;
  };

  const handleFieldChange = (e) => {
    let values = { ...projectFields };
    values[e.target.name] = e.target.checked;
    setProjectFields(values);
  };

  const displayInputFields = () => {
    return Object.keys(projectFields).map((key) => {
      return (
        <div key={key} className="m-2">
          <label>{capitalize(key)}</label>&nbsp;
          {key !== "summary" ? (
            <input
              type="checkbox"
              name={key}
              defaultChecked={projectFields[key]}
              onChange={(e) => handleFieldChange(e)}
            />
          ) : (
            <input
              type="checkbox"
              name={key}
              defaultChecked={projectFields[key]}
              onChange={(e) => handleFieldChange(e)}
              disabled
            />
          )}
        </div>
      );
    });
  };

  const handleAddTag = () => {
    if (tag == "") {
      toast.warning("You cannot add an empty tag");
    } else {
      setTags((prevTags) => [...prevTags, tag]);
      setTag("");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("project[name]", projectName);
    formData.append(
      "project[project_category_id]",
      projectCategory === "" ? "1" : projectCategory
    );
    formData.append("project[idea]", projectIdea);
    formData.append("project[purpose]", projectPurpose);
    formData.append("project[description]", projectDescription);
    formData.append("project[mission]", projectMission);
    formData.append("project[input_fields]", JSON.stringify(projectFields));
    formData.append("project[tags]", JSON.stringify(tags));
    formData.append("project[is_private]", projectPrivacy);
    if (projectImage !== null) {
      formData.append("project[image]", projectImage);
    }
    formData.append("project[top_ten_entry_id]", ttId);
    formData.append("project[suggestion_id]", sugId);

    createProject(formData);
    closeModal();
  };

  return (
    <div className="custom-modal">
      <div className="custom-modal-content">
        <div className="custom-modal-header">
          <h3>Create Project</h3>
          <button type="button" onClick={closeModal}>
            <i className="fas fa-times fa-2x"></i>
          </button>
        </div>
        <div className="custom-modal-body p-3">
          <form onSubmit={handleFormSubmit}>
            <div className="form-group m-3 row">
              <div className="add-image-projects">
                <label>Add image</label>
                <input
                  type="file"
                  className="form-control"
                  name="idea"
                  onChange={(e) => setProjectImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label>Project Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label>Project Category</label>
                <select
                  id="inputCategory"
                  onChange={(e) => setProjectCategory(e.target.value)}
                  placeholder="Select a person"
                  className="form-control"
                  defaultValue={projectCategory}
                  name="category"
                >
                  {displayCategories()}
                </select>
              </div>
            </div>

            <div className="form-group  row">
              <div className="col-12">
                <label>Idea</label>
                <CKEditor
                  className="form-control"
                  editor={ClassEditor}
                  data={projectIdea}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setProjectIdea(data);
                  }}
                />
              </div>
            </div>
            <div className="form-group  row">
              <div className="col-12">
                <label>Purpose</label>
                <CKEditor
                  className="form-control"
                  editor={ClassEditor}
                  data={projectPurpose}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setProjectPurpose(data);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <label>Mission</label>
                <CKEditor
                  className="form-control"
                  editor={ClassEditor}
                  data={projectMission}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setProjectMission(data);
                  }}
                />
              </div>
            </div>

            <div className="form-group  row">
              <div className="col-12">
                <label>Project Description</label>
                <CKEditor
                  className="form-control"
                  editor={ClassEditor}
                  data={projectDescription}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setProjectDescription(data);
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-12">
                <h3>Select Fields</h3>
                <div className="input-fields">
                  {displayInputFields()}
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Do you want to make this project private?</label>
              &nbsp;&nbsp;
              <input
                type="checkbox"
                name="privacy"
                defaultChecked={projectPrivacy}
                onChange={(e) => setProjectPrivacy(e.target.checked)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags">Tags</label>
              {_.isEmpty(tags) ? (
                <div className="p-2 text-muted">
                  <span>Add tags and they will appear here</span>
                </div>
              ) : (
                <>
                  <div className="tt-tags-display">
                    {tags.map((tag, idx) => (
                      <TagBadge key={idx} tag={`#${tag}`} />
                    ))}
                  </div>
                </>
              )}
              <div className="d-flex">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Add tag here"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
                <button
                  className="btn btn-success mv-sg"
                  type="button"
                  onClick={handleAddTag}
                >
                  +
                </button>
              </div>
            </div>
            <button type="submit" className="btn create-project-btn m-3">
              Create
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProjectModal);
