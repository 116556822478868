import { BASEURL } from "../../API/apiData";

const upvote = async (upvoteable_id, upvoteable_type) => {
  const token = localStorage.getItem("token");
  const data = {
    upvote: { upvoteable_id, upvoteable_type },
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}upvotes`, requestOptions);
  const results = await response.json();
  return results;
};

const downvote = async (upvoteable_id, upvoteable_type) => {
  const token = localStorage.getItem("token");
  const data = {
    upvoteable_id,
    upvoteable_type,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}unvote`, requestOptions);
  const results = await response.json();
  return results;
};

export { upvote, downvote };
