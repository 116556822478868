import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { NotificationManager } from "react-notifications";

function ProjectInvitation({ singleProject }) {
  const { data } = singleProject;
  const serviceID = "service_5wmoc64";
  const templateID = "template_8jbqs6v";
  const userID = "T6Il1uFE9LmxDXwyp";

  const [formState, setFormState] = useState({
    name: "",
    email: "",
    projectName: data.result.project.name,
    topic: "",
  });
  const [loader, setButtonLoader] = useState(false);
  const [disable, setDisableButton] = useState(false);

  //   ==== SET BUTTON LOADER ===

  const buttonLoader = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  // ==== SEND THE EMAIL INVITATION ====
  const sendEmailInvite = async () => {
    try {
      const results = await emailjs.send(
        serviceID,
        templateID,
        formState,
        userID
      );
      if (results.status === 200) {
        NotificationManager.info(
          `Invitation message to ${formState.email} sent successfully`
        );
        setFormState({
          name: "",
          email: "",
          projectName: data.result.project.name,
          topic: "",
        });
        setButtonLoader(false);
        setDisableButton(false);
        return;
      } else {
        NotificationManager.error(
          "Something went wrong. Kindly check your internet connection and try again"
        );
      }
    } catch (error) {
      NotificationManager.error(
        "Something went wrong. Kindly check your internet connection and try again"
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoader(true);
    setDisableButton(true);
    sendEmailInvite();
  };

  return (
    <div className="card invitation-card container" style={{width: "400px", minWidth: "400px"}}>
      <div className="card-body">
        <div className="invitation-title">Share This Discussion</div>
        <div className="Invitation-form">
          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                name="name"
                onChange={(e) =>
                  setFormState({ ...formState, name: e.target.value })
                }
                value={formState.name}
                required
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                aria-describedby="emailHelp"
                name="email"
                onChange={(e) =>
                  setFormState({ ...formState, email: e.target.value })
                }
                value={formState.email}
                required
              />
            </div>

            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="topic"
                rows="3"
                name="topic"
                onChange={(e) =>
                  setFormState({ ...formState, topic: e.target.value })
                }
                value={formState.topic}
              ></textarea>
            </div>
            <button
              className="btn-success btn rounded text-white mt-2"
              type="submit"
              style={{ width: "100%" }}
              onClick={handleSubmit}
              disabled={disable}
            >
              {loader === true ? buttonLoader() : `Send`}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProjectInvitation;
