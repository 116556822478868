import emailjs from "@emailjs/browser";

const serviceID = "service_5wmoc64";
const templateID = "template_mnjw3mc";
const userID = "T6Il1uFE9LmxDXwyp";

export const sendProjectInvite = async (data) => {

    const results = await emailjs.send(
        serviceID,
        templateID,
        data,
        userID
    )

    return results
}

