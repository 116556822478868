import React from "react";
import "../../stylesheets/landingPage.css";

const LandingBtn = ({ name, clicked }) => {
  return (
    <button className="landing-cta-btn" onClick={clicked}>
      {name}
    </button>
  );
};

export default LandingBtn;
