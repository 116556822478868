import React, { useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";

const GoalsModal = ({ isOpen, closeModal, id, handleSubmit}) => {
  const [inputFields, setInputFields] = useState([
    { description: "", profile_id: id },
  ]);
  const [title, setTitle] = useState("");
  const handleAddField = () => {
    const values = [...inputFields];
    values.push({ description: "", profile_id: id });
    setInputFields(values);
  };

  const handleFormSubmit = (event) => {
    const objectives_attributes = inputFields;
    const data = {
      name: title,
      objectives_attributes: objectives_attributes,
    };
    handleSubmit(data)
    setInputFields([
      { description: "", profile_id: id },
    ])
    closeModal()
    event.preventDefault();
  };

  const handleChange = (index, event) => {
    const values = [...inputFields];
    values[index].description = event.target.value;
    setInputFields(values);
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  return (
    <div>
      <>
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header
            className="text-white"
            style={{ backgroundColor: "#047C7C" }}
            closeButton
          >
            <Modal.Title>Create Goal and Objectives</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleFormSubmit}>
              <div className="form-group">
                <label htmlFor="title">Goal</label>
                <input
                  className="form-control"
                  type="text"
                  name="goal"
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Goal"
                  required
                />
              </div>
              <div className="form-group mt-2 d-flex justify-content-between">
                <h5 className="pt-2">Add Objectives</h5>
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => handleAddField()}
                >
                  +
                </button>
              </div>
              <div className="row mx-auto">
                {inputFields.map((field, idx) => (
                  <Fragment key={idx}>
                    <div className="form-group col-md-11 mt-2">
                      <input
                        type="text"
                        className="form-control"
                        id="field_name"
                        name="description"
                        value={field.description}
                        placeholder="objective"
                        onChange={(event) => handleChange(idx, event)}
                        required
                      />
                    </div>
                    <div className="form-group col-md-1 remove-btn">
                      <button
                        className="btn btn-danger mt-2"
                        type="button"
                        onClick={() => handleRemoveField(idx)}
                      >
                        -
                      </button>
                    </div>
                  </Fragment>
                ))}
              </div>
              <hr />
              <div className="form-group float-right">
                <button className="add-btn rounded" type="submit">
                  Create
                </button>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <h5>TUC SYSTEM</h5>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default GoalsModal;
