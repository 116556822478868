import { BASEURL } from "../../API/apiData";

const linkSuggestionToProject = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}main_suggestion_projects`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const removeSuggestionToProject = async (id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}main_suggestion_projects/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectGoalObjective = async (data) => {
  const token = localStorage.getItem("token");
  const { project_id, goal_id } = data;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${goal_id}/project_goal_objectives`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectGoalObjective = async (data) => {
  const token = localStorage.getItem("token");
  const { project_id, goal_id, id } = data;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${goal_id}/project_goal_objectives/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectGoalObjectiveKpi = async (data) => {
  const token = localStorage.getItem("token");
  const { project_id, goal_id, objective_id } = data;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${goal_id}/project_goal_objectives/${objective_id}/project_goal_objective_key_performance_indexes`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const fetchProjectGoalObjectiveKpi = async (data) => {
  const token = localStorage.getItem("token");
  const { project_id, goal_id, objective_id } = data;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${goal_id}/project_goal_objectives/${objective_id}/project_goal_objective_key_performance_indexes`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectGoalObjectiveKpi = async (data) => {
  const token = localStorage.getItem("token");
  const { project_id, goal_id, objective_id, id } = data;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${goal_id}/project_goal_objectives/${objective_id}/project_goal_objective_key_performance_indexes/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  linkSuggestionToProject,
  removeSuggestionToProject,
  createProjectGoalObjective,
  editProjectGoalObjective,
  createProjectGoalObjectiveKpi,
  fetchProjectGoalObjectiveKpi,
  editProjectGoalObjectiveKpi,
};
