/*eslint-disable */
import React, { useState } from "react";
import _ from "lodash";
import parse from "html-react-parser";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { NotificationManager } from "react-notifications";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Badge from "@mui/material/Badge";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { styled } from "@mui/material/styles";
import AppProfilesList from "./globals/AppProfilesList";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import CommentBox from "./globals/CommentBox";
import {
  createGoalComment,
  createProjectGoalCommentResponses,
  editCommentProjectGoal,
  editProjectGoalCommentResponses,
  fetchAllGoalComments,
} from "../../services/projects/ProjectGoalService";
import { useEffect } from "react";
import {
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../services/projects/ProjectAssumptionService";

const ProjectGoalDetailsCard = ({
  data,
  setOpenEditModal,
  setEditState,
  setKpiModal,
  setSelectedGoal,
  setObjectiveModal,
  setObjectiveEditState,
  setSelectedObjective,
  setObjectiveEditModal,
  projectGoalKpis,
  setEditKpiState,
  setKpiEditModal,
  setKpiID,
  like,
  dislike,
  refreshGoals,
  projectID,
  likeButton,
}) => {
  const [openProfiles, setOpenProfiles] = useState(false);
  const [upvotedProfiles, setUpvotedProfiles] = useState([]);
  // Function to handle the click event for creating a comment and opening the comment box
  const [allComments, setAllComments] = useState([]);
  const [commentBox, setCommentBox] = useState(false);
  const [goalID, setGoalID] = useState(null);
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [goalTitle, setGoalTitle] = useState("");

  useEffect(() => {
    setAllComments([...data.comments]);
  }, [data]);

  // ====  EFFICIENTRY HANDLE THE SUBMIT BUTTON ====
  const [submitButton, setSubmitButton] = useState(false);
  const [editSubmitButton, setEditSubmitButton] = useState(false);

  const handleEdit = (data) => {
    setEditState(data);
    setOpenEditModal(true);
  };

  // ==== REFRESH COMMENTS WHEN NEW ONE IS ADDED OR DELETED =====
  const refreshComments = async () => {
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
    };
    try {
      const { result } = await fetchAllGoalComments(data);
      setAllComments([...result]);
    } catch (error) {
      console.log(error);
    }
  };
  // === THIS METHOD IS FOR THE EDIT OF GOAL COMMENT ====
  const handleEditOfComment = async (editState, setEditState) => {
    setEditSubmitButton(true);
    const { itemId, content } = editState;
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      id: itemId,
      commentData: {
        comment: {
          content: content,
          project_goal_id: goalID,
        },
      },
    };

    try {
      const { message } = await editCommentProjectGoal(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("comment updated successfully");
        refreshComments();
        setEditState({
          form: false,
          itemId: null,
          content: "",
        });
        setEditSubmitButton(false);
      }
    } catch (error) {
      console.log("Error in creating a new comment ", error);
      NotificationManager.error("Something went wrong!");
      setEditSubmitButton(false);
    }
  };
  // === RENDER ALL PROJECT KPI FOR ANY PARTICULAR GOAL ===
  const renderProjectGoalsKpis = (objectiveID, obj, data) => {
    const result = projectGoalKpis.filter((value, index) => {
      if (value.id == objectiveID) return value;
    });

    if (result.length < 1) return;
    if (result[0].project_goal_objective_kpis.length < 1)
      return <div className="no-kpi-added-yet"> No KPIs added yet.</div>;

    const display = result[0].project_goal_objective_kpis.map(
      (value, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              color="secondary"
              fontSize="18px"
              fontWeight="900"
            >
              KPI {index + 1}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot color="secondary" />
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent
              fontSize="20px"
              marginTop="-5px"
              key={index}
              className="timeline-content-kpi-goal"
            >
              {parse(value.name)}
              <Button
                className="edit-kpi"
                variant="outlined"
                sx={{
                  marginLeft: "5px",
                }}
                onClick={() => {
                  setEditKpiState(value.name);
                  setKpiID(value.id);
                  setSelectedObjective(obj.id);
                  setSelectedGoal(data.id);
                  setKpiEditModal(true);
                }}
              >
                Edit KPI
              </Button>
            </TimelineContent>
          </TimelineItem>
        );
      }
    );

    return display;
  };

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  const SmallAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
    width: 30,
    height: 30,
  }));

  const handleClose = () => {
    setOpenProfiles(false);
  };

  const handleOpen = () => {
    setOpenProfiles(true);
  };

  const handleProfileModal = (data) => {
    setUpvotedProfiles([...data.upvoted_by]);
    handleOpen();
  };

  const displayUpvotedProfiles = (data) => {
    if (data.upvoted_by.length < 1) return null;

    const profiles = () => {
      return data.upvoted_by.map((item, idx) => {
        return (
          <SmallAvatar
            key={idx}
            alt={`${item.profile.first_name} ${item.profile.last_name}`}
            src={
              item.profile.profile_avatar !== null
                ? item.profile.profile_avatar.url
                : "/broken-image.jpg"
            }
          />
        );
      });
    };

    return (
      <SmallAvatarGroup
        max={2}
        className="upvoted_group_container"
        onClick={() => handleProfileModal(data)}
      >
        {profiles()}
      </SmallAvatarGroup>
    );
  };

  const handleCreateComment = async () => {
    setSubmitButton(true);
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      commentData: {
        comment: {
          content: commentBoxDetail,
          project_goal_id: goalID,
        },
      },
    };
    try {
      const { message } = await createGoalComment(data);
      if (message === "Comment created succesfully") {
        NotificationManager.success("comment created");
        refreshComments();
        setCommentBoxDetail("");
        setSubmitButton(false);
      }
    } catch (error) {
      console.log("Error in creating a new comment ", error);
      NotificationManager.error("Something went wrong!");
      setSubmitButton(false);
    }
  };

  const handleUpvoteOfComment = async (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "ProjectGoalComment",
      },
    };
    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project Goal comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };
  const handleUnvoteOfComment = async (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "ProjectGoalComment",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project goal comment"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      project_goal_comment_id: itemReplyId,
      commentData: {
        comment: {
          project_goal_comment_id: itemReplyId,
          content: replyContent,
        },
      },
    };
    try {
      const { message } = await createProjectGoalCommentResponses(data);
      if (message === "Comment created succesfully") {
        NotificationManager.success("Response created!!!");
        refreshComments();
        setReplyState({
          display: true,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the creation of project goal comment response"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    replyState
  ) => {
    const { responseID, content } = editCommentResponse;
    const { itemReplyId } = replyState;
    const data = {
      project_id: projectID,
      project_goal_id: goalID,
      project_goal_comment_id: itemReplyId,
      id: responseID,
      commentData: {
        comment: {
          project_gaol_comment_id: itemReplyId,
          content: content,
        },
      },
    };
    try {
      const { message } = await editProjectGoalCommentResponses(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Edited sucessfully!!!");
        refreshComments();
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project Goal comments response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectGoalCommentResponse",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project Goal response comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectGoalCommentResponse",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project Goal comments response"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  return (
    <>
      <AppProfilesList
        profiles={upvotedProfiles}
        open={openProfiles}
        handleClose={handleClose}
      />
      <CommentBox
        open={commentBox}
        setOpen={setCommentBox}
        comments={allComments}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Goal"
        labelDetails={goalTitle}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        refreshGoals={refreshGoals}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        commentBoxIdea={""}
        commentBoxPurpose={""}
      />
      <div className="card border-bottom rounded button-goal-collapse">
        <div className="card-header justify-content-between goal-coording-operator">
          <div className=" goal-coording-operator" id={`headingOne${data.id}`}>
            <button
              className="btn btn-link open-coording-button-desk"
              type="button"
              data-toggle="collapse"
              data-target={`#collapseOne${data.id}`}
              aria-expanded="false"
              aria-controls={`collapseOne${data.id}`}
            >
              <Badge
                badgeContent={data.project_goal_objectives.length}
                color="secondary"
              >
                <i
                  className="fa fa-chevron-circle-down down-btn-goal"
                  aria-hidden="true"
                ></i>
              </Badge>
            </button>
            <div className="mb-0 project-goal-display">
              <span className="goal-bar-display">Goal:</span>{" "}
              <span> {parse(data.project_goal)}</span>
            </div>
          </div>
          <div className="button_group_goal">
            <IconButton
              data-toggle="collapse"
              data-target={`#collapseOne${data.id}`}
              aria-expanded="false"
              aria-controls={`collapseOne${data.id}`}
              style={{ marginBottom: "-18px", position: "relative" }}
              className="mobile-collapse-button"
            >
              <Badge
                badgeContent={data.project_goal_objectives.length}
                color="secondary"
              >
                <ArrowDropDownCircleIcon
                  style={{ color: "#000", fontSize: "30px" }}
                />
              </Badge>
            </IconButton>

            {displayUpvotedProfiles(data)}
            <div className="edit-goal-new-button mr-1">
              {data.likes.upvoted ? (
                <IconButton onClick={() => dislike(data)} disabled={likeButton}>
                  <Badge badgeContent={data.likes.counter} color="secondary">
                    <FavoriteIcon style={{ color: "red", fontSize: "30px" }} />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton onClick={() => like(data)} disabled={likeButton}>
                  <Badge badgeContent={data.likes.counter} color="secondary">
                    <FavoriteBorderOutlinedIcon
                      style={{ color: "#000", fontSize: "30px" }}
                    />
                  </Badge>
                </IconButton>
              )}
            </div>

            <IconButton
              variant="outlined"
              className="edit-goal-new-button mr-1"
              onClick={() => {
                setGoalID(data.id);
                setGoalTitle(data.project_goal);
                setCommentBox(true);
              }}
            >
              <Badge badgeContent={data.comments_counter} color="secondary">
                <ChatBubbleOutlineOutlinedIcon
                  style={{ color: "#000", fontSize: "30px" }}
                />
              </Badge>
            </IconButton>

            <IconButton
              className="edit-goal-new-button"
              onClick={() => handleEdit(data)}
              sx={{
                zIndex: 1,
              }}
            >
              <EditIcon style={{ color: "#000", fontSize: "30px" }} />
            </IconButton>

            <IconButton
              className="mt-3 ml-2 edit-goal-new-button"
              color="secondary"
              onClick={() => {
                setSelectedGoal(data.id);
                setObjectiveModal(true);
              }}
            >
              <AddCircleIcon style={{ color: "#000", fontSize: "30px" }} />
            </IconButton>
          </div>
        </div>
        <div
          id={`collapseOne${data.id}`}
          className="collapse"
          aria-labelledby={`headingOne${data.id}`}
          data-parent={`#goalAccordian`}
        >
          <div className="card-body border-bottom">
            {!_.isEmpty(data.project_goal_objectives) ? (
              <ul className="objectives-display">
                {data.project_goal_objectives.map((obj, index) => (
                  <div className="card" key={obj.id}>
                    <div className="card-body">
                      {" "}
                      <div>
                        <span className="objective-bar">
                          Objective {index + 1}:
                        </span>{" "}
                        <span className="objective-it-self">
                          {parse(obj.name)}
                        </span>
                      </div>
                      <div className="button_group">
                        <Button
                          className="mr-2"
                          variant="outlined"
                          color="warning"
                          onClick={() => {
                            setObjectiveEditState(obj.name);
                            setSelectedObjective(obj.id);
                            setObjectiveEditModal(true);
                          }}
                          endIcon={<EditIcon />}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<AddCircleIcon />}
                          color="warning"
                          onClick={() => {
                            setSelectedObjective(obj.id);
                            setSelectedGoal(data.id);
                            setKpiModal(true);
                          }}
                        >
                          Kpi's
                        </Button>
                      </div>
                    </div>
                    <div className="card-footer">
                      <Timeline
                        sx={{
                          [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.05,
                          },
                        }}
                        className="timeline-kpi-goal-project"
                      >
                        {renderProjectGoalsKpis(obj.id, obj, data)}
                      </Timeline>
                    </div>
                  </div>
                ))}
              </ul>
            ) : (
              <h5>No Objectives for this goal</h5>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectGoalDetailsCard;
