import React, { useState} from 'react'
import Modal from "react-bootstrap/Modal";


const LibraryCategoryModal = ({ isOpen, closeModal, handleSubmit }) => {
    const [name, setName] = useState("");
    const [indicator, setIndicator] = useState("");


    const handleFormSubmit = (e) => {
      e.preventDefault();
      const data = {
        tuc_inventory_category: {
          name: name,
          indicator: indicator,
        },
      };
      handleSubmit(data);
      setName("");
      setIndicator("");
      closeModal();
    };
  return (
    <>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#309897" }}
          closeButton
        >
          <Modal.Title>Add New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                place="category name here"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="indicator">Choose Indicator</label>
              <input
                type="color"
                className="form-control"
                name="indicator"
                onChange={(e) => setIndicator(e.target.value)}
              />
            </div>
            <div className="form-group float-right">
              <button className="add-btn rounded text-white" type="submit">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LibraryCategoryModal