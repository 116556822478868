import React from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const searchfilter = createFilterOptions();

const AppProjectSearchBar = ({ searchValue, setSearchValue, projects }) => {
  return (
    <Autocomplete
      value={searchValue}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setSearchValue({
            name: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setSearchValue({
            name: newValue.inputValue,
          });
        } else {
          setSearchValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = searchfilter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={projects}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      sx={{ width: "100%" }}
      freeSolo
      renderInput={(params) => (
        <TextField {...params} label="SEARCH FOR A PROJECT BY NAME" />
      )}
    />
  );
};

export default AppProjectSearchBar;
