import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../stylesheets/ribbon.css";
import TTEditModal from "./TTEditModal";
import Tooltip from "@material-ui/core/Tooltip";
import Top10 from "../images/top10.png";

const TopTenCard = ({
  id,
  catId,
  title,
  entries,
  description,
  currentUser,
}) => {
  const [openEdit, setOpenEdit] = useState(false);

  const openEditModal = () => {
    setOpenEdit(true);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
  };

  const showEdit = () => {
    if (openEdit) {
      return (
        <TTEditModal
          isOpen={openEdit}
          closeEditModal={closeEditModal}
          name={title}
          desc={description}
          id={id}
          catId={catId}
          currentUser={currentUser}
        />
      );
    }
  };

  return (
    <>
      {showEdit()}

      <div className="col-md-12 col-lg-6 col-xl-4 list-cards">
        <Tooltip
          interactive
          className="toolTip"
          title={
            <span
              style={{
                fontSize: "20px",
                fontFamily: "Open Sans Condensed",
                padding: "2px",
                lineHeight: "30px",
              }}
            >
              {description}
            </span>
          }
          placement="top"
        >
          <div className="card">
            <div className="card-header">
              <h3 className="tt-list-title-banner"> {title}</h3>
            </div>
            <div className="divison-color"></div>
            <div className="card-entry-container card-body top-ten-image row">
              <img src={Top10} alt="top10 logo" />
              <div className="title-bac-ground-10">
                {" "}
                <h4>{title}</h4>{" "}
              </div>
            </div>
            <div className="btn-view-tt">
              <Link
                to={`/categories/${catId}/topten/${id}/entries`}
                className="btn tt-explore-btn"
              >
                Explore
              </Link>
              <button
                className="border-0 bg-transparent btn tt-explore-btn"
                onClick={openEditModal}
              >
                Edit
              </button>
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default TopTenCard;
