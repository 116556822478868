import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import Navbar from "../components/navbar/Navbar";
import TagBadge from "../components/reusablecomponents/TagBadge";
import { fetchTags } from "../services/tags/tagService";

import "../stylesheets/tags.css";

const TagsContainer = () => {
  const [tags, setTags] = useState({});

  const getTags = async () => {
    const result = await fetchTags();
    setTags(result);
  };

   useEffect(() => {
     getTags();
   }, []);


  const showTags = () => {
    if (!tags) {
      return <h2 className="text-center text-danger">404 ERROR!!!</h2>;
    } else if (_.isEmpty(tags.result)) {
      return (
        <h2 className="text-center text-danger">Sorry No Tags Available</h2>
      );
    } else if (!_.isEmpty(tags))
      return tags.result.map((tag) => (
        <Link to={`tags/${tag.id}`}>
          <TagBadge tag={tag.name} />
        </Link>
      ));
  };

  return (
    <>
      <Navbar />
      <h2 className="tag-header">#HASHTAGS</h2>
      <div className="tag-container w-100 d-flex flex-wrap">{showTags()}</div>
    </>
  );
};

export default TagsContainer;
