import React, { useState, useEffect } from "react";
import styles from "../cssModules/main.module.css";
import TopBar from "../globals/TopBar";
import DesktopSideBar from "./DesktopSideBar";
import FilterComponent from "../globals/FilterComponent";
import { Alert, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ListUpdates from "../globals/ListUpdates";

function DeskTopDisplayUpdates({
  selection,
  setMenuSelection,
  updates,
  updateInfo,
  setUpdateInfo,
  handleEdit,
  toggleDrawer,
  setCommentModal,
  createUpdateModalOpen,
  setEditModal,
  setEditData,
}) {
  const [filterdUpdates, setFilteredUpdates] = useState([]);

  useEffect(() => {
    if (updates != null) {
      if (selection.name === "Completed Updates")
        return setFilteredUpdates([...updates.complete]);
      if (selection.name === "Upcoming Updates")
        return setFilteredUpdates([...updates.upcoming]);
      if (selection.name === "Bug Tracker") return setFilteredUpdates([]);
    }
    return () => {
      setFilteredUpdates([]);
    };
  }, [updates, selection.name]);

  const displayUpdates = () => {
    if (filterdUpdates.length >= 1) {
      const result = filterdUpdates.map((update) => (
        <div className="col-md-6 col-sm-12 col-xl-3" key={update.id}>
          <ListUpdates
            setCommentModal={setCommentModal}
            update={update}
            setEditModal={setEditModal}
            setEditData={setEditData}
          />
        </div>
      ));
      return result;
    } else {
      return <Alert severity="info">There are no items on this list.</Alert>;
    }
  };

  const openCreateModal = () => {
    if (
      selection.name === "Completed Updates" ||
      selection.name === "Upcoming Updates"
    ) {
      createUpdateModalOpen();
      return;
    }
    if (selection.name === "Bug Tracker") {
      // open bug form model
      return;
    }
  };

  return (
    <div className={styles.desk_top_view}>
      <TopBar selection={selection} />
      <div className={styles.content_container}>
        <div className={styles.desk_top_side_bar}>
          <DesktopSideBar tab={selection.name} setTab={setMenuSelection} />
        </div>
        <div className={styles.desk_top_info_container}>
          <div className={styles.filter_and_add_button_container}>
            <FilterComponent />
            <Button
              color="secondary"
              variant="outlined"
              className={styles.add_new_button}
              endIcon={<AddIcon />}
              onClick={() => openCreateModal()}
            >
              {" "}
              Add New Bug/update
            </Button>
          </div>
          <div className={`container-fluid`}>
            <div className="row">{displayUpdates()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeskTopDisplayUpdates;
