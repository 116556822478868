import React from "react";

function NoBmcComponent({ message, setShow, setShowInfoModal }) {
  return (
    <div className="container-fluid mt-2 bmc-container">
      <div className="create-new-swort">
        <div className="learn-button" onClick={() => setShowInfoModal(true)}>
          <span> learn what is bmc</span>{" "}
          <i className="fa fa-question-circle" aria-hidden="true"></i>
        </div>
        <button
          className="add-btn rounded text-white m-3"
          onClick={() => setShow(true)}
        >
          Add new
        </button>
      </div>

      <div className="not-found-statement container">
        <div className="alert alert-danger" role="alert">
          <i className="fa fa-frown-o" aria-hidden="true"></i>{" "}
          <h3>{message}</h3>
        </div>
      </div>
    </div>
  );
}

export default NoBmcComponent;
