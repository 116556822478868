import React from 'react';

function TopTenBanner() {
 return(
    <>
    <div className="suggestion-box-banner top-ten-banner">
      <div className="card text-white">
        <div className="card-img-overlay">
        </div>
      </div>
    </div>
  </>
 )
}

export default TopTenBanner;
