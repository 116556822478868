import {FETCH_TOPTENS_SUCCESS, FETCH_TOPTENS_REQUEST, FETCH_TOPTENS_FAILURE} from './topTenTypes'

const initialState = {
  loading: true,
  data: [],
  errorMsg: '',
};

const topTensReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOPTENS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOPTENS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: '',
      };
    case FETCH_TOPTENS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default topTensReducer;