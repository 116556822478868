import React from "react";
import ideaImage from "../../images/ideaImage.png";

const SuggestionBoxBanner = () => {
  return (
    <>
      <div className="suggestion-box-banner">
        <div className="card text-white">
          <img className="card-img" src={ideaImage} alt="Card" />
          <div className="card-img-overlay">
            <h5 className="card-title">Suggestion Box</h5>
            <hr />
            <p className="card-text">
              TUC is a platform that enables people from all over the world
              bring ideas together and build something great with it.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuggestionBoxBanner;
