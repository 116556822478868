import { BASEURL } from "../../API/apiData";

const fetchAllGoals = async (projectID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_goals`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const upvoteProjectGoal = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}upvotes`, requestOptions);
  const results = await response.json();
  return results;
};

const unvoteProjectGoal = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}unvote`, requestOptions);
  const results = await response.json();
  return results;
};

const createGoalComment = async (data) => {
  const { project_id, project_goal_id, commentData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const fetchAllGoalComments = async (data) => {
  const { project_id, project_goal_id } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editCommentProjectGoal = async (data) => {
  const { project_id, project_goal_id, id, commentData } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectGoalCommentResponses = async (data) => {
  const { project_id, project_goal_id, project_goal_comment_id, commentData } =
    data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments/${project_goal_comment_id}/project_goal_comment_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectGoalCommentResponses = async (data) => {
  const {
    project_id,
    project_goal_id,
    project_goal_comment_id,
    commentData,
    id,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}/projects/${project_id}/project_goals/${project_goal_id}/project_goal_comments/${project_goal_comment_id}/project_goal_comment_responses/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export {
  upvoteProjectGoal,
  unvoteProjectGoal,
  fetchAllGoals,
  createGoalComment,
  fetchAllGoalComments,
  editCommentProjectGoal,
  createProjectGoalCommentResponses,
  editProjectGoalCommentResponses,
};
