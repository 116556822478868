import React from "react";
import Logo from "../../assets/img/logo.png";
const Footer = () => {
  return (
    <div className="footer-content">
      <div className="footer-group pb-5 d-block d-lg-flex">
        <div className="footer-left pb-5">
          <div className="footer-group-one">
            <div className="pb-3">
              <h5>
                <img src={Logo} alt="" className="footer-logo" /> The Ultimate
                Collaboration
              </h5>
              <p>
                <li className="pb-2"> Transparent </li>
                <li className="pb-2"> Inclusive </li>
                <li className="pb-2"> Equitable </li>
                <li className="pb-2"> Engaging </li>
                <li className="pb-2"> Impact Investing </li>
              </p>
              <div className="d-flex">
                <i className="fab fa-instagram-square"></i>
                <i className="fab fa-twitter-square ml-4"></i>
                <i className="fab fa-facebook-square ml-4"></i>
                <i className="fab fa-facebook-square ml-4"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-right d-flex w-100 justify-content-around">
          <div className="footer-group-three">
            <div>
              <h5 className="pb-3">Company</h5>
              <ul>
                <li className="pb-2">WhoWeAre</li>
                <li className="pb-2">Blog</li>
                <li className="pb-2">Contact Us</li>
                <li className="pb-2">Pricing</li>
                <li className="pb-2">Testimonials</li>
              </ul>
            </div>
          </div>
          <div className="footer-group-four">
            <div>
              <h5 className="pb-3">Support</h5>
              <ul>
                <li className="pb-2">Help Center</li>
                <li className="pb-2">Contact Us</li>
                <li className="pb-2">Legal</li>
                <li className="pb-2">Privacy of Policy</li>
                <li className="pb-2">Status</li>
              </ul>
            </div>
          </div>
          <div className="footer-group-two">
            <div>
              <h5 className="pb-3">Reach Us</h5>
              <ul>
                <li className="pb-3">
                  <i className="far fa-envelope-open text-warning mr-2"></i>
                  hello@Tuc-community.com
                </li>
                <li className="pb-3">
                  <i className="fas fa-phone-volume text-warning mr-2"></i>+1
                  401 365 5665{" "}
                </li>
                <li className="pb-3">
                  <i className="far fa-address-book text-warning mr-2"></i>772
                  Lyonwood Ave Walnut, CA 91789{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr className="" />
      <div>
        <div className="d-flex">
          <div className="p-2">
            <p>© 2021 TUC. All rights reserved</p>
          </div>
          <div className="ml-auto">
            <div className="d-flex">
              <p className="p-2">Terms & Conditions | </p>
              <p className="p-2"> Privacy Policy | </p>
              <p className="p-2"> Sitemap | </p>
              <p className="p-2"> Disclaimer </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
