/*eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import LandingBtn from "./button";

const Header = () => {
  return (
    <div className="row">
      <div className="col-12 col-lg-6 welcome-msg">
        <div className="welcome-msg-content">
          <h1 className="">
            Welcome To <br /> The Ultimate Collaboration
          </h1>
          <p className="">
            A global initiative to increase follow through and help Good Ideas
            reach their Full Potential. Even for the most successful
            organizations, full potential is hard to imagine and solutions can
            seem impossible
          </p>
          <p className="together-bold">
            Together we will create the future we need to see
          </p>
          <div className="centre-button-landing float-left d-none d-lg-block">
            <LandingBtn name="In development" />
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-6 welcome-video">
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            src="https://player.vimeo.com/video/664182433?h=a1b04ff564"
            width="640"
            height="564"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          ></iframe>
        </div>
        <div className="d-lg-none centre-button-landing">
          <button className="btn landing-cta-btn-sm">CTA BUTTON</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
