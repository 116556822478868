import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {  CREATE_PROJECTCOMMENTS_REQUEST, CREATE_PROJECTCOMMENTS_SUCCESS, CREATE_PROJECTCOMMENTS_FAILURE, FETCH_PROJECTCOMMENTS_REQUEST, FETCH_PROJECTCOMMENTS_SUCCESS, FETCH_PROJECTCOMMENTS_FAILURE } from "./projectCommentTypes";


  // ==== Create Project commments ====
  export const createProjectCommentsRequest = () =>({
    type: CREATE_PROJECTCOMMENTS_REQUEST,
  })
  
  export const createProjectCommentsSuccess = (response) =>({
    type: CREATE_PROJECTCOMMENTS_SUCCESS,
    payload: response
  })
  
  export const createProjectCommentsFailure = (error) =>({
    type: CREATE_PROJECTCOMMENTS_FAILURE,
    payload: error
  })

export const createProjectComments = (id, data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createProjectCommentsRequest());
    return axios.post(`${BASEURL}projects/${id}/project_comments`, data ,{
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(createProjectCommentsSuccess(response.data));
      }).catch(error => {
        return dispatch(createProjectCommentsFailure(error));
      });
}

// ====== fetch Project comments
export const fetchProjectCommentsRequest = () =>({
  type: FETCH_PROJECTCOMMENTS_REQUEST,
})

export const fetchProjectCommentsSuccess = (response) =>({
  type: FETCH_PROJECTCOMMENTS_SUCCESS,
  payload: response
})

export const fetchProjectCommentsFailure = (error) =>({
  type: FETCH_PROJECTCOMMENTS_FAILURE,
  payload: error
})

export const fetchProjectComments = (id) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchProjectCommentsRequest());
  return axios.get(`${BASEURL}projects/${id}/project_comments`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchProjectCommentsSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchProjectCommentsFailure(error));
    });
}
