/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import nocores from "../../assets/nocorevalues.png";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";
import EditAssumption from "./projectAssumptions/EditAssumption";
import AppReusableCard from "./globals/AppReusableCard";
import {
  createProjectAssumptionComment,
  createProjectAssumptionCommentResponses,
  editProjectAssumptionComment,
  editProjectAssumptionCommentResponses,
  fetchProjectAssumptionComment,
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../services/projects/ProjectAssumptionService";
import AppReusableDisplayUpvotedProfiles from "./globals/AppReusableDisplayUpvotedProfiles";
import ShareAssumptionModal from "./globals/ShareAssumptionModal";
import Pagination from "@mui/material/Pagination";
import { Button } from "@mui/material";
// ==== Comment Box
import CommentBox from "./globals/CommentBox";

const ProjectAssumptions = ({
  projectAssumption,
  setProjectAssumption,
  projectID,
  createProjectAssumption,
  fetchProjectsAssumptions,
  project,
  allProfiles,
  speedDialState,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [isOpen, setIsOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const closeModal = () => setIsOpen(false);
  const closeEditModal = () => setOpenEditModal(false);
  const [assumptionID, setAssumptionID] = useState(null);
  const [editState, setEditState] = useState({});
  const [commentModal, setCommentModal] = useState(false);
  // ==== OPEN UPVOTES PROFILES ====
  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [votersProfiles, setVotersProfiles] = useState([]);
  const [drawerTitle, setDrawerTitle] = useState("");
  // ===== SHARE MODAL ====
  const [openShareModal, setOpenShareModal] = useState(false);
  const [profiles, setProfiles] = useState([]);
  // ==== DISPLAY THE DISCUSSIONS CONDITIONALLY ====
  const [oneAssumption, setOneAssumption] = useState({});

  // ==== PAGINATION STATE ====
  const [assumptionPosts, setAssumptionPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = assumptionPosts.slice(indexOfFirstPost, indexOfLastPost);
  const actualPaginationCount = assumptionPosts.length / postsPerPage;
  const roundedFigure = Math.round(actualPaginationCount);

  // ==== COMMENT BOX ====
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  // ====  EFFICIENTRY HANDLE THE SUBMIT BUTTON ====
  const [submitButton, setSubmitButton] = useState(false);
  const [editSubmitButton, setEditSubmitButton] = useState(false);
  const [assumptionSelection, setAssumptionSelection] = useState({
    idea: "",
    purpose: "",
    assumption: "",
  });
  const [assumptionComments, setAssumptionComments] = useState([]);
  const [likeButton, setLikeButton] = useState(false);

  useEffect(() => {
    fetchAllProfiles();
  }, []);

  useEffect(() => {
    setAssumptionPosts(projectAssumption);
  }, [projectAssumption]);

  const fetchAllProfiles = async () => {
    const { type, payload } = await allProfiles();
    if (type === "FETCH_ALL_PROFILES_SUCCESS") return setProfiles([...payload]);
  };

  //   ==== OPENING THE SHARE MODAL ====
  const handleOpenShareModal = (value) => {
    setOneAssumption({ ...value });
    setOpenShareModal(true);
  };

  const handleOpenCommentModal = (id, comments, data) => {
    setAssumptionID(id);
    setAssumptionComments([...comments]);
    setAssumptionSelection({
      idea: data?.idea || "",
      purpose: data?.purpose || "",
      assumption: data?.assumption || "",
    });
    setCommentModal(true);
  };

  // ===== HANDLE EDIT ACTION ====
  const handleEditAction = (value) => {
    setAssumptionID(value.id);
    setEditState({
      assumption: value.assumption,
      idea: value.idea,
      purpose: value.purpose,
    });
    setOpenEditModal(true);
  };

  const refreshAssumtionsAfterUpvote = (ass, action) => {
    const currentAssumption = projectAssumption.findIndex(
      (assumption) => assumption.id === ass.id
    );
    const updatedAssumption = {
      ...projectAssumption[currentAssumption],
      likes: {
        upvoted: action === "upvote" ? true : false,
        counter:
          action === "upvote"
            ? projectAssumption[currentAssumption].likes.counter + 1
            : projectAssumption[currentAssumption].likes.counter - 1,
      },
      upvoted_by: projectAssumption[currentAssumption].upvoted_by,
    };
    const newProjectAssumptions = [...projectAssumption];
    newProjectAssumptions[currentAssumption] = updatedAssumption;
    setProjectAssumption(newProjectAssumptions);
  };

  // ==== HANDLE LIKE ACTION ====
  const handleLikeAction = async (ass) => {
    const initialAssumtionsState = projectAssumption;
    setLikeButton(true);
    refreshAssumtionsAfterUpvote(ass, "upvote");

    const data = {
      upvote: {
        upvoteable_id: ass.id,
        upvoteable_type: "ProjectAssumption",
      },
    };
    const { message } = await upvoteProjectAssumption(data);
    if (message === "upvote successful") {
      const { payload } = await fetchProjectsAssumptions(projectID);
      if (payload.message === "Project assumptions fetched successfully") {
        setProjectAssumption(payload.result);
      }
      setLikeButton(false);
      return;
    }
    setProjectAssumption(initialAssumtionsState);
    NotificationManager.error(
      "Something went wrong. Check the network connection and try again"
    );
  };

  //  ==== HANDLE DISLIKE ACTION
  const handleDislikeAction = async (ass) => {
    const initialAssumtionsState = projectAssumption;
    setLikeButton(true);
    refreshAssumtionsAfterUpvote(ass, "downvote");

    const data = {
      upvoteable_id: ass.id,
      upvoteable_type: "ProjectAssumption",
    };
    const { message } = await unvoteProjectAssumption(data);
    if (message === "Your vote has been removed") {
      const { payload } = await fetchProjectsAssumptions(projectID);
      if (payload.message === "Project assumptions fetched successfully") {
        setProjectAssumption(payload.result);
      }
      setLikeButton(false);
      return;
    }
    setProjectAssumption(initialAssumtionsState);
    NotificationManager.error(
      "Something went wrong. Check the network connection and try again"
    );
  };

  // ==== HANDLE DISPLAY OF THE LIST IN ASSUMPTIONS ARRAY
  const diplayListItems = () => {
    const results = currentPosts.map((value) => {
      return (
        <div key={value.id} className="assumption-card-cont">
          <AppReusableCard
            id={value.id}
            idea={value.idea}
            purpose={value.purpose}
            inputFieldName="Assumption"
            inputField={value.assumption}
            creatorFirstName={value.creator_profile.first_name}
            creatorLastName={value.creator_profile.last_name}
            createdAt={value.created_at}
            editAction={() => handleEditAction(value)}
            openCommentModal={handleOpenCommentModal}
            creatorImage={value.avatar}
            actionItems={true}
            upvoted_by={value.upvoted_by}
            shared_with={value.shared_with}
            votes={value.likes.counter}
            userLiked={value.likes.upvoted}
            likeAction={() => handleLikeAction(value)}
            unLikeAction={() => handleDislikeAction(value)}
            setVotersToggleDrawer={setVotersToggleDrawer}
            setVotersProfiles={setVotersProfiles}
            setDrawerTitle={setDrawerTitle}
            openShareModal={() => handleOpenShareModal(value)}
            comments={value.comments}
            data={value}
            likeButton={likeButton}
          />
        </div>
      );
    });

    return results;
  };

  // === HANDLE THE DISPLAY OF LIST ITEMS IN THE VIEW CONDITIONALLY
  const displayAssumptions = () => {
    if (projectAssumption.length < 1) {
      return (
        <>
          <div className="w-100 text-center mt-4">
            <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
            <h4 className="text-dark">
              There are no assumptions for this project at the moment
            </h4>
          </div>
        </>
      );
    }
    return <>{diplayListItems()}</>;
  };

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };

  //  ==== Functions for handling the comments ====
  const refreshTheAssumptions = async () => {
    try {
      const { payload } = await fetchProjectsAssumptions(projectID);
      if (payload.message === "Project assumptions fetched successfully") {
        setProjectAssumption(payload.result);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error retrieving the data for project assumptions"
      );
    }
  };

  const refreshAssumptionsComments = async () => {
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
    };
    try {
      const { message, result } = await fetchProjectAssumptionComment(data);
      if (message === "Comments fetched successfully") {
        setAssumptionComments([...result]);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong while fetching the assumption comments"
      );
    }
  };

  const handleCreateComment = async () => {
    setSubmitButton(true);
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      commentData: {
        comment: {
          content: commentBoxDetail,
          project_assumption_id: assumptionID,
        },
      },
    };
    try {
      const { message } = await createProjectAssumptionComment(data);
      if (message === "Comment created succesfully") {
        NotificationManager.success("New Comment", "Created successfully");
        refreshAssumptionsComments();
        setCommentBoxDetail("");
        setSubmitButton(false);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error creating the assumptions comment in projects"
      );
      NotificationManager.error("Error", "Failed to create new comment");
      setSubmitButton(false);
    }
  };

  const handleEditOfComment = async (editState, setEditState) => {
    const { itemId, content } = editState;
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      id: itemId,
      commentData: {
        comment: {
          content: content,
          project_assumption_id: assumptionID,
        },
      },
    };
    try {
      const { message } = await editProjectAssumptionComment(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Comment edited successfully");
        refreshAssumptionsComments();
        setEditState({
          form: false,
          itemId: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error Editing the assumptions comment in projects"
      );
      NotificationManager.error("Error", "Failed to edit comment");
      setEditState({
        form: false,
        itemId: null,
        content: "",
      });
    }
  };

  // ==== THIS WILL HANDLE UPVOTE AND UNVOTE  OF COMMENTS ====
  const handleUpvoteOfComment = async (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "ProjectAssumptionComment",
      },
    };
    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfComment = async (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "ProjectAssumptionComment",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  // ===== FUNCTIONS TO HANDLE COMMENT RESPONSES ====
  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      project_assumption_comment_id: itemReplyId,
      commentData: {
        comment: {
          project_assumption_comment_id: itemReplyId,
          content: replyContent,
        },
      },
    };
    try {
      const { message } = await createProjectAssumptionCommentResponses(data);
      if (message === "Comment created succesfully") {
        NotificationManager.success("Response created!!!");
        refreshAssumptionsComments();
        setReplyState({
          display: true,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    replyState
  ) => {
    const { responseID, content } = editCommentResponse;
    const { itemReplyId } = replyState;
    const data = {
      project_id: projectID,
      project_assumption_id: assumptionID,
      project_assumption_comment_id: itemReplyId,
      id: responseID,
      commentData: {
        comment: {
          project_assumption_comment_id: itemReplyId,
          content: content,
        },
      },
    };

    try {
      const { message } = await editProjectAssumptionCommentResponses(data);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Edited sucessfully!!!");
        refreshAssumptionsComments();
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project assumption comments response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectAssumptionCommentReply",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectAssumptionCommentReply",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshAssumptionsComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  return (
    <>
      <div className="profile-core-values container-m-well">
        <div className="container-fluid">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => toggleProjectSpeedDialMenu("assumption", true)}
            startIcon={<i className="fas fa-plus-circle"></i>}
            className="add_item_button"
          >
            Add Assumption
          </Button>
        </div>
        <CommentBox
          open={commentModal}
          setOpen={setCommentModal}
          comments={assumptionComments}
          handleCreateComment={handleCreateComment}
          detail={commentBoxDetail}
          setCommentBoxDetail={setCommentBoxDetail}
          submitButton={submitButton}
          setSubmitButton={setSubmitButton}
          label="Assumption"
          labelDetails={assumptionSelection.assumption}
          commentBoxIdea={assumptionSelection.idea}
          commentBoxPurpose={assumptionSelection.purpose}
          handleEditOfComment={handleEditOfComment}
          editSubmitButton={editSubmitButton}
          refreshGoals={refreshTheAssumptions}
          handleUpvoteOfComment={handleUpvoteOfComment}
          handleUnvoteOfComment={handleUnvoteOfComment}
          handleCreateOfCommentReponses={handleCreateOfCommentReponses}
          handleEditOfCommentReponses={handleEditOfCommentResponses}
          handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
          handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        />
        <ShareAssumptionModal
          openClose={openShareModal}
          setOpenShareModal={setOpenShareModal}
          profiles={profiles}
          selected={oneAssumption}
          fetchProjectsAssumptions={fetchProjectsAssumptions}
          projectID={projectID}
          setProjectAssumption={setProjectAssumption}
        />
        <AppReusableDisplayUpvotedProfiles
          votersToggleDrawer={votersToggleDrawer}
          setVotersToggleDrawer={setVotersToggleDrawer}
          profiles={votersProfiles}
          drawerTitle={drawerTitle}
          setDrawerTitle={setDrawerTitle}
          setVotersProfiles={setVotersProfiles}
        />
        <NotificationContainer />
        <EditAssumption
          openEditModal={openEditModal}
          closeEditModal={closeEditModal}
          assumptionID={assumptionID}
          editState={editState}
          projectID={projectID}
          setProjectAssumption={setProjectAssumption}
          projectAssumption={projectAssumption}
          fetchProjectsAssumptions={fetchProjectsAssumptions}
        />
        <div className="assumption-container">{displayAssumptions()}</div>
        <div className="assumptions-pagination-container">
          <Pagination
            count={roundedFigure}
            color="secondary"
            defaultPage={1}
            onChange={handlePaginationChange}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectAssumptions;
