/*eslint-disable */
import React, { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import LibraryItem from "./LibraryItem";
import LibraryItemModal from "./LibraryItemModal";
import {
  createMasterInventoryItem,
  fetchInventoryItems,
  updateInventoryItem,
} from "../../services/library/libraryService";

const LibraryItemViewModal = ({ id, items, owner,close,priv }) => {

  const [open, setOpen] = useState(false);
  const [list, setList] = useState(items);

  const createInventory = async (data) => {
    try {
      await createMasterInventoryItem(id, data);
      const xData = await fetchInventoryItems(id);
      setList(xData.result);
      toast.success("Item successfully added");
    } catch (error) {
      console.log(error);
    }
  };

  const updateInventory = async (itemId, data) => {
    try {
      await updateInventoryItem(id, itemId, data);
      const xData = await fetchInventoryItems(id);
      setList(xData.result);
      toast.warning("Item edited successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleUpdateItem = (itemId, xData) => {
    updateInventory(itemId,xData)
  };

  const showItems = () => {
    if (_.isEmpty(list)) {
      return (
        <>
          <h5 className="text-danger text-center">
            <i className="fa fa-frown-o" aria-hidden="true"></i>
            &nbsp; Sorry No Items Available
          </h5>
        </>
      );
    } else {
      return (
        <>
          {list.map((item) => (
            <LibraryItem
              key={item.id}
              data={item}
              priv={priv}
              owner={owner}
              handleUpdate={handleUpdateItem}
            />
          ))}
        </>
      );
    }
  };

  const handleCreateItem = (xData) => {
    createInventory(xData);
  };

  return (
    <>
      <LibraryItemModal
        invId={id}
        data={null}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleCreateItem}
      />
      <div id="library-item-modal" className="library-item-modal">
        <div className="library-modal-content">
          <div className="library-modal-header">
            <h5>.</h5>
            <button type="button" onClick={() => close()}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div className="library-modal-body">
            {owner ? (
              <>
                <button
                  className="app-add-btn float-right m-2"
                  onClick={openModal}
                >
                  + Add Item
                </button>
              </>
            ) : null}
            {showItems()}
          </div>
        </div>
      </div>
    </>
  );
};

export default LibraryItemViewModal;
