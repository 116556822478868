import React, { useState } from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SearchIcon from "@mui/icons-material/Search";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Paper from "@mui/material/Paper";

const SuggestionMobileMenu = ({ setSearchButton, setMobileSuggestionForm, setViewTrending, setSelectedCategory }) => {
  const [value, setValue] = useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (newValue === "search") {
      setSearchButton(true);
      setSelectedCategory(null)
      return
    }

    if(newValue === "addSuggestion"){
      setMobileSuggestionForm(true)
      return
    }

    if( newValue === "viewTrending"){
      setViewTrending(true)
      return
    }
    
  };

  return (
    <div className="suggestion-mobile-menu-container">
      <Box sx={{ width: "100%" }}>
        <Paper
          sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              handleChange(newValue);
            }}
          >
            <BottomNavigationAction
              label="Search"
              icon={<SearchIcon />}
              value="search"
            />
            <BottomNavigationAction
              label="New Suggestion"
              icon={<ControlPointIcon />}
              value="addSuggestion"
            />
            <BottomNavigationAction
              label="Trending"
              icon={<TrendingUpIcon />}
              value="viewTrending"
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </div>
  );
};

export default SuggestionMobileMenu;
