import { BASEURL } from "../../API/apiData";

const upvoteProjectSuggestion = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}upvotes`, requestOptions);
  const results = await response.json();
  return results;
};

const unvoteProjectSuggestion = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}unvote`, requestOptions);
  const results = await response.json();
  return results;
};

const getOneSuggestion = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}refresh_suggestion_comments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

// ===== COMMENTS END POINTS =====

const editProjectSuggestionComment = async (data) => {
  const { commentData, projectID, suggestionID, ID } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/project_suggestions/${suggestionID}/project_suggestion_comments/${ID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createProjectSuggestionCommentResponses = async (data) => {
  const {
    project_id,
    project_suggestion_id,
    project_suggestion_comment_id,
    commentData,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_suggestions/${project_suggestion_id}/project_suggestion_comments/${project_suggestion_comment_id}/project_suggestion_comment_responses`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const editProjectSuggestionCommentResponses = async (data) => {
  const {
    project_id,
    project_suggestion_id,
    project_suggestion_comment_id,
    commentData,
    id,
  } = data;
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentData),
  };
  const response = await fetch(
    `${BASEURL}projects/${project_id}/project_suggestions/${project_suggestion_id}/project_suggestion_comments/${project_suggestion_comment_id}/project_suggestion_comment_responses/${id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};
export {
  upvoteProjectSuggestion,
  unvoteProjectSuggestion,
  getOneSuggestion,
  editProjectSuggestionComment,
  createProjectSuggestionCommentResponses,
  editProjectSuggestionCommentResponses,
};
