import { BASEURL } from "../../API/apiData";

const getAllOpenItemsAssignment = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}open_item_assignments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewOpenItem = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}open_items`, requestOptions);
  const results = await response.json();
  return results;
};

const getSpecificOpenItems = async (id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${BASEURL}open_items/${id}`, requestOptions);
  const results = await response.json();
  return results;
};

const updateSpecificOpenItems = async (id, data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}open_items/${id}`, requestOptions);
  const results = await response.json();
  return results;
};

const createNewOpenitemComment = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}open_item_comments`, requestOptions);
  const results = await response.json();
  return results;
};

const openItemProfileAssignment = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}open_item_assignments`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const openItemDrag = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}openitemdrag`, requestOptions);
  const results = await response.json();
  return results;
};

export {
  createNewOpenItem,
  getSpecificOpenItems,
  createNewOpenitemComment,
  updateSpecificOpenItems,
  openItemProfileAssignment,
  getAllOpenItemsAssignment,
  openItemDrag,
};
