import React, { useEffect, useState } from "react";
import nocores from "../../assets/nocorevalues.png";
import _ from "lodash";
import ProjectGoalDetailsCard from "./ProjectGoalDetailsCard";
import ProjectGoalEditModal from "./ProjectGoalEditModal";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Pagination from "@mui/material/Pagination";
import ProjectKpisModal from "./ProjectKpisModal";
import ProjectGoalObjectiveModal from "./ProjectGoalObjectiveModal";
import {
  createProjectGoalObjective,
  createProjectGoalObjectiveKpi,
  editProjectGoalObjective,
  editProjectGoalObjectiveKpi,
} from "../../services/singleProject/singleProject";
import ProjectGoalobjectiveEdit from "./ProjectGoalobjectiveEdit";
import ProjectKpiEditModal from "./ProjectKpiEditModal";
import { Button } from "@mui/material";
import {
  fetchAllGoals,
  unvoteProjectGoal,
  upvoteProjectGoal,
} from "../../services/projects/ProjectGoalService";

const ProjectGoals = ({
  id,
  goals,
  editState,
  setEditState,
  fetchSingleProject,
  setGoals,
  projectGoalKpis,
  setProjectGoalKpis,
  speedDialState,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const closeEditModal = () => setOpenEditModal(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [kpiModal, setKpiModal] = useState(false);
  const [objectiveModal, setObjectiveModal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [objectiveEditModal, setObjectiveEditModal] = useState(false);
  const [objectiveEditstate, setObjectiveEditState] = useState("");
  const [editKpiState, setEditKpiState] = useState("");
  const [kpiEditModal, setKpiEditModal] = useState(false);
  const [kpiID, setKpiID] = useState(null);

  // ==== PAGINATION STATE ====
  const [goalPosts, setGoalPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = goalPosts.slice(indexOfFirstPost, indexOfLastPost);
  const actualPaginationCount = goalPosts.length / postsPerPage;
  const roundedFigure = Math.round(actualPaginationCount);
  // ==== Set project goal disable button ====
  const [likeButton, setLikeButton] = useState(false);

  useEffect(() => {
    setGoalPosts(goals);
  }, [goals]);

  // ==== REFRESH GOALS

  const refreshGoals = async () => {
    const { message, result } = await fetchAllGoals(id);
    if (message === "Project goals fetched successfully") {
      setGoals([...result]);
    }
  };

  const refreshGaolsAfterVote = (action, data) => {
    const currentGoal = goals.findIndex((goal) => goal.id === data.id);
    const updatedGoals = {
      ...goals[currentGoal],
      likes: {
        upvoted: action === "upvote" ? true : false,
        counter:
          action === "upvote"
            ? goals[currentGoal].likes.counter + 1
            : goals[currentGoal].likes.counter - 1,
      },
      upvoted_by: goals[currentGoal].upvoted_by,
    };
    const newProjectGoals = [...goals];
    newProjectGoals[currentGoal] = updatedGoals;
    setGoals(newProjectGoals);
  };

  // ==== HANDLE LIKE ACTION ====
  const handleLikeAction = async (goal) => {
    setLikeButton(true);
    const initialGoals = goals;
    refreshGaolsAfterVote("upvote", goal);
    const data = {
      upvote: {
        upvoteable_id: goal.id,
        upvoteable_type: "ProjectGoal",
      },
    };
    const { message } = await upvoteProjectGoal(data);
    if (message === "upvote successful") {
      // === REFRESH THE GOALS ====
      refreshGoals();
      setLikeButton(false);
      return;
    }
    NotificationManager.error(
      "Something went wrong. Check the network connection and try again"
    );
    setGoals(initialGoals);
    setLikeButton(false);
  };

  //  ==== HANDLE DISLIKE ACTION

  const handleDislikeAction = async (goal) => {
    setLikeButton(true);
    const initialGoals = goals;
    refreshGaolsAfterVote("unvote", goal);

    const data = {
      upvoteable_id: goal.id,
      upvoteable_type: "ProjectGoal",
    };
    const { message } = await unvoteProjectGoal(data);
    if (message === "Your vote has been removed") {
      //  ==== REFRESH THE GOALS ====
      refreshGoals();
      setLikeButton(false);
      return;
    }
    NotificationManager.error(
      "Something went wrong. Check the network connection and try again"
    );
    setGoals(initialGoals);
    setLikeButton(false);
  };

  const showData = () => {
    if (!_.isEmpty(goals)) {
      return (
        <div className="accordion container-fluid" id={`goalAccordian`}>
          {currentPosts.map((goal, idx) => (
            <ProjectGoalDetailsCard
              setEditState={setEditState}
              setOpenEditModal={setOpenEditModal}
              closeModal={closeEditModal}
              key={`gg${idx}`}
              data={goal}
              setKpiModal={setKpiModal}
              setSelectedGoal={setSelectedGoal}
              setObjectiveModal={setObjectiveModal}
              setObjectiveEditState={setObjectiveEditState}
              setSelectedObjective={setSelectedObjective}
              setObjectiveEditModal={setObjectiveEditModal}
              projectGoalKpis={projectGoalKpis}
              editKpiState={editKpiState}
              setEditKpiState={setEditKpiState}
              kpiEditModal={kpiEditModal}
              setKpiEditModal={setKpiEditModal}
              setKpiID={setKpiID}
              like={handleLikeAction}
              dislike={handleDislikeAction}
              refreshGoals={refreshGoals}
              projectID={id}
              likeButton={likeButton}
            />
          ))}
        </div>
      );
    } else if (_.isEmpty(goals)) {
      return (
        <div className="w-100 text-center">
          <h4 className="text-dark mt-1 mb-2">No Goals for this project.</h4>
          <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
        </div>
      );
    }
  };

  const handlePaginationChange = (e, value) => {
    setCurrentPage(value);
  };

  const handleCreateNewObjective = async (data) => {
    try {
      const { message } = await createProjectGoalObjective(data);
      if (message === "Project goal objective created") {
        NotificationManager.info("Objective created successfully");
        const { payload } = await fetchSingleProject(id);
        setGoals(payload.result.project_goals);
        setProjectGoalKpis(payload.result.project_goal_kpis);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong objective not created");
    }
  };

  const handleEditObjective = async (data) => {
    try {
      const { message } = await editProjectGoalObjective(data);
      if (message === "Project Goal Objective updated successfuly") {
        NotificationManager.info("Objective Edited successfully");
        const { payload } = await fetchSingleProject(id);
        setGoals(payload.result.project_goals);
        setProjectGoalKpis(payload.result.project_goal_kpis);
      }
    } catch (error) {
      NotificationManager.error(
        "Something went wrong Edit objective not created"
      );
    }
  };

  const handleCreateKpi = async (data) => {
    try {
      const { message } = await createProjectGoalObjectiveKpi(data);
      if (message === "Project goal objective Kpi created") {
        NotificationManager.info("Project Objective KPI  created successfully");
        const { payload } = await fetchSingleProject(id);
        setProjectGoalKpis(payload.result.project_goal_kpis);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong KPI not created");
    }
  };

  const handleEditKpi = async (data) => {
    try {
      const { message } = await editProjectGoalObjectiveKpi(data);
      if (message === "Project Goal Objective Kpi updated successfuly") {
        NotificationManager.info(
          "Project Goal Objective Kpi updated successfuly"
        );
        const { payload } = await fetchSingleProject(id);
        setProjectGoalKpis(payload.result.project_goal_kpis);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong KPI not created");
    }
  };

  return (
    <div className="profile-core-values container-m-well">
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("goal", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button mt-4 mb-4"
        >
          Add Goal
        </Button>
        {/* === THIS WILL BE ADDED AS FOR THE SEARCH BAR === */}
        {/* <div className="container">
          <AppProjectSearch
            data={goals && goals.length > 1 ? [...goals] : []}
            moduleType={"project_goal"}
            value={goalFilter}
            setValue={setGoalFilters}
          />
        </div> */}
      </div>
      <ProjectKpiEditModal
        editKpiState={editKpiState}
        setEditKpiState={setEditKpiState}
        kpiEditModal={kpiEditModal}
        setKpiEditModal={setKpiEditModal}
        projectID={id}
        goalID={selectedGoal}
        objectiveID={selectedObjective}
        handleEditKpi={handleEditKpi}
        kpiID={kpiID}
      />
      <ProjectKpisModal
        kpiModal={kpiModal}
        setKpiModal={setKpiModal}
        projectID={id}
        goalID={selectedGoal}
        objectiveID={selectedObjective}
        handleCreateKpi={handleCreateKpi}
      />
      <ProjectGoalObjectiveModal
        objectiveModal={objectiveModal}
        setObjectiveModal={setObjectiveModal}
        handleCreateNewObjective={handleCreateNewObjective}
        projectID={id}
        goalID={selectedGoal}
      />
      <ProjectGoalobjectiveEdit
        objectiveEditModal={objectiveEditModal}
        setObjectiveEditModal={setObjectiveEditModal}
        projectID={id}
        goalID={selectedGoal}
        objectiveEditstate={objectiveEditstate}
        setObjectiveEditState={setObjectiveEditState}
        handleEditObjective={handleEditObjective}
        objectiveID={selectedObjective}
        setSelectedObjective={setSelectedObjective}
      />
      <NotificationContainer />
      <ProjectGoalEditModal
        editState={editState}
        isOpen={openEditModal}
        closeModal={closeEditModal}
        id={id}
        fetchSingleProject={fetchSingleProject}
        setGoals={setGoals}
      />

      {showData()}
      <div className="assumptions-pagination-container">
        <Pagination
          count={roundedFigure}
          color="secondary"
          defaultPage={1}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default ProjectGoals;
