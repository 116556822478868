import React from 'react'
import SoartingFinancialForm from './SoartingFinancialForm'
function FinancialsCard({allRecords, employees}) {
    return (
        <div  className="container-fluid financial-body">
            <SoartingFinancialForm employees={employees} allRecords={allRecords}/> 
        </div>
    )
}
export default FinancialsCard
