import React, { Fragment, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import styles from "./CommentBox.module.css";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Avatar, Button, Divider, ListItem } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AvatarGroup from "@mui/material/AvatarGroup";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ReplyIcon from "@mui/icons-material/Reply";
import Badge from "@mui/material/Badge";
import { deepPurple } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import { displayDate, displayTime } from "../../../helpers/timeModule";
import parse from "html-react-parser";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Alert from "@mui/material/Alert";
import AspectRatio from "@mui/joy/AspectRatio";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import List from "@mui/material/List";

// From text, to editor ====
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
// ==== THIS WILL BE A REUSABLE COMMENT BOX FOE THE PROJECT FIELDS ====

/*
ACTIONS:

1. handleCreateComment is a method to create a comment to the backend
2. handleCreateCommentReply is a method for creating a reply for a particular comment
3. handleUpvoteComment is for creating an upvote for comment,
4. handleUpvoteCommentReply is for creating an upvote for comment reply
5. handleEditOfComment is for editing a comment
6. handleEditOfCommentReply is to handle edit of comment reply

props:

1. Detail: This is for showing the details of the module.
2. Comments: This is for displaying all comments
3. CommentsReply: This is for showing all the comments reply.
*/
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BottomAppBar({
  setOpen,
  handleCreateComment,
  setCommentBoxDetail,
  detail,
  submitButton,
  setSubmitButton,
  refreshGoals,
}) {
  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar position="fixed" color="inherit" sx={{ top: "auto", bottom: 0 }}>
        <Toolbar className={styles.tool_bar_container_box}>
          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
        <div className={styles.comment_box_area_box}>
          <label className={styles.new_comment_reply_label}>New Comment</label>
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={detail}
            onChange={(event, editor) => {
              const data = editor.getData();
              setCommentBoxDetail(data);
            }}
            label="Comment"
          />
          <Button
            variant="outlined"
            color="error"
            startIcon={<ArrowBackIcon />}
            className="mt-2 mb-1 mr-2"
            onClick={() => {
              setOpen(false);
              refreshGoals();
            }}
          >
            back
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<SendOutlinedIcon />}
            className="mt-2 mb-1 ml-2"
            onClick={handleCreateComment}
            disabled={submitButton}
          >
            {submitButton ? <CircularProgress color="success" /> : "submit"}
          </Button>
        </div>
      </AppBar>
    </React.Fragment>
  );
}

const UpvoteProfileDisplayModal = ({ state, itemID }) => {
  const { upvoted_by, show, id } = state;

  return show && id === itemID ? (
    <Alert severity="success">
      <h6>Upvoted profiles</h6>

      <div className={styles.alert_displayed_profiles}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            py: 1,
            overflow: "auto",
            scrollSnapType: "x mandatory",
            "& > *": {
              scrollSnapAlign: "center",
            },
            "::-webkit-scrollbar": { display: "inline", height: "8px" },
          }}
        >
          {upvoted_by.map((item, indx) => (
            <AspectRatio
              ratio="1"
              sx={{ minWidth: 56, minHeight: 56, background: "unset" }}
              key={indx}
            >
              <Avatar
                alt={`${item.profile.first_name} ${item.profile.last_name_name}`}
                src={`${
                  item.profile.profile_avatar
                    ? item.profile.profile_avatar.url
                    : null
                }`}
                sx={{ width: 56, height: 56 }}
              />
            </AspectRatio>
          ))}
        </Box>
      </div>
    </Alert>
  ) : null;
};

function CommentBox({
  handleCreateComment,
  handleEditOfComment,
  detail,
  setCommentBoxDetail,
  comments,
  open,
  setOpen,
  submitButton,
  setSubmitButton,
  label,
  labelDetails,
  editSubmitButton,
  refreshGoals,
  handleUpvoteOfComment,
  handleUnvoteOfComment,
  handleCreateOfCommentReponses,
  handleEditOfCommentReponses,
  handleUpvoteOfCommentResponses,
  handleUnvoteOfCommentResponses,
  commentBoxIdea,
  commentBoxPurpose,
}) {
  // === THIS STATE IS RESPONSIBLE FOR DISPLAYING PEOPLE THAT HAVE UPVOTED =====
  const [state, setState] = useState({
    show: false,
    upvoted_by: [],
    id: null,
  });

  // ==== EDIT STATE HANDLES THE EDIT OF COMMENT FUNCTIONALITY ====
  const [editState, setEditState] = useState({
    form: false,
    itemId: null,
    content: "",
  });

  // ==== COMMENT REPLY STATE IT HANDLES THE REPLY OF REPLY FUNCTIONALITY ====
  const [replyState, setReplyState] = useState({
    display: false,
    itemReplyId: null,
    replyContent: "",
  });

  // ===== HANDLES COMMENT EDIT REPONSES AND ALSO UPVOTE OF COMMENT RESPONSES =====
  const [editCommentResponse, setEditCommentResponses] = useState({
    form: false,
    responseID: null,
    content: "",
  });

  // ====== THIS DISPLAYS THE EDIT FORM FOR EACH COMMENT ====
  const displayEditForm = () => {
    const { content } = editState;
    return (
      <>
        <label className={styles.new_comment_reply_label}>Edit Comment</label>
        <CKEditor
          className="form-control"
          editor={ClassEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditState({ ...editState, content: data });
          }}
          label="Comment-edit"
        />
        <Button
          variant="outlined"
          color="secondary"
          endIcon={<SendOutlinedIcon />}
          className="mt-2 mb-1"
          onClick={() => handleEditOfComment(editState, setEditState)}
          disabled={editSubmitButton}
        >
          submit
        </Button>
        <Button
          variant="outlined"
          color="error"
          endIcon={<HighlightOffOutlinedIcon />}
          className="mt-2 mb-1 ml-2"
          onClick={() =>
            setEditState({
              form: false,
              itemId: null,
              content: "",
            })
          }
        >
          cancel
        </Button>
      </>
    );
  };

  const displayEditResponseForm = () => {
    const { content } = editCommentResponse;
    return (
      <>
        <label className={styles.new_comment_reply_label}>
          Edit Comment Reply
        </label>
        <CKEditor
          className="form-control"
          editor={ClassEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditCommentResponses({
              ...editCommentResponse,
              content: data,
            });
          }}
          label="Comment-reply-edit"
        />
        <div className={styles.response_action_edit_items}>
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<SendOutlinedIcon />}
            className="mt-2 mb-1"
            onClick={() =>
              handleEditOfCommentReponses(
                editCommentResponse,
                setEditCommentResponses,
                replyState
              )
            }
            disabled={editSubmitButton}
          >
            edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            endIcon={<HighlightOffOutlinedIcon />}
            className="mt-2 mb-1 ml-2"
            onClick={() =>
              setEditCommentResponses({
                form: false,
                responseID: null,
                content: "",
              })
            }
          >
            cancel
          </Button>
        </div>
      </>
    );
  };

  const displayCommentsResponses = (commentResponses) => {
    const { replyContent } = replyState;
    const { form, responseID } = editCommentResponse;
    if (commentResponses.length < 1)
      return (
        <div>
          <label className={styles.new_comment_reply_label}>
            Reply to this comment
          </label>
          <CKEditor
            className={`form-control`}
            editor={ClassEditor}
            data={replyContent}
            onChange={(event, editor) => {
              const data = editor.getData();
              setReplyState({ ...replyState, replyContent: data });
            }}
            label="Comment-reply"
          />

          <Button
            variant="outlined"
            color="secondary"
            endIcon={<SendOutlinedIcon />}
            className="mt-2 mb-1"
            onClick={() =>
              handleCreateOfCommentReponses(replyState, setReplyState)
            }
          >
            submit
          </Button>
          <Button
            variant="outlined"
            color="error"
            endIcon={<HighlightOffOutlinedIcon />}
            className="mt-2 mb-1 ml-2"
            onClick={() =>
              setReplyState({
                form: false,
                itemId: null,
                content: "",
              })
            }
          >
            cancel
          </Button>
        </div>
      );

    const results = commentResponses.map((value, index) => {
      return (
        <Fragment key={index}>
          <ListItem className={styles.responses_container}>
            <div className={styles.each_comment_container_reponses}>
              <Avatar
                alt={`${value.creator.first_name} ${value.creator.last_name}`}
                src={`${value.creator.profile_avatar.url}`}
                sx={{ width: 40, height: 40 }}
              />
              <div className={styles.comment_content_container}>
                <div className={styles.date_and_action_items}>
                  <div
                    className={styles.creator_name}
                  >{`${value.creator.first_name} ${value.creator.last_name} `}</div>
                  <div className={styles.creator_date_reponses}>
                    {displayDate(value.created_at).value}
                    &nbsp;
                    {displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}
                  </div>
                </div>

                <div className={styles.comment_details}>
                  {form && value.id === responseID
                    ? displayEditResponseForm()
                    : parse(value.content)}
                </div>
              </div>
            </div>
          </ListItem>
          <div className={styles.actions_buttons_responses}>
            <div className={styles.display_of_upvoted_profiles}>
              <div className={styles.avators}>
                <AvatarGroup max={2}>
                  {value.upvoted_by.length < 1
                    ? null
                    : value.upvoted_by.map((value, index) => {
                        return (
                          <Avatar
                            alt={`${value.profile.first_name} ${value.profile.last_name}`}
                            src={`${
                              value.profile.profile_avatar
                                ? value.profile.profile_avatar.url
                                : null
                            }`}
                            sx={{ width: 40, height: 40 }}
                            key={index}
                          />
                        );
                      })}
                </AvatarGroup>
              </div>
              {value.likes.upvoted ? (
                <IconButton
                  aria-label="upvote"
                  onClick={() => handleUnvoteOfCommentResponses(value.id)}
                >
                  <Badge badgeContent={value.likes.counter} color="secondary">
                    <FavoriteIcon
                      style={{
                        fontSize: "30px",
                        color: "#ff0000",
                      }}
                    />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  aria-label="unvote"
                  onClick={() => handleUpvoteOfCommentResponses(value.id)}
                >
                  <Badge badgeContent={value.likes.counter} color="secondary">
                    <FavoriteBorderIcon
                      style={{
                        fontSize: "30px",
                        color: "#000",
                      }}
                    />
                  </Badge>
                </IconButton>
              )}
            </div>

            <IconButton
              aria-label="edit"
              onClick={() => {
                setEditCommentResponses({
                  form: true,
                  responseID: value.id,
                  content: value.content,
                });
              }}
            >
              <ModeEditIcon
                style={{
                  fontSize: "30px",
                  color: "#000",
                }}
              />
            </IconButton>
          </div>
        </Fragment>
      );
    });

    return (
      <>
        {results}

        <>
          <label className={styles.new_comment_reply_label}>
            Reply to this comment
          </label>
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={replyContent}
            onChange={(event, editor) => {
              const data = editor.getData();
              setReplyState({ ...replyState, replyContent: data });
            }}
            label="Comment-reply"
          />

          <Button
            variant="outlined"
            color="secondary"
            endIcon={<SendOutlinedIcon />}
            className="mt-2 mb-1"
            onClick={() =>
              handleCreateOfCommentReponses(replyState, setReplyState)
            }
          >
            submit
          </Button>
          <Button
            variant="outlined"
            color="error"
            endIcon={<HighlightOffOutlinedIcon />}
            className="mt-2 mb-1 ml-2"
            onClick={() =>
              setReplyState({
                form: false,
                itemId: null,
                content: "",
              })
            }
          >
            cancel
          </Button>
        </>
      </>
    );
  };

  const displayUpvotedProfiles = (profiles) => {
    return profiles.map((value, index) => {
      return (
        <Avatar
          key={index}
          alt={`${value.profile.first_name} ${value.profile.last_name}`}
          sx={{ bgcolor: deepPurple[500], width: 40, height: 40 }}
          src={value.profile.profile_avatar.url}
        />
      );
    });
  };

  const displayComments = () => {
    // ==== EDIT STATE DESTRUCTURE ===
    const { itemId, form } = editState;
    // ==== REPLY STATE DESTRUCTURE
    const { display, itemReplyId } = replyState;

    if (comments.length < 1) return "There are no comments at this time";
    const results = comments.map((item, indx) => {
      return (
        <div key={indx} className={styles.main_comment_container}>
          <UpvoteProfileDisplayModal
            state={state}
            setState={setState}
            itemID={item.id}
          />
          <div className={styles.each_comment_container}>
            {item.creator ? (
              <Avatar
                alt={`${item.creator.first_name} ${item.creator.last_name}`}
                src={`${item.creator.profile_avatar.url}`}
                sx={{ width: 40, height: 40 }}
              />
            ) : (
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                sx={{ width: 40, height: 40 }}
              />
            )}

            <div className={styles.comment_content_container}>
              <div className={styles.date_and_action_items}>
                <div className={styles.creator_name}>
                  {`${item.creator.first_name} ${item.creator.last_name}`}
                </div>
                <div className={styles.creator_date}>
                  {displayDate(item.created_at).value}
                  &nbsp;
                  {displayTime(
                    displayDate(item.created_at).unit,
                    displayDate(item.created_at).value
                  )}
                </div>
              </div>
              <div className={styles.comment_details}>
                {form && itemId === item.id
                  ? displayEditForm()
                  : parse(item.content)}
              </div>
              {display && itemReplyId === item.id
                ? displayCommentsResponses(item.comment_responses)
                : null}
            </div>
          </div>
          <div className={styles.action_items_container}>
            <div className={styles.action_items_buttons}>
              <div className={styles.upvoted_profiles}>
                <AvatarGroup
                  max={3}
                  onClick={() =>
                    setState({
                      show: !state.show,
                      upvoted_by: item.upvoted_by,
                      id: item.id,
                    })
                  }
                >
                  {item.upvoted_by.length > 0
                    ? displayUpvotedProfiles(item.upvoted_by)
                    : null}
                </AvatarGroup>
              </div>
              {item.likes.upvoted ? (
                <IconButton
                  aria-label="upvote"
                  onClick={() => handleUnvoteOfComment(item)}
                >
                  <Badge badgeContent={item.likes.counter} color="secondary">
                    <FavoriteIcon
                      style={{
                        fontSize: "30px",
                        color: "#ff0000",
                      }}
                    />
                  </Badge>
                </IconButton>
              ) : (
                <IconButton
                  aria-label="unvote"
                  onClick={() => handleUpvoteOfComment(item)}
                >
                  <Badge badgeContent={item.likes.counter} color="secondary">
                    <FavoriteBorderIcon
                      style={{
                        fontSize: "30px",
                        color: "#000",
                      }}
                    />
                  </Badge>
                </IconButton>
              )}
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setEditState({
                    form: true,
                    itemId: item.id,
                    content: item.content,
                  });
                }}
              >
                <ModeEditIcon
                  style={{
                    fontSize: "30px",
                    color: "#000",
                  }}
                />
              </IconButton>
              <IconButton
                aria-label="reply"
                onClick={() => {
                  setReplyState({
                    ...replyState,
                    display: true,
                    itemReplyId: item.id,
                  });
                }}
              >
                <Badge
                  badgeContent={item.comment_responses.length}
                  color="success"
                >
                  <ReplyIcon
                    style={{
                      fontSize: "30px",
                      color: "#000",
                    }}
                  />
                </Badge>
              </IconButton>
            </div>
          </div>
        </div>
      );
    });

    return results;
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        onClose={() => {
          setOpen(false);
          refreshGoals();
        }}
        open={open}
        TransitionComponent={Transition}
      >
        <div className={styles.scroll_area}>
          <div className={styles.comment_header_container}>
            <div className={styles.comments_number_over_view}>
              <QuestionAnswerIcon fontSize="large" />
              <span>{`Comments(${comments.length})`}</span>
            </div>
            <Divider />
            <div className={styles.module_overview_container}>
              <div className={styles.heading_title}>
                <h4> {label} Overview: </h4>
              </div>
              <List
                dense
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <div className={styles.details_container}>
                    <div className={styles.details_label_container}>
                      <DescriptionTwoToneIcon fontSize="large" />{" "}
                      <h6 className={styles.label_title}> {label}:</h6>
                    </div>
                    <div className={styles.actual_details}>
                      <ReadMoreReusable>{labelDetails}</ReadMoreReusable>
                    </div>
                  </div>
                </ListItem>
                {commentBoxIdea !== "" && (
                  <ListItem>
                    <div className={styles.details_container}>
                      <div className={styles.details_label_container}>
                        <TipsAndUpdatesIcon fontSize="large" />{" "}
                        <h6 className={styles.label_title}> idea:</h6>
                      </div>
                      <div className={styles.actual_details}>
                        <ReadMoreReusable>{commentBoxIdea}</ReadMoreReusable>
                      </div>
                    </div>
                  </ListItem>
                )}

                {commentBoxPurpose !== "" && (
                  <ListItem>
                    <div className={styles.details_container}>
                      <div className={styles.details_label_container}>
                        <ContactSupportIcon fontSize="large" />{" "}
                        <h6 className={styles.label_title}> purpose:</h6>
                      </div>
                      <div className={styles.actual_details}>
                        <ReadMoreReusable>{commentBoxPurpose}</ReadMoreReusable>
                      </div>
                    </div>
                  </ListItem>
                )}
              </List>
            </div>
            <Divider />
          </div>
          <DialogContent className={styles.comment_content_area}>
            {displayComments()}
          </DialogContent>
        </div>

        <BottomAppBar
          setOpen={setOpen}
          handleCreateComment={handleCreateComment}
          detail={detail}
          setCommentBoxDetail={setCommentBoxDetail}
          submitButton={submitButton}
          setSubmitButton={setSubmitButton}
          refreshGoals={refreshGoals}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default CommentBox;
