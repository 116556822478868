import React, {useState} from 'react'
import parse from "html-react-parser";
import _ from "lodash";

const ReadMoreReusable = ({children}) =>  {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    if (_.size(text) < 150) return <div className="text">{parse(text)}</div>;

    return (
      <>
        <div className="text">
          {isReadMore ? parse(text.slice(0, 150)) : parse(text)}
        </div>
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : "show less"}
        </span>
      </>
    );
}

export default ReadMoreReusable