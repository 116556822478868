/*eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  getAllSubProjects,
  postSubProject,
} from "../../../services/subProjects/subProjectService";
import { NotificationManager } from "react-notifications";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import FormGroup from "@mui/material/FormGroup";
import _ from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const filter = createFilterOptions();

function SubProjectModal({
  allProjects,
  singleProjectID,
  setSubProjects,
  setShowSubProject,
  isOpen,
  closeModal 
}) {
  const [selectedProject, setSelectedProject] = useState([]);
  const [searchState, setSearchState] = useState(null);

  const handleClose = () => {
    setSelectedProject([])
    closeModal()
  };

  const SearchBar = () => {
    return (
      <Stack spacing={2} sx={{ width: 300 }}>
        <Autocomplete
          value={searchState}
          onChange={(event, newValue) => {
            if (typeof newValue === "string") {
              setSearchState({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setSearchState({
                name: newValue.inputValue,
              });
            } else {
              setSearchState(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                name: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="project-search-bar"
          options={allProjects}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.name;
          }}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          sx={{ width: 300 }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Search Project" />
          )}
        />
      </Stack>
    );
  };

  const createSubProject = async () => {
    if (selectedProject.length < 1)
      return NotificationManager.warning(
        "You need to choose one or more projects"
      );

     // ===== Create links for selected projects ====
     selectedProject.forEach( async (value) => {
      const data = {
        subproject_id: value,
      };
      try {
         await postSubProject(singleProjectID, data);
          // ==== Refresh the state of the subprojects 
         const { result } = await getAllSubProjects(singleProjectID);
         setSubProjects(result);
         handleClose()
      } catch (error) {
        if(error) {
          NotificationManager.error(
          "Something went wrong kindy check internet connection and try again"
        )
        handleClose();
        return
      };
      }
      })
     handleClose()
     setShowSubProject(false)
     NotificationManager.info("Sub Project created sucessfully");
  };

  const handleSubmit = () => {
    createSubProject();
  };

  const displaySubProjectsInputs = () => {
    if (_.isEmpty(allProjects)) return <p>loading...</p>;

    if (searchState !== null) {
      return (
        <FormControlLabel
          value={searchState.id}
          control={<Checkbox />}
          label={searchState.name}
          onChange={(e) => {
            if (e.target.checked) {
              const results = [...selectedProject, e.target.value];
              const array = _.uniq(results);
              setSelectedProject(array);
            } else {
              setSelectedProject(
                [...selectedProject].filter((value) => value !== e.target.value)
              );
            }
          }}
        />
      );
    }

    if (allProjects.length > 0) {
      const results = allProjects.map((project) => {
        if (project.id == singleProjectID) {
          return null;
        }
        return (
          <Fragment key={project.id}>
            <FormControlLabel
              value={project.id}
              control={<Checkbox />}
              label={project.name}
              onChange={(e) => {
                if (e.target.checked) {
                  const results = [...selectedProject, e.target.value];
                  const array = _.uniq(results);
                  setSelectedProject(array);
                } else {
                  setSelectedProject(
                    [...selectedProject].filter(
                      (value) => value !== e.target.value
                    )
                  );
                }
              }}
            />
          </Fragment>
        );
      });
      return results;
    }
  };

  return (
    <Dialog
      open={ isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <div className="search-Project-title-cont">Select Project</div>

        <SearchBar />
      </DialogTitle>
      <DialogContent>
        <FormGroup>{displaySubProjectsInputs()}</FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleSubmit}
          startIcon={<SendIcon />}
          color="success"
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setShowSubProject(false);
            setSelectedProject([])
            closeModal()
          }}
          color="error"
          startIcon={<CancelIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SubProjectModal;
