import {FETCH_FIELDS_REQUEST, FETCH_FIELDS_SUCCESS, FETCH_FIELDS_FAILURE} from './fieldTypes'

const initialState = {
    loading: true,
    data: [],
    errorMsg: '',
  };
  
  const fieldsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_FIELDS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_FIELDS_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          errorMsg: '',
        };
      case FETCH_FIELDS_FAILURE:
        return {
          ...state,
          loading: false,
          data: [],
          errorMsg: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default fieldsReducer;