import { CREATE_COMMENT_REQUEST, CREATE_COMMENT_SUCCESS, CREATE_COMMENT_FAILURE } from "./commentTypes";

const initialState = {
    loading: true,
    data: [],
    errorMsg: '',
  };

  const commentsReducer = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_COMMENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_COMMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          errorMsg: '',
        };
      case CREATE_COMMENT_FAILURE:
        return {
          ...state,
          loading: false,
          data: [],
          errorMsg: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default commentsReducer;