import React from 'react'

function SummaryContactCard({icon, title, address, contactNo}) {
  return (
    <div className="summary-contact-card">
      <div className="profile-mv-card-icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="profile-mv-card-details">
        <h3 className="profile-mv-card-details-title">{title}</h3>
      <h4>Phone: 0{contactNo}</h4>
      <h4>Address: {address}</h4>
      <div className='d-flex flex-row'>
        <h4>Social Links</h4>
        <i className="fab fa-facebook-square fa-2x p-2 text-primary"></i>
        <i className="fab fa-twitter fa-2x p-2 text-info"></i>
        <i className="fab fa-linkedin fa-2x p-2 text-primary"></i>
      </div>
      </div>
    </div>
  )
}

export default SummaryContactCard
