import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import styles from "../../stylesheets/taskModules/TaskMenuBar.module.css";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import _ from "lodash";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";

const AllAssignedProfiles = ({
  displayProfiles,
  setDisplayProfiles,
  selectedTask,
  setSelectedTask,
}) => {
  const history = useHistory();

  const handleNavigate = (item) => {
    history.push({
      pathname: `/profile/${item.id}`,
    });
  };

  const profiles = () => {
    if (!_.isEmpty(selectedTask)) {
      const { collaborators } = selectedTask;
      return collaborators.map((item, index) => {
        return (
          <Tooltip
            title={`${item.first_name} ${item.last_name}`}
            placement="top-start"
            key={index}
          >
            {item.profile_avatar.url !== null ? (
              <Avatar
                alt={`${item.first_name} ${item.last_name}`}
                src={`${item.profile_avatar.url}`}
                sx={{ width: 56, height: 56, bgcolor: deepOrange[500] }}
                onClick={() => handleNavigate(item)}
              />
            ) : (
              <Avatar
                alt={`${item.first_name} ${item.last_name}`}
                src="/static/images/avatar/1.jpg"
                sx={{ width: 56, height: 56, bgcolor: deepOrange[500] }}
                onClick={() => handleNavigate(item)}
              />
            )}
          </Tooltip>
        );
      });
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <div className={styles.drawer_header}>
        {" "}
        <h3 className="pl-4">collaborators</h3>
        <Button
          variant="contained"
          edge="right"
          className={styles.close_button_drawer}
          color="secondary"
          endIcon={<CloseIcon />}
          onClick={() => {
            setDisplayProfiles(false);
            setSelectedTask({});
          }}
        >
          close
        </Button>
      </div>
      <div className={styles.drawer_body}>
        <Stack direction="row" spacing={0.5}>
          {profiles()}
        </Stack>
      </div>
    </Box>
  );

  return (
    <div>
      {["top"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer anchor={anchor} open={displayProfiles}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default AllAssignedProfiles;
