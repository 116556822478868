/*eslint-disable */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchNeedSupport,
  createNeedSupport,
} from "../../services/masterNeeds/masterNeedsService";
import NeedSupportMessage from "./NeedSupportMessage";

const NeedSupport = ({ id }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const getSupportMessages = async () => {
    try {
      const data = await fetchNeedSupport(id);
      setMessages(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const createSupportMessage = async (data) => {
    try {
      await createNeedSupport(id, data);
      setMessage("");
      getSupportMessages();
      toast.success("Thanks for your support!");
    } catch (error) {}
  };

  const handleMessageSubmit = () => {
    const data = {
      need_support: {
        message: message,
      },
    };
    createSupportMessage(data);
  };

  useEffect(() => {
    getSupportMessages();
  }, []);

  const showMessages = () => {
    if (_.isEmpty(messages)) {
      return (
        <div className="text-center text-muted">
          <i className="fa fa-exclamation-circle fa-2x" aria-hidden="true"></i>
          <h6>No Support available</h6>
        </div>
      );
    } else {
      return (
        <>
          {messages.map((msg) => (
            <NeedSupportMessage key={msg.id} data={msg} />
          ))}
        </>
      );
    }
  };

  return (
    <div className="master-need-card-body-support">
      <h5 className="master-need-card-body-header">
        <i className="fa fa-medkit" aria-hidden="true"></i>
        &nbsp; Support Forum
      </h5>
      <div className="master-need-card-body-msgs">{showMessages()}</div>
      <div className="master-need-card-body-form">
        <input
          className="form-control"
          type="text"
          placeholder="show your support here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <button type="button" onClick={handleMessageSubmit}>Post</button>
      </div>
    </div>
  );
};

export default NeedSupport;
