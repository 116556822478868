import { CREATE_REMIX_REQUEST, CREATE_REMIX_SUCCESS,CREATE_REMIX_FAILURE} from './remixTypes'
import axios from 'axios';
import { BASEURL } from '../../API/apiData';

export const createRemixRequest = () =>({
  type: CREATE_REMIX_REQUEST,
})

export const createRemixSuccess = (response) =>({
  type: CREATE_REMIX_SUCCESS,
  payload: response
})

export const createRemixFailure = (error) =>({
  type: CREATE_REMIX_FAILURE,
  payload: error
})

export const createRemix = (catId,tId,tteId,data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createRemixRequest());
  return axios.post(`${BASEURL}categories/${catId}/top_tens/${tId}/top_ten_entries/${tteId}/remixes`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createRemixSuccess(response.data));
    }).catch(error => {
      return dispatch(createRemixFailure(error));
    });
};