import React from "react";
import styles from "../css/main.module.css";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import Alert from "@mui/material/Alert";
import Typography from "@mui/joy/Typography";
import CircularProgress from "@mui/joy/CircularProgress";
import { useCountUp } from "use-count-up";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";

const InfoCard = ({ dashboardContent }) => {
  const { count, percentage_engagement } = dashboardContent.user_activity_count;

  const { value } = useCountUp({
    isCounting: true,
    duration: 1,
    start: 0,
    end: Math.round(percentage_engagement),
  });

  const displayInfoConditionally = () => {
    // eslint-disable-next-line eqeqeq
    if (value == 0) {
      return (
        <Alert severity="info">
          <ReadMoreReusable>
            {` You dont have any engagements yet. Welcome, in order for you to
            attain a USER ENGAGEMENT BADGE, kindly start engageging in the
            platform`}
          </ReadMoreReusable>{" "}
        </Alert>
      );
    }
    if (value > 0 && value <= 25) {
      return (
        <Alert severity="error">
          {" "}
          <ReadMoreReusable>
            {`Your engagement is poor. You are ${value}% engaged. It means you
          need&nbsp; ${100 - value}% more Engagements to attain the MOST ENGAGED
          USER BADGE.`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    if (value > 25 && value <= 50) {
      return (
        <Alert severity="error">
          <ReadMoreReusable>
            {`Your engagement is Improving, However you still need to engage more to
          improve your score. You are ${value}% engaged. It means you need&nbsp;
          ${
            100 - value
          }% more Engagements to attain the MOST ENGAGED USER BADGE.`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    if (value > 50 && value <= 75) {
      return (
        <Alert severity="info">
          {" "}
          <ReadMoreReusable>
            {`Your engagement is above average, However you still need to engage
            more to improve your score. You are ${value}% engaged. It means you
            need&nbsp; ${100 - value}% more Engagements to attain the MOST
            ENGAGED USER BADGE.`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    if (value > 75 && value <= 99) {
      return (
        <Alert severity="success">
          {" "}
          <ReadMoreReusable>
            {`Your engagement is excellent, However you still need to engage more
            in order to attain the MOST ENGAGED USER BADGE. You are ${value}%
            engaged. It means you need&nbsp; ${100 - value}% more Engagements.
            Lets keep going !!`}
          </ReadMoreReusable>
        </Alert>
      );
    }
    // eslint-disable-next-line eqeqeq
    if (value == 100) {
      return (
        <Alert severity="success">
          {" "}
          <ReadMoreReusable>
            {` Your engagement is excellent, You are the user who has the MOST
            ENGAGED USER BADGE. You are ${value}% engaged. Keep engaging to
            retain your badge. Great work!!!`}
          </ReadMoreReusable>
        </Alert>
      );
    }
  };

  return (
    <div className={styles.info_card_container}>
      <div className={styles.info_card_header}>
        {" "}
        <GroupWorkIcon
          sx={{
            fontSize: "32px",
            marginRight: "5px",
            fontWeight: "600",
          }}
        />
        <h4 className={styles.info_card_header_title}>Your total engagement</h4>
      </div>
      <div className={styles.info_card_body}>
        <div className={styles.card_info_header}>
          <div className={styles.number_value_container}>
            <h2 className={styles.number_value}>{count}</h2>
            <h5 className={styles.name_value}>Engagements</h5>
          </div>
          <div className={styles.progress_circular}>
            <CircularProgress
              size="lg"
              determinate
              value={parseInt(value)}
              color="primary"
            >
              <Typography>{value}%</Typography>
            </CircularProgress>
          </div>
        </div>
        <div className={styles.card_info_body}>
          {displayInfoConditionally()}
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
