import React from 'react'
import projectImage from "../../images/imageProjects.png";
import { Link } from 'react-router-dom'
import parse from "html-react-parser";

const ProjectCard = ({project}) => {
  return (
    <div className="project-card shadow-lg">
      <div className="project-card-image">
        <img src={projectImage} alt="p-img" />
      </div>
      <h5 className="project-card-title">{project.name}</h5>
      <div className="project-card-details">{parse(project.idea)}</div>
      <Link to={`/projects/${project.id}`} className="project-card-btn">
        View Project
      </Link>
    </div>
  );
}

export default ProjectCard