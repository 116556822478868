import React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import projectImage from "../../../assets/nocorevalues.png";
import PushPinIcon from "@mui/icons-material/PushPin";
import ReadMoreReusable from "../../reusablecomponents/ReadMoreReusable";
import parse from "html-react-parser";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import { Alert, Button } from "@mui/material";
import Groups3Icon from "@mui/icons-material/Groups3";
import AvatarGroup from "@mui/material/AvatarGroup";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import _ from "lodash";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ProjectCard = ({
  project,
  handleUnfollowAction,
  handleFollowAction,
  navigateTo,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePin = (project) => {
    //  handle pin logic
    project.is_favorite = true;
    handleFollowAction(project.id);
  };

  const handleUnpin = (project) => {
    // handle unpin logic
    project.is_favorite = false;
    handleUnfollowAction(project.id);
  };

  const displayCollaborators = () => {
    if (_.isEmpty(project.collaborators))
      return (
        <Alert variant="outlined" severity="success">
          This project dont have any collaborators
        </Alert>
      );

    const results = project.collaborators.map((value) => {
      return (
        // ==== add the avator image
        <Tooltip
          title={`${value.first_name} ${value.last_name}`}
          placement="top"
          sx={{
            cursor: "pointer",
          }}
        >
          <Avatar
            alt={`${value.first_name}`}
            src="/static/images/avatar/1.jpg"
          />
        </Tooltip>
      );
    });

    return <AvatarGroup max={8}>{results}</AvatarGroup>;
  };

  return (
    <Card>
      <CardHeader
        sx={{
          backgroundColor: "#f7f7f7",
          color: "#000",
          fontWeight: "700",
        }}
        avatar={
          project.created_by.profile_avatar.url === null ? (
            <Avatar
              sx={{ bgcolor: red[500] }}
              aria-label="recipe"
              alt={`${project.created_by.first_name}`}
            />
          ) : (
            <Avatar
              alt={`${project.created_by.first_name} ${project.created_by.last_name}`}
              src={`${project.created_by.profile_avatar.url}`}
            />
          )
        }
        action={
          project.is_favorite ? (
            <Button
              sx={{
                marginTop: "5px",
              }}
              size="small"
              variant="contained"
              color="error"
              onClick={() => handleUnpin(project)}
              startIcon={
                <PushPinIcon
                  fontSize="medium"
                  sx={{
                    fontSize: "28px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                  style={{ color: "white" }}
                />
              }
            >
              unpin
            </Button>
          ) : (
            <Button
              sx={{
                marginTop: "5px",
              }}
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handlePin(project)}
              startIcon={
                <PushPinIcon
                  fontSize="medium"
                  sx={{
                    fontSize: "28px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                  style={{ color: "black" }}
                />
              }
            >
              pin
            </Button>
          )
        }
        title="Creator:"
        subheader={`${project.created_by.first_name} ${project.created_by.last_name}`}
      />
      <CardMedia
        component="img"
        height="200"
        image={projectImage}
        alt="Paella dish"
        sx={{
          objectFit: "fill",
          objectPosition: "center",
        }}
      />

      <CardContent
        sx={{
          minHeight: "150px",
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            color: "#000",
            fontSize: "17px",
          }}
        >
          <ReadMoreReusable>{project.name}</ReadMoreReusable>
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        sx={{
          backgroundColor: "#f7f7f7",
        }}
      >
        <div
          style={{
            textTransform: "uppercase",
            fontWeight: "700",
          }}
        >
          <span
            style={{
              color: "purple",
              fontWeight: 800,
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            {" "}
            ({project.engagements.eng_count})
          </span>
          Engagements{" "}
        </div>
        <Button
          onClick={() => navigateTo(project.id)}
          startIcon={<Groups3Icon />}
          variant="outlined"
          color="secondary"
          sx={{
            marginLeft: "20px",
          }}
          size="small"
        >
          Engage
        </Button>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon
            fontSize="medium"
            style={{ color: "black" }}
            sx={{
              fontSize: "28px",
            }}
          />
        </ExpandMore>
      </CardActions>
      <Divider />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              fontWeight: 700,
              textTransform: "uppercase",
              color: "purple",
              marginTop: "10px",
              marginBottom: "20px",
              textDecoration: "underline",
            }}
          >
            <TipsAndUpdatesIcon
              sx={{
                marginRight: "10px",
              }}
            />
            Project Idea:
          </div>
          {parse(project.idea)}

          <Divider />

          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                textTransform: "uppercase",
                color: "purple",
                marginTop: "10px",
                marginBottom: "10px",
                textDecoration: "underline",
              }}
            >
              <Groups3Icon
                sx={{
                  marginRight: "10px",
                }}
              />{" "}
              collaborators:
            </div>
            {displayCollaborators()}
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ProjectCard;
