import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ideaIcon from "../../../images/idea.png";
import ClassEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'

const ProjectSuggestionEditModal = ({ suggestion, closeModal, isOpen, handleSubmit }) => {

  const [idea, setIdea] = useState(suggestion.idea)
  const [purpose, setPurpose] = useState(suggestion.purpose)
  const [details, setDetails] = useState(suggestion.details)


  useEffect(() => {
      setIdea(suggestion.idea);
  }, [suggestion.idea ]);

  useEffect(() => {
    setPurpose(suggestion.purpose);
}, [suggestion.purpose]);

useEffect(() => {
  setDetails(suggestion.details)
}, [suggestion.details ]);

 
  const handleFormSubmit = (event) => {
   event.preventDefault()
   const suggestionForm = new FormData();
   suggestionForm.append("project_suggestion[idea]", idea);
   suggestionForm.append("project_suggestion[purpose]", purpose);
   suggestionForm.append("project_suggestion[details]", details);
   handleSubmit(suggestion.id,suggestionForm)
   closeModal();
  };


  return (
    <Modal show={isOpen} onHide={closeModal}>
    <Modal.Header className="text-white" style={{"backgroundColor": "#309897"}} closeButton>
      <Modal.Title>Edit Project Suggestion</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <form onSubmit={handleFormSubmit}>
      <div className="form-group">
          <label htmlFor="description">Suggestion</label>
          <CKEditor 
                className="form-control"
                editor={ClassEditor}
                data ={details}
                onChange={(event,editor) => {
                  const data = editor.getData()
                  setDetails(data)
                }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="idea"><img className="psb-idea-icon" src={ideaIcon} alt="idea-icon" />Idea</label>
          <CKEditor 
                className="form-control"
                editor={ClassEditor}
                data ={idea}
                onChange={(event,editor) => {
                  const data = editor.getData()
                  setIdea(data)
                }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Purpose</label>
          <CKEditor 
                className="form-control"
                editor={ClassEditor}
                data ={purpose}
                onChange={(event,editor) => {
                  const data = editor.getData()
                  setPurpose(data)
                }}
          />
        </div>
        
        <div> 
          <hr />
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              Edit
            </button>
          </div>
        </div>
      </form>
    </Modal.Body>
    <Modal.Footer>
      <h5>TUC SYSTEM</h5>
    </Modal.Footer>
  </Modal>
  )
}

export default React.memo(ProjectSuggestionEditModal)