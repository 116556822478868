import React, { useState } from "react";
import parse from 'html-react-parser'
import LibraryItemViewModal from "./LibraryItemViewModal";
import LibraryModal from "./LibraryModal";

const LibraryCard = ({data,idx, handleSubmit,priv,master,categories}) => {

  const {inventory, items, owner} = data
  const [details, setDetails] = useState(false);
  const [showItems, setShowItems] = useState(false)
  const [open, setOpen] = useState(false);

 const openModal = () => {
   setOpen(true);
 };

 const closeModal = () => {
   setOpen(false);
 };

 const handlePrivacy = (privacy) => {
  const xData = {
    tuc_inventory: {
      is_private: privacy,
    },
  };
  handleSubmit(inventory.id, xData);
 }

 const handleUpdate = (xData) => {
   handleSubmit(inventory.id, xData)
 }

  return (
    <>
      <LibraryModal
        data={data.inventory}
        categories={categories}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleUpdate}
        master={master}
      />
      {showItems ? (
        <LibraryItemViewModal
          id={inventory.id}
          items={items}
          owner={owner}
          priv={priv}
          close={setShowItems}
        />
      ) : null}
      <div className="library-card shadow-lg">
        <div className="library-card-header">
          <h5 className="p-2">
            <i className="fa fa-list-alt" aria-hidden="true">
              &nbsp;{idx + 1}
            </i>
            &nbsp;{inventory.list_name}
          </h5>
          <button onClick={() => setDetails(!details)}>
            <i className="fa fa-chevron-down mt-2"></i>
          </button>
        </div>
        {details ? (
          <div className="library-card-body">
            <div className="library-card-body-details">
              <h6>
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                &nbsp; Description
              </h6>
              <div className="library-card-body-info">
                <div className="library-card-body-info-top">
                  {parse(inventory.list_description)}
                </div>
                <div className="library-card-body-info-bottom">
                  <div></div>
                  <div>
                    <button
                      type="button"
                      onClick={() => setShowItems(!showItems)}
                    >
                      <i className="fas fa-eye text-muted"></i>
                      &nbsp; View List
                    </button>
                    {owner ? (
                      <>
                        <button type="button" onClick={openModal}>
                          <i className="fas fa-edit text-primary"></i>
                          &nbsp; Edit
                        </button>
                        {!master ? (
                          <>
                            {" "}
                            {inventory.is_private ? (
                              <>
                                <button
                                  type="button"
                                  onClick={() => handlePrivacy(false)}
                                >
                                  <i className="fas fa-unlock text-success"></i>
                                  &nbsp; Make Public
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  onClick={() => handlePrivacy(true)}
                                >
                                  <i className="fas fa-lock text-danger"></i>
                                  &nbsp; Make private
                                </button>
                              </>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LibraryCard;
