import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EditIcon from "@mui/icons-material/Edit";
import MenuIcon from "@mui/icons-material/Menu";

const BottomBar = ({ toggleDrawer, createUpdateModalOpen, selection }) => {
  const [value, setValue] = useState(null);

  const navigateToHome = () => {
    console.log("am Home");
  };

  const openCreateModal = () => {
    if (
      selection.name === "Completed Updates" ||
      selection.name === "Upcoming Updates"
    ) {
      createUpdateModalOpen();
      return;
    }
    if (selection.name === "Bug Tracker") {
      // open bug form model
      return;
    }
  };

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          if (newValue === 0) {
            navigateToHome();
            setValue(0);
          }
          if (newValue === 1) {
            setValue(1);
            return;
          }
          if (newValue === 2) {
            setValue(2);
            return;
          }
        }}
      >
        <BottomNavigationAction label="Back" icon={<KeyboardBackspaceIcon />} />
        <BottomNavigationAction
          label="Add Item"
          icon={<EditIcon />}
          onClick={() => openCreateModal()}
        />
        <BottomNavigationAction
          label="Menu"
          icon={<MenuIcon />}
          onClick={toggleDrawer("right", true)}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomBar;
