import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import styles from "./TopProjectBar.module.css";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import DescriptionIcon from "@mui/icons-material/Description";
import ProjectStatistics from "../ProjectStatistics";
import ProjectDescription from "../ProjectDescription";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// ==== MATERIAL UI DIALOG FOR DISPLAYING COLLABORATORS ====
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AppMobileCaurosel from "../MobileDesign/AppMobileCaurosel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

// ==== THIS COMPONENT IS RESPONSIBLE FOR THE SUMMARY SLIDER AND NAME OF PROJECT  ITS A CHILD COMPONENT====
const ProjectNameAndCollaborators = ({
  project,
  toggleProjectSpeedDialMenu,
}) => {
  const { name, created_at } = project;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6">
          <ProjectDescription
            description={project.description}
            project={project}
            projectName={name}
            toggleProjectSpeedDialMenu={toggleProjectSpeedDialMenu}
          />
        </div>
        {/* This will display the timeline */}
        <div className="col-md-6">
          <div className={styles.jumbotron_container}>
            <div
              className={`jumbotron jumbotron-fluid ${styles.actual_jumbotron_area}`}
              style={{
                backgroundColor: "#eee",
                boxShadow: "unset",
                textAlign: "unset",
                fontFamily: "unset",
              }}
            >
              <div className={styles.project_name_container}>
                <div className={styles.project_image_container}>
                  <BookmarksIcon />{" "}
                  <span className={styles.project_name_display}>
                    Project Phases
                  </span>
                </div>
              </div>
              {/* ==== DISPLAY CAROUSEL ==== */}
              <div className="main-body-area-single-project-caurosel">
                <ProjectStatistics time={created_at} />
              </div>

              {/* <====== MOBILE CAROUSEL ====> */}
              <div className="main-mobile-slider-area">
                <AppMobileCaurosel />
              </div>
            </div>
          </div>
        </div>
        {/* This is the project name and the carousel */}
      </div>
    </div>
  );
};
// ===== THIS IS A CHILD COMPONENT FOR SHOWING ALL OF THE COLLABORATORS ====
const ProjectCollaboratorsDisplay = ({ collaborator, open, handleClose }) => {
  const navigateToProfile = (id) => {
    window.location.href = `/profile/${id}`;
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Collaborators"}</DialogTitle>
      <hr />
      <DialogContent>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {collaborator.map((value, idx) => {
            return (
              <Fragment key={idx}>
                <ListItem
                  alignItems="flex-start"
                  onClick={() => navigateToProfile(value.id)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={
                        value !== null
                          ? `${value.first_name} ${value.last_name}`
                          : null
                      }
                      src={
                        value !== null ? `${value.profile_avatar.url}` : null
                      }
                      sx={{ width: 38, height: 38 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      value !== null
                        ? `${value.first_name} ${value.last_name}`
                        : null
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {value !== null ? value.role : null}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="secondary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// ==== THIS COMPONENT IS RESPONSIBLE FOR THE TOP BAR OF A PROJECT ITS THE MAIN COMPONENT ====
const TopProjectBar = (props) => {
  const {
    singleProject,
    showTabSelection,
    tab,
    users,
    showCollabModal,
    speedDialState,
  } = props;
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const { data } = singleProject;
  const { result } = data;
  const history = useHistory();
  // === STATE ===
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const displayTabName = () => {
    if (tab === "" || tab === "sum") {
      return "Project Summary";
    }

    if (tab === "des") {
      return "Project Description";
    }

    if (tab === "quick") {
      return "Project Quick Links";
    }

    if (tab === "goa") {
      return "Project goals";
    }

    if (tab === "ass") {
      return "Project Assumptions";
    }

    if (tab === "nee") {
      return "Project Needs";
    }

    if (tab === "gal") {
      return "Project Gallery";
    }

    if (tab === "dis") {
      return "Project Discussions";
    }

    if (tab === "top") {
      return "Project Top10 list";
    }

    if (tab === "sug") {
      return "Project Suggestion Box";
    }

    if (tab === "sub") {
      return "Project Sub-projects";
    }

    if (tab === "swo") {
      return "Project SWOT analysis";
    }
    if (tab === "bmc") {
      return "Project Business Model Canvas";
    }
    if (tab === "cal") {
      return "Project Calender";
    }
  };

  const navigateToHome = () => {
    history.push({
      pathname: `/dashboard`,
    });
  };

  const navigateToProjects = () => {
    history.push({
      pathname: `/projects`,
    });
  };

  const displayCollaborators = () => {
    if (users.length < 1) return "(0)";
    let collaboratorList = users.map((value, idx) => {
      return (
        <Avatar
          key={idx}
          alt={value !== null ? `${value.first_name} ${value.last_name}` : null}
          src={value !== null ? `${value.profile_avatar.url}` : null}
          sx={{ width: 38, height: 38 }}
          className="image_display"
        />
      );
    });

    return collaboratorList;
  };

  return (
    <>
      <ProjectCollaboratorsDisplay
        collaborator={users}
        open={open}
        handleClose={handleClose}
      />
      <AppBar
        position="static"
        color="transparent"
        className={styles.top_bar_container}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10">
              <div role="presentation" className={styles.bread_navigation}>
                <Breadcrumbs aria-label="breadcrumb">
                  <StyledBreadcrumb
                    component="a"
                    onClick={navigateToHome}
                    label="DashBoard"
                    icon={<HomeIcon fontSize="small" />}
                    className={styles.bread_project_name_one}
                  />
                  <StyledBreadcrumb
                    component="a"
                    icon={<SummarizeIcon fontSize="small" />}
                    onClick={navigateToProjects}
                    label="projects"
                    className={styles.bread_project_name_one}
                  />
                  <StyledBreadcrumb
                    label={`${result.project.name}`}
                    className={styles.bread_project_name}
                    icon={<DescriptionIcon fontSize="small" color="#000" />}
                  />
                  <StyledBreadcrumb
                    label={displayTabName()}
                    className={styles.bread_project_name_active}
                    icon={<CheckCircleIcon fontSize="small" color="#000" />}
                  />
                </Breadcrumbs>
              </div>
            </div>
            <div
              className={`col-md-2 col-12 ${styles.collaborators_container}`}
            >
              <div className="container-fluid">
                <div className="row">
                  {/* <div className="col-md-6">
                    <div className={styles.upvote_container}>
                      <div className={styles.upvote_items}>
                        {result.project.is_favorite ? (
                          <Button
                            variant="contained"
                            color="secondary"
                            endIcon={<StarPurple500Icon />}
                            onClick={() => handleUnfollowAction()}
                          >
                            Remove From favorites
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="secondary"
                            endIcon={<StarPurple500Icon />}
                            onClick={() => handleFollowAction()}
                          >
                            Add to favorite
                          </Button>
                        )}
                      </div>
                    </div>{" "}
                  </div> */}
                  <div className={styles.favorite_container}>
                    <div className={styles.favorite_container_items}>
                      <AddCircleIcon
                        className={styles.favorite_icon}
                        fontSize="large"
                        onClick={() => showCollabModal()}
                      />
                      <span>Collaborators</span>
                    </div>
                    <div className={styles.upvoted_by_container}>
                      <AvatarGroup max={2} onClick={() => handleClickOpen()}>
                        {displayCollaborators()}
                      </AvatarGroup>
                    </div>
                  </div>{" "}
                  {/* <==== Favorite container */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppBar>

      {/* ==== Display the component for the project name and collaborators ==== */}
      <ProjectNameAndCollaborators
        project={result.project}
        showTabSelection={showTabSelection}
        displayTabName={displayTabName}
        toggleProjectSpeedDialMenu={toggleProjectSpeedDialMenu}
      />
      {showTabSelection()}
    </>
  );
};

export default TopProjectBar;
