import React from "react";
import ProfileMVCard from "./ProfileMVCard";
import ProfileRecents from "./ProfileRecents";
import visionicon from "../../assets/vision-icon.png";
import missionicon from "../../assets/mission-icon.png";
import contacticon from "../../assets/contact-info-icon.png";
import SummaryContactCard from "./SummaryContactCard";
import ProfileIdeaCard from "./ProfileIdeaCard";
import { profileIdeas } from "../../API/profileData";

const ProfileSummary = ({ profile }) => {
  return (
    <>
      <div className="shadow-lg profile-summary">
        <div className="profile-summary-details">
          <ProfileMVCard
            icon={visionicon}
            title={"Vision"}
            details={profile.vision}
          />
          <ProfileMVCard
            icon={missionicon}
            title={"Mission"}
            details={profile.mission}
          />
          <SummaryContactCard
            icon={contacticon}
            title={"Contact Information"}
            address={profile.address}
            contactNo={profile.contact_no}
          />
        </div>
        <div className="profile-summary-feed">
          <ProfileRecents title={"Trending Projects"}>
            <p className="p-3">
              New Technology File System (NTFS) is a proprietary journaling file
              system developed by Microsoft.Starting with Windows NT 3.1,
              it is the default file system of the Windows NT family.It
              superseded File Allocation Table (FAT) as the preferred filesystem
              on Windows but is supported in Linux and BSD as well. NTFS reading
              and writing support is provided using a free and open-source
              kernel implementation known as NTFS3 in Linux and the NTFS-3G
              driver in BSD.Windows can convert FAT32/16/12 into NTFS
              without the need to rewrite all files.
            </p>
          </ProfileRecents>
          <ProfileRecents title={"My New Ideas"}>
            {profileIdeas.map((id, idx) => (
              <ProfileIdeaCard key={`ss${idx}`} idea={id.idea} date={id.date} />
            ))}
          </ProfileRecents>
        </div>
      </div>
    </>
  );
};

export default ProfileSummary;
