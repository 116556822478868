/*eslint-disable */
import React, { Fragment, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import eye from "../../images/eye.png";
import remix from "../../images/remix.png";
import share from "../../images/share.png";
import TopTenComments from "./TopTenComments";
import TopTenCommentsDisplay from "./TopTenCommentsDisplay";
import TopTenBanner from "./TopTenBanner";
import { useHistory } from "react-router-dom";
import _ from "lodash-contrib";
import { displayDate, displayTime } from "../../helpers/timeModule";

function TopTenShow() {
  const history = useHistory();
  //  ==== Number of comments
  const [commentsNumber, setCommentsNumber] = useState(0);

  const numberOfComments = (number) => {
    setCommentsNumber(number);
  };

  // ==== Get state data from params.
  const location = useLocation();
  const { catId, tId, eId } = useParams();
  const { object, creator, time } = location.state;

  // ==== Loop through the entries keys remove the NAME which is always a constant key.
  let objName = Object.keys(object);
  const dataArray = objName.filter((value) => {
    if (value !== "Name") {
      return value;
    }
  });

  const displayLinks = () => {
    const results = dataArray.map((value, index) => {
      const checkValue = object[value].toLowerCase();
      if (
        _.strContains(checkValue, "https") ||
        _.strContains(checkValue, "http")
      ) {
        return (
          <p className="container-fluid suggestion-purpose" key={index}>
            <span>Link to the article/video:</span>
            <a href={object[value]} target="_blank">
              {value}
            </a>
          </p>
        );
      }
    });

    return results;
  };

  // ===== Loop through the entries data and display the information given.
  const displayInfo = () => {
    const results = dataArray.map((value, index) => {
      const checkValue = object[value].toLowerCase();
      if (
        _.strContains(checkValue, "https") ||
        _.strContains(checkValue, "http")
      ) {
        if (_.strContains(object[value], "www")) {
          const url = _.replace(object[value], "www", "embed");
          return (
            <p className="container-fluid suggestion-purpose" key={index}>
              <iframe
                src={url}
                frameBorder="0"
                scrolling="no"
                allowFullScreen
                width="100%"
                height="450px"
              ></iframe>
            </p>
          );
        } else if (_.strContains(object[value], "embed")) {
          return (
            <p className="container-fluid suggestion-purpose" key={index}>
              <iframe
                src={object[value]}
                frameBorder="0"
                scrolling="no"
                allowFullScreen
              ></iframe>
            </p>
          );
        } else {
          <p className="container-fluid suggestion-purpose" key={index}>
            <a href={object[value]}>{value}</a>
          </p>;
        }
      } else {
        return (
          <p className="container-fluid suggestion-purpose" key={index}>
            {" "}
            <span>{value}:</span>
            {object[value]}
          </p>
        );
      }
    });

    return results;
  };
  // ==== Navigate to previous page
  const handleBack = () => {
    history.push(`/categories/${catId}/topten/${tId}/entries`);
  };

  return (
    <Fragment>
      <Navbar />
      <button
        className="btn btn-warning btn-sm back-button-show"
        onClick={handleBack}
      >
        back
      </button>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-xl-8">
            <div className="card tt-suggestion-card show-display-tt">
              <cite className="text-right">
                {" "}
                {displayDate(time).value}{" "}
                {displayTime(displayDate(time).unit, displayDate(time).value)}
              </cite>
              <div className="card-body container-fluid">
                <div className="tt-suggestion-card-title row">
                  <div className="col-md-10 col-sm-12 col-12 suggest-idea ">
                    <span> {object["Name"]}</span>
                  </div>
                </div>
                {displayInfo()}
                {displayLinks()}

                <cite className="text-left"> Created by: {creator}</cite>
                <div className="sb-like-comment-btn">
                  <button>
                    <i className="fas fa-heart text-danger"></i>&nbsp;&nbsp;{" "}
                    <span> {"X"} </span>
                  </button>
                  <button>
                    <i className="fa fa-commenting text-success"></i>
                    &nbsp;&nbsp;{" "}
                    <span>
                      {commentsNumber > 0
                        ? `${commentsNumber}`
                        : `${commentsNumber}`}
                    </span>
                  </button>

                  <button>
                    <img src={eye} alt="sb-like-btn" /> <span> 0 </span>
                  </button>

                  <button>
                    <img src={remix} alt="sb-like-btn" /> <span> 0 </span>
                  </button>

                  <button>
                    <img src={share} alt="sb-like-btn" />
                  </button>
                </div>
              </div>
              <hr />
              <TopTenComments categoryID={catId} topTenID={tId} id={eId} />
              <hr />
              <TopTenCommentsDisplay
                categoryID={catId}
                topTenID={tId}
                entry={eId}
                numberOfComments={numberOfComments}
                commentsNumber={commentsNumber}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-xl-4">
            <div className="show-display-tt text-center">
              <TopTenBanner />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TopTenShow;
/*eslint-disable */
