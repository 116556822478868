import React from "react";
import { Formik } from "formik";

import ViewPasswordBtn from "../ViewPasswordBtn";
import AppInputField from "./AppInputField";
import AppLabel from "./AppLabel";
import { signInSchema } from "./schema";
import { Link } from "react-router-dom";

function AppSignInForm({ logInUser, disable, loader, buttonLoader }) {
  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={(value) => logInUser(value)}
        validationSchema={signInSchema}
      >
        {({ handleSubmit }) => {
          return (
            <form className="w-75 mx-auto mt-3" onSubmit={handleSubmit}>
              <div className="form-group">
                <AppLabel>Email</AppLabel>
                <AppInputField
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  classname="form-control bg-light"
                />
              </div>
              <div className="form-group">
                <AppLabel>Password</AppLabel>
                <AppInputField
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  classname="form-control bg-light pwd"
                />
                <div className="w-100 text-right">
                  <ViewPasswordBtn />
                </div>
              </div>
              <div className="text-center m-3">
                <button
                  className="auth-btn-one mt-3"
                  type="submit"
                  disabled={disable}
                >
                  {loader === true ? buttonLoader() : `SIGN IN`}
                </button>
                <br />
                <Link
                  to={"/passwordresetrequest"}
                  className="text-danger text-center mt-3"
                >
                  Forgot your password?
                </Link>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default AppSignInForm;
