/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import ProjectTopTenEditModal from "./ProjectTopTenEditModal";
import { Button } from "@mui/material";
import { deleteTop10 } from "../../../services/ProjectTop10/projectTop10";
import { NotificationManager } from "react-notifications";
import parse from "html-react-parser";

const ProjectTopTenCard = ({
  pId,
  id,
  title,
  description,
  entries,
  setId,
  setTopTens,
  setFilteredList,
}) => {
  const [openEdit, setOpenEdit] = useState(false);

  const openEditModal = () => {
    setOpenEdit(true);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
  };

  const showEdit = () => {
    if (openEdit) {
      return (
        <ProjectTopTenEditModal
          isOpen={openEdit}
          closeEditModal={closeEditModal}
          name={title}
          desc={description}
          pId={pId}
          ttId={id}
          setTopTens={setTopTens}
          setFilteredList={setFilteredList}
        />
      );
    }
  };

  const handleTop10Delete = async () => {
    try {
      const { message } = await deleteTop10(pId, id);
      if (message === "Project Top10 deleted successfully")
        NotificationManager.success(`${message}`);
      window.location.reload();
      return;
    } catch (error) {
      NotificationManager.error("something went wrong. Top10 not deleted");
    }
  };

  return (
    <>
      {showEdit()}
      <div className="project-top-ten-card shadow-lg">
        <div className="project-top-ten-card-header">
          <h5 className="project-top-ten-card-header-text">{parse(title)}</h5>
        </div>
        <hr className="header-split" />
        <div className="project-top-ten-card-list">
          <div className="col w-50 position-well-items">
            {entries
              .filter((item, idx) => idx < 5)
              .map((i, ix) => (
                <h6 key={i.id} className="card-list-item">
                  {ix + 1}. {i.name}
                </h6>
              ))}
          </div>
          <div className="col w-50 position-well-items">
            {entries
              .filter((item, idx) => idx > 4 && idx <= 10)
              .map((i, ix) => (
                <h6 key={i.id} className="card-list-item">
                  {ix + 6}. {i.name}
                </h6>
              ))}
          </div>
        </div>
        <div className="project-top-ten-card-buttons">
          <button
            className="ptt-exp-btn"
            type="button"
            onClick={() => setId(id)}
          >
            Explore
          </button>

          <button
            className="ptt-edit-btn"
            type="button"
            onClick={openEditModal}
          >
            Edit
          </button>
          <Button variant="contained" color="error" onClick={handleTop10Delete}>
            Delete
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProjectTopTenCard;
