/*eslint-disable*/
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";

const AwardModal = ({ isOpen, closeModal, data, profileId,handleAssignBadge}) => {
  const [badgeId, setBadgeId] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (badgeId == "") {
      toast.warning("Please select a badge for this user");
    } else {
      const profile_badge_data = {
        profile_badge: {
          profile_id: profileId,
          badge_id: badgeId
        }
      }
      handleAssignBadge(profile_badge_data);
      setBadgeId("");
      closeModal();
    }
  };

  return (
    <>
      <ToastContainer />
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C" }}
          closeButton
        >
          <Modal.Title>Award Badge</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="users">Select Badge</label>
              <select
                className="form-control"
                name="users"
                onChange={(e) => setBadgeId(e.target.value)}
              >
                <option value="">None</option>
                {data.map((badge) => {
                  return (
                    <option key={badge.id} value={badge.id}>
                      {badge.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group float-right">
              <button className="add-btn rounded text-white" type="submit">
                Award
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(AwardModal);
