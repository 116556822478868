import { combineReducers } from "redux";
import categoriesReducer from "./category/categoriesReducer";
import commentsReducer from "./comments/commentReducer";
import entriesReducer from "./entries/entriesReducer";
import entryReducer from "./entry/sEntryReducer";
import fieldsReducer from "./fields/fieldReducer";
import notificationsReducer from "./notifications/notificationReducer";
import loggedInReducer from "./sessions/loggedInReducer";
import topTensReducer from "./topTens/topTensReducer";
import commentFetchReducer from "./comments/commentFetchReducer";
import suggestionReducer from "./suggestions/suggestionReducer";
import suggestionResponseReducer from "./suggestionsResponses/suggestionResponseReducer";
import commentResponseReducer from "./commentsResponses/commentResponseReducer";
import profileReducer from "./profile/profileReducer";
import corevalueReducer from "./coreValues/corevalueReducer";
import myNetworkReducer from "./MyNetwork/myNetworkReducer";
import goalsReducer from "./Goals/goalsReducer";
import inventoryReducer from "./inventories/inventoryReducer";
import legendReducer from "./legends/legendReducer";
import legendCategoryReducer from "./legendCategory/legendCategoryReducer";
import legendAssociationReducer from "./legendAssociation/legendAssociationReducer";
import projectReducer from "./projects/projectReducer";
import singleProjectReducer from "./singleProject/singleProjectReducer";
import passwordsReducer from "./passwords/passwordsReducer";
import projectCommentsReducer from "./projectComments/projectCommentReducer";
import ProjectCommentsResponseReducer from "./ProjectCommentsResponses/ProjectCommentsResponsesReducer";
import suggestionCategoriesReducer from "./suggestionCategory/suggestionCategoryReducer";
import projectNeedsReducer from "./projectNeeds/projectNeedsReducer";
import projectAssumptionsReducer from "./ProjectAssumptions/ProjectAssumptionsReducer";
import projectDiscussionReducer from "./projectDiscussions/ProjectDiscussionReducer";

const rootReducer = combineReducers({
  loggedIn: loggedInReducer,
  categories: categoriesReducer,
  topTens: topTensReducer,
  entries: entriesReducer,
  entry: entryReducer,
  fields: fieldsReducer,
  notifications: notificationsReducer,
  comments: commentsReducer,
  commentsFetch: commentFetchReducer,
  commentResponse: commentResponseReducer,
  suggestions: suggestionReducer,
  suggestionsResponseReply: suggestionResponseReducer,
  suggestionCategories: suggestionCategoriesReducer,
  profile: profileReducer,
  corevalues: corevalueReducer,
  mynetwork: myNetworkReducer,
  goals: goalsReducer,
  inventories: inventoryReducer,
  legends: legendReducer,
  legendCategory: legendCategoryReducer,
  legendAssociations: legendAssociationReducer,
  projects: projectReducer,
  singleProject: singleProjectReducer,
  password: passwordsReducer,
  projectComments: projectCommentsReducer,
  projectCommentsResponses: ProjectCommentsResponseReducer,
  projectNeeds: projectNeedsReducer,
  projectAssumptions: projectAssumptionsReducer,
  projectDiscussionReducer: projectDiscussionReducer,
});

export default rootReducer;
