import React from "react";
import styles from "./css/styles.module.css";
import NetworkCard from "./reusableComponents/NetworkCard";
import Alert from "@mui/material/Alert";
// --- lodash ---
import _ from "lodash";

const DesktopDisplay = ({ props }) => {
  const {
    networkData,
    setNetworkID,
    setAddProjectsToNetworkModal,
    searchValue,
  } = props;

  // ---- DISPLAY THE DATA -----

  const filterData = () => {
    if (searchValue === null) return networkData;

    return networkData.filter(
      (value) => value.name === searchValue.name && value
    );
  };

  const displayData = () => {
    if (_.isEmpty(networkData))
      return <Alert>There are no networks at the moment</Alert>;

    return filterData().map((value) => {
      return (
        <div className={`col-md-3 ${styles.card_container_sec}`} key={value.id}>
          <NetworkCard
            data={value}
            setNetworkID={setNetworkID}
            setAddProjectsToNetworkModal={setAddProjectsToNetworkModal}
          />
        </div>
      );
    });
  };

  return (
    <div className={`${styles.desktop_display} container-fluid`}>
      <div className="row">{displayData()}</div>
    </div>
  );
};

export default DesktopDisplay;
