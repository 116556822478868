import React from 'react'

function NoSwotsComponent({message, tryAgain, fetchProjectSwot, createNewProjectSort, loader, setModalShow}) {
  return (
   <div className='container-fluid mt-2'> 
        <div className='create-new-swort'>
            <div className='learn-button' onClick={() => setModalShow(true)}>
                <span> learn what is swot</span> <i className="fa fa-question-circle" aria-hidden="true"></i>
            </div>
            <button className="add-btn rounded text-white m-3 " onClick={() => createNewProjectSort()} disabled={loader} >
            Add new
            </button>
        </div>

        <div className='not-found-statement container'>
            <div className="alert alert-danger" role="alert">
            <i className="fa fa-frown-o" aria-hidden="true"></i> <h3>{message}</h3>  
            </div>
        </div>
        {
            tryAgain && <div>
            <button onClick={() => fetchProjectSwot()}>Try again</button>
           </div>
        }

   </div>
  )
}

export default NoSwotsComponent