import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_MYNETWORK_REQUEST, CREATE_MYNETWORK_SUCCESS, CREATE_MYNETWORK_FAILURE, FETCH_MYNETWORK_FAILURE, FETCH_MYNETWORK_SUCCESS, FETCH_MYNETWORK_REQUEST } from "./myNetworkTypes";

export const createMyNetworkRequest = () =>({
    type: CREATE_MYNETWORK_REQUEST,
  })
  
  export const createMyNetworkSuccess = (response) =>({
    type: CREATE_MYNETWORK_SUCCESS,
    payload: response
  })
  
  export const createMyNetworkFailure = (error) =>({
    type: CREATE_MYNETWORK_FAILURE,
    payload: error
  })

  export const fetchMyNetworkRequest = () =>({
    type: FETCH_MYNETWORK_REQUEST,
  })
  
  export const fetchMyNetworkSuccess = (response) =>({
    type: FETCH_MYNETWORK_SUCCESS,
    payload: response
  })
  
  export const fetchMyNetworkFailure = (error) =>({
    type: FETCH_MYNETWORK_FAILURE,
    payload: error
  })
  
export const fetchMyNetwork = (profileID) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchMyNetworkRequest());
    return axios.get(`${BASEURL}profiles/${profileID}/contacts`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchMyNetworkSuccess(response.data));
      }).catch(error => {
        return dispatch(fetchMyNetworkFailure(error));
      });
  }


  export const createMyNetwork = (profileID, data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createMyNetworkRequest());
    return axios.post(`${BASEURL}profiles/${profileID}/contacts`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    } )
      .then(response => {
        return dispatch(createMyNetworkSuccess(response.data));
      }).catch(error => {
        return dispatch(createMyNetworkFailure(error));
      });
  };
  