import { BASEURL } from "../../API/apiData";
import {bmcListsRoutes, bmcMainRoutes} from "./routes"

const getAllCustomerRelationsShips = async (projectID , bmcID) => {
  const token = localStorage.getItem("token");

    const requestOptions = {
        method: "GET",
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        },
    };
    const response = await fetch(
        `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.relationships}`,
        requestOptions
      );
      const results = await response.json();
      return results;
}

const createNewCustomerRelationShip = async (projectID, projectName, bmcID) => {
  const token = localStorage.getItem("token");

    const data = {
      customer_relationship_name: `${projectName} customer relationships`
    }
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      };
    const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.relationships}`,
    requestOptions
    );
    const results = await response.json();
    return results;
} 

// === LIST ITEMS ===
const createNewCustomerRelationShipList = async (projectID, bmcID, channelID, name ) => {
  const token = localStorage.getItem("token");

  const data = {
    customer_relationship_item: {
      item_name: name
    }
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.relationships}/${channelID}/${bmcListsRoutes.relationshipsItems}`,
    requestOptions
    );

const results = await response.json();
return results;

}

const editNewCustomerRelationShipList = async (projectID, bmcID, channelID, name, ID ) => {
  const token = localStorage.getItem("token");

const data = {
  customer_relationship_item: {
    item_name: name
  }
}

const requestOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(data),
};

const response = await fetch(
  `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.relationships}/${channelID}/${bmcListsRoutes.relationshipsItems}/${ID}`,
  requestOptions
  );

const results = await response.json();
return results;

}

export {getAllCustomerRelationsShips, createNewCustomerRelationShip, createNewCustomerRelationShipList, editNewCustomerRelationShipList }