/*eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";

import ProfileHeadDetails from "../components/profile/ProfileHeadDetails";
import ProfileSummary from "../components/profile/ProfileSummary";
import ProfileCoreValues from "../components/profile/ProfileCoreValues";
import ProfileNetwork from "../components/profile/ProfileNetwork";
import ProfileGoals from "../components/profile/ProfileGoals";
import ProfileBadges from "../components/profile/ProfileBadges";
import ProfileInventories from "../components/profile/ProfileInventories";
import EditProfile from "../components/EditProfile";
import ProfileNav from "../components/profile/ProfileNav";
import {
  checkLoggedInStatus,
  fetchProfile,
  updateProfile,
  createCoreValue,
  fetchCoreValue,
  fetchGoals,
  createGoals,
  createInventory,
  fetchInventory,
  createProject,
} from "../redux";
import _ from "lodash";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/profiles.css";
import "../stylesheets/projects.css";
import {
  createMyNetwork,
  fetchMyNetwork,
} from "../redux/MyNetwork/myNetworkActions";
import ProfileProjects from "../components/profile/ProfileProjects";
import ProfileSuggestions from "../components/profile/ProfileSuggestions";

const Profile = ({
  isLoggedIn,
  fetchProfile,
  profile,
  updateProfile,
  createCoreValue,
  fetchCoreValue,
  createGoals,
  fetchGoals,
  createMyNetwork,
  createProject,
  fetchMyNetwork,
}) => {
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [tabNo, setTabNo] = useState(0);
  const [coreValues, setCoreValues] = useState({});
  const [goals, setGoals] = useState({});
  const [myNetwork, setMyNetwork] = useState({});
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    isLoggedIn().then((res) => {
      setId(res.payload.profile_id);
      fetchProfile(res.payload.profile_id)
        .then((res) => {
          setProjects(res.payload.projects);
          fetchCoreValue(res.payload.profile.id).then((results) => {
            setCoreValues(results.payload);
          });
          fetchGoals(res.payload.profile.id).then((results) => {
            setGoals(results.payload);
          });
          fetchMyNetwork(res.payload.profile.id).then((results) => {
            setMyNetwork(results.payload);
          });
        })
        .catch((e) => "Internal Server Error");
    });
  }, [isLoggedIn, fetchProfile, fetchCoreValue, fetchGoals, fetchMyNetwork]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleCoreValue = (profileCoreValue) => {
    createCoreValue(id, profileCoreValue).then((res) => {
      fetchCoreValue(id).then((results) => {
        setCoreValues(results.payload);
      });
    });
  };

  const handleGoal = (goal) => {
    createGoals(id, goal).then((res) => {
      fetchGoals(id).then((results) => {
        setGoals(results.payload);
      });
    });
  };

  const handleNetwork = (contact) => {
    createMyNetwork(id, contact).then((res) => {
      fetchMyNetwork(id).then((results) => {
        setMyNetwork(results.payload);
      });
    });
  };

  const handleCreateProject = (data) => {
    createProject(data)
      .then((res) => {
        toast.success(res.payload.message);
        fetchProfile(id)
          .then((res) => {
            setProjects(res.payload.projects);
          })
          .catch((e) => "Internal Server Error");
      })
      .catch((e) => console.log("Internal Server Error"));
  };

  const showTab = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;
      switch (tabNo) {
        case 0:
          return <ProfileSummary profile={res} />;
        case 1:
          return (
            <ProfileCoreValues
              data={coreValues}
              handleCoreValueSumbit={handleCoreValue}
              privacy={true}
            />
          );
        case 2:
          return (
            <ProfileNetwork
              myNetwork={myNetwork}
              handleNetworkSubmit={handleNetwork}
              privacy={true}
            />
          );
        case 3:
          return (
            <ProfileGoals
              id={id}
              goals={goals}
              handleGoalSubmit={handleGoal}
              privacy={true}
            />
          );
        case 4:
          return <ProfileBadges userId={res.id} />;
        case 5:
          return <ProfileInventories id={id} privacy={true} />;
        case 6:
          return (
            <ProfileProjects
              projects={projects}
              privacy={true}
              projectCategories={profile.data.project_categories}
              handleCreateProject={handleCreateProject}
            />
          );
        case 7:
          return <ProfileSuggestions  profile={profile.data} />;
        default:
          return <ProfileSummary profile={res} />;
      }
    }
  };

  const showData = () => {
    if (!_.isEmpty(profile.data)) {
      const res = profile.data.profile;
      return (
        <>
          <div className="profile-header">
            <div className="profile-header-cont">
              <ProfileHeadDetails
                avatar={res.profile_avatar.url}
                fName={res.first_name}
                lName={res.last_name}
                address={res.address}
                role={res.role}
                openModal={openModal}
              />
              <EditProfile
                isOpen={open}
                closeModal={closeModal}
                currentUser={profile.data.profile}
                updateProfile={updateProfile}
              />
            </div>
            <div className="profile-header-cont-2 edit-area">
              <button className="prof-edit-btn" onClick={openModal}>
                <i className="fas fa-pen"></i>&nbsp;Edit Profile
              </button>
              <button className="dots-temp-btn">
                <i className="fas fa-ellipsis-h fa-2x"></i>
              </button>
            </div>
          </div>
          <div className="profile-main">
            <ProfileNav setTabNo={setTabNo} />
            {showTab()}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      {showData()}
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

const mapDispatchToProps = (dispatch) => ({
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
  fetchProfile: (id) => dispatch(fetchProfile(id)),
  updateProfile: (id, data) => dispatch(updateProfile(id, data)),
  createCoreValue: (id, data) => dispatch(createCoreValue(id, data)),
  fetchCoreValue: (id) => dispatch(fetchCoreValue(id)),
  createGoals: (id, data) => dispatch(createGoals(id, data)),
  fetchGoals: (id) => dispatch(fetchGoals(id)),
  createInventory: (id, data) => dispatch(createInventory(id, data)),
  fetchInventories: (id) => dispatch(fetchInventory(id)),
  createMyNetwork: (id, data) => dispatch(createMyNetwork(id, data)),
  fetchMyNetwork: (id) => dispatch(fetchMyNetwork(id)),
  createProject: (data) => dispatch(createProject(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
