import React, { useState } from 'react'
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "react-bootstrap/Modal";

const UpdateModal = ({isOpen,closeModal,handleCreate}) => {

  const [title, setTitle] = useState("")
  const [details, setDetails] = useState("")
  const [complete, setComplete] = useState(false)
 
  const handleSubmit = (e) => {
    e.preventDefault()
    const data = {
      update_notice: {
        update_title: title,
        update_details: details,
        is_complete: complete
      }
    }
    handleCreate(data)
    setTitle("")
    setDetails("")
    setComplete(false)
    closeModal()
  }

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>Add Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setTitle(e.target.value)}
              placeholder="your update title here"
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Details</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={details}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDetails(data);
              }}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="title">Has this update been complete?</label>
            <input
              className='ml-3 mt-1'
              type="checkbox"
              onChange={(e) => setComplete(e.target.checked)}
            />
          </div>
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              Add
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateModal