/*eslint-disable */
import { Tooltip } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmModal from "../reusablecomponents/ConfirmModal";

const UserRoleTableRow = ({
  data,
  idx,
  authId,
  openModal,
  setRoleId,
  dropRole,
}) => {
  const handleRole = () => {
    openModal();
    setRoleId(data.id);
  };

  const handleRequest = () => {
    toast.success("Your request has been submitted successfully");
  };

  const deleteRole = () => {
    const xData = {
      user_id: null,
      is_vacant: true,
    };
    if (confirm("Are you sure you want to make this role vacant?")) {
      dropRole(data.id, xData);
      toast.success("Role has been dropped and is now vacant");
    }
  };

  return (
    <>
      <tr>
        <td>{data.sub_role_name}</td>
        <td>
          {data.user ? (
            <>
              {data.user.profile_avatar.url != null ? (
                <Link
                  className="dec-na"
                  key={data.id}
                  to={`/profile/${data.user.id}`}
                >
                  <img
                    className="profile-avatar-thumb-xs"
                    src={data.user.profile_avatar.url}
                    alt="profile-avatar"
                  />
                  &nbsp; &nbsp;
                  {`${data.user.first_name} ${data.user.last_name}`}
                </Link>
              ) : (
                <Link
                  className="dec-na"
                  key={data.id}
                  to={`/profile/${data.user.id}`}
                >
                  <span className="profile-avatar-badge">{`${data.user.first_name[0]}${data.user.last_name[0]}`}</span>
                  &nbsp;&nbsp;
                  {`${data.user.first_name} ${data.user.last_name}`}
                </Link>
              )}
            </>
          ) : (
            "NOT ASSIGNED"
          )}
        </td>
        <td className="text-center">
          {data.is_vacant ? (
            <i className="fa fa-check text-success" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-times text-danger" aria-hidden="true"></i>
          )}
        </td>
        <td className="text-center">
          {data.is_vacant && authId !== 1 ? (
            <Tooltip interactive className="toolTip" title="Request Assignment">
              <button
                className="asgn-btn"
                type="button"
                onClick={handleRequest}
              >
                <i className="fa fa-briefcase" aria-hidden="true"></i>
              </button>
            </Tooltip>
          ) : null}
          &nbsp; &nbsp;
          {data.is_vacant ? (
            <>
              {authId === 1 ? (
                <Tooltip interactive className="toolTip" title="Assign Role">
                  <button
                    className="asgn-btn"
                    type="button"
                    onClick={handleRole}
                  >
                    <i
                      className="fa fa-plus text-success"
                      aria-hidden="true"
                    ></i>
                  </button>
                </Tooltip>
              ) : null}
            </>
          ) : (
            <>
              {data.current_user_id == data.user.user_id || authId == 1 ? (
                <Tooltip interactive className="toolTip" title="Drop role">
                  <button
                    className="asgn-btn"
                    type="button"
                    onClick={deleteRole}
                  >
                    <i
                      className="fa fa-minus text-danger"
                      aria-hidden="true"
                    ></i>
                  </button>
                </Tooltip>
              ) : null}
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default UserRoleTableRow;
