const OpenItemPriority = [

    {
        level: 1,
        name: "oi1 today (needs immediate attention)" 
    },
    {
        level: 2,
        name: "oi2 soon (keep an eye on them)  "
    },
    {
        level: 3,
        name: "oi3 weekly type batch"
    },
    {
        level: 4,
        name: "oi4 monthly type batch"
    },
    {
        level: 5,
        name: "oi5 yearly batch / Project Batch"
    },
    {
        level: 6,
        name: "oi6 (someday) updates / reports"
    },
]

export  default OpenItemPriority