import React, {useState} from 'react'

const ProfileNetworkForm = ({showForm,handleSubmit}) => {

  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const profileMyNetwork = {
      name: name,
      role: role,
      email: email,
      website: website,
    };
    handleSubmit(profileMyNetwork)
    setName("")
    setRole("")
    setEmail("")
    setWebsite("")
    showForm();
  };

  return (
    <>
      <form className="border p-3 rounded" onSubmit={handleFormSubmit}>
        <div className="d-flex flex-row">
          <label htmlFor="name">
            <i className="fa fa-id-card text-info fa-2x mt-1" aria-hidden="true"></i>
          </label>
          &nbsp;&nbsp;
          <input
              className="form-control"
              type="text"
              name="name"
              onChange={e => setName(e.target.value)}
              placeholder="Enter contact name here"
            />
        </div>
        <div className="d-flex flex-row">
          <label htmlFor="role">
            <i className="fa fa-user text-info fa-2x mt-1" aria-hidden="true"></i>
          </label>
          &nbsp;&nbsp;
          <input
              className="form-control ml-2"
              type="text"
              name="role"
              onChange={e => setRole(e.target.value)}
              placeholder="Enter contact role here"
            />
        </div>
        <div className="d-flex flex-row">
          <label htmlFor="email">
            <i className="fa fa-envelope text-info fa-2x mt-1" aria-hidden="true"></i>
          </label>
          &nbsp;&nbsp;
          <input
              className="form-control ml-1"
              type="text"
              name="email"
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter contact email here"
            />
        </div>
        <div className="d-flex flex-row">
          <label htmlFor="website">
            <i className="fas fa-globe text-info fa-2x mt-1" aria-hidden="true"></i>
          </label>
          &nbsp;&nbsp;
          <input
              className="form-control ml-1"
              type="url"
              name="website"
              onChange={e => setWebsite(e.target.value)}
              placeholder="Enter contact website here"
            />
        </div>
        <div className="mt-3 text-right">
          <button className="add-btn rounded text-white" type="submit">Add Contact</button>
        </div>
      </form>
    </>
  )
}

export default ProfileNetworkForm
