// ==== THIS FILES HANDLES QUERIES FOR ALL NETWORK CONTAINER ====
import { useQuery } from "react-query";
import {
  getAllNetworks,
  getAllProjectsForNetwork,
  getShowNetworkPageData,
} from "../../services/NetworksService";

// --- THIS FETCHES ALL THE NETWORKS ----
const UseFetchAllNetworks = () => {
  const fetchAllNetworks = async () => {
    const { result } = await getAllNetworks();
    return result;
  };
  return useQuery({
    queryKey: ["networks"],
    queryFn: fetchAllNetworks,
  });
};

const UseFetchAllProjectsForNetwork = (networkId) => {
  const fetchAllProjectsForNetwork = async () => {
    const { result } = await getAllProjectsForNetwork(networkId);
    return result;
  };
  return useQuery({
    queryKey: ["networks", "project_networks", networkId],
    queryFn: fetchAllProjectsForNetwork,
  });
};

const UseFetchNetworkShowPage = (pageID) => {
  const fetchNetworkShowPage = async () => {
    const { result } = await getShowNetworkPageData(pageID);
    return result;
  };
  return useQuery({
    queryKey: ["networks", pageID],
    queryFn: fetchNetworkShowPage,
  });
};

export {
  UseFetchAllNetworks,
  UseFetchAllProjectsForNetwork,
  UseFetchNetworkShowPage,
};
