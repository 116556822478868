import axios from "axios";
import { BASEURL } from "../../API/apiData";
import {FETCH_LEGEND_REQUEST,FETCH_LEGEND_SUCCESS, 
  FETCH_LEGEND_FAILURE,CREATE_LEGEND_FAILURE,
  CREATE_LEGEND_SUCCESS,CREATE_LEGEND_REQUEST,EDIT_LEGEND_FAILURE,
  EDIT_LEGEND_SUCCESS,EDIT_LEGEND_REQUEST,
  FETCH_S_LEGEND_REQUEST,FETCH_S_LEGEND_SUCCESS,FETCH_S_LEGEND_FAILURE} from "./legendTypes";


export const fetchLegendsRequest = () => ({
  type: FETCH_LEGEND_REQUEST,
});

export const fetchLegendsSuccess = (response) => ({
  type: FETCH_LEGEND_SUCCESS,
  payload: response,
});

export const fetchLegendsFailure = (error) => ({
  type: FETCH_LEGEND_FAILURE,
  payload: error,
});

export const createLegendRequest = () => ({
  type: CREATE_LEGEND_REQUEST,
});

export const createLegendSuccess = (response) => ({
  type: CREATE_LEGEND_SUCCESS,
  payload: response,
});

export const createLegendFailure = (error) => ({
  type: CREATE_LEGEND_FAILURE,
  payload: error,
});

export const editLegendRequest = () => ({
  type: EDIT_LEGEND_REQUEST,
});

export const editLegendSuccess = (response) => ({
  type: EDIT_LEGEND_SUCCESS,
  payload: response,
});

export const editLegendFailure = (error) => ({
  type: EDIT_LEGEND_FAILURE,
  payload: error,
});

export const fetchSLegendRequest = () => ({
  type: FETCH_S_LEGEND_REQUEST,
});

export const fetchSLegendSuccess = (response) => ({
  type: FETCH_S_LEGEND_SUCCESS,
  payload: response,
});

export const fetchSLegendFailure = (error) => ({
  type: FETCH_S_LEGEND_FAILURE,
  payload: error,
});

export const createLegend = (data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(createLegendRequest());
  return axios
    .post(`${BASEURL}legends`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(createLegendSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(createLegendFailure(error));
    });
};

export const editLegend = (id,data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(editLegendRequest());
  return axios
    .put(`${BASEURL}legends/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(editLegendSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(editLegendFailure(error));
    });
};

export const fetchLegends = () => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchLegendsRequest());
  return axios
    .get(`${BASEURL}legends`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchLegendsSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchLegendsFailure(error));
    });
};

export const fetchSLegend = (id) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchSLegendRequest());
  return axios
    .get(`${BASEURL}legends/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchSLegendSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchSLegendFailure(error));
    });
};
