import React, { useEffect, useState } from "react";
import styles from "../cssModules/main.module.css";
import TopBar from "../globals/TopBar";
import BottomBar from "./BottomBar";
import ListUpdates from "../globals/ListUpdates";
import listStyles from "../cssModules/ListUpdate.module.css";
import FilterComponent from "../globals/FilterComponent";
import Alert from "@mui/material/Alert";

function MobileDisplayUpdates({
  updates,
  updateInfo,
  setUpdateInfo,
  handleEdit,
  toggleDrawer,
  setCommentModal,
  selection,
  createUpdateModalOpen,
  setEditModal,
  setEditData,
}) {
  const [filterdUpdates, setFilteredUpdates] = useState([]);

  useEffect(() => {
    if (updates != null) {
      if (selection.name === "Completed Updates")
        return setFilteredUpdates([...updates.complete]);
      if (selection.name === "Upcoming Updates")
        return setFilteredUpdates([...updates.upcoming]);
      if (selection.name === "Bug Tracker") return setFilteredUpdates([]);
    }
    return () => {
      setFilteredUpdates([]);
    };
  }, [updates, selection.name]);

  const displayUpdates = () => {
    if (filterdUpdates.length >= 1) {
      const result = filterdUpdates.map((update) => (
        <div className="col-md-6 col-sm-12" key={update.id}>
          <ListUpdates
            setCommentModal={setCommentModal}
            update={update}
            setEditModal={setEditModal}
            setEditData={setEditData}
          />
        </div>
      ));
      return result;
    } else {
      return <Alert severity="info">There are no items on this list.</Alert>;
    }
  };

  return (
    <div className={styles.mobile_view}>
      <TopBar selection={selection} />
      <FilterComponent />
      <div className={`${listStyles.list_item_container} container-fluid`}>
        <div className="row">{displayUpdates()}</div>
      </div>
      <BottomBar
        toggleDrawer={toggleDrawer}
        createUpdateModalOpen={createUpdateModalOpen}
        selection={selection}
      />
    </div>
  );
}

export default MobileDisplayUpdates;
