import React, {useState} from 'react'
import Modal from "react-bootstrap/Modal";
import { createSpecificSwotDetail } from '../../../services/swotAnalysis/swotDetails';
import _ from "lodash"
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function CreateNewSwotModal({swotName, swotTitleDetail, projectID, swotID,  dynamicRoute, setSwotDetails, fetchSwotSpecificDetails, ...otherProps}) {
    const [name, setName] = useState("")

    const createNewListItem = async(e) => {
        e.preventDefault()
        let titleName = swotTitleDetail.toLowerCase()
        var data = {}
        data[`${titleName}_name`] = name

       const {result} = await createSpecificSwotDetail(projectID, swotID, dynamicRoute, data)
       if(!_.isEmpty(result)) {
        fetchSwotSpecificDetails(dynamicRoute)
        otherProps.onHide()
        setName("")
       }
    }
    
  return (
    <Modal
      {...otherProps}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         Add a new  {swotTitleDetail} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={createNewListItem}>
        <div className="form-group">
            <label for="exampleInputEmail1"> {swotTitleDetail}</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={name}
              onChange={(event, editor) => {
                const data = editor.getData();
                setName(data);
              }}
              required
            />
        </div>
        <button  className="create-project-btn m-3 btn">Create</button>
      </form>
      </Modal.Body>
    </Modal>
  )
}

export default CreateNewSwotModal