/*eslint-disable */
import React, { useEffect, useState } from "react";
import nocores from "../../assets/nocorevalues.png";
import { toast } from "react-toastify";
import _ from "lodash";
import "../../stylesheets/masterNeeds.css";
import "../../stylesheets/library.css";
import LibraryCard from "../library/LibraryCard";
import {
  createMasterInventory,
  updateMasterInventory,
} from "../../services/library/libraryService";
import LibraryModal from "../library/LibraryModal";

import { fetchProfileInventory } from "../../services/profile/profileService";
import { fetchLibraryCategories } from "../../services/library/libraryService";

const ProfileInventories = ({ id, privacy = false }) => {
  const [open, setOpen] = useState(false);
  const [inventories, setInventories] = useState([]);
  const [categories, setCategories] = useState([]);

  const getInventory = async () => {
    try {
      const data = await fetchProfileInventory(id);
      setInventories(data.result.inventories);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      const data = await fetchLibraryCategories();
      setCategories(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const createInventory = async (data) => {
    try {
      await createMasterInventory(data);
      getInventory();
      toast.success("List added successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const updateInventory = async (id, data) => {
    try {
      await updateMasterInventory(id, data);
      getInventory();
      toast.warning("List updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInventory();
    getCategories();
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleUpdateInventory = (id, data) => {
    updateInventory(id, data);
  };

  const handleCreateInventory = (data) => {
    createInventory(data);
  };

  const showInventory = () => {
    if (!_.isEmpty(inventories)) {
      return inventories.map((inv, idx) => (
        <LibraryCard
          key={inv.inventory.id}
          idx={idx}
          data={inv}
          master={false}
          priv={privacy}
          categories={categories}
          handleSubmit={handleUpdateInventory}
        />
      ));
    } else if (_.isEmpty(inventories.result)) {
      return (
        <div className="w-100 text-center">
          <img className="no-cores-thumbnail" src={nocores} alt="no-cv" />
          <h4 className="text-dark">{inventories.message}</h4>
        </div>
      );
    }
  };

  return (
    <>
      {" "}
      <LibraryModal
        data={null}
        category={1}
        categories={categories}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleCreateInventory}
        master={false}
      />
      <div className="master-need-container mv">
        {privacy ? (
          <>
            <div className="master-needs-header">
              <h3 className="app-head-title ml-1">My Inventory</h3>
              <button type="button" onClick={openModal}>
                Add New
              </button>
            </div>
          </>
        ) : null}
        <div className="master-need-card-container">{showInventory()}</div>
      </div>
    </>
  );
};

export default ProfileInventories;
