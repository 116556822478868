import React, { Fragment, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
// ==== MATERIAL UI ====
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import AttachEmailTwoToneIcon from "@mui/icons-material/AttachEmailTwoTone";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import SendIcon from "@mui/icons-material/Send";
// ==== Styles ====
import styles from "./shareDiscussionModal.module.css";
import { Button, Chip } from "@mui/material";
import ProjectInvitation from "../projectDiscussion/ProjectInvitation";
import { createNewDiscussionShare } from "../../../services/projects/ProjectDiscussionService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const filter = createFilterOptions();
const ShareDiscussionModal = ({
  openClose,
  closeCommentModal,
  singleProject,
  profiles,
  selected,
  fetchProjectDiscussion,
  setProjectDiscussion,
  projectID,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("profiles");
  const [searchValue, setSearchValue] = useState(null);

  useEffect(() => {
    if (openClose) return setOpen(true);

    return setOpen(false);
  }, [openClose]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    closeCommentModal();
  };

  const findDifferentObjects = (
    array1 = profiles,
    array2 = selected.shared_with
  ) => {
    let differences = [];
    // Loop through the first array of objects
    for (let i = 0; i < array1.length; i++) {
      // Get the 'id' value of the current object
      let id = array1[i].id;
      // Check if the 'id' value of the current object in the second array matches
      let match = array2.find((obj) => obj.profile.id === id);
      // If there is no match, add the object to the differences array
      if (!match) {
        differences.push(array1[i]);
      }
    }
    // Return the differences array
    return differences;
  };

  const handleShareDiscussion = async (obj) => {
    console.log(obj);
    const { user_id } = obj;
    const { id } = selected;
    console.log(selected);

    const data = {
      share: {
        user_id: user_id,
        sharable_id: id,
        sharable_type: "Discussion",
      },
    };
    const { message } = await createNewDiscussionShare(data);
    if (message === "share successful.") {
      NotificationManager.success(`${message}`);
      const { payload } = await fetchProjectDiscussion(projectID);
      setProjectDiscussion(payload.result);
      handleClose();
      return;
    }
    NotificationManager("Something went wrong kindly try again");
  };

  const DisplayProfile = () => {
    const showProfileList = () => {
      if (searchValue !== null) {
        return (
          <Fragment>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: deepOrange[500] }}
                  alt={searchValue.first_name}
                  src={
                    searchValue.profile_avatar && searchValue.profile_avatar.url
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${searchValue.first_name} ${searchValue.last_name}`}
                className={searchValue.list_item_list}
              />

              <div className={`form-check ${styles.check_box}`}>
                <Button
                  size="small"
                  endIcon={<SendIcon />}
                  color="secondary"
                  variant="outlined"
                  onClick={() => handleShareDiscussion(searchValue)}
                >
                  share
                </Button>
              </div>
            </ListItem>
          </Fragment>
        );
      }

      if (selected.shared_with.length >= 1) {
        const results = findDifferentObjects().map((value, idx) => {
          return (
            <Fragment key={idx}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: deepOrange[500] }}
                    alt={value.first_name}
                    src={value.profile_avatar && value.profile_avatar.url}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${value.first_name} ${value.last_name}`}
                  className={styles.list_item_list}
                />

                <div className={`form-check ${styles.check_box}`}>
                  <Button
                    size="small"
                    endIcon={<SendIcon />}
                    color="secondary"
                    variant="outlined"
                    onClick={() => handleShareDiscussion(value)}
                  >
                    share
                  </Button>
                </div>
              </ListItem>
            </Fragment>
          );
        });

        return results;
      }
      const results = profiles.map((value, idx) => {
        return (
          <Fragment key={idx}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: deepOrange[500] }}
                  alt={value.first_name}
                  src={value.profile_avatar && value.profile_avatar.url}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${value.first_name} ${value.last_name}`}
                className={styles.list_item_list}
              />

              <div className={`form-check ${styles.check_box}`}>
                <Button
                  size="small"
                  endIcon={<SendIcon />}
                  color="secondary"
                  variant="outlined"
                  onClick={() => handleShareDiscussion(value)}
                >
                  share
                </Button>
              </div>
            </ListItem>
          </Fragment>
        );
      });

      return results;
    };

    return (
      <Card
        sx={{ minWidth: 400, width: 400, minHeight: 430 }}
        className={styles.profile_display_container_card}
      >
        <CardContent
          className={styles.profile_display_container_card_content_area}
        >
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            <Autocomplete
              value={searchValue}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setSearchValue({
                    first_name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setSearchValue({
                    first_name: newValue.inputValue,
                  });
                } else {
                  setSearchValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.first_name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    first_name: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={
                selected.shared_with.length >= 1
                  ? findDifferentObjects()
                  : profiles
              }
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.first_name;
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  {option.first_name} {option.last_name}{" "}
                </li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Search by first name" />
              )}
            />
          </Typography>
          <div className={styles.profiles}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {showProfileList()}
            </List>
          </div>
        </CardContent>
      </Card>
    );
  };

  const displayContent = () => {
    if (value === "profiles") return <DisplayProfile />;
    else if (value === "email")
      return <ProjectInvitation singleProject={singleProject} />;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle className={styles.main_title_area}>
        Share Discussion with:
        <div className={styles.close_icon}>
          <HighlightOffTwoToneIcon fontSize="large" onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent className={styles.main_content_area}>
        <div className={styles.main_navigation_container}>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab
                value="profiles"
                label={
                  <Chip label="Profiles" icon={<PeopleAltTwoToneIcon />} />
                }
              />
              <Tab
                value="email"
                label={
                  <Chip label="New User" icon={<AttachEmailTwoToneIcon />} />
                }
              />
            </Tabs>
          </Box>
        </div>

        <div className={styles.main_content_display_area}>
          {displayContent()}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDiscussionModal;
