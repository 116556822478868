import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Alert, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

function InvitationForm({ setEmail, email, handleCreateInvitation, loader }) {
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.checkValidity()) {
      handleCreateInvitation();
    } else {
      setError(
        "Please fill out all fields and ensure you add in a correct email address"
      );
    }
  };
  return (
    <>
      {error && <Alert severity="error"> {error}</Alert>}

      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1 },
          width: "100%",
          display: "flex",
        }}
        autoComplete="off"
        onSubmit={handleSubmit}
        noValidate
      >
        <TextField
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required
          label="New user Email"
          sx={{
            width: "400px",
          }}
          color="secondary"
          focused
          inputProps={{
            type: "email",
          }}
        />
        <Button
          disabled={loader}
          variant="contained"
          color="secondary"
          type="submit"
          endIcon={<SendIcon />}
          sx={{
            height: "55px",
            marginTop: "8px",
            marginLeft: "20px",
            marginBottom: "8px",
          }}
        >
          {loader ? "sending..." : " send invite"}
        </Button>
      </Box>
    </>
  );
}

export default InvitationForm;
