import React from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import AllOutIcon from "@mui/icons-material/AllOut";
import BackpackIcon from "@mui/icons-material/Backpack";
import ForumIcon from "@mui/icons-material/Forum";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import FolderIcon from "@mui/icons-material/Folder";
import MailIcon from "@mui/icons-material/Mail";

const AppRightDrawer = ({
  rightDrawer,
  setRightDrawer,
  setTopProjectDrawer,
  setMobileChoice,
}) => {
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setRightDrawer(false);
  };

  const displayIcons = (name) => {
    switch (name) {
      case "Summary":
        return <SummarizeIcon />;
      case "Description":
        return <TextSnippetIcon />;
      case "Goals":
        return <FlagCircleIcon />;
      case "Assumptions":
        return <AllOutIcon />;
      case "Needs":
        return <BackpackIcon />;
      case "Discussion":
        return <ForumIcon />;
      case "Top10":
        return <FormatListNumberedIcon />;
      case "Suggestion_Box":
        return <MarkChatReadIcon />;
      case "Sub_projects":
        return <AccountTreeIcon />;
      case "Swot":
        return <NoteAltIcon />;
      case "Bmc":
        return <FolderIcon />;
      default:
        return <MailIcon />;
    }
  };

  const checkClicked = (name) => {
    switch (name) {
      case "Summary":
        return setMobileChoice("");
      case "Description":
        return setMobileChoice("des");
      case "Goals":
        return setMobileChoice("goa");
      case "Assumptions":
        return setMobileChoice("ass");
      case "Needs":
        return setMobileChoice("nee");
      case "Discussion":
        return setMobileChoice("dis");
      case "Top10":
        return setMobileChoice("top");
      case "Suggestion_Box":
        return setMobileChoice("sug");
      case "Sub_projects":
        return setMobileChoice("sub");
      case "Swot":
        return setMobileChoice("swo");
      case "Bmc":
        return setMobileChoice("bmc");
      default:
        return setMobileChoice("sum");
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Summary", "Description", "Goals", "Assumptions"].map(
          (text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  checkClicked(text);
                  setTopProjectDrawer(true);
                }}
              >
                <ListItemIcon>{displayIcons(text)}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      <Divider />
      <List>
        {[
          "Needs",
          "Discussion",
          "Top10",
          "Suggestion_Box",
          "Sub_projects",
          "Swot",
          "Bmc",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={() => {
                checkClicked(text);
                setTopProjectDrawer(true);
              }}
            >
              <ListItemIcon>{displayIcons(text)}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={rightDrawer}
            onClose={toggleDrawer(false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default AppRightDrawer;
