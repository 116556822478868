import React from "react";
import avatar from "../../images/avatar.jpeg";

const UserCard = ({ data, showAwardModal, setUserId }) => {
  const handleShowModal = () => {
    showAwardModal();
    setUserId(data.id);
  };
  return (
    <>
      <div className="user-card">
        <div>
          <img
            className="profile-avatar-thumb-xs"
            src={data.profile_avatar.url ? data.profile_avatar.url : avatar}
            alt="avatar"
          />
          &nbsp; &nbsp;
          {`${data.first_name} ${data.last_name}`}
        </div>
        <div>
          <button
            type="button"
            className="app-add-btn-2"
            onClick={handleShowModal}
          >
            Award Badge
          </button>
        </div>
      </div>
    </>
  );
};

export default UserCard;
