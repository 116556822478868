/*eslint-disable */
import React, { useState } from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "react-bootstrap/Modal";

const LibraryItemModal = ({
  invId,
  data,
  isOpen,
  closeModal,
  handleSubmit,
}) => {
  const [name, setName] = useState(data == null ? "" : data.name);
  const [specs, setSpecs] = useState(data == null ? "" : data.specs);
  const [inventoryId, setInventoryId] = useState(
    invId == null ? data.tuc_inventory_id : invId
  );
  const [statusId, setStatusId] = useState(
    data == null ? 1 : data.tuc_inventory_item_status_id
  );
  const [image, setImage] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("tuc_inventory_item[name]", name);
    formData.append("tuc_inventory_item[specs]", specs);
    if (image !== null) {
      formData.append("tuc_inventory_item[image]", image);
    }
    formData.append("tuc_inventory_item[tuc_inventory_item_status_id]", 1);
    formData.append("tuc_inventory_item[tuc_inventory_id]", inventoryId);
    if (data == null) {
      handleSubmit(formData);
    } else {
      handleSubmit(data.id, formData);
    }
    if (data == null) {
      setName("");
      setSpecs("");
      setImage(null);
    }
    closeModal();
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>{data == null ? "Add New Item" : "Edit Item"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={name}
              place="category name here"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="description">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={specs}
              onChange={(event, editor) => {
                const data = editor.getData();
                setSpecs(data);
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="image">Upload image</label>
            <br />
            <input
              type="file"
              name="idea"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              {data == null ? "Add" : "Edit"}
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
};

export default LibraryItemModal;
