/*eslint-disable*/
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import noImage from "../../images/no-image.jpeg";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import idea from "../../images/idea.png";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import parse from "html-react-parser";
import ArticleIcon from "@mui/icons-material/Article";
import TagBadge from "../reusablecomponents/TagBadge";
import Button from '@mui/material/Button';
import AddLinkIcon from '@mui/icons-material/AddLink';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function SuggestionCard({ suggestion, handlePageNavigation, showProjectModal }) {

  const imageArr = [
    suggestion.sug_img_one.url,
    suggestion.sug_img_two.url,
    suggestion.sug_img_three.url,
    suggestion.sug_img_four.url,
  ];


  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const displayDate = (value) => {
    const timeNow = new Date();
    const parsedNow = Date.parse(timeNow);
    const commentTime = Date.parse(value);
    let createdAt = parsedNow - commentTime;
    let minutes = Math.floor(createdAt / 60000);
    if (minutes > 60 && minutes < 1440) {
      let hours = Math.floor(minutes / 60);
      return {
        value: hours,
        unit: "hr",
      };
    } else if (minutes > 1440) {
      let days = Math.floor(minutes / 1440);
      return {
        value: days,
        unit: "days",
      };
    }
    return {
      value: minutes,
      unit: "min",
    };
  };

  const displayTime = (unit, value) => {
    const unitValues = ["hr", "days", "min"];
    if (unit === unitValues[0]) {
       // eslint-disable-next-line
      if (value == 1) {
        return "hour";
      }
      return "hours";
    } else if (unit === unitValues[1]) {
       // eslint-disable-next-line
      if (value == 1) {
        return "day";
      }
      return "days";
    } else {
       // eslint-disable-next-line
      if (value == 1) {
        return "minute";
      }
      return "minutes";
    }
  };

  const displayCreatorAvator = () => {
 
    if (suggestion.created_by === undefined)
      return <Avatar src="/broken-image.jpg" />;

    return (
      <Avatar
        alt={`${suggestion.created_by.first_name} ${suggestion.created_by.last_name}`}
        src={`${suggestion.created_by.image.url}`}
      />
    );
  };


  return (
    <Card sx={{ maxWidth: 400 }}>
      <CardHeader
        avatar={displayCreatorAvator()}
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={suggestion.created_by === undefined ? null : `${suggestion.created_by.first_name} ${suggestion.created_by.last_name}`}
        subheader={`${displayDate(suggestion.created_at).value} ${displayTime(
          displayDate(suggestion.created_at).unit,
          displayDate(suggestion.created_at).value
        )}  ago`}
      />
      <ImageList sx={{ width: 400, height: 80 }} cols={4} rowHeight={20}>
        {imageArr.map((img, idx) => (
          <ImageListItem key={`${idx}`}>
            <img
              src={img !== null ? img : noImage}
              srcSet={img !== null ? img : noImage}
              alt={`sb+${idx}`}
              loading="lazy"
              className="sb-reusable-card-img"
            />
          </ImageListItem>
        ))}
      </ImageList>

      <CardContent>
        <Typography variant="body2" color="text.secondary">
          <div className="tt-suggestion-card-title row reusable-card">
            {/* === IDEA === */}
            <div className="col-md-12 col-sm-12 col-12 suggest-idea">
              {suggestion.idea === null ? null : (
                <div className="suggestion-idea-title">
                  <img src={idea} alt="thumbnail" /> <span> IDEA</span>
                </div>
              )}

              <div
                onClick={() => handlePageNavigation(suggestion.id)}
                className="suggestion-purpose"
              >
                {" "}
                {suggestion.idea !== null ? `${suggestion.idea}` : null}
              </div>
            </div>
          </div>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            <div className="suggest-idea mt-1">
              {" "}
              {suggestion.purpose !== "" ? (
                <div className="suggestion-idea-title mb-3">
                  <FlagCircleIcon /> <span> PURPOSE</span>
                </div>
              ) : null}
              {suggestion.purpose !== null ? parse(suggestion.purpose) : null}{" "}
            </div>
          </Typography>
          <Typography paragraph>
            <div className="suggest-idea">
              {" "}
              {suggestion.details !== "" ? (
                <div className="suggestion-idea-title mb-3">
                  <ArticleIcon /> <span> DETAILS</span>
                </div>
              ) : null}
              {suggestion.details !== null ? parse(suggestion.details) : null}
            </div>
          </Typography>
          <Typography paragraph>
            <div className="tt-tags-display">
              {_.isEmpty(suggestion.tags) ? null : (
                <>
                  {suggestion.tags.map((tag, idx) => (
                    <TagBadge key={idx} tag={`#${tag.name}`} />
                  ))}
                </>
              )}
            </div>
          </Typography>
          <Typography>
            <div className="project-link-div">
              <div className="project-link-title">
                <i className="fas fa-project-diagram text-info"></i>
                &nbsp;&nbsp;
                <span>Project</span>&nbsp;&nbsp;
                <i className="fa fa-chevron-right text-info"></i>&nbsp;&nbsp;
                  <span className="text-danger">
                    {"No project"}
                  </span>
              </div>
              <div>
                {suggestion.project === null && (
                  <>
                   <Button variant="outlined" startIcon={<AddLinkIcon  />}>
                   link a project
                 </Button>
                  <button type="button" onClick={showProjectModal}>
                    Create project
                  </button>
                  </>
                )}
              </div>
            </div>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default SuggestionCard;
