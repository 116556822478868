import React from 'react'
import { Link } from 'react-router-dom';
import avatar from "../../images/avatar.jpeg";

const NotificationListItem = ({data, handleUpdate}) => {

  return (
    <li className="notifications-list-item">
      <div className="notifications-list-item-left">
        {data.sender_avatar ? (
          <img
            className="profile-avatar-thumb-sm ml-3"
            src={data.sender_avatar}
            alt="avatar"
          />
        ) : (
          <img
            className="profile-avatar-thumb-sm ml-3"
            src={avatar}
            alt="avatar"
          />
        )}
      </div>
      <Link to={data.link} className="notifications-list-item-center">
        {data.message}
      </Link>
      <div className="notifications-list-item-right">
        <button type="button" onClick={() => handleUpdate(data.id)}>
          <i className="fa fa-times text-danger" aria-hidden="true"></i>
        </button>
      </div>
    </li>
  );
}

export default NotificationListItem