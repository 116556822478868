import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";

function SkeletonChildrenDemo(props) {
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
        
      </Box>
      <Skeleton variant="rectangular" width="100%">
        <div style={{ paddingTop: "57%" }} />
      </Skeleton>
      

    </div>
  );
}

const AppLoadingSkeleton = () => {
  return (
    <div className="skeleton-container container">
      <Grid container spacing={8}>
        <Grid item xs>
          <SkeletonChildrenDemo loading />
        </Grid>
        <Grid item xs>
          <SkeletonChildrenDemo />
        </Grid>
        
      </Grid>
    </div>
  );
};

export default AppLoadingSkeleton;
