import client from "../../assets/img/client.jpeg";
import client2 from "../../assets/img/client2.jpeg";
import client3 from "../../assets/img/client3.jpeg";
import client4 from "../../assets/img/client4.jpeg";
const commentsData = [
  {
    id: "1",
    title: "Jeff J Mack",
    body: "TUC Is What The Future Looks Like❗️ A Safe Place to Develop Your Passions. Opportunity Is A Human Right.... It must start with ACCESS. #getaprofile",
    pics: client,
    name: "Jeff J Mack",
    profession: "CEO#4, CCO#1, CMO#2, CTO#5, COO#3, CFO#7",
  },
  {
    id: "2",
    title: "Kelly Mack",
    body: "In today's day and age, life should be more efficient. Join us to see how TUC makes this all possible. #getaprofile",
    pics: client2,
    name: "Kelly Mack",
    profession: "CLO#4, CMO#8, @AdvisorsNetwork, @EditorsCircle",
  },
  {
    id: "3",
    title: "Elbie Moonga",
    body: "An open platform to seize multiple opportunities Don't let your ideas get blown into the wind #PutItInTheSystem Make your visions become a reality",
    pics: client4,
    name: "Elbie Moonga",
    profession: "COO#3, CTO#4, Ambassodar to Zambia",
  },
  {
    id: "4",
    title: "Peter Robert",
    body: "You don't know the struggle till you experience it. At TUC, this is what we are trying to change.  #TogetherWeCan",
    pics: client3,
    name: "Peter Robert",
    profession: "CTO#3, Blockchain Lead",
  },
];

export default commentsData;
