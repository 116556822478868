import {
  createNewChannel,
  createNewChannelList,
  editNewChannelList,
  getAllChannels,
} from "../../../services/bmc/bmcChannels";
import {
  createNewCostStructure,
  createNewCostStructureList,
  editNewCostStructureList,
  getAllCostStructure,
} from "../../../services/bmc/bmcCostStructure";
import {
  createNewCustomerRelationShip,
  createNewCustomerRelationShipList,
  editNewCustomerRelationShipList,
  getAllCustomerRelationsShips,
} from "../../../services/bmc/bmcCustomerRelationship";
import {
  createNewCustomerSegments,
  createNewCustomerSegmentsList,
  editNewCustomerSegmentsList,
  getAllCustomerSegments,
} from "../../../services/bmc/bmcCustomerSegments";
import {
  createNewKeyActivities,
  createNewKeyActivitiesList,
  editNewKeyActivitiesList,
  getAllKeyActivities,
} from "../../../services/bmc/bmcKeyActivity";
import {
  createNewKeyPartners,
  createNewKeyPartnersList,
  editNewKeyPartnersList,
  getAllKeyPartners,
} from "../../../services/bmc/bmcKeyPartners";
import {
  createNewKeyResources,
  createNewKeyResourcesList,
  editNewKeyResourcesList,
  getAllKeyResources,
} from "../../../services/bmc/bmcKeyResources";
import {
  createNewKeyRevenueStreams,
  createNewKeyRevenueStreamsList,
  editNewKeyRevenueStreamsList,
  getAllKeyRevenueStreams,
} from "../../../services/bmc/bmcKeyRevenueStreams";
import {
  createNewValueProportions,
  createNewValueProportionsList,
  editNewValueProportionsList,
  getAllValueProportions,
} from "../../../services/bmc/bmcValueProportions";

const blockData = [
  {
    id: 9,
    name: "value propositions",
    fetchRoute: getAllValueProportions,
    createRoute: createNewValueProportions,
    items: "value_proposition_items",
    createList: createNewValueProportionsList,
    editList: editNewValueProportionsList,
    block: "value_proposition",
    classname: "badge badge-primary",
    details:
      "This component identifies the different groups of people or companies that a business is targeting and trying to sell its products or services to. Each segment may have different needs and pain points, so it's important to tailor value propositions accordingly",
  },
  {
    id: 5,
    name: "Key activities",
    fetchRoute: getAllKeyActivities,
    createRoute: createNewKeyActivities,
    items: "key_activity_items",
    createList: createNewKeyActivitiesList,
    editList: editNewKeyActivitiesList,
    block: "key_activity",
    classname: "badge badge-warning",
    details:
      " Key activities are the crucial actions that a business must perform to deliver its value proposition and create value for its customers. This component includes activities such as production, marketing, distribution, and customer support",
  },
  {
    id: 7,
    name: "Key resources",
    fetchRoute: getAllKeyResources,
    createRoute: createNewKeyResources,
    items: "key_resource_items",
    createList: createNewKeyResourcesList,
    editList: editNewKeyResourcesList,
    block: "key_resource",
    classname: "badge badge-dark",
    details:
      "Key resources are the essential assets that a business needs to deliver its value proposition and operate effectively. This component includes physical resources, intellectual property, human resources, and financial resources",
  },
  {
    id: 6,
    name: "Key partners",
    fetchRoute: getAllKeyPartners,
    createRoute: createNewKeyPartners,
    items: "key_partner_items",
    createList: createNewKeyPartnersList,
    editList: editNewKeyPartnersList,
    block: "key_partner",
    classname: "badge badge-info",
    details:
      "Key partners are the external entities that a business collaborates with to leverage their resources, expertise, or distribution channels. This component includes suppliers, strategic alliances, and other business partners",
  },
  {
    id: 2,
    name: "cost structures",
    fetchRoute: getAllCostStructure,
    createRoute: createNewCostStructure,
    items: "cost_structure_items",
    createList: createNewCostStructureList,
    editList: editNewCostStructureList,
    block: "cost_structure",
    classname: "badge badge-primary",
    details:
      "The cost structure component outlines the costs incurred by a business to operate and deliver its value proposition. It includes both fixed and variable costs, as well as economies of scale and cost-saving measures",
  },

  {
    id: 8,
    name: "revenue streams",
    fetchRoute: getAllKeyRevenueStreams,
    createRoute: createNewKeyRevenueStreams,
    items: "stream_items",
    createList: createNewKeyRevenueStreamsList,
    editList: editNewKeyRevenueStreamsList,
    block: "stream",
    classname: "badge badge-light",
    details:
      "Revenue streams describe how a business generates revenue from its value propositions. This component considers the pricing models, payment methods, and sources of revenue for each customer segment",
  },

  {
    id: 4,
    name: "customer segments",
    fetchRoute: getAllCustomerSegments,
    createRoute: createNewCustomerSegments,
    items: "customer_segmet_items",
    createList: createNewCustomerSegmentsList,
    editList: editNewCustomerSegmentsList,
    block: "customer_segmet",
    classname: "badge badge-danger",
    details:
      "This component identifies the different groups of people or companies that a business is targeting and trying to sell its products or services to. Each segment may have different needs and pain points, so it's important to tailor value propositions accordingly",
  },
  {
    id: 3,
    name: "customer relationships",
    fetchRoute: getAllCustomerRelationsShips,
    createRoute: createNewCustomerRelationShip,
    items: "customer_relationship_items",
    createList: createNewCustomerRelationShipList,
    editList: editNewCustomerRelationShipList,
    block: "customer_relationship",
    classname: "badge badge-success",
    details:
      "This component focuses on how a business builds and maintains relationships with its customers. It considers the type of relationship that is most suitable for each customer segment",
  },

  {
    id: 1,
    name: "channels",
    fetchRoute: getAllChannels,
    createRoute: createNewChannel,
    items: "channel_items",
    createList: createNewChannelList,
    editList: editNewChannelList,
    block: "channel",
    classname: "badge badge-secondary",
    details:
      "Channels refer to the marketing and distribution channels through which a business communicates its value proposition to its customers. This component considers how customers want to be communicated with and how they can purchase products or services",
  },
];

export default blockData;
