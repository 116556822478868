/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import _ from "lodash";
import ListingPage from "./ListingPage";
import blockData from "./blocks";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function BmcBlocksComponent({
  projectName,
  projectID,
  bmcID,
  setListingShow,
  setCreatingList,
  setBlockInfo,
  setListItems,
  listItems,
  setFetchListingFunction,
  setItems,
  blockInfo,
  setShowInfoModal,
  setlistingEditShow,
  setListEditInfo,
  setEditList,
  setItemID,
  bmcInfo,
}) {
  const [switchListingPage, setSwitchListingPage] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [value, setValue] = useState(0);
  const [tabDetails, setTabDetails] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // === THE BLOCK IS NOT YET CREATED SO WE CREATE A NEW BLOCK FIRST IN THE DATABASE ===
  const persistNewBlock = async (values) => {
    let { createRoute, fetchRoute, items, createList, name, block } = values;

    await createRoute(projectID, projectName, bmcID);
    const { result } = await fetchRoute(projectID, bmcID);
    let response = result[0];

    setListItems(response[items]);
    setCreatingList({ createList });
    setBlockInfo({ name, ...response[block] });
    setFetchListingFunction({ fetchRoute });

    setListLoader(false);
    return;
  };

  // === THE BLOCK IS ALREADY CREATED NO NEED FOR CREACTING AGAIN ===
  const alreadyPersistedBlock = async (result, values) => {
    let { name, createList, block, items, fetchRoute, editList } = values;
    let response = result[0];

    setListItems(response[items]);
    setBlockInfo({ name, ...response[block] });
    setCreatingList({ createList });
    setFetchListingFunction({ fetchRoute });
    setEditList({ editList });

    setListLoader(false);
    return;
  };

  // === CONDITIONALLY EXECUTE THE FIRST AND SECOND FUNCTIONS ON TOP ===
  const handleClick = async (e, values) => {
    e.preventDefault();
    let { items, fetchRoute, details } = values;

    setItems(items);
    setSwitchListingPage(true);
    setTabDetails(details);
    setListLoader(true);

    const { result } = await fetchRoute(projectID, bmcID);
    // === check if the block is created ===
    if (_.isEmpty(result)) {
      persistNewBlock({ ...values });
      return;
    }

    // === if the block is created execute this function ===
    alreadyPersistedBlock(result, { ...values });
  };

  const displayBlocks = () => {
    const results = blockData.map((value, idx) => {
      return (
        <Tab
          label={value.name}
          key={idx}
          onClick={(e) => handleClick(e, { ...value })}
        />
      );
    });
    return results;
  };

  return (
    <div className="bmc-view-container  container-fluid container-m-well">
      <div className="row blocks-row">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            background: "#eee",
          }}
          className="bmc-swot-tab-box"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="scrollable auto tabs example"
            className="tabs-items-bmc"
            allowScrollButtonsMobile
          >
            <Tab label="What is BMC ?" value={0} className="what-is-bmc" />
            {displayBlocks()}
          </Tabs>
        </Box>
        {value === 0 ? (
          <div className="what_is_swot">
            <div className="swot-info-container">
              <p>
                The business model canvas is a great tool to help you understand
                a business model in a straightforward, structured way. Using
                this canvas will lead to insights about the customers you serve,
                what value propositions are offered through what channels, and
                how your company makes money. You can also use the business
                model canvas to understand your own business model or that of a
                competitor! The Business Model Canvas was created by Alexander
                Osterwalder, of Strategyzer.
              </p>
            </div>
          </div>
        ) : (
          <ListingPage
            listItems={listItems}
            setListingShow={setListingShow}
            setSwitchListingPage={setSwitchListingPage}
            listLoader={listLoader}
            blockInfo={blockInfo}
            setlistingEditShow={setlistingEditShow}
            setListEditInfo={setListEditInfo}
            setItemID={setItemID}
            tabDetails={tabDetails}
          />
        )}
      </div>
    </div>
  );
}

export default BmcBlocksComponent;
