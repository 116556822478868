
export const profileIdeas = [
  { idea:"TUC needs to create a way for users to learn what specific systems are and what certain words mean",
    date: "June 18 2021"
  },
  { idea:"TUC needs to create a way for users to learn what specific systems are and what certain words mean",
    date: "Sept 28 2021"
  },
  { idea:"TUC needs to create a way for users to learn what specific systems are and what certain words mean",
    date: "Oct 19 2021"
  },
  { idea:"TUC needs to create a way for users to learn what specific systems are and what certain words mean",
    date: "Nov 13 2021"
  },
]