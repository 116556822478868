import React, { useState } from "react";
import styles from "./ProjectCalender.module.css";
import { TextField } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import OpenItemPriority from "./PriorityModule";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { NotificationManager } from "react-notifications";
import { updateSpecificOpenItems } from "../../../services/openItem/openItemService";

const EditOpenItem = ({
  detail,
  end,
  priority,
  start,
  title,
  id,
  allDay,
  handleSingleOpenItem,
  setEditView,
  fetchAllProjectOpenItems,
}) => {
  const [state, setState] = useState({
    title: title,
    details: detail,
    priority: priority,
    startDate: dayjs(start),
    dueDate: dayjs(end),
    allDay: allDay,
  });

  const handlePrioritySelection = (e) => {
    if (e.target.checked)
      return setState({ ...state, priority: e.target.value });
  };

  const displayPriorityList = () => {
    const results = OpenItemPriority.map((value, index) => {
      return (
        <FormControlLabel
          defaultValue={state.priority}
          value={value.level}
          control={<Radio />}
          label={value.name}
          key={index}
          onChange={(e) => handlePrioritySelection(e)}
        />
      );
    });

    return results;
  };

  const handleEdit = async () => {
    const data = {
      open_item: {
        title: state.title,
        detail: state.details,
        start: state.startDate,
        end: state.dueDate,
        allDay: state.allDay,
        priority: state.priority,
      },
    };
    try {
      const result = await updateSpecificOpenItems(id, data);

      if (result.message === "Project Open item updated successfuly") {
        handleSingleOpenItem(id);
        setEditView(false);
        fetchAllProjectOpenItems();
      }
    } catch (error) {
      NotificationManager.error("Something went wrong. Open Item not Updated");
    }
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="need" className={styles.form_detail_cont}>
          Task Name
        </label>
        <TextField
          type="text"
          className={styles.task_name_field}
          placeholder="Task name of open item(required)"
          multiline
          rows={1}
          value={state.title}
          label="Required"
          required
          onChange={(e) => {
            setState({
              ...state,
              title: e.target.value,
            });
          }}
        />
      </div>

      <div className="form-group">
        <label htmlFor="idea" className={styles.form_detail_cont}>
          Task Detail
        </label>
        <CKEditor
          className="form-control"
          editor={ClassEditor}
          data={state.details}
          onChange={(event, editor) => {
            const data = editor.getData();
            setState({ ...state, details: data });
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="purpose" className={styles.form_detail_cont}>
          Priority
        </label>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={state.priority}
          name="radio-buttons-group"
        >
          {displayPriorityList()}
        </RadioGroup>
      </div>
      <div className={styles.date_picker_display}>
        <div className="form-group">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={state.startDate}
              onChange={(newValue) =>
                setState({ ...state, startDate: newValue })
              }
            />
          </LocalizationProvider>
        </div>
        <div className="form-group">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Due Date"
              value={state.dueDate}
              onChange={(newValue) => setState({ ...state, dueDate: newValue })}
            />
          </LocalizationProvider>
        </div>
      </div>
      <Button
        variant="contained"
        color="secondary"
        className="mt-4 mb-4"
        endIcon={<ModeEditIcon />}
        onClick={handleEdit}
      >
        Edit Open Item
      </Button>
    </div>
  );
};

export default EditOpenItem;
