import React, { useState, Fragment, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { BASEURL } from "../../API/apiData";
import { NotificationManager } from "react-notifications";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function ProjectGoalEditModal({
  isOpen,
  closeModal,
  id,
  editState,
  fetchSingleProject,
  setGoals,
}) {
  const [inputFields, setInputFields] = useState([
    { name: "", project_id: id },
  ]);

  const [goal, setGoal] = useState("");
  // eslint-disable-next-line no-unused-vars
  const handleAddField = () => {
    const values = [...inputFields];
    values.push({ name: "", project_id: id });
    setInputFields(values);
  };

  const handleChange = (index, event) => {
    const values = [...inputFields];
    values[index].name = event.target.value;
    setInputFields(values);
  };

  const handleRemoveField = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  // === UPDATED EDITED STATE ===
  const updateState = async () => {
    try {
      const { payload } = await fetchSingleProject(id);
      NotificationManager.info("Goal edited successfully");
      setGoals(payload.result.project_goals);
    } catch (error) {
      NotificationManager.error(
        "Something went wrong kindly check your network connection and try again"
      );
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const goalData = {
      name: goal,
      project_goal_objectives_attributes: inputFields,
    };
    //   ==== Handle Edit ====
    const token = localStorage.getItem("token");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(goalData),
    };

    fetch(
      `${BASEURL}projects/${id}/project_goals/${editState.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        updateState();
        closeModal();
      });
  };

  useEffect(() => {
    setGoal(editState.project_goal);
    setInputFields(editState.project_goal_objectives);
  }, [editState]);

  // eslint-disable-next-line no-unused-vars
  const displayInputFields = () => {
    if (inputFields !== undefined) {
      const results = inputFields.map((field, idx) => (
        <Fragment key={idx}>
          <div className="form-group col-md-11 mt-2">
            <input
              type="text"
              className="form-control"
              id="field_name"
              name="description"
              value={field.name}
              placeholder="Enter Objective"
              onChange={(event) => handleChange(idx, event)}
              required
            />
          </div>
          <div className="form-group col-md-1 remove-btn">
            <button
              className="btn btn-danger mt-2"
              type="button"
              onClick={() => {
                handleRemoveField(idx);
              }}
            >
              -
            </button>
          </div>
        </Fragment>
      ));
      return results;
    }
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Edit Project Goal and Objectives</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="project-goals-tt" onSubmit={handleFormSubmit}>
          <div className="form-group project-goal-field">
            <label htmlFor="title">Goal</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={editState.project_goal}
              onChange={(event, editor) => {
                const data = editor.getData();
                setGoal(data);
              }}
            />
          </div>
          <button type="submit" className="btn  btn-secondary m-4">
            Edit Goal
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ProjectGoalEditModal;
