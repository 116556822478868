import { BASEURL } from "../../API/apiData";
import { bmcListsRoutes, bmcMainRoutes } from "./routes";

const getAllChannels = async (projectID, bmcID) => {
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.channel}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewChannel = async (projectID, projectName, bmcID) => {
  const token = localStorage.getItem("token");

  const data = {
    channel_name: `${projectName} channels`,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.channel}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewChannelList = async (projectID, bmcID, channelID, name) => {
  const token = localStorage.getItem("token");

  const data = {
    channel_item: {
      item_name: name,
    },
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.channel}/${channelID}/${bmcListsRoutes.channelItems}`,
    requestOptions
  );

  const results = await response.json();
  return results;
};

const editNewChannelList = async (projectID, bmcID, channelID, name, ID) => {
  const token = localStorage.getItem("token");

  const data = {
    channel_item: {
      item_name: name,
    },
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.channel}/${channelID}/${bmcListsRoutes.channelItems}/${ID}`,
    requestOptions
  );

  const results = await response.json();
  return results;
};

export {
  getAllChannels,
  createNewChannel,
  createNewChannelList,
  editNewChannelList,
};
