/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import _ from "lodash";
import {
  createEntry,
  fetchEntries,
  fetchFields,
  fetchTopTens,
  createProject,
} from "../redux";
import { createVote } from "../services/votes/voteService";
import TopTenEntryCard from "../components/topTen/TopTenEntryCard";
import { useHistory } from "react-router-dom";
import EntryModal from "../components/EntryModal";
import LoadingSpinner from "../components/LoadingSpinner";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/suggestion.css";
import "../stylesheets/singleProject.css";
import "../stylesheets/profiles.css";
import "../stylesheets/projectmodal.css";
import "../stylesheets/projects.css";

const TTEntryContainer = ({
  match,
  entries,
  fetchEntries,
  createEntry,
  fields,
  fetchFields,
  top10s,
  fetchTopTens,
  createProject,
}) => {
  const categoryID = match.params.catId;
  const topTenID = match.params.tId;
  const history = useHistory();

  useEffect(() => {
    fetchFields(categoryID, topTenID);
    fetchEntries(categoryID, topTenID);
    fetchTopTens(categoryID);
     return () => {
       clearTimeout()
     };
  }, [fetchEntries, fetchFields, categoryID, topTenID, fetchTopTens]);



  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    createEntry(categoryID, topTenID, data);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  const sendVote = async (data) => {
    try {
      const res = await createVote(data);
      res.message == "Thanks for your vote" ? toast.success(res.message) : toast.error(res.message)
    } catch (error) {
      console.log(error);
    }
  };

  const gotoPrevPage = () => {
    history.goBack();
  };

  const handleVote = (data) => {
    sendVote(data);
  };

  const handleCreateProject = (data) => {
    createProject(data)
      .then((res) => {
        toast.success(res.payload.message);
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
      .catch((e) => console.log("Internal Server Error"));
  };

  const displayCategory = () => {
    if (!_.isEmpty(top10s.data.result)) {
      let results = top10s.data.result.map((value, index) => {
        if (value.category_id == categoryID) {
          return { category: value.category_name };
        }
      });
      return results;
    }
  };

  const dispTop10title = () => {
    if (!_.isEmpty(top10s.data.result)) {
      let results = top10s.data.result.map((value, index) => {
        if (value.id == topTenID) {
          return {
            title: value.title,
            description: value.description,
            creator: value.created_by,
          };
        }
      });
      return results;
    }
  };

  let titleDesc = _.compact(dispTop10title());
  let categoryTitle = _.compact(displayCategory());

  const showData = () => {
    if (
      !_.isEmpty(entries.data.result) &&
      entries.data.loggedIn === true &&
      !_.isEmpty(top10s.data.result)
    ) {
      const headings = Object.keys(entries.data.result[0].entry);
      const res = entries.data.result;
      return (
        <div className="entry-container mx-auto">
          <div className="d-flex justify-content-between mb-3">
            <button
              className="border-0 btn btn-link tt-entry-bb-btn"
              onClick={gotoPrevPage}
            >
              <i className="fa fa-chevron-left" aria-hidden="true"></i> back
            </button>
            <div className="top10NameDisplay"> {titleDesc[0].title}</div>
            <button className="btn add-new-button" onClick={openModal}>
              <i className="fas fa-plus"></i>&nbsp;&nbsp;Add Entry
            </button>
          </div>
          <div className="container-fluid mt-5 ">
            <div className="row">
              {res.map((e, idx) => (
                <TopTenEntryCard
                  key={e.id}
                  idx={idx}
                  data={e}
                  catId={categoryID}
                  ttId={topTenID}
                  handleVote={handleVote}
                  handleCreateProject={handleCreateProject}
                />
              ))}
            </div>
          </div>
        </div>
      );
    } else if (_.isEmpty(entries.data) && entries.errorMsg) {
      return (
        <h1 className="text-danger text-center p-3">{entries.data.message}</h1>
      );
    } else if (
      _.isEmpty(entries.data.result) &&
      entries.data.loggedIn === true
    ) {
      return (
        <div className="entry-container mx-auto">
          <div className="d-flex justify-content-between mb-3">
            <button className="add-btn rounded" onClick={gotoPrevPage}>
              GO BACK
            </button>
            <button className="add-btn rounded" onClick={openModal}>
              <i className="fas fa-plus"></i>&nbsp;&nbsp;ADD ENTRY
            </button>
          </div>
          <h1 className="text-center text-success">No entries available</h1>
        </div>
      );
    }

    if (entries.loading) {
      return <LoadingSpinner />;
    }
  };

  const renderModal = () => {
    if (fields.data.loggedIn) {
      return (
        <EntryModal
          closeModal={closeModal}
          isOpen={open}
          handleSubmit={handleSubmit}
          inputFields={fields.data.result}
        />
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid top-ten-cont">
        <ToastContainer />
        {renderModal()}
        {showData()}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  entries: state.entries,
  fields: state.fields,
  top10s: state.topTens,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTopTens: (catId) => dispatch(fetchTopTens(catId)),
  fetchFields: (catId, tId) => dispatch(fetchFields(catId, tId)),
  fetchEntries: (catId, tId) => dispatch(fetchEntries(catId, tId)),
  createEntry: (catId, tId, data) => dispatch(createEntry(catId, tId, data)),
  createProject: (data) => dispatch(createProject(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TTEntryContainer);

/* eslint-enable */
