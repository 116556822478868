import React, {useState} from "react";
import GoalsModal from "../GoalsModal";
import GoalDetailsCard from "./GoalDetailsCard";
import nocores from '../../assets/nocorevalues.png'
import _ from "lodash";

const ProfileGoals = ({id, goals, handleGoalSubmit, privacy= false}) => {
  const [openGoals, setOpenGoals] = useState(false);

  const openGoalModal = () => {
    setOpenGoals(true);
  };

  const closeGoalModal = () => {
    setOpenGoals(false);
  };

  const handleSubmit = (goal) => {
    handleGoalSubmit(goal)
  }

  const showData = () => {
    if(!_.isEmpty(goals.result)){
      return (
        <div className="mt-3 p-3">
          <div className="accordion" id={`goalAccordian`}>
            {goals.result.map((goal, idx) => (
              <GoalDetailsCard key={`gg${idx}`} data={goal} privacy={privacy}/>
            ))}
          </div>
        </div>
      );
    }else if(_.isEmpty(goals.result)) {
      return (
        <div className="w-100 text-center">
          <img className="no-cores-thumbnail"src={nocores} alt="no-cv" />
          <h4 className="text-dark">{goals.message}</h4>
        </div>
      )
    }
  }

  return (
    <>
      <div className="profile-core-values">
    { privacy ? <div className="d-flex flex-row justify-content-between p-3">
        <h1>My Goals</h1>
        <button className="add-btn rounded text-white" onClick={openGoalModal}>Add New</button>
      </div> : null}
        <GoalsModal
            isOpen={openGoals}
            closeModal={closeGoalModal}
            id={id}
            handleSubmit={handleSubmit}
        />
        {showData()}
      </div>
    </>
  );
};

export default ProfileGoals;
