import {CREATE_GOALS_SUCCESS, CREATE_GOALS_REQUEST, CREATE_GOALS_FAILURE} from './goalsTypes'

const initialState = {
  loading: true,
  data: [],
  errorMsg: '',
};

const goalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GOALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_GOALS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: '',
      };
    case CREATE_GOALS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default goalsReducer