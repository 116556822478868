const inputFields = {
  summary: true,
  description: true,
  goals: true,
  assumptions: true,
  needs: true,
  gallery: true,
  discussion: true,
  top10: true,
  suggestion_Box: true,
  sub_projects: true,
  swot: true,
  bmc: true
};

export default inputFields;
