import React from 'react'
import UserRoleTableRow from './UserRoleTableRow';

const UserRoleTable = ({title, data, authId, openModal,setRoleId,dropRole}) => {
  
  return (
    <>
      <table className="user-role-table">
        <thead>
          <tr>
            <th colSpan={5}>{title}</th>
          </tr>
          <tr>
            <td>ROLE</td>
            <td>ASSIGNMENT</td>
            <td>VACANCY</td>
            <td className="text-center">
              <i className="fa fa-cog text-info" aria-hidden="true"></i>
            </td>
          </tr>
        </thead>
        <tbody>
          {data.map((d, idx) => (
            <UserRoleTableRow
              key={d.id}
              data={d}
              idx={idx}
              authId={authId}
              openModal={openModal}
              setRoleId={setRoleId}
              dropRole={dropRole}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

export default UserRoleTable