import React, { useState } from "react";
import { getSpecificSwotDetail } from "../../../services/swotAnalysis/swotDetails";
import CreateNewSwotModal from "./CreateNewSwotModal";
import _ from "lodash";
import SwotDetailsView from "./SwotDetailsView";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function SwotBlocks({ projectID, swotID }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [swotDetails, setSwotDetails] = useState([]);
  const [swotTitleDetail, setSwotTitleDetail] = useState("");
  const [dynamicRoute, setDynamicRoute] = useState("");
  const [value, setValue] = useState(0);
  const [tabDetails, setTabDetails] = useState("");
  const [block, setBlock] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const swotBlocks = [
    {
      id: 1,
      name: "Strength",
      describe:
        "Internal factors that give the project, idea, business, or organization an advantage",
      route: "swot_strengths",
      classname: "badge badge-primary",
      commentRoute: "swot_strength_comments",
      commentReponseRoute: "swot_strength_comment_responses",
    },
    {
      id: 2,
      name: "Weakness",
      describe: "Internal factors that may hinder its success",
      route: "swot_weaknesses",
      classname: "badge badge-success",
      commentRoute: "swot_weakness_comments",
      commentReponseRoute: "swot_weakness_comment_responses",
    },
    {
      id: 3,
      name: "Opportunity",
      describe: "External factors that could be leveraged to its advantage",
      route: "swot_opportunities",
      classname: "badge badge-warning",
      commentRoute: "swot_opportunity_comments",
      commentReponseRoute: "swot_opportunity_comment_responses",
    },
    {
      id: 4,
      name: "Threat",
      describe: "External factors that may pose a risk or challenge",
      route: "swot_threats",
      classname: "badge badge-info",
      commentRoute: "swot_threat_comments",
      commentReponseRoute: "swot_threat_comment_responses",
    },
  ];

  const fetchSwotSpecificDetails = async (route) => {
    const { result } = await getSpecificSwotDetail(projectID, swotID, route);
    if (_.isEmpty(result)) {
      setShowCreateModal(true);
      return;
    }

    setSwotDetails(result);
  };

  const displaySwotBlocks = () => {
    const results = swotBlocks.map((value) => {
      return (
        <Tab
          value={value.id}
          label={value.name}
          key={value.id}
          className="tabs-items-swot"
          onClick={() => {
            fetchSwotSpecificDetails(value.route);
            setSwotTitleDetail(value.name);
            setDynamicRoute(value.route);
            setTabDetails(value.describe);
            setBlock(value);
          }}
        />
      );
    });
    return results;
  };

  return (
    <div className="container-fluid mt-2 swot-main-container">
      <CreateNewSwotModal
        swotName="strength"
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        swotTitleDetail={swotTitleDetail}
        projectID={projectID}
        swotID={swotID}
        dynamicRoute={dynamicRoute}
        setSwotDetails={setSwotDetails}
        fetchSwotSpecificDetails={fetchSwotSpecificDetails}
      />
      <div className="row swot-row-cont">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            background: "#eee",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <Tab
              value={0}
              label={"What is swot"}
              key={0}
              className="tabs-items-swot"
            />
            {displaySwotBlocks()}
          </Tabs>
        </Box>
        {_.isEmpty(swotDetails) || value === 0 ? (
          <div className="what_is_swot">
            <div className="swot-info-container">
              <p>
                SWOT (strengths, weaknesses, opportunities, and threats)
                analysis is a framework used to evaluate a company's competitive
                position and to develop strategic planning. SWOT analysis
                assesses internal and external factors, as well as current and
                future potential. A SWOT analysis is designed to facilitate a
                realistic, fact-based, data-driven look at the strengths and
                weaknesses of an organization, initiatives, or within its
                industry. The organization needs to keep the analysis accurate
                by avoiding pre-conceived beliefs or gray areas and instead
                focusing on real-life contexts. Companies should use it as a
                guide and not necessarily as a prescription.
              </p>
            </div>
          </div>
        ) : (
          <SwotDetailsView
            swotTitleDetail={swotTitleDetail}
            swotDetails={swotDetails}
            setSwotDetails={setSwotDetails}
            setShowCreateModal={setShowCreateModal}
            projectID={projectID}
            swotID={swotID}
            dynamicRoute={dynamicRoute}
            fetchSwotSpecificDetails={fetchSwotSpecificDetails}
            tabDetails={tabDetails}
            block={block}
          />
        )}
      </div>
    </div>
  );
}

export default SwotBlocks;
