import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_FIELDS_SUCCESS, FETCH_FIELDS_REQUEST, FETCH_FIELDS_FAILURE} from './fieldTypes'

export const fetchFieldsRequest = () =>({
  type: FETCH_FIELDS_REQUEST,
})

export const fetchFieldsSuccess = (response) =>({
  type: FETCH_FIELDS_SUCCESS,
  payload: response
})

export const fetchFieldsFailure = (error) =>({
  type: FETCH_FIELDS_FAILURE,
  payload: error
})

export const fetchFields = (catId,tId) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchFieldsRequest());
  return axios.get(`${BASEURL}categories/${catId}/top_tens/${tId}/top_ten_fields`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchFieldsSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchFieldsFailure(error));
    });
};