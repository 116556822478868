import {FETCH_ENTRIES_SUCCESS, FETCH_ENTRIES_REQUEST, FETCH_ENTRIES_FAILURE} from './entryTypes'

const initialState = {
  loading: true,
  data: [],
  errorMsg: '',
};


const entriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ENTRIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ENTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: '',
      };
    case FETCH_ENTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default entriesReducer