/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { fetchSuggestionResponse, createSuggestionResponse } from "../../redux";
import _, { values } from "lodash";
import like from "../../images/like.png";
import nolike from "../../images/nolike.png";
import pencil from "../../images/pencil.png";
import Avatar from "@mui/material/Avatar";
import SuggestionReplyEditForm from "./SuggestionReplyEditForm";
import SuggestionCommentReply from "./SuggestionCommentReply";
import SuggestionCommentReplyEdit from "./SuggestionCommentReplyEdit";
import { displayDate, displayTime } from "../../helpers/timeModule";
import { createVote } from "../../services/votes/voteService";
import { toast } from "react-toastify";
import { Grid, Paper } from "@material-ui/core";

const SuggestionReplyBody = ({
  sugID,
  fetchSugResponse,
  createSugResponse,
  getfetchCreateMethods,
  setNumberOfcomments,
}) => {
  const [responses, setResponses] = useState([]);
  const [commentEditForm, setCommentEditForm] = useState(false);
  const [suggestionReplyID, setSuggestionReplyID] = useState(null);
  const [suggestionComment, setSuggestionComment] = useState("");
  const [commentReplyForm, setCommentReplyForm] = useState(false);
  const [commentReplyEditForm, setCommentReplyEditForm] = useState(false);
  const [commentReplyID, setCommentReplyID] = useState(null);
  const [commentReplyContent, setCommentContent] = useState("");

  useEffect(() => {
    fetchSuggestionComments();
  }, []);

  useEffect(() => {
    getfetchCreateMethods(
      fetchSugResponse,
      createSugResponse,
      setResponses,
      responses
    );
  }, []);

  const sendVote = async (data) => {
    try {
      const res = await createVote(data);
      res.message === "Thanks for your vote"
        ? toast.success(res.message)
        : toast.error(res.message);
        fetchSuggestionComments()
    } catch (error) {
      console.error(error);
    }
  };

  const voteEntry = (modelName, model, likes) => {
    const xData = {
      likeable_type: modelName,
      likeable_id: model.id,
    };
    sendVote(xData);
  };

  // ==== THIS FUNCTION GET ALL THE SUGGESTION COMMENTS
  const fetchSuggestionComments = async () => {
    try {
      const results = await fetchSugResponse(sugID);
      const { result } = results.payload;
      setResponses(result);
      calculateResponses(result);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateResponses = (data) => {
    const number = data.length;
    setNumberOfcomments(number);
  };

  const displayReplyResponses = (
    suggestionCommentReplies,
    suggestionResponseID
  ) => {
    if (suggestionCommentReplies.length < 1) return null;

    const results = suggestionCommentReplies.map((value, idx) => {
      return (
        <Grid item key={idx}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            className="main-grid"
            key={idx}
          >
            <Grid item>
              {value.profile !== null ? (
                <Avatar
                  alt="profile avatar"
                  src={
                    value.profile.profile_avatar.url &&
                    value.profile.profile_avatar.url
                  }
                  sx={{ width: 56, height: 56 }}
                  className="mr-3"
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              {suggestionCommentReplies.length < 2 ||
              value.id ===
                suggestionCommentReplies[suggestionCommentReplies.length - 1]
                  .id ? null : (
                <div className="vertical-bar-comment-box-sub"></div>
              )}
            </Grid>

            <Grid item xs={11} zeroMinWidth className="sub-comment-container">
              <h4
                style={{ margin: 0, textAlign: "left" }}
                className="creator-of-comment"
              >
                {value.profile !== null && value.profile.first_name}
                {value.profile !== null && value.profile.last_name}
              </h4>
              <p
                style={{ textAlign: "left", color: "gray" }}
                className="comment-date-posted"
              >
                Posted &nbsp;
                {displayDate(value.created_at).value < 1
                  ? "Just now"
                  : displayDate(value.created_at).value}{" "}
                {displayDate(value.created_at).value < 1
                  ? null
                  : displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}{" "}
              </p>
              <div style={{ textAlign: "left" }} className="main-sb-comment" dangerouslySetInnerHTML={{ __html: value.comment }}/>
            
              <div className="suggestion-comments-icons-v container">
                <div className="sugget-like">
                  <button
                    type="button"
                    onClick={() =>
                      voteEntry("SuggestionCommentReply", value, values.votes)
  
                    }
                  >
                    {value.user_likes ? (
                      <img src={like} alt="sb-like-btn" />
                    ) : (
                      <img src={nolike} alt="sb-like-btn" />
                    )}{" "}
                    <span>Upvote ({value.votes})</span>
                  </button>
                </div>

                <div
                  className="comments-edit"
                  onClick={() => {
                    setCommentContent(value.comment);
                    setCommentReplyID(value.id);
                    setCommentReplyEditForm(!commentReplyEditForm);
                  }}
                >
                  <img src={pencil} alt="pencil" /> <span>Edit</span>
                </div>
              </div>
              {commentReplyEditForm && commentReplyID === value.id ? (
              <SuggestionCommentReplyEdit
                commentReplyContent={commentReplyContent}
                replyComment={value}
                suggestionID={sugID}
                suggestionResponseID={suggestionResponseID}
                setCommentReplyEditForm={setCommentReplyEditForm}
                fetchSuggestionComments={fetchSuggestionComments}
              />
            ) : null}
            </Grid>
   
            
          </Grid>
        </Grid>
      );
    });
    return results;
  };
  const displayResponses = () => {
    if (!_.isEmpty(responses)) {
      const results = responses.map((value, idx) => {
        return (
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            className="main-grid"
            key={idx}
          >
            <Grid item>
              {value.profile !== null ? (
                <Avatar
                  alt="profile avatar"
                  src={
                    value.profile.profile_avatar.url &&
                    value.profile.profile_avatar.url
                  }
                  sx={{ width: 56, height: 56 }}
                  className="mr-3"
                />
              ) : (
                <Avatar src="/broken-image.jpg" />
              )}
              {responses.length < 2 ||
              value.id === responses[responses.length - 1].id ? null : (
                <div className="vertical-bar-comment-box"></div>
              )}
            </Grid>
            <Grid item xs zeroMinWidth>
              <h4
                style={{ margin: 0, textAlign: "left" }}
                className="creator-of-comment"
              >
                {value.profile !== null && value.profile.first_name}
                {value.profile !== null && value.profile.last_name}
              </h4>
              <p
                style={{ textAlign: "left", color: "gray" }}
                className="comment-date-posted"
              >
                Posted &nbsp;
                {displayDate(value.created_at).value < 1
                  ? "Just now"
                  : displayDate(value.created_at).value}{" "}
                {displayDate(value.created_at).value < 1
                  ? null
                  : displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}{" "}
              </p>
              <div style={{ textAlign: "left" }} className="main-sb-comment" dangerouslySetInnerHTML={{ __html: value.response_text}} />
              <div className="suggestion-comments-icons-v">
                <div className="sugget-like">
                  <button
                    type="button"
                    onClick={() =>
                      voteEntry("SuggestionResponse", value, values.votes)
                    }
                  >
                    {value.user_likes ? (
                      <img src={like} alt="sb-like-btn" />
                    ) : (
                      <img src={nolike} alt="sb-like-btn" />
                    )}
                    <span>Upvote ({value.votes})</span>
                  </button>
                </div>

                <div
                  className="comments-edit"
                  onClick={() => {
                    setSuggestionReplyID(value.id);
                    setSuggestionComment(value.response_text);
                    setCommentEditForm(!commentEditForm);
                  }}
                >
                  <img src={pencil} alt="pencil" /> <span>Edit</span>
                </div>

                <div
                  className="comments-reply"
                  onClick={() => {
                    setSuggestionReplyID(value.id);
                    setCommentReplyForm(!commentReplyForm);
                  }}
                >
                  <i className="fa fa-reply-all" aria-hidden="true"></i>{" "}
                  <span>Reply</span>
                </div>
              </div>
              {commentEditForm && suggestionReplyID === value.id ? (
                <SuggestionReplyEditForm
                  suggestionComment={suggestionComment}
                  suggestionReplyID={suggestionReplyID}
                  fetchSuggestionComments={fetchSuggestionComments}
                  setCommentEditForm={setCommentEditForm}
                />
              ) : null}
              {commentReplyForm && suggestionReplyID === value.id ? (
                <SuggestionCommentReply
                  setCommentReplyForm={setCommentReplyForm}
                  commentReplyForm={commentReplyForm}
                  fetchSuggestionComments={fetchSuggestionComments}
                  suggestionResponse={value}
                  suggestionID={sugID}
                />
              ) : null}
              {displayReplyResponses(
                value.suggestion_comment_replies,
                value.id
              )}
            </Grid>
          </Grid>
        );
      });
      return results;
    } else {
      return null;
    }
  };

  return (
    <>
      <div style={{ padding: 14 }}>
        <Paper style={{ padding: "40px 20px" }}>{displayResponses()}</Paper>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchSugResponse: (sId) => dispatch(fetchSuggestionResponse(sId)),
  createSugResponse: (sId, data) =>
    dispatch(createSuggestionResponse(sId, data)),
});

export default connect(null, mapDispatchToProps)(SuggestionReplyBody);
