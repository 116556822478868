import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_PROJECTTOPTENS_SUCCESS, FETCH_PROJECTTOPTENS_REQUEST, 
  FETCH_PROJECTTOPTENS_FAILURE, CREATE_PROJECTTOPTEN_REQUEST, 
  CREATE_PROJECTTOPTEN_SUCCESS, CREATE_PROJECTTOPTEN_FAILURE, CREATE_PROJECTTOPTENUPDATE_REQUEST,
  CREATE_PROJECTTOPTENUPDATE_SUCCESS,CREATE_PROJECTTOPTENUPDATE_FAILURE} from './projectTopTenTypes'

export const fetchProjectTopTensRequest = () =>({
  type: FETCH_PROJECTTOPTENS_REQUEST,
})

export const fetchProjectTopTensSuccess = (response) =>({
  type: FETCH_PROJECTTOPTENS_SUCCESS,
  payload: response
})

export const fetchProjectTopTensFailure = (error) =>({
  type: FETCH_PROJECTTOPTENS_FAILURE,
  payload: error
})

export const createProjectTopTenRequest = () =>({
  type: CREATE_PROJECTTOPTEN_REQUEST,
})

export const createProjectTopTenSuccess = (response) =>({
  type: CREATE_PROJECTTOPTEN_SUCCESS,
  payload: response
})

export const createProjectTopTenFailure = (error) =>({
  type: CREATE_PROJECTTOPTEN_FAILURE,
  payload: error
})

export const createProjectTopTenUpdateRequest = () =>({
  type: CREATE_PROJECTTOPTENUPDATE_REQUEST,
})

export const createProjectTopTenUpdateSuccess = (response) =>({
  type: CREATE_PROJECTTOPTENUPDATE_SUCCESS,
  payload: response
})

export const createProjectTopTenUpdateFailure = (error) =>({
  type: CREATE_PROJECTTOPTENUPDATE_FAILURE,
  payload: error
})

export const fetchProjectTopTens = pId => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchProjectTopTensRequest());
  return axios.get(`${BASEURL}projects/${pId}/project_top_tens`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchProjectTopTensSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchProjectTopTensFailure(error));
    });
};

export const createProjectTopTen = (pId, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createProjectTopTenRequest());
  return axios.post(`${BASEURL}projects/${pId}/project_top_tens`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createProjectTopTenSuccess(response.data));
    }).catch(error => {
      return dispatch(createProjectTopTenFailure(error));
    });
};

export const updateProjectTopTen = (pId,pttId, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createProjectTopTenUpdateRequest());
  return axios.put(`${BASEURL}projects/${pId}/project_top_tens/${pttId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createProjectTopTenUpdateSuccess(response.data));
    }).catch(error => {
      return dispatch(createProjectTopTenUpdateFailure(error));
    });
};
