/*eslint-disable */
import React, { useState } from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import { toast } from "react-toastify";

const NeedModal = ({
  categories = [],
  category,
  projects,
  isOpen,
  closeModal,
  handleSubmit,
  proId
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState(category);
  const [projectId, setProjectId] = useState(proId);

  const displayProjects = () => {
    if (!_.isEmpty(projects)) {
      return (
        <>
          {projects.map((pro) => (
            <option key={pro.id} value={pro.id}>
              {pro.name}
            </option>
          ))}
        </>
      );
    }
  };

  const displayCategories = () => {
    if (!_.isEmpty(categories)) {
      return (
        <>
          {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </>
      );
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (projectId == "") {
      toast.error("Please select a project from list");
    } else if (categoryId == null) {
      toast.error("Please select a category from list");
    } else {
      const data = {
        need: {
          name,
          description,
          is_procured: false,
          need_category_id: categoryId,
          project_id: projectId,
        },
      };
      handleSubmit(data);
      closeModal();
    }
  };

  return (
    <>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#309897" }}
          closeButton
        >
          <Modal.Title>Add New Need</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="name">Need</label>
              <input
                className="form-control"
                type="text"
                name="name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Purpose</label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
              />
            </div>
            <div className="form-group">
              {_.isEmpty(categories) ? (
                <>
                  {" "}
                  <label htmlFor="project">Select Project</label>
                  <select
                    className="form-control"
                    name="project"
                    id="project-list"
                    onChange={(e) => setProjectId(e.target.value)}
                  >
                    <option value={""}></option>
                    {displayProjects()}
                  </select>
                </>
              ) : (
                <>
                  {" "}
                  <label htmlFor="project">Select Category</label>
                  <select
                    className="form-control"
                    name="category"
                    id="category-list"
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value={""}></option>
                    {displayCategories()}
                  </select>
                </>
              )}
            </div>
            <div className="form-group float-right">
              <button className="add-btn rounded text-white" type="submit">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NeedModal;
