export const CREATE_LEGEND_REQUEST = 'CREATE_LEGEND_REQUEST';
export const CREATE_LEGEND_SUCCESS = 'CREATE_LEGEND_SUCCESS';
export const CREATE_LEGEND_FAILURE = 'CREATE_LEGEND_FAILURE';
export const FETCH_LEGEND_REQUEST = 'FETCH_LEGEND_REQUEST';
export const FETCH_LEGEND_SUCCESS = 'FETCH_LEGEND_SUCCESS';
export const FETCH_LEGEND_FAILURE = 'FETCH_LEGEND_FAILURE';
export const EDIT_LEGEND_REQUEST = 'EDIT_LEGEND_REQUEST';
export const EDIT_LEGEND_SUCCESS = 'EDIT_LEGEND_SUCCESS';
export const EDIT_LEGEND_FAILURE = 'EDIT_LEGEND_FAILURE';
export const FETCH_S_LEGEND_REQUEST = 'FETCH_LEGEND_REQUEST';
export const FETCH_S_LEGEND_SUCCESS = 'FETCH_LEGEND_SUCCESS';
export const FETCH_S_LEGEND_FAILURE = 'FETCH_LEGEND_FAILURE';




