import React, { useState, useEffect } from "react";
import logo from "../assets/TUC_LOGO.png";
import { connect } from "react-redux";
import _ from "lodash";
import { updatePwd, checkLoggedInStatus } from "../redux";
import LoggedInComp from "../components/LoggedInComp";
import ConfirmEmailModal from "../components/ConfirmEmailModal";
import ViewPasswordBtn from "../components/ViewPasswordBtn";

const errorMsg = "Something went wrong, please try again";
const successMsg =
  "Your password was successfully changed, press OK and sign in to continue enjoying The Ultimate Collaboration";

const PwdReset = ({ status, history, isLoggedIn, updatePwd }) => {
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);

    setToken(params.get("reset_password_token"));
  }, [token]);

  const handleSubmit = (event) => {
    const pwdData = {
      user: {
        reset_password_token: token,
        password: password,
        password_confirmation: passwordConfirmation,
      },
    };

    updatePwd(pwdData)
      .then((response) => {
        const result = response;

        setTitle("Password Reset");
        if (result.type === "UPDATE_PWD_SUCCESS") {
          setMessage(successMsg);
          setOpen(true);
        } else {
          setMessage(errorMsg);
          setOpen(true);
        }
      })
      .catch((errors) => console.log("Internal Server Error"));
    event.preventDefault();
  };

  const closeModal = () => {
    setOpen(false);
    history.push("/");
  };

  const showData = () => {
    if (!_.isEmpty(status.data)) {
      return (
        <>
          <LoggedInComp username={status.data.user.username} />
        </>
      );
    }

    if (_.isEmpty(status.data) && status.errorMsg) {
      return (
        <>
          <ConfirmEmailModal
            isOpen={open}
            closeModal={closeModal}
            title={title}
            message={message}
          />
          <div className="row auth-of">
            <div className="auth-col-height col-md-4 auth-col">
              <img className="logo-one" src={logo} alt="logo" />
              <br />
              <br />

              <div className="text-center text-white mt-5">
                <h1>WELCOME</h1>
                <h1>CAPTURE YOUR NEW PASSWORD</h1>
                <br />
              </div>
            </div>
            <div className="auth-col-height col-md-8 d-flex flex-column auth-col-II">
              <div className="p-3">
                <h1 className="text-center text-orange mt-5">Password Reset</h1>
                <form className="w-75 mx-auto mt-3" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label
                      htmlFor="password"
                      className="w-50 custom-font-b text-orange"
                    >
                      Password
                    </label>
                    <input
                      className="form-control pwd"
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <div className="w-100 text-right">
                      <ViewPasswordBtn />
                    </div>
                    <small
                      id="passwordHelpBlock"
                      className="form-text text-secondary"
                    >
                      Your password must be 6-20 characters long, contain
                      letters and numbers, and must not contain spaces, special
                      characters, or emoji.
                    </small>
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="password_confirmation"
                      className="w-50 custom-font-b text-orange"
                    >
                      Password confirmation
                    </label>
                    <input
                      className="form-control pwd"
                      type="password"
                      name="password_confirmation"
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      required
                    />
                    <div className="w-100 text-right">
                      <ViewPasswordBtn />
                    </div>
                  </div>
                  <div className="text-center">
                    <button className="auth-btn-one mt-3" type="submit">
                      CHANGE PASSWORD
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="container-fluid">{showData()}</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  updatePwd: (data) => dispatch(updatePwd(data)),
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PwdReset);
