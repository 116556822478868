import React, { useState, useEffect } from "react";
import { requestPwdReset, checkLoggedInStatus } from "../redux";
import { connect } from "react-redux";
import ConfirmEmailModal from "../components/ConfirmEmailModal";
import { useHistory } from "react-router";
import _ from "lodash";
import LoggedInComp from "../components/LoggedInComp";
import AppPasswordResetForm from "../components/auth/AppPasswordResetForm";
import AppAuthScreen from "../components/auth/AppAuthScreen";

const errorMsg =
  "Its seems you typed an email that does not exist, Please try again!";
const successMsg =
  "Sorry you forgot your password. Please check your registered email for reset instructions!";

const PasswordResetRequest = ({ status, requestPwdReset, isLoggedIn }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);

  const resetPassword = ({ email }) => {
    const pwdData = {
      user: {
        email: email,
      },
    };

    requestPwdReset(pwdData)
      .then((response) => {
        setTitle("Password Recovery Instructions");
        if (response.type === "RESET_PWD_SUCCESS") {
          setMessage(successMsg);
          setOpen(true);
        } else {
          setMessage(errorMsg);
          setOpen(true);
        }
      })
      .catch((errors) => console.log("internal application error"));
  };

  const closeModal = () => {
    setOpen(false);
    message === successMsg
      ? history.push("/")
      : history.push("/passwordresetrequest");
  };

  const showData = () => {
    if (!_.isEmpty(status.data)) {
      return (
        <>
          <LoggedInComp username={status.data.user.username} />
        </>
      );
    }

    if (_.isEmpty(status.data) && status.errorMsg) {
      return (
        <>
          <ConfirmEmailModal
            isOpen={open}
            closeModal={closeModal}
            title={title}
            message={message}
          />
          <div className="row">
            <AppAuthScreen />
            <div className="col-md-7">
              <div className="p-3 register-content-form">
                <h1 className="text-center mt-5">Password Reset</h1>
                <AppPasswordResetForm resetPassword={resetPassword} />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="container-fluid">{showData()}</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  requestPwdReset: (data) => dispatch(requestPwdReset(data)),
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetRequest);
