import React from "react";
import "../stylesheets/viewPasswordBtn.css";
const ViewPasswordBtn = () => {
  const handleClick = () => {
    const attrType = document.getElementsByClassName("pwd");

    if (attrType[0].type === "password") {
      attrType[0].setAttribute("type", "text");
    } else {
      attrType[0].setAttribute("type", "password");
    }

    if (
      attrType[1] &&
      attrType[1].type === "password" &&
      attrType[1] !== undefined
    ) {
      attrType[1].setAttribute("type", "text");
    } else if (attrType[1] && attrType[1].type === "text") {
      attrType[1].setAttribute("type", "password");
    }
  };

  return (
    <>
      <button className="view-pass-btn" type="button" onClick={handleClick}>
        <i className="fas fa-eye"></i>
      </button>
    </>
  );
};

export default ViewPasswordBtn;
