import TopTenCommentsBody from './TopTenCommentsBody';

function TopTenCommentsDisplay({ categoryID, topTenID, entry,  numberOfComments, commentsNumber }) {
  return (
    <div className="suggestion-comments-container">
      <div className="card">
        <div className="card-body">
          <div className="suggestion-comments-counter">
            <h3> {commentsNumber > 0 ? `${commentsNumber} comments` : `${commentsNumber} comment`} </h3>
            <div className="suggetion-comments-sort text-right">
              <div className="dropdown">
                <span
                  className="dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Vote
                </span>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="/">
                    Action
                  </a>
                  <a className="dropdown-item" href="/">
                    Another action
                  </a>
                  <a className="dropdown-item" href="/">
                    Something else here
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="suggestions-comments-body">
            <TopTenCommentsBody categoryID={categoryID} topTenID={topTenID} entry={entry} numberOfComments={numberOfComments} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default TopTenCommentsDisplay;
