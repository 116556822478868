import React from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { blue } from "@mui/material/colors";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AppProfilesList({ handleClose, open, profiles }) {
  if (profiles.length < 1) return null;

  const navigateToProfile = (id) => {
    window.location.href = `/profile/${id}`;
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      aria-describedby="profiles_slide_in"
    >
      <DialogTitle>Upvoted Profiles</DialogTitle>
      <List sx={{ pt: 0 }}>
        {profiles.map((person, index) => (
          <ListItem disableGutters key={index}>
            <ListItemButton
              onClick={() => navigateToProfile(person.profile.id)}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: blue[100], color: blue[600] }}
                  alt={`${person.profile.first_name} ${person.profile.last_name}`}
                  src={
                    person.profile.profile_avatar
                      ? person.profile.profile_avatar.url
                      : "/broken-image.jpg"
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${person.profile.first_name} ${person.profile.last_name}`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default AppProfilesList;
