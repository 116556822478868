import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import styles from "../css/main.module.css";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { displayDate, displayTime } from "../../../helpers/timeModule";

const MobileEngagementInfo = ({ open, setOpen, dashboardContent }) => {
  const toggleDrawer = (newOpen) => () => {
    setOpen({
      ...open,
      value: null,
      notificationPage: newOpen,
    });
  };

  const history = useHistory();

  const navigateToUserProfile = (profile) => {
    history.push({
      pathname: `/profile/${profile}`,
    });
  };

  const navigateToProject = (project) => {
    history.push({
      pathname: `/projects/${project}`,
    });
  };

  const displayActivites = () => {
    const { activities } = dashboardContent;
    if (activities.length < 1)
      return (
        <Alert severity="info">There are no Engagements at the moment</Alert>
      );

    const results = activities.map((value) => {
      return (
        <Fragment key={value.id}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              {value.user.profile_avatar && value.user.profile_avatar.url ? (
                <Avatar
                  alt={`${value.user.first_name} ${value.user.last_name}`}
                  src={`${value.user.profile_avatar.url}`}
                />
              ) : (
                <Avatar
                  sx={{ bgcolor: deepOrange[500] }}
                  src="/broken-image.jpg"
                  alt={`${value.user.first_name}`}
                />
              )}
            </ListItemAvatar>

            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: "inline",
                      fontSize: "16px",
                      fontWeight: "500",
                      letterSpacing: "1px",
                      color: "purple",
                    }}
                    variant="body2"
                    color="text.primary"
                  >
                    <Link
                      sx={{
                        display: "inline",
                        fontWeight: "500",
                        paddingRight: "10px",
                        fontSize: "16px",
                      }}
                      component="button"
                      variant="body2"
                      onClick={() => {
                        navigateToUserProfile(value.user.id);
                      }}
                    >
                      {value.user.first_name} {value.user.last_name}
                    </Link>
                    {`has ${value.action} a ${value.model}.`}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{
                      display: "inline",
                      fontWeight: "500",
                      paddingRight: "10px",
                    }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Project Name:
                  </Typography>
                  <Link
                    sx={{
                      display: "inline",
                      fontWeight: "500",
                      paddingRight: "10px",
                    }}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      navigateToProject(value.project.id);
                    }}
                  >
                    {value.project.name}
                  </Link>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginTop: "10px",
                      textAlign: "right",
                      color: "#5f5d5d",
                      fontWeight: "600",
                      fontFamily: "Roboto, sans-serif",
                      textTransform: "lowercase",
                    }}
                    variant="body2"
                    color="text.primary"
                  >
                    {value.action}: {displayDate(value.created_at).value}
                    &nbsp;
                    {displayTime(
                      displayDate(value.created_at).unit,
                      displayDate(value.created_at).value
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </Fragment>
      );
    });

    return (
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>{results}</List>
    );
  };

  const DrawerList = (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      sx={{
        width: "380px",
        overflowX: "hidden",
      }}
    >
      <div className={styles.recent_activity_mobile_container_head}>
        <QuestionAnswerIcon
          color="secondary"
          fontSize="medium"
          sx={{
            paddingLeft: "2px",
            marginRight: "10px",
            marginTop: "-5px",
          }}
        />
        <h6>
          <b>Recent Activity</b>
        </h6>
      </div>
      <Divider />
      {displayActivites()}
    </Box>
  );

  return (
    <div>
      <Drawer
        open={open.notificationPage}
        onClose={toggleDrawer(false)}
        anchor={"right"}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default MobileEngagementInfo;
