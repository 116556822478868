import React from "react";
import DashBoardDesktop from "./DashBoardDesktop";
import DashBoardMobile from "./DashBoardMobile";

const DashBoardMainView = (props) => {
  return (
    <>
      <DashBoardDesktop props={props} />
      <DashBoardMobile props={props} />
    </>
  );
};

export default DashBoardMainView;
