import { BASEURL } from "../../API/apiData";

const getAllDashBoardContents = async () => {
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(`${BASEURL}dashboard_content`, requestOptions);
  const results = await response.json();
  return results;
};

export default getAllDashBoardContents;
