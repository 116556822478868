import ListIcon from "@mui/icons-material/List";
import ChecklistIcon from "@mui/icons-material/Checklist";
import GroupsIcon from "@mui/icons-material/Groups";
import TopicIcon from "@mui/icons-material/Topic";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssistantIcon from "@mui/icons-material/Assistant";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import MessageIcon from "@mui/icons-material/Message";
import EditNoteIcon from "@mui/icons-material/EditNote";

const features = [
  {
    name: "Projects",
    image: (
      <TopicIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#A6374C", "#FD868C"],
    route: "projects",
  },
  {
    name: "Networks",
    image: (
      <AccountTreeIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#9a9a1e", "#e9e94e"],
    route: "networks",
  },
  {
    name: "Suggestion Box",
    image: (
      <AssistantIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#ff8b00", "#fab25b"],
    route: "suggestion",
  },
  {
    name: "TOP1O System",
    image: (
      <ListIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#091F3E", "#3F6BB6"],
    route: "categories",
  },
  {
    name: "TUC 1/1O",
    image: (
      <GroupsIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#05bab1", "#4fe4dd"],
    route: "roles",
  },
  {
    name: "OI System",
    image: (
      <ChecklistIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#084337", "#2DC56E"],
    route: "openitems",
  },

  {
    name: "Needs",
    image: (
      <Diversity3Icon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#cf1b1b", "#e44747"],
    route: "needs",
  },
  {
    name: "Library",
    image: (
      <CollectionsBookmarkIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#1413eb", "#5b5beb"],
    route: "library",
  },
  {
    name: "Notice Board",
    image: (
      <EditNoteIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#400466", "#c28ae6"],
    route: "updates",
  },
  {
    name: "Message Board",
    image: (
      <MessageIcon
        sx={{
          fontSize: "28px",
          color: "purple",
        }}
      />
    ),
    colors: ["#39393b", "#959799"],
    route: "under_construction",
  },
];

export default features;
