import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import ProjectTopTenEntryEditModal from "./ProjectTopTenEntryEditModal";
import _ from "lodash";
import IconButton from "@mui/material/IconButton";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CommentBox from "../globals/CommentBox";
import {
  createNewCommentReponseForTop10Entry,
  createProjectTop10EntryComment,
  editCommentReponseForTop10Entry,
  editOfProjectTopEntryComment,
  getOneProjectTop10Entry,
} from "../../../services/ProjectTop10/projectTop10";
import {
  unvoteProjectAssumption,
  upvoteProjectAssumption,
} from "../../../services/projects/ProjectAssumptionService";

const ProjectTop10EntryCard = ({
  idx,
  data,
  handleSubmit,
  handleVote,
  showProfiles,
  projectID,
  top10ID,
  fetchEntries,
}) => {
  const [details, showDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [userLiked, setUserLiked] = useState(data.user_liked);
  const [votes, setVotes] = useState(data.votes);
  // <==== THIS WILL HANDLE THE COMMENTS IN TOP 10 ENTRIES ====>
  const [commentModal, setCommentModal] = useState(false);
  const [top10Comments, setTop10Comments] = useState(data.comments);
  const [commentBoxDetail, setCommentBoxDetail] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  const [editSubmitButton] = useState(false);
  const [top10Selection, setTop10Selection] = useState({
    name: "",
  });

  const voteEntry = () => {
    const xData = {
      likeable_type: "ProjectTopTenEntry",
      likeable_id: data.id,
    };
    handleVote(xData);
    setUserLiked(!userLiked);
    if (userLiked) {
      setVotes((prev) => prev - 1);
    } else {
      setVotes((prev) => prev + 1);
    }
  };

  const openEditModal = () => {
    setOpen(true);
  };

  const closeEditModal = () => {
    setOpen(false);
  };

  const displayProjectInfo = () => {
    let inputValues = _.keys(data.entry);
    const results = inputValues.map((value, idx) => {
      return (
        <div key={idx} className="value-display-container">
          <span className="badge badge-secondary value-title">{value} </span>
          <span className="entry-values-tt">{data.entry[value]} </span>
        </div>
      );
    });

    return results;
  };

  const displayLikedProfiles = (profiles) => {
    //  If no profiles have liked this entry yet, show 'null'.
    if (profiles.length < 1) return null;
    return (
      <AvatarGroup max={2} onClick={() => showProfiles(profiles)}>
        {profiles.map((value, index) => {
          return (
            <Avatar
              key={index}
              alt={`${value.profile.first_name}  ${value.profile.last_name}`}
              src={`${
                value.profile.profile_avatar
                  ? value.profile.profile_avatar.url
                  : ""
              }`}
            />
          );
        })}
      </AvatarGroup>
    );
  };
  // <==== METHODS FOR HANDLING THE COMMENTS ====
  const refreshEntries = () => {
    fetchEntries();
  };

  const refreshEntriesComments = async () => {
    const comment = {
      project_id: projectID,
      project_top_ten_id: top10ID,
      id: data.id,
    };
    try {
      const { message, result } = await getOneProjectTop10Entry(comment);
      if (message === "Entry Found") {
        setTop10Comments(result.comments);
      }
    } catch (error) {
      console.log(
        error,
        "There was an error creating the assumptions comment in projects"
      );
    }
  };

  const handleCreateComment = async () => {
    setSubmitButton(true);
    const comment = {
      project_id: projectID,
      project_top_ten_id: top10ID,
      project_top_ten_entry_id: data.id,
      commentData: {
        comment: {
          content: commentBoxDetail,
          project_top_ten_entry_id: data.id,
        },
      },
    };
    try {
      const { message } = await createProjectTop10EntryComment(comment);
      if (message === "Comment created succesfully") {
        NotificationManager.success("New Comment", "Created successfully");
        refreshEntriesComments();
        setCommentBoxDetail("");
        setSubmitButton(false);
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error creating the assumptions comment in projects"
      );
      NotificationManager.error("Error", "Failed to create new comment");
      setSubmitButton(false);
    }
  };

  const handleEditOfComment = async (editState, setEditState) => {
    const { itemId, content } = editState;
    const comment = {
      project_id: projectID,
      project_top_ten_id: top10ID,
      project_top_ten_entry_id: data.id,
      id: itemId,
      commentData: {
        comment: {
          content: content,
          project_top_ten_entry_id: data.id,
        },
      },
    };
    try {
      const { message } = await editOfProjectTopEntryComment(comment);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Comment edited successfully");
        refreshEntriesComments();
        setEditState({
          form: false,
          itemId: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "There was an error Editing the Top 10 Entry Comment in projects"
      );
      NotificationManager.error("Error", "Failed to edit comment");
      setEditState({
        form: false,
        itemId: null,
        content: "",
      });
    }
  };
  const handleUpvoteOfComment = async (item) => {
    const data = {
      upvote: {
        upvoteable_id: item.id,
        upvoteable_type: "ProjectTopTenEntriesComment",
      },
    };
    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshEntriesComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfComment = async (item) => {
    const data = {
      upvoteable_id: item.id,
      upvoteable_type: "ProjectTopTenEntriesComment",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshEntriesComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  const handleCreateOfCommentReponses = async (replyState, setReplyState) => {
    const { itemReplyId, replyContent } = replyState;

    const comment = {
      project_id: projectID,
      project_top_ten_id: top10ID,
      project_top_ten_entry_id: data.id,
      project_top_ten_entries_comment_id: itemReplyId,
      commentData: {
        comment: {
          content: replyContent,
          project_top_ten_entries_comments_id: itemReplyId,
        },
      },
    };

    try {
      const { message } = await createNewCommentReponseForTop10Entry(comment);
      if (message === "Comment created succesfully") {
        NotificationManager.success("Response created!!!");
        refreshEntriesComments();
        setReplyState({
          display: true,
          itemReplyId: itemReplyId,
          replyContent: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the creation of comment response in the top10entry"
      );
      NotificationManager.error("Error", "Failed to respond to comment");
      setReplyState({
        display: false,
        itemReplyId: null,
        replyContent: "",
      });
    }
  };

  const handleEditOfCommentResponses = async (
    editCommentResponse,
    setEditCommentResponses,
    replyState
  ) => {
    const { responseID, content } = editCommentResponse;
    const { itemReplyId } = replyState;

    const comment = {
      project_id: projectID,
      project_top_ten_id: top10ID,
      project_top_ten_entry_id: data.id,
      project_top_ten_entries_comment_id: itemReplyId,
      id: responseID,
      commentData: {
        comment: {
          content: content,
          project_top_ten_entries_comments_id: itemReplyId,
        },
      },
    };

    try {
      const { message } = await editCommentReponseForTop10Entry(comment);
      if (message === "Comment updated successfully") {
        NotificationManager.success("Edited sucessfully!!!");
        refreshEntriesComments();
        setEditCommentResponses({
          form: false,
          responseID: null,
          content: "",
        });
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the edit of project top 10 comments response"
      );
      NotificationManager.error("Error", "Failed to Edit");
      setEditCommentResponses({
        form: false,
        responseID: null,
        content: "",
      });
    }
  };

  const handleUpvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvote: {
        upvoteable_id,
        upvoteable_type: "ProjectTopTenEntriesCommentResponse",
      },
    };

    try {
      const { message } = await upvoteProjectAssumption(data);
      if (message === "upvote successful") {
        NotificationManager.success("Comment liked");
        refreshEntriesComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the upvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to upvote comment");
    }
  };

  const handleUnvoteOfCommentResponses = async (upvoteable_id) => {
    const data = {
      upvoteable_id,
      upvoteable_type: "ProjectTopTenEntriesCommentResponse",
    };
    try {
      const { message } = await unvoteProjectAssumption(data);
      if (message === "Your vote has been removed") {
        NotificationManager.success("You have unvoted this comment");
        refreshEntriesComments();
      }
      return;
    } catch (error) {
      console.log(
        error,
        "Something went wrong with the unvote of project assumption comments"
      );
      NotificationManager.error("Error", "Failed to unvote comment");
    }
  };

  return (
    <>
      <ProjectTopTenEntryEditModal
        id={data.id}
        isOpen={open}
        closeModal={closeEditModal}
        object={data.entry}
        handleSubmit={handleSubmit}
      />
      <CommentBox
        open={commentModal}
        setOpen={setCommentModal}
        comments={top10Comments}
        handleCreateComment={handleCreateComment}
        detail={commentBoxDetail}
        setCommentBoxDetail={setCommentBoxDetail}
        submitButton={submitButton}
        setSubmitButton={setSubmitButton}
        label="Top10 Entry"
        labelDetails={top10Selection.name}
        handleEditOfComment={handleEditOfComment}
        editSubmitButton={editSubmitButton}
        refreshGoals={refreshEntries}
        handleUpvoteOfComment={handleUpvoteOfComment}
        handleUnvoteOfComment={handleUnvoteOfComment}
        handleCreateOfCommentReponses={handleCreateOfCommentReponses}
        handleEditOfCommentReponses={handleEditOfCommentResponses}
        handleUpvoteOfCommentResponses={handleUpvoteOfCommentResponses}
        handleUnvoteOfCommentResponses={handleUnvoteOfCommentResponses}
        commentBoxIdea={""}
        commentBoxPurpose={""}
      />
      <div className="project-tt-entry-card mt-2 shadow">
        <div className="project-tt-entry-card-header">
          <div className="project-entry-name-tt">
            <span className="project-tt-entry-card-header-idx">{idx + 1}</span>
            <span>{data.entry["Name"]}</span>
          </div>
          <div className="project-tt-entry-card-btns">
            {displayLikedProfiles(data.upvoted_by)}
            <button type="button" onClick={voteEntry}>
              {userLiked ? (
                <i className="fas fa-heart text-danger"></i>
              ) : (
                <i className="fas fa-heart"></i>
              )}
              &nbsp;&nbsp;{votes}
            </button>
            <IconButton
              aria-label="Comments"
              onClick={() => {
                setTop10Selection({
                  name: data.entry["Name"],
                });
                setCommentModal(true);
              }}
            >
              <Badge badgeContent={top10Comments.length} color="secondary">
                <ChatBubbleRoundedIcon />
              </Badge>
            </IconButton>

            <button
              type="button"
              onClick={openEditModal}
              className="edit-top-entrty-btn"
            >
              <i className="fas fa-edit text-primary"></i>
            </button>
            <div>
              <button
                className="p-ttdet-btn"
                onClick={() => showDetails(!details)}
              >
                <i className="fas fa-chevron-down mt-2"></i>
              </button>
            </div>
          </div>
        </div>
        {details ? (
          <div className="card">
            <div className="card-body">{displayProjectInfo()}</div>
            <div className="ptt-e-creator card-footer">
              <h6 className="text-center">Created by: {data.created_by}</h6>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ProjectTop10EntryCard;
