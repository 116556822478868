import _ from "lodash";
import React, { useState, useEffect } from "react";
import EngagementCard from "../components/engagement/EngagementCard";
import Navbar from "../components/navbar/Navbar";
import {
  fetchEngagementStats,
  fetchUserEngagements,
  fetchProjectEngagements,
  fetchSuggestionEngagements
} from "../services/engagement/engagementService";
import avatar from "../images/avatar.jpeg";
import "../stylesheets/engagement.css";

const EngagementManagerContainer = () => {
  const [stats, setStats] = useState([]);
  const [userEngagements, setUserEngagements] = useState([]);
  const [projectEngagements, setProjectEngagements] = useState([]);
  const [suggestionEngagements, setSuggestionEngagements] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [tabNo, setTabNo] = useState(0);
  const [title, setTitle] = useState("User")

  const getStats = async () => {
    try {
      const result = await fetchEngagementStats();
      setStats(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserEngagements = async () => {
    try {
      const result = await fetchUserEngagements();
      setUserEngagements(result);
      setSearchResults(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectEngagements = async () => {
    try {
      const result = await fetchProjectEngagements();
      setProjectEngagements(result);
      setSearchResults(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getSuggestionEngagements = async () => {
    try {
      const result = await fetchSuggestionEngagements();
      setSuggestionEngagements(result);
      setSearchResults(result);
    } catch (error) {
      console.log(error);
    }
  };

  const filterResults = (value) => {
    if (!_.isEmpty(stats)) {
      switch (tabNo) {
        case 0:
           setSearchResults(
             userEngagements.filter((stat) => stat.name.includes(value))
           );
          break;
        case 1:
           setSearchResults(
             projectEngagements.filter((stat) => stat.name.includes(value))
           );
           break;
        case 2:
           setSearchResults(
             suggestionEngagements.filter((stat) => stat.name.includes(value))
           );
           break;
        default:
          setSearchResults(
             userEngagements.filter((stat) => stat.name.includes(value))
           );
          break;
      }
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      getStats();
      getProjectEngagements();
    }
    return () => {
      isSubscribed = false;
    };
  }, []);

  const renderStats = () => {
    if (!_.isEmpty(stats)) {
      return (
        <>
          <div className="admin-header">
            <span>
              <i className="fa fa-area-chart" aria-hidden="true"></i>
              &nbsp;&nbsp;TUC Engagement Manager
            </span>
          </div>
          <div className="engagement-stats-container">
            <div className="engagement-stats-card">
              <h4 className="text-center">Most Engaged Project</h4>
              <img
                src={"https://cdn-icons-png.flaticon.com/512/1087/1087815.png"}
                alt="icon"
              />
              <h5>{stats.most_engaged_project.project.name}</h5>
              <h6 className="text-success">
                {stats.most_engaged_project.count} <span>engagements</span>
              </h6>
            </div>
            <div className="engagement-stats-card">
              <h4 className="text-center">Most Engaged Suggestion</h4>
              <img
                src={
                  "https://media.istockphoto.com/id/501685831/zh/%E5%90%91%E9%87%8F/suggestion-box-with-feedback-notes.jpg?s=612x612&w=0&k=20&c=TLvtmRJfBakxAPdpzpenBnKnE-A-3ER-hvpBwLQ_0dM="
                }
                alt="icon"
              />
              <h5>{stats.most_engaged_suggestion.suggestion.idea}</h5>
              <h6 className="text-success">
                {stats.most_engaged_suggestion.count} <span>engagements</span>
              </h6>
            </div>
            <div className="engagement-stats-card">
              <h4 className="text-center">Most Engaged User</h4>
              <img
                src={
                  stats.most_engaged_user.profile.profile_avatar.url
                    ? stats.most_engaged_user.profile.profile_avatar.url
                    : avatar
                }
                alt="icon"
              />
              <h5>{`${stats.most_engaged_user.profile.first_name} ${stats.most_engaged_user.profile.last_name}`}</h5>
              <h6 className="text-success">
                {stats.most_engaged_user.count} <span>engagements</span>
              </h6>
            </div>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const renderSearch = () => {
    if (
      !_.isEmpty(userEngagements) ||
      !_.isEmpty(projectEngagements) ||
      !_.isEmpty(suggestionEngagements)
    ) {
      return (
        <>
          <div className="engagement-search-bar p-3">
            <input
              className="form-control"
              type="text"
              name="search"
              placeholder={`Search ${title} to view statistics`}
              onChange={(e) => filterResults(e.target.value)}
            />
          </div>
          <div className="d-flex flex-column p-2">
            {searchResults.map((data, idx) => (
              <EngagementCard key={idx} idx={idx} data={data} />
            ))}
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Navbar />
      {renderStats()}
      <div className="p-3 d-flex justify-content-between p-3">
        <button
          className="btn btn-info w-25"
          onClick={() => {
            setTabNo(1);
            setTitle("Project");
            getProjectEngagements();
          }}
        >
          Project Engagement
        </button>
        <button
          className="btn btn-success w-25"
          onClick={() => {
            setTabNo(2);
            setTitle("Suggestion");
            getSuggestionEngagements();
          }}
        >
          Suggestions Engagement
        </button>
        <button
          className="btn btn-primary w-25"
          onClick={() => {
            setTabNo(0);
            setTitle("User");
            getUserEngagements();
          }}
        >
          User Engagement
        </button>
      </div>
      {renderSearch()}
    </>
  );
};

export default EngagementManagerContainer;
