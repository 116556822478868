import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectGoalObjectiveModal = ({
  objectiveModal,
  setObjectiveModal,
  handleCreateNewObjective,
  projectID,
  goalID,
}) => {
  const [state, setState] = useState("");

  const handleCreate = () => {
    const data = {
      project_id: projectID,
      goal_id: goalID,
      name: state,
    };

    handleCreateNewObjective(data);
    setState("");
    setObjectiveModal(false);
  };

  return (
    <Dialog
      open={objectiveModal}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          color: "purple",
          textTransform: "uppercase",
          fontWeight: "600",
          textDecoration: "underline",
        }}
      >
        Add New Objective
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            marginTop: "10px",
          }}
        >
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={state}
            onChange={(event, editor) => {
              const data = editor.getData();
              setState(data);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} variant="contained" color="secondary">
          create
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setObjectiveModal(false)}
        >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectGoalObjectiveModal;
