/*eslint-disable */
import React, { useEffect, useState } from "react";
import BmcBlocksComponent from "../components/singleProject/bmc/BmcBlocksComponent";
import BmcInfoModal from "../components/singleProject/bmc/BmcInfoModal";
import CreateNewBmcModal from "../components/singleProject/bmc/CreateNewBmcModal";
import CreateNewListingModal from "../components/singleProject/bmc/createNewListingModal";
import EditListModal from "../components/singleProject/bmc/EditListModal";
import NoBmcComponent from "../components/singleProject/bmc/NoBmcComponent";
import { createNewBmc, getAllBmc } from "../services/bmc/projectBmc";

import "../stylesheets/bmc.css";

function BmcContainer({ projectID, singleProject, setBmcLength }) {
  const { data } = singleProject;
  let projectName = data.result.project.name;

  const [listItems, setListItems] = useState([]);
  const [bmcInfo, setBmcInfo] = useState({});
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [listingShow, setListingShow] = useState(false);
  const [creatingList, setCreatingList] = useState({});
  const [blockInfo, setBlockInfo] = useState({});
  const [fetchlistingFunction, setFetchListingFunction] = useState({});
  const [items, setItems] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [listingEditShow, setlistingEditShow] = useState(false);
  const [listEditInfo, setListEditInfo] = useState("");
  const [editListItem, setEditList] = useState([]);
  const [itemID, setItemID] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlelistingModalClose = () => setListingShow(false);
  const handlelistingModalShow = () => setListingShow(true);

  const fetchAllBmc = async () => {
    const { message, result } = await getAllBmc(projectID);
    if (message === "BCM not available") {
      setLoader(false);
      setBmcInfo({
        message,
      });
      return;
    } else if (message === "BCM found successfully") {
      setBmcInfo({
        message,
        title: result.title,
        description: result.description,
        id: result.id,
      });
      setBmcLength(1)
      setLoader(false);
      return;
    }
  };

  useEffect(() => {
    fetchAllBmc();
  }, []);

  const createNewProjectBmc = async (bmcDescription) => {
    setLoader(true);
    try {
      const { message, result } = await createNewBmc(
        projectID,
        projectName,
        bmcDescription
      );
      if (message === "BCM created successfuly") {
        setBmcInfo({
          message,
          title: result.title,
          description: result.description,
          id: result.id,
        });
        fetchAllBmc();
        setLoader(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const displayLoader = () => {
    return (
      <>
        <div className="loader-items-sort">
          <span
            className="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Loading...</span>
        </div>
        <div className="text-center">creating bmc... </div>
      </>
    );
  };

  const displayInfo = () => {
    if (bmcInfo.message === "BCM not available") {
      return (
        <NoBmcComponent
          message={bmcInfo.message}
          loader={loader}
          setShow={setShow}
          setShowInfoModal={setShowInfoModal}
        />
      );
    } else if (bmcInfo.message === "BCM found successfully") {
      return (
        <BmcBlocksComponent
          setCreatingList={setCreatingList}
          projectName={projectName}
          projectID={projectID}
          bmcID={bmcInfo.id}
          setLoader={setLoader}
          setListingShow={setListingShow}
          setBlockInfo={setBlockInfo}
          setListItems={setListItems}
          listItems={listItems}
          setFetchListingFunction={setFetchListingFunction}
          setItems={setItems}
          blockInfo={blockInfo}
          setShowInfoModal={setShowInfoModal}
          setlistingEditShow={setlistingEditShow}
          setListEditInfo={setListEditInfo}
          setEditList={setEditList}
          setItemID={setItemID}
          bmcInfo={bmcInfo}
        />
      );
    }
  };

  return (
    <div>
      <EditListModal
        setlistingEditShow={setlistingEditShow}
        listingEditShow={listingEditShow}
        listEditInfo={listEditInfo}
        projectID={projectID}
        bmcID={bmcInfo.id}
        blockInfo={blockInfo}
        fetchlistingFunction={fetchlistingFunction}
        items={items}
        setListItems={setListItems}
        editListItem={editListItem}
        itemID={itemID}
      />
      <BmcInfoModal
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
      />
      <CreateNewListingModal
        listingShow={listingShow}
        setListingShow={setListingShow}
        handlelistingModalClose={handlelistingModalClose}
        handlelistingModalShow={handlelistingModalShow}
        creatingList={creatingList}
        projectID={projectID}
        bmcID={bmcInfo.id}
        blockInfo={blockInfo}
        setListItems={setListItems}
        fetchlistingFunction={fetchlistingFunction}
        items={items}
      />
      <CreateNewBmcModal
        handleClose={handleClose}
        handleShow={handleShow}
        show={show}
        projectName={projectName}
        createNewProjectBmc={createNewProjectBmc}
        setBmcLength={setBmcLength}
      />
      {loader === true ? displayLoader() : displayInfo()}
    </div>
  );
}

export default BmcContainer;
