import React from 'react'
import { Link } from 'react-router-dom';

const PrivacyWarning = ({title}) => {
  return (
    <>
      <div className="w-100 text-center mt-5 p-5">
        <i className="fa fa-user-secret fa-3x" aria-hidden="true"></i>
        <h3 className="text-danger">This {title} is private</h3>
        {title === "Project" ? (
          <Link to={"/projects"}>Go back</Link>
        ) : (
          <Link to={"/users"}>Go back</Link>
        )}
      </div>
    </>
  );
}

export default PrivacyWarning