import React from "react";
import LandingBtn from "./button";
import homeWall from "../../assets/img/prospect.png";
import "../../stylesheets/landingPage.css";

function Prospect() {
  return (
    <div className="card border-0">
      <div className="row no-gutters flex-column-reverse flex-lg-row">
        <div className="col-lg-6 prospect-text">
          <div className="card-body">
            <h2 className="card-title font-weight-bold text-warning">
              This Is What The Future Looks Like!
            </h2>
            <p className="card-text prospect-card-text">
              At TUC we feel that it only makes sense that our female population
              would lead an effort to rid the world of unnecessary waste and
              barriers. We are to develop regenerative systems which acknowledge
              the value of all resources and identify gaps, which might be the
              missing pieces to self-sufficiency and a sustainable future.
            </p>

            <p className="card-text prospect-card-text">
              We are developing regenerative systems which acknowledge the value
              of all people and resources to identify gaps, which might be the
              missing pieces to self-sufficiency and a sustainable future.
            </p>
            <h1 className="card-text prospect-card-text font-weight-bold text prospect-12">
              Please join us in helping reimagine the old adage Think Global Act
              Local.
            </h1>
            <div>
              <LandingBtn name="In development" />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="">
            <img src={homeWall} alt="" className="img-fluid prospect-img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prospect;
