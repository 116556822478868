/*eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "../stylesheets/masterNeeds.css";
import "../stylesheets/library.css";
import {
  createMasterInventory,
  fetchMasterInventory,
  updateMasterInventory,
} from "../services/library/libraryService";
import LibraryCard from "../components/library/LibraryCard";
import _ from "lodash";
import LibraryModal from "../components/library/LibraryModal";

const LibraryContainer = ({ match }) => {
  const libId = match.params.id;

  const [category, setCategory] = useState();
  const [open, setOpen] = useState(false);
  const [inventories, setInventories] = useState([]);

  const getMasterInventory = async (id) => {
    try {
      const data = await fetchMasterInventory(id);
      setInventories(data.result.inventories);
      setCategory(data.result.category);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMasterInventory(libId);
  }, []);

  const createInventory = async (data) => {
    try {
      await createMasterInventory(data);
      getMasterInventory(libId);
      toast.success("List added successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const updateInventory = async (id, data) => {
    try {
      await updateMasterInventory(id, data);
      getMasterInventory(libId);
      toast.warning("List updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleUpdateInventory = (id, data) => {
    updateInventory(id, data);
  };

  const handleCreateInventory = (data) => {
    createInventory(data);
  };

  const showInventory = () => {
    if (_.isEmpty(inventories)) {
      return (
        <h4 className="text-danger text-center">
          <i className="fa fa-frown-o" aria-hidden="true"></i>
          &nbsp; Sorry No Inventory Available
        </h4>
      );
    } else {
      return inventories.map((inv, idx) => (
        <LibraryCard
          key={inv.inventory.id}
          idx={idx}
          data={inv}
          master={true}
          priv={true}
          handleSubmit={handleUpdateInventory}
        />
      ));
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <LibraryModal
        data={null}
        category={libId}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleCreateInventory}
        master={true}
      />
      <div className="master-need-container">
        <div className="master-needs-header">
          <h2 className="app-head-title ml-1">
            &nbsp;<Link to={"/library"}>Categories</Link> {">"} {category}
          </h2>
          <button type="button" onClick={openModal}>
            Add New
          </button>
        </div>
        <div className="master-need-card-container">{showInventory()}</div>
      </div>
    </>
  );
};

export default LibraryContainer;
