/*eslint-disable */
import React, { useState } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import NeedSupport from "./NeedSupport";
import NeedEditModal from "./NeedEditModal";

const NeedCard = ({ idx, data, master, projects,categories, handleSubmit }) => {

  const [details, setDetails] = useState(false);
  const [support, setSupport] = useState(false);
  const [open, setOpen] = useState(false)
  
  const openModal = ()  => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleClose = () => {
     const xData = {
       need: {
        is_procured: true,
       },
     };
    if (confirm("Are you sure you want to close this need?"))
      handleSubmit(data.id, xData);
  }

  return (
    <>
      <NeedEditModal
        data={data}
        isOpen={open}
        closeModal={closeModal}
        projects={projects}
        categories={categories}
        handleSubmit={handleSubmit}
      />
      <div className="master-need-card shadow-lg">
        <div className="master-need-card-header">
          <h5 className="p-2">
            <i className="fa fa-ticket" aria-hidden="true">
              &nbsp;{idx + 1}
            </i>
            &nbsp;{data.name}
          </h5>
          <button onClick={() => setDetails(!details)}>
            <i className="fa fa-chevron-down mt-2"></i>
          </button>
        </div>
        {details ? (
          <div className="master-need-card-body">
            <div className="master-need-card-body-details">
              <h6>
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                &nbsp; Purpose
              </h6>
              <div className="master-need-card-body-info">
                <div className="master-need-card-body-info-top">
                  {parse(data.description)}
                </div>
                <div className="master-need-card-body-info-bottom">
                  <div>
                    {master ? (
                      <>
                        <i className="fas fa-project-diagram text-info pl-3"></i>
                        &nbsp;&nbsp;
                        <span>Project</span>&nbsp;&nbsp;
                        <i className="fa fa-chevron-right text-info"></i>
                        &nbsp;&nbsp;
                        <Link to={`/projects/${data.project.id}`}>
                          {data.project.name}
                        </Link>
                      </>
                    ) : null}
                  </div>
                  <div>
                    <button type="button" onClick={openModal}>
                      <i className="fas fa-edit text-primary"></i>
                      &nbsp; Edit
                    </button>
                    {data.is_procured ? null : (
                      <button
                        type="button"
                        onClick={() => setSupport(!support)}
                      >
                        <i className="fas fa-medkit text-info"></i>
                        &nbsp; Show Support
                      </button>
                    )}
                    {data.is_procured ? null : (
                      <button type="button" onClick={handleClose}>
                        <i className="fas fa-times-circle text-danger"></i>
                        &nbsp; Close
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {support ? <NeedSupport id={data.id} /> : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default NeedCard;
