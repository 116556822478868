import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_INVENTORY_REQUEST, CREATE_INVENTORY_SUCCESS, CREATE_INVENTORY_FAILURE, FETCH_INVENTORY_FAILURE, FETCH_INVENTORY_SUCCESS, FETCH_INVENTORY_REQUEST } from "./inventoryTypes";

export const createInventoryRequest = () =>({
    type: CREATE_INVENTORY_REQUEST,
  })
  
  export const createInventorySuccess = (response) =>({
    type: CREATE_INVENTORY_SUCCESS,
    payload: response
  })
  
  export const createInventoryFailure = (error) =>({
    type: CREATE_INVENTORY_FAILURE,
    payload: error
  })

  export const fetchInventoryRequest = () =>({
    type: FETCH_INVENTORY_REQUEST,
  })
  
  export const fetchInventorySuccess = (response) =>({
    type: FETCH_INVENTORY_SUCCESS,
    payload: response
  })
  
  export const fetchInventoryFailure = (error) =>({
    type: FETCH_INVENTORY_FAILURE,
    payload: error
  })
  
export const fetchInventory = (profileID) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchInventoryRequest());
    return axios.get(`${BASEURL}profiles/${profileID}/inventories`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchInventorySuccess(response.data));
      }).catch(error => {
        return dispatch(fetchInventoryFailure(error));
      });
  }


  export const createInventory = (profileID, data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createInventoryRequest());
    return axios.post(`${BASEURL}profiles/${profileID}/inventories`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    } )
      .then(response => {
        return dispatch(createInventorySuccess(response.data));
      }).catch(error => {
        return dispatch(createInventoryFailure(error));
      });
  };
  