import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_PROJECTSUGGESTIONCOMMENT_REQUEST, 
  CREATE_PROJECTSUGGESTIONCOMMENT_SUCCESS, CREATE_PROJECTSUGGESTIONCOMMENT_FAILURE} from './projectSuggestionCommentTypes'

  export const createProjectSuggestionCommentRequest = () =>({
    type: CREATE_PROJECTSUGGESTIONCOMMENT_REQUEST,
  })
  
  export const createProjectSuggestionCommentSuccess = (response) =>({
    type: CREATE_PROJECTSUGGESTIONCOMMENT_SUCCESS,
    payload: response
  })
  
  export const createProjectSuggestionCommentFailure = (error) =>({
    type: CREATE_PROJECTSUGGESTIONCOMMENT_FAILURE,
    payload: error
  })

  export const createProjectSuggestionComment = (pId, psId,data) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(createProjectSuggestionCommentRequest());
    return axios.post(`${BASEURL}projects/${pId}/project_suggestions/${psId}/project_suggestion_comments`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    } )
      .then(response => {
        return dispatch(createProjectSuggestionCommentSuccess(response.data));
      }).catch(error => {
        return dispatch(createProjectSuggestionCommentFailure(error));
      });
  };