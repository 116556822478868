import { BASEURL } from "../../API/apiData";

const createNewDiscussionLike = async (projectID, discussionID, data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/discussion_likes`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const deleteNewDiscussionLike = async (projectID, discussionID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}/unvotediscussion`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const getOneDiscussion = async (projectID, discussionID) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/discussion/${discussionID}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export { createNewDiscussionLike, deleteNewDiscussionLike, getOneDiscussion };
