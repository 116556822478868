import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_ENTRIES_SUCCESS, 
  FETCH_ENTRIES_REQUEST, 
  FETCH_ENTRIES_FAILURE, 
  CREATE_ENTRY_REQUEST, 
  CREATE_ENTRY_SUCCESS, 
  CREATE_ENTRY_FAILURE} from './entryTypes'

  export const fetchEntriesRequest = () =>({
    type: FETCH_ENTRIES_REQUEST,
  })
  
  export const fetchEntriesSuccess = (response) =>({
    type: FETCH_ENTRIES_SUCCESS,
    payload: response
  })
  
  export const fetchEntriesFailure = (error) =>({
    type: FETCH_ENTRIES_FAILURE,
    payload: error
  })
  
  export const createEntryRequest = () =>({
    type: CREATE_ENTRY_REQUEST,
  })
  
  export const createEntrySuccess = (response) =>({
    type: CREATE_ENTRY_SUCCESS,
    payload: response
  })
  
  export const createEntryFailure = (error) =>({
    type: CREATE_ENTRY_FAILURE,
    payload: error
  })

  export const fetchEntries = (catId,tId) => dispatch => {
    const token = localStorage.getItem('token')
    dispatch(fetchEntriesRequest());
    return axios.get(`${BASEURL}categories/${catId}/top_tens/${tId}/top_ten_entries`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
        return dispatch(fetchEntriesSuccess(response.data));
      }).catch(error => {
        return dispatch(fetchEntriesFailure(error));
      });
  };


export const createEntry = (catId,tId,data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createEntryRequest());
  return axios.post(`${BASEURL}categories/${catId}/top_tens/${tId}/top_ten_entries/`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createEntrySuccess(response.data));
    }).catch(error => {
      return dispatch(createEntryFailure(error));
    });
};
