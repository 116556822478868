import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Modal from "react-bootstrap/Modal";
import {
  editProjectQuickLink,
  fetchAllQuickLinks,
} from "../../services/projects/ProjectQuickLink";

const ProjectEditOfQuickLinkModal = ({
  isOpen,
  onClose,
  editLink,
  projectId,
  setQuickLinks,
}) => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [quickID, setQuickID] = useState(null);

  useEffect(() => {
    setName(editLink.name);
    setLink(editLink.url);
    setQuickID(editLink.id);

    return () => {
      setName("");
      setLink("");
      setQuickID(null);
    };
  }, [editLink]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const data = {
      quikData: {
        project_quick_link: {
          name: name,
          url: link,
        },
      },
      id: quickID,
      projectId: projectId,
    };
    try {
      const { message } = await editProjectQuickLink(data);
      if (message === "Quick link updated successfuly") {
        const { result } = await fetchAllQuickLinks(projectId);
        NotificationManager.success("Quick Link Edited Succesfully");
        setQuickLinks(result);
        onClose();
      }
    } catch (error) {
      console.log(error, "Something is wrong editing if a quick link");
      onClose();
    }
  };
  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header
          className="text-white"
          style={{ backgroundColor: "#047C7C" }}
          closeButton
        >
          <Modal.Title>Add Quick Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                className="form-control"
                name="name"
                type="text"
                placeholder="type name here"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="link">Link</label>
              <input
                className="form-control"
                name="link"
                type="url"
                placeholder="type url here"
                required
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div className="form-group float-right">
              <button className="add-btn rounded text-white" type="submit">
                Edit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectEditOfQuickLinkModal;
