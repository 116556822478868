import React from "react";
import StatisticsCard from "./StatisticsCard";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";

const ProjectStatistics = ({ time }) => {
  const data = [
    <StatisticsCard
      heading={"Discovery Phase"}
      status={"In Progress"}
      bgc={"stc-dev"}
    />,
    <StatisticsCard
      heading={"Planning Phase"}
      status={"In Progress"}
      bgc={"stc-planning"}
    />,
    <StatisticsCard
      heading={"Design Phase"}
      status={"In Progress"}
      bgc={"stc-design"}
    />,
    <StatisticsCard
      heading={"Development Phase"}
      status={"In Progress"}
      bgc={"stc-dev"}
    />,
    <StatisticsCard
      heading={"Execution Phase"}
      status={"Not Started"}
      bgc={"stc-planning"}
    />,
    <StatisticsCard
      heading={"Pivot and Monitoring Phase"}
      status={"In Progress"}
      bgc={"stc-testing"}
    />,
    <StatisticsCard
      heading={"Expansion Phase"}
      status={"Not Started"}
      bgc={"stc-dev"}
    />,
    <StatisticsCard
      heading={"Exit Strategy"}
      status={"In Progress"}
      bgc={"stc-dev"}
    />,
    <StatisticsCard
      heading={"Full Potential"}
      status={"In Progress"}
      bgc={"stc-dev"}
    />,
  ];

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        py: 1,
        overflow: "auto",
        scrollSnapType: "x mandatory",
        "& > *": {
          scrollSnapAlign: "center",
        },
        "::-webkit-scrollbar": { display: "inline" },
      }}
    >
      {data.map((item, indx) => (
        <Card orientation="horizontal" size="sm" key={indx} variant="contained">
          <AspectRatio ratio="2" sx={{ minWidth: 60 }}>
            {item}
          </AspectRatio>
        </Card>
      ))}
    </Box>
  );
};

export default ProjectStatistics;
