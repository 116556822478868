import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./css/main.module.css";
import BottomAppBar from "./reusableComponents/BottomAppBar";
import InfoCard from "./reusableComponents/InfoCard";
import MostEngagedCard from "./reusableComponents/MostEngagedCard";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssistantIcon from "@mui/icons-material/Assistant";
import MostEngagedSuggestionCard from "./reusableComponents/MostEngagedSuggestionCard";
import LatestProject from "./reusableComponents/LatestProject";
import ReleaseNotesCardDisplay from "./reusableComponents/ReleaseNotesCardDisplay";
import TopBar from "./reusableComponents/TopBar";
import logo from "../../assets/img/logo.png";
// ==== SIDE BAR MENU ====
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { deepOrange } from "@mui/material/colors";
import MobileEngagementInfo from "./reusableComponents/MobileEngagementInfo";

const SidebarFooter = ({ profile }) => {
  const history = useHistory();
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const navigateTo = (profile) =>
    history.push(`/profile/${profile.profile_id}`);
  return (
    <>
      <div className={styles.footer_container}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          onClick={() => navigateTo(profile)}
          sx={{
            cursor: "pointer",
          }}
        >
          {profile.profile.profile_avatar &&
          profile.profile.profile_avatar.url !== null ? (
            <Avatar
              alt={`${profile.profile.first_name} ${profile.profile.last_name}`}
              src={`${profile.profile.profile_avatar.url}`}
              sx={{
                height: "70px",
                width: "70px",
              }}
            />
          ) : (
            <Avatar
              sx={{ bgcolor: deepOrange[500], height: "70px", width: "70px" }}
              src="/broken-image.jpg"
              alt={`${profile.profile.first_name}`}
            />
          )}
        </StyledBadge>
      </div>
      <div className={styles.footer_name}>
        <h5>{`${profile.profile.first_name} ${profile.profile.last_name}`}</h5>
        <h6>{`${profile.user.email}`}</h6>
      </div>
    </>
  );
};

const SidebarMenu = ({ features, profile, open, setOpen }) => {
  const history = useHistory();
  const toggleDrawer = (newOpen) => () => {
    setOpen({
      ...open,
      value: null,
      menuPage: newOpen,
    });
  };

  const navigateTo = (route) => history.push(`/${route}`);

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <div className={styles.mobile_menu_top_container}>
        <img
          className={styles.tuc_logo}
          src={logo}
          alt="nPmRzQ.png"
          border="0"
        />
      </div>
      <Divider />
      <List>
        {features.map((text, index) => (
          <ListItem
            key={text.name}
            className={styles.mobile_menu_text}
            onClick={() => navigateTo(text.route)}
          >
            <ListItemButton>
              <ListItemIcon>{text.image}</ListItemIcon>
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className={styles.mobile_menu_footer}>
        <SidebarFooter profile={profile} />
      </div>
    </Box>
  );
  return (
    <div>
      <Drawer open={open.menuPage} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};

const DashBoardMobile = ({ props }) => {
  const { profile, features, dashboardContent } = props;
  const [state, setState] = useState({
    value: null,
    menuPage: false,
    homePage: false,
    notificationPage: false,
  });
  return (
    <>
      <div className={styles.mobile_view}>
        <SidebarMenu
          features={features}
          profile={profile}
          open={state}
          setOpen={setState}
        />
        <MobileEngagementInfo
          open={state}
          setOpen={setState}
          dashboardContent={dashboardContent}
        />
        <TopBar profile={profile} />
        <div className={`container-fluid ${styles.main_area_info_container}`}>
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <InfoCard dashboardContent={dashboardContent} />
                </div>
                <div className="col-xl-6 col-sm-12">
                  <MostEngagedCard
                    title={"Most Engaged Project"}
                    icon={
                      <AccountTreeIcon
                        sx={{
                          fontSize: "32px",
                          marginRight: "5px",
                          fontWeight: "600",
                        }}
                      />
                    }
                    project_activity={dashboardContent.project_activity}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <MostEngagedSuggestionCard
                    title={"Most Engaged Suggestion"}
                    suggestion_activity={dashboardContent.suggestion_activity}
                    icon={
                      <AssistantIcon
                        sx={{
                          fontSize: "32px",
                          marginRight: "5px",
                          fontWeight: "600",
                        }}
                      />
                    }
                  />
                </div>
                <div className="col-xl-6 col-sm-12">
                  <LatestProject
                    title={"New Project"}
                    icon={
                      <AccountTreeIcon
                        sx={{
                          fontSize: "32px",
                          marginRight: "5px",
                          fontWeight: "600",
                        }}
                      />
                    }
                    project_activity={dashboardContent.recent_created_project}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <ReleaseNotesCardDisplay
                    release_activity={dashboardContent.recent_release_notes}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomAppBar value={state} setValue={setState} />
      </div>
    </>
  );
};

export default DashBoardMobile;
