/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { fetchTopTens, createTopTen } from "../redux";
import Navbar from "../components/navbar/Navbar"
import TopTenCard from "../components/TopTenCard";
import TopTenModal from "../components/TopTenModal";
import LoadingSpinner from "../components/LoadingSpinner";
import { useHistory } from "react-router-dom";

const TopTenContainer = ({ match, topTens, fetchTopTens, createTopTen }) => {
  const category_id = match.params.id;
  const history = useHistory();

  useEffect(() => {
    fetchTopTens(category_id);
  }, [fetchTopTens, category_id]);

  const [open, setOpen] = useState(false);

  const handleSubmit = (title, description, fields_attributes) => {
    const topTenData = {
      title: title,
      description: description,
      fields_attributes: fields_attributes,
    };
    createTopTen(category_id, topTenData);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const gotoPrevPage = () => {
    history.goBack();
  };

  const categoryName = () => {
    let cat_id_name = parseInt(category_id);
    const results = topTens.data.result.map((value, idx) => {
      if (idx === 0) {
        if (value.category_id === cat_id_name) return value.category_name;
      }
    });
    return results;
  };

  const showData = () => {
    if (!_.isEmpty(topTens.data.result) && topTens.data.loggedIn === true) {
      const results = topTens.data.result.map((t) => (
        <TopTenCard
          key={t.id}
          id={t.id}
          catId={category_id}
          title={t.title}
          entries={t.entries}
          description={t.description}
          currentUser={topTens.data.current_user.id}
        />
      ));

      return (
        <>
          <section className="top-ten-list">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-12 col-sm-6 category-top-10">
                  <span>{categoryName()}</span> <i className="fa fa-arrow-circle-right" aria-hidden="true"></i> <span> </span> <span>Top10 List</span>
                </div>
                <div className="col-md-4 col-12 col-sm-12 text-center header-name">
                  <h3>Top10 List</h3>
                </div>
                <div className="col-md-4 col-12  col-sm-6 text-right">
                  <button
                    className="add-btn rounded add-new-button"
                    id="btn-added-tt"
                    onClick={openModal}
                  >
                    <i className="fas fa-plus"></i> New List
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div className="container-fluid">
              <div className="row top-ten-list-body">
                {results}
              </div>
            </div>
          </section>
        </>
      );
    } else if (_.isEmpty(topTens.data) && topTens.errorMsg) {
      return (
        <h1 className="text-danger text-center p-3">
          {topTens.errorMsg.response.data.error}
        </h1>
      );
    } else if (
      _.isEmpty(topTens.data.result) &&
      topTens.data.loggedIn === true
    ) {
      return (
        <div className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <button className="add-btn rounded" onClick={gotoPrevPage}>
              Go Back
            </button>
            <button className="add-btn rounded" onClick={openModal}>
              New List
            </button>
          </div>
          <h1 className="text-center text-danger">No Lists available</h1>
        </div>
      );
    }

    if (topTens.loading) {
      return <LoadingSpinner />;
    }
  };

  const renderModal = () => {
    if (topTens.data.loggedIn === true && topTens.data.current_user.id) {
      return (
        <TopTenModal
          user={topTens.data.current_user.id}
          closeModal={closeModal}
          isOpen={open}
          handleSubmit={handleSubmit}
        />
      );
    }
  };

  return (
    <>
      <Navbar />
      <div className="top-ten-cont container-fluid">
        {renderModal()}
        <div>
          <br />
          {showData()}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  topTens: state.topTens,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTopTens: (id) => dispatch(fetchTopTens(id)),
  createTopTen: (id, data) => dispatch(createTopTen(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopTenContainer);
/* eslint-enable array-callback-return */
