import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const UserRoleModal = ({ users, open, closeModal, updateRole }) => {
  const [userId, setUserId] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      user_id: userId,
      is_vacant: false,
    };
    updateRole(data);
    closeModal();
  };

  return (
    <Modal show={open} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>Assign Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="users">Select User</label>
            <select
              className="form-control"
              name="users"
              onChange={(e) => setUserId(e.target.value)}
            >
              <option value={null}>None</option>
              {users.map((user) => (
                <option key={user.id} value={user.user_id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              Assign
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserRoleModal;
