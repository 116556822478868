import React from "react";
import LandingBtn from "./button";
import "../../stylesheets/landingPage.css";

const Pricing = () => {
  return (
    <div className="pricing8 py-5">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h3 className="mb-3 font-weight-bold">
              Future Pricing (subscription model)
            </h3>
            <h6 className="subtitle">
              Stop wasting time and money talking and reading about making the
              world a better place.
            </h6>
            <h6 className="subtitle">
              JOIN US in building THE ULTIMATE COLLABORATION (TUC), the world’s
              largest experiment, and help all Good Ideas reach their full
              potential.
            </h6>
            <h6 className="subtitle font-weight-bold ">
              Efficiency and Happiness Guaranteed!
            </h6>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-4 ml-auto pricing-box align-self-center">
            <div className="card mb-4">
              <div className="card-body p-4 text-center">
                <h5 className="font-weight-normal">AlphaBeta user</h5>
                <sup className="text-warning">$</sup>
                <span className="text-warning display-5">1</span>
                <h6 className="font-weight-light font-14">Per Year</h6>
                <div className="mt-4">
                  <ul>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>{" "}
                      Inclusive online community
                    </li>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>{" "}
                      Improved access to needed resources
                    </li>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>
                      Regenerative systems to increase the global distribution
                      of good ideas
                    </li>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>{" "}
                      Collective engagement for making the world a better place{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-5 mr-auto ml-auto">
                <LandingBtn name="Coming soon?" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 ml-auto pricing-box align-self-center">
            <div className="card mb-4">
              <div className="card-body p-4 text-center">
                <h5 className="font-weight-normal">Impact Investor</h5>
                <sup className="text-warning">$</sup>
                <span className="text-warning display-5">1</span>
                <h6 className="font-weight-light font-14">Per Year</h6>
                <div className="mt-4">
                  <ul>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>
                      Create opportunity and help good ideas reach full
                      potential{" "}
                    </li>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>{" "}
                      Member of @AdvisorsNetwork
                    </li>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>{" "}
                      Your suggestion here.....
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-5 mr-auto ml-auto">
                <LandingBtn name="Coming soon?" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 ml-auto pricing-box align-self-center">
            <div className="card mb-4">
              <div className="card-body p-4 text-center">
                <h5 className="font-weight-normal">Stratigic Partner</h5>
                <sup className="text-warning">$</sup>
                <span className="text-warning display-5">1</span>
                <h6 className="font-weight-light font-14">Per Year</h6>
                <div className="mt-4">
                  <ul>
                    <li className="mb-3">
                      <i className="fas fa-check-circle text-success mr-4"></i>{" "}
                      A Win-Win for sustainability, inclusion, diversity,
                      innovation and empowerment.{" "}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-5 mr-auto ml-auto">
                <LandingBtn name="Coming soon?" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
