import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProjectKpiEditModal = ({
  editKpiState,
  setEditKpiState,
  kpiEditModal,
  setKpiEditModal,
  handleEditKpi,
  projectID,
  goalID,
  objectiveID,
  kpiID,
}) => {
  const handleClick = () => {
    const data = {
      name: editKpiState,
      project_id: projectID,
      goal_id: goalID,
      objective_id: objectiveID,
      id: kpiID,
    };
    handleEditKpi(data);
    setEditKpiState("");
    setKpiEditModal(false);
  };
  return (
    <Dialog
      open={kpiEditModal}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          color: "purple",
          textTransform: "uppercase",
          fontWeight: "600",
          textDecoration: "underline",
        }}
      >
        Edit KPI
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: 500,
            maxWidth: "100%",
            marginTop: "10px",
          }}
        >
          <CKEditor
            className="form-control"
            editor={ClassEditor}
            data={editKpiState}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEditKpiState(data);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} variant="contained" color="secondary">
          Edit Kpi
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setKpiEditModal(false)}
        >
          cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectKpiEditModal;
