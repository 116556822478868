import axios from "axios";
import { BASEURL } from "../../API/apiData";
import {
  CREATE_PROJECTDISCUSSION_REQUEST,
  CREATE_PROJECTDISCUSSION_SUCCESS,
  CREATE_PROJECTDISCUSSION_FAILURE,
  FETCH_PROJECTDISCUSSION_REQUEST,
  FETCH_PROJECTDISCUSSION_SUCCESS,
  FETCH_PROJECTDISCUSSION_FAILURE,
} from "./ProjectDiscussionsTypes";

// ==== FETCH PROJECT DISCUSSIONS ====

export const fetchProjectsDiscussionRequest = () => ({
  type: FETCH_PROJECTDISCUSSION_REQUEST,
});

export const fetchProjectsDiscussionSuccess = (response) => ({
  type: FETCH_PROJECTDISCUSSION_SUCCESS,
  payload: response,
});

export const fetchProjectsDiscussionFailure = (error) => ({
  type: FETCH_PROJECTDISCUSSION_FAILURE,
  payload: error,
});

//   ==== CREATE PROJECT DISCUSSIONS ====

export const createProjectDiscussionRequest = () => ({
  type: CREATE_PROJECTDISCUSSION_REQUEST,
});

export const createProjectDiscussionSuccess = (response) => ({
  type: CREATE_PROJECTDISCUSSION_SUCCESS,
  payload: response,
});

export const createProjectDiscussionFailure = (error) => ({
  type: CREATE_PROJECTDISCUSSION_FAILURE,
  payload: error,
});

// ==== CREATE PROJECT DISCUSSION ====

export const createProjectDiscussion = (projectID, data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(createProjectDiscussionRequest());
  return axios
    .post(`${BASEURL}projects/${projectID}/discussion`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(createProjectDiscussionSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(createProjectDiscussionFailure(error));
    });
};

// ==== FETCH PROJECT DISCUSSION ====

export const fetchProjectDiscussion = (projectID) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchProjectsDiscussionRequest());
  return axios
    .get(`${BASEURL}projects/${projectID}/discussion`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchProjectsDiscussionSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchProjectsDiscussionFailure(error));
    });
};
