/*eslint-disable */
import axios from "axios";
import { BASEURL } from "../../API/apiData";
import { fetchRequestOptions } from "../requestOptions/requestOptions";
import { toast } from "react-toastify";

export const fetchBadges = async () => {
  try {
    const result = await axios.get(
      `${BASEURL}badges`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const assignBadge = async (data) => {
  try {
    const result = await axios.post(
      `${BASEURL}badges/assign_badge`,
      data,
      fetchRequestOptions
    );
    if(result.data.message == "Badge assigned successfully"){
      toast.success(result.data.message);
    }else {
      toast.warning(result.data.message);
    }
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserBadges = async (data) => {
  try {
    const result = await axios.post(
      `${BASEURL}badges/all_user_badges`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
