import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import { CREATE_QUICKLINK_REQUEST,CREATE_QUICKLINK_SUCCESS, CREATE_QUICKLINK_FAILURE,
  UPDATE_QUICKLINK_REQUEST,UPDATE_QUICKLINK_SUCCESS, UPDATE_QUICKLINK_FAILURE
 } from "./projectQuickLinkTypes";

export const createQuickLinkRequest = () => ({
  type: CREATE_QUICKLINK_REQUEST,
})

export const createQuickLinkSuccess = (response) => ({
  type: CREATE_QUICKLINK_SUCCESS,
  payload: response
})

export const createQuickLinkFailure = (error) => ({
  type: CREATE_QUICKLINK_FAILURE,
  payload: error
})

export const updateQuickLinkRequest = () => ({
  type: UPDATE_QUICKLINK_REQUEST,
})

export const updateQuickLinkSuccess = (response) => ({
  type: UPDATE_QUICKLINK_SUCCESS,
  payload: response
})

export const updateQuickLinkFailure = (error) => ({
  type: UPDATE_QUICKLINK_FAILURE,
  payload: error
})

export const createQuickLink = (projectID, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createQuickLinkRequest());
  return axios.post(`${BASEURL}projects/${projectID}/project_quick_links`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createQuickLinkSuccess(response.data));
    }).catch(error => {
      return dispatch(createQuickLinkFailure(error));
    });
};