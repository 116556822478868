import axios from 'axios'
import { BASEURL } from '../../API/apiData';
import { fetchRequestOptions } from '../requestOptions/requestOptions';



export const fetchUpdateNotices = async () => {
  try {
    const result = await axios.get(`${BASEURL}update_notices`, fetchRequestOptions);
    return result.data;
  } catch (error) {
    console.log(error)
  }
}

export const createUpdateNotice = async (data) => {
   try {
     const result = await axios.post(
       `${BASEURL}update_notices`,
       data,
       fetchRequestOptions
     );
     return result.data;
   } catch (error) {
     console.log(error);
   }
}

export const editUpdateNotice = async (id,data) => {
  try {
    const result = await axios.put(
      `${BASEURL}update_notices/${id}`,
      data,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchReleaseNotes = async () => {
  try {
    const result = await axios.get(
      `${BASEURL}update_notices/recent_releases`,
      fetchRequestOptions
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};