/*eslint-disable */
import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";

const EntryModal = ({ closeModal, isOpen, handleSubmit, inputFields}) => {
 
  const properties = {};

  const handleFormSubmit = (event) => {
    const data = {
      properties: properties,
    };
    handleSubmit(data);
    closeModal();
    event.preventDefault();
  };

  const handleChange = (e) => {
    properties[e.target.name] = e.target.value;
  };


  return (
    <>
      <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header className="text-white" style={{"backgroundColor": "#309897"}} closeButton>
          <Modal.Title>Create New Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
          <div>
            {inputFields.map((field, idx) => {
              return (
                <Fragment key={idx}>
                  <div className="form-group mt-2 mb-2">
                    <label htmlFor={field.field_name.toLowerCase()}>
                      {field.field_name}
                    </label>
                    {field.field_name == "Name" ? <input
                      className="form-control"
                      type={field.field_type}
                      name={field.field_name}
                      onChange={(event) => handleChange(event)}
                      required
                    /> : <textarea
                      className="form-control"
                      type={field.field_type}
                      name={field.field_name}
                      onChange={(event) => handleChange(event)}
                      required
                    />}
                  </div>
                </Fragment>
              );
            })}
          </div>
            <div className="form-group float-right">
              <button className="add-btn rounded" type="submit">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EntryModal;
