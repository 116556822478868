/*eslint-disable */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchNotifications, updateNotification } from "../../redux/notifications/notificationActions";
import NotificationListItem from "./NotificationListItem";

const Notifications = ({ getNotifications, updateNotification }) => {

  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    getNotifications()
      .then((res) => setNotifications(res.payload))
      .catch((e) => console.log("Internal Server Error"));
  }, []);

  const handleUpdate = (id) => {
    updateNotification(id).then(res => {
      getNotifications()
        .then((res) => setNotifications(res.payload))
        .catch((e) => console.log("Internal Server Error"));
    }).catch(e => console.log("Internal Server Error"))
  }

  const showNoitifications = () => {
    if(_.isEmpty(notifications)){
      return (
        <div className="text-center w-100 text-muted mt-5">
          <i className="fa fa-ban fa-2x" aria-hidden="true"></i>
          <h5>You have no Notififications</h5>
        </div>
      );
    }else if(!_.isEmpty(notifications)) {
      return (
        <ul className="notifications-list">
          {
            notifications.map(not => <NotificationListItem key={not.id} data={not} handleUpdate={handleUpdate}/>)
          }
        </ul>
      );
    }
  }

  return (
    <div className="notifications-container shadow-lg">
      <div className="notifications-container-header">
        <h4>Notifications</h4>
        <button type="button">All</button>
        <button type="button">Unread</button>
      </div>
      {showNoitifications()}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => dispatch(fetchNotifications()),
  updateNotification: (id) => dispatch(updateNotification(id))
});

export default connect(null, mapDispatchToProps)(Notifications);
