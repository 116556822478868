import React, { useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import CollectionsIcon from "@mui/icons-material/Collections";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ForumIcon from "@mui/icons-material/Forum";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./MenuSpeedDial.module.css";

const actions = [
  { icon: <BorderColorIcon color="success" />, name: "Edit-Project" },
  { icon: <FlagCircleIcon color="info" />, name: "Goal" },
  { icon: <TipsAndUpdatesIcon color="success" />, name: "Assumption" },
  { icon: <VolunteerActivismIcon color="error" />, name: "Need" },
  { icon: <CollectionsIcon color="primary" />, name: "Top10" },
  { icon: <FormatListNumberedIcon />, name: "Discussion" },
  { icon: <ForumIcon color="secondary" />, name: "Suggestion" },
  { icon: <FormatListBulletedIcon color="success" />, name: "Sub-Project" },
];

const MenuSpeedDial = ({ speedDialState }) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [open, setOpen] = useState(false);

  const handleSelection = (selection) => {
    switch (selection) {
      case "Goal":
        toggleProjectSpeedDialMenu("goal", true);
        break;
      case "Assumption":
        toggleProjectSpeedDialMenu("assumption", true);
        break;
      case "Need":
        toggleProjectSpeedDialMenu("need", true);
        break;
      case "Top10":
        toggleProjectSpeedDialMenu("top10", true);
        break;
      case "Discussion":
        toggleProjectSpeedDialMenu("discussion", true);
        break;
      case "Suggestion":
        toggleProjectSpeedDialMenu("suggestion", true);
        break;
      case "Sub-Project":
        toggleProjectSpeedDialMenu("subproject", true);
        break;
      case "Edit-Project":
        toggleProjectSpeedDialMenu("editproject", true);
        break;
      default:
        toggleProjectSpeedDialMenu("null", false);
        break;
    }
  };
  return (
    <div className={styles.speed_dial_container}>
      <Box sx={{ height: 400, transform: "translateZ(0px)", flexGrow: 1 }}>
        <SpeedDial
          ariaLabel="SpeedDial openIcon example"
          icon={<SpeedDialIcon openIcon={<EditIcon />} />}
          onClick={() => setOpen(!open)}
          open={open}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              onClick={() => handleSelection(action.name)}
            />
          ))}
        </SpeedDial>
      </Box>
    </div>
  );
};

export default MenuSpeedDial;
