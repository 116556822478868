import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
// import { formatDate } from "@fullcalendar/core";
import { Box, Button, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import ChecklistIcon from "@mui/icons-material/Checklist";
import styles from "./ProjectCalender.module.css";
import CreateNewOpenItem from "./CreateNewOpenItem";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AddTaskIcon from "@mui/icons-material/AddTask";
import OpenItemDisplay from "./OpenItemDisplay";
import {
  createNewOpenItem,
  getSpecificOpenItems,
} from "../../../services/openItem/openItemService";
import { createNewProjectOpenItem } from "../../../services/openItem/projectOpenItemService";
import CreateNewOpenItemMobile from "./CreateNewOpenItemMobile";

const ProjectCalender = ({
  allProfiles,
  currentEvents,
  setCurrentEvents,
  fetchAllProjectOpenItems,
}) => {
  const calendarComponentRef = React.createRef();
  const { id } = useParams();
  // ==== STATES =====
  const [formState, setFormState] = useState({
    title: "",
    details: "",
    priority: null,
    AssignedProfiles: [],
  });
  const [openItemModal, setOpenItemModal] = useState(false);
  const [openItemMobileModal, setOpenItemMobileModal] = useState(false);
  const [profiles, setAllProfiles] = useState([]);
  const [selectionApi, setSelectionApi] = useState({
    selected: null,
    calenderApi: null,
  });
  const [singleOpenItemDetails, setSingleOpenItemDetails] = useState({});
  const [creatorProfile, setCreatorProfile] = useState({});
  const [openItemComments, setOpenItemComments] = useState([]);
  const [assignedProfiles, setAssignedProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaderSingleItem, setLoaderSingleItem] = useState(false);

  // ==== EFFECTS =====
  useEffect(() => {
    // ==== FETCH ALL USERS ====
    const fetchUsers = async () => {
      try {
        let { payload } = await allProfiles();
        setAllProfiles(payload);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUsers();
  }, [allProfiles]);

  // ==== EVENTS ====
  const [openItemDisplay, setOpenItemDisplay] = useState(false);
  const handleOpenItemDispay = () => setOpenItemDisplay(!openItemDisplay);
  // ==== Close Modal for Creating Open Item ====
  const handleCloseModal = () => setOpenItemModal(false);
  //  ==== Open Modal For Creating Open Item ====
  const handleOpenModal = () => setOpenItemModal(true);

  // ==== CREATE EVENT IN THE CALENDER AND POPULATE TO THE DATABASE THEN RESET THE STATE ====
  const populate = async (selected, calenderApi) => {
    setLoading(true);
    const data = {
      open_item: {
        title: formState.title,
        detail: formState.details,
        priority: formState.priority,
        start: selected.startStr,
        end: selected.endStr,
        allDay: selected.allDay,
      },
    };

    try {
      const openItem = await createNewOpenItem(data);
      if (openItem.message === "Open item Created Succesfully") {
        const projectData = {
          project_open_item: {
            open_item_id: openItem.result.id,
            project_id: id,
          },
        };
        const projectTask = await createNewProjectOpenItem(projectData);
        if (projectTask.message === "Project Open item Created Succesfully") {
          fetchAllProjectOpenItems();
          calenderApi.addEvent({
            start: selected.startStr,
            end: selected.endStr,
            allDay: selected.allDay,
            title: formState.title,
            details: formState.details,
            priority: formState.priority,
            id: openItem.result.id,
          });
          setLoading(false);
          // ==== close modal ====
          handleCloseModal();
        }
      }
    } catch (error) {
      NotificationManager.error(
        "Something went wrong kindly check your network connection and try again"
      );
    }
  };

  const stateReset = () => {
    setFormState({
      title: "",
      details: "",
      priority: null,
    });

    setSelectionApi({
      selected: null,
      calenderApi: null,
    });
  };

  // ==== Single Open Item ====
  const handleSingleOpenItem = async (id) => {
    setOpenItemDisplay(true);
    setLoaderSingleItem(true);
    try {
      const data = await getSpecificOpenItems(id);
      if (data.message === "Open item fetched Succesfully") {
        setSingleOpenItemDetails({ ...data.result });
        setCreatorProfile({ ...data.creator });
        setOpenItemComments([...data.open_item_comments]);
        setAssignedProfiles([...data.assigned_profiles]);
        setLoaderSingleItem(false);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong");
      setLoaderSingleItem(false);
    }
  };

  const handleCreateOpenItem = () => {
    const { selected, calenderApi } = selectionApi;
    calenderApi.unselect();
    // ==== populate to database ====
    populate(selected, calenderApi);
    // ==== state reset ====
    stateReset();
  };

  // ==== ADD EVENT TO THE CALENDER ====
  const handleDateClick = (selected) => {
    handleOpenModal();
    const calenderApi = selected.view.calendar;
    setSelectionApi({
      selected,
      calenderApi,
    });
  };

  // ==== WHEN YOU CLICK THE EVENT ON THE CALENDER ====
  const handleEventClick = (selected) => {
    handleSingleOpenItem(selected.event.id);
    // if (window.confirm("are you sure you want to delete the event")) {
    //   selected.event.remove();
    // }
  };

  // ==== PRIORITY CHECK FUNCTION ====
  const checkPriority = (priority) => {
    if (priority === 1)
      return <Chip icon={<AddTaskIcon />} label="OI 1" color="error" />;
    if (priority === 2)
      return <Chip icon={<AddTaskIcon />} label="OI 2" color="secondary" />;
    if (priority === 3)
      return <Chip icon={<AddTaskIcon />} label="OI 3" color="primary" />;
    if (priority === 4)
      return <Chip icon={<AddTaskIcon />} label="OI 4" color="success" />;
    if (priority === 5)
      return <Chip icon={<AddTaskIcon />} label="OI 5" variant="outlined" />;
    if (priority === 6) return <Chip icon={<AddTaskIcon />} label="OI 6" />;
  };

  // ==== COMPONENTS ====
  const displayEvents = () => {
    return currentEvents.map((item, index) => {
      return (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            className={styles.event_container_item}
            onClick={() => handleSingleOpenItem(item.id)}
          >
            <Typography>
              {checkPriority(
                item.extendedProps ? item.extendedProps.priority : item.priority
              )}
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              className={styles.event_title}
            >
              {item.title}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      );
    });
  };

  const DisplayNoEvents = () => {
    return (
      <div className={styles.no_event_container}>
        <AddTaskIcon className={styles.icon_no_item} /> No Open Items
      </div>
    );
  };

  const getHeaderProps = () => {
    return {
      left: "",
      center: "prev,title,next",
      right: window.innerWidth < 765 ? "" : "dayGridMonth,listWeek",
    };
  };

  const handleWindowResize = () => {
    const calendar = calendarComponentRef.current.getApi();
    calendar.changeView(window.innerWidth < 765 ? "listWeek" : "dayGridMonth");
    calendar.setOption("header", getHeaderProps());
  };

  return (
    <Fragment>
      <OpenItemDisplay
        open={openItemDisplay}
        handleClose={handleOpenItemDispay}
        loaderSingleItem={loaderSingleItem}
        singleOpenItemDetails={singleOpenItemDetails}
        creatorProfile={creatorProfile}
        openItemComments={openItemComments}
        handleSingleOpenItem={handleSingleOpenItem}
        fetchAllProjectOpenItems={fetchAllProjectOpenItems}
        profiles={profiles}
        assignedProfiles={assignedProfiles}
      />
      <CreateNewOpenItem
        isOpen={openItemModal}
        handleClose={handleCloseModal}
        formState={formState}
        setFormState={setFormState}
        handleCreateOpenItem={handleCreateOpenItem}
        loading={loading}
      />
      <CreateNewOpenItemMobile
        isOpen={openItemMobileModal}
        formState={formState}
        setFormState={setFormState}
        handleCreateOpenItem={handleCreateOpenItem}
        loading={loading}
      />
      <Box m="5px">
        {/* create event mobile button */}
        <div className={styles.mobile_button_event}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenItemMobileModal(true)}
          >
            create open item
          </Button>
        </div>
        <Box display="flex" justifyContent="space-between">
          {/* CALENDAR */}
          <Box flex="1 1 100%" ml="5px" className={styles.calender_container}>
            <FullCalendar
              height="75vh"
              themeSystem="bootstrap"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right:
                  window.innerWidth < 765
                    ? ""
                    : "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
              }}
              header={getHeaderProps()}
              initialView={
                window.innerWidth < 765 ? "listWeek" : "dayGridMonth"
              }
              ref={calendarComponentRef}
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              select={handleDateClick}
              eventClick={handleEventClick}
              eventsSet={(events) => setCurrentEvents(events)}
              initialEvents={[...currentEvents]}
              windowResize={handleWindowResize}
            />
          </Box>

          {/* CALENDAR SIDEBAR */}
          <Box
            flex="1 1 30%"
            p="15px"
            borderRadius="4px"
            className={styles.project_event_display}
            ml="10px"
          >
            <div className={styles.project_event_display_title}>
              <ChecklistIcon className={styles.to_do_icon} />{" "}
              <Typography variant="span">Open Items</Typography>
            </div>

            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {currentEvents.length < 1 ? <DisplayNoEvents /> : displayEvents()}
            </Timeline>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ProjectCalender;
