import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import styles from "../css/main.module.css";
import { Divider } from "@mui/material";
import logo from "../../../assets/img/logo.png";
import { deepOrange } from "@mui/material/colors";

const SidebarFooter = ({ profile }) => {
  const history = useHistory();
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const navigateTo = (profile) =>
    history.push(`/profile/${profile.profile_id}`);
  return (
    <>
      <div className={styles.footer_container}>
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          onClick={() => navigateTo(profile)}
          sx={{
            cursor: "pointer",
          }}
        >
          {profile.profile.profile_avatar &&
          profile.profile.profile_avatar.url !== null ? (
            <Avatar
              alt={`${profile.profile.first_name} ${profile.profile.last_name}`}
              src={`${profile.profile.profile_avatar.url}`}
              sx={{
                height: "70px",
                width: "70px",
              }}
            />
          ) : (
            <Avatar
              sx={{ bgcolor: deepOrange[500], height: "70px", width: "70px" }}
              src="/broken-image.jpg"
              alt={`${profile.profile.first_name}`}
            />
          )}
        </StyledBadge>
      </div>
      <div className={styles.footer_name}>
        <h5>{`${profile.profile.first_name} ${profile.profile.last_name}`}</h5>
        <h6>{`${profile.user.email}`}</h6>
      </div>
    </>
  );
};

const DesktopSideBar = ({ features, profile }) => {
  const [collapsed] = useState(false);
  const history = useHistory();
  const navigateTo = (route) => history.push(`/${route}`);

  const menuItems = () => {
    const results = features.map((item) => {
      return (
        <MenuItem
          key={item.name}
          icon={item.image}
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            "&:active": {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          onClick={() => navigateTo(item.route)}
        >
          {item.name}
        </MenuItem>
      );
    });

    return results;
  };

  return (
    <Sidebar
      backgroundColor="#fff"
      collapsed={collapsed}
      className={styles.side_bar_container}
    >
      <div className={styles.sidebar_top_bar}>
        <img
          className={styles.tuc_logo}
          src={logo}
          alt="nPmRzQ.png"
          border="0"
        />
      </div>
      <Divider light={true} className={styles.side_bar_divider} />
      <Menu>
        {menuItems()}
        <Divider />
        <SidebarFooter profile={profile} />
      </Menu>
    </Sidebar>
  );
};

export default DesktopSideBar;
