import React from "react";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "react-bootstrap/Modal";

const UpdateEditsModal = ({
  data,
  setEditData,
  closeModal,
  handleEdit,
  isOpen,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const xData = {
      update_notice: {
        update_title: data.title,
        update_details: data.details,
        is_complete: data.is_complete,
      },
    };
    handleEdit(data.id, xData);
    closeModal();
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#309897" }}
        closeButton
      >
        <Modal.Title>Add Update</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              value={data.title}
              type="text"
              className="form-control"
              onChange={(e) =>
                setEditData({
                  ...data,
                  title: e.target.value,
                })
              }
              placeholder="your update title here"
            />
          </div>
          <div className="form-group">
            <label htmlFor="title">Details</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={data.details}
              onChange={(event, editor) => {
                const data = editor.getData();
                setEditData({
                  ...data,
                  details: data,
                });
              }}
            />
          </div>
          <div className="form-group d-flex">
            <label htmlFor="title">Has this update been complete?</label>
            <input
              defaultChecked={data.is_complete}
              className="ml-3 mt-1"
              type="checkbox"
              onChange={(e) => {
                setEditData({
                  ...data,
                  is_complete: e.target.checked,
                });
              }}
            />
          </div>
          <div className="form-group float-right">
            <button className="add-btn rounded text-white" type="submit">
              Edit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateEditsModal;
