import React from 'react'
import { useHistory } from 'react-router-dom'


const ConstructionContainer = () => {
  const history = useHistory();

  const goBack = () => {
    history.goBack()
  }
  return (
     <div className="p-5">
       <button className="btn btn-success m-3" onClick={goBack}>Go Back</button>
      <div className="card mb-3">
        <img className="card-img-top" src="https://c8.alamy.com/comp/2B77M04/3d-web-page-under-construction-concept-2B77M04.jpg" alt="card" />
        </div>
      </div>
  )
}

export default ConstructionContainer
