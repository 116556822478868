/*eslint-disable no-unused-vars, eqeqeq */
import React, { useEffect, useState } from "react";
import { createSuggestion } from "../../redux/suggestions/suggestionActions";
import { connect } from "react-redux";
import _, { create } from 'lodash'
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import ckeditor, { CKEditor } from "@ckeditor/ckeditor5-react";
import ideaIcon from "../../images/idea.png";
import TagBadge from "../reusablecomponents/TagBadge";
import { toast } from "react-toastify";
import { createSuggestionRemix } from "../../services/suggestions/suggestions";

const SuggestionForm = ({
  suggestion,
  createSuggestion,
  suggestionCategories,
  action
}) => {
  const [idea, setIdea] = useState("");
  const [purpose, setPurpose] = useState("");
  const [details, setDetails] = useState("");
  const [category, setCategory] = useState( null );
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [parentID,setParentID] = useState("")
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [imageThree, setImageThree] = useState(null);
  const [imageFour, setImageFour] = useState(null);

  useEffect(() => {
    setIdea(suggestion ? suggestion.idea : '');
    setPurpose(suggestion ? suggestion.purpose : "");
    setDetails(suggestion ? suggestion.details : "");
    setCategory(
      suggestion ? suggestion.category_id : 1);
    setTags(
      suggestion ? suggestion.tags.map((item) => item.name) : [])
      setParentID(suggestion ? suggestion.id : "")
   
  }, [suggestion])
  
  
  const handleAddTag = () => {
    if(tag == ""){
      toast.warning("You cannot add an empty tag")
    }else {
      setTags((prevTags) => [...prevTags, tag.replace(/^#/, "")]);
      setTag("")
    }
  }

  const handleSubmit = (e) => {
    const suggestionFormData = new FormData();

    suggestionFormData.append("suggestion[idea]", idea);
    suggestionFormData.append("suggestion[purpose]", purpose);
    suggestionFormData.append("suggestion[details]", details);
    suggestionFormData.append("suggestion[suggestion_category_id]",category);
    suggestionFormData.append("suggestion[tags]", JSON.stringify(tags));
    suggestionFormData.append("suggestion[parent_id]", parentID)
    suggestionFormData.append("suggestion[sug_img_one]", imageOne);
    suggestionFormData.append("suggestion[sug_img_two]", imageTwo);
    suggestionFormData.append("suggestion[sug_img_three]", imageThree);
    suggestionFormData.append("suggestion[sug_img_four]", imageFour);
  
    e.preventDefault();
    if (action == "create"){
      createSuggestion(suggestionFormData);
      window.location.reload(true);
    }else if(action == "remix") {
      createSuggestionRemix(parentID,suggestionFormData)
      window.location.reload(true)
    }

    
  };

  const setActionText = () => {
    switch (action) {
      case "create":
        return "Suggestion"
      case "remix":
        return "Remix"
      default:
        return "create"
    }
  }

  return (
    <>
      <div className="card m-5 mx-auto new-suggestion-form-tt">
        <div className="card-body">
          <div className="form-title">
            <h2> Create {setActionText()} </h2>
          </div>

          <hr />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="suggestion_category">Select Category</label>
              <select
                className="form-control"
                name="suggestion_category"
                id="suggestion_category"
                onChange={(e) => setCategory(e.target.value)}
              >
                {suggestionCategories.map((value) => (
                  <option key={value.category.id} value={value.category.id}>
                    {value.category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="idea">
                Idea <img src={ideaIcon} alt="idea" />
              </label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setIdea(e.target.value)}
                name="idea"
                value={idea}
                id="idea"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="purpose">Purpose</label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={purpose}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPurpose(data);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="details">Description</label>
              <CKEditor
                className="form-control"
                editor={ClassEditor}
                data={details}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDetails(data);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="tags">Tags</label>
              {_.isEmpty(tags) ? (
                <div className="p-2 text-muted">
                  <span>Add tags and they will appear here</span>
                </div>
              ) : (
                <>
                  <div className="tt-tags-display">
                    {tags.map((tag, idx) => (
                      <TagBadge key={idx} tag={`#${tag}`} />
                    ))}
                  </div>
                </>
              )}
              <div className="d-flex">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Add tag here"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                />
                <button
                  className="btn btn-success mv-sg"
                  type="button"
                  onClick={handleAddTag}
                >
                  +
                </button>
              </div>
            </div>
            <div className="mt-3 mb-2 border p-2">
              <h6>Upload Images</h6>
              <input
                type="file"
                className="form-control border-0"
                name="image-one"
                onChange={(event) => setImageOne(event.target.files[0])}
              />
              <input
                type="file"
                className="form-control border-0"
                name="image-two"
                onChange={(event) => setImageTwo(event.target.files[0])}
              />
              <input
                type="file"
                className="form-control border-0"
                name="image-three"
                onChange={(event) => setImageThree(event.target.files[0])}
              />
              <input
                type="file"
                className="form-control border-0"
                name="image-four"
                onChange={(event) => setImageFour(event.target.files[0])}
              />
            </div>
            <input type="text" className="form-control" value={parentID} onChange={e => setParentID(e.target.value)} hidden/>
            <button
              type="submit"
              className="btn btn-success mt-4 text-right submit-btn"
            >
              Submit {setActionText()}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createSuggestion: (data) => dispatch(createSuggestion(data)),
});

export default connect(null, mapDispatchToProps)(SuggestionForm);
