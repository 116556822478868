import React, { useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const SuggestionReplyForm = ({ createNewComment }) => {
  const [reply, setReply] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const replyData = {
      response_text: reply,
    };
    createNewComment(replyData);
    setReply("");
  };

  return (
    <div className="container comment-reply-form">
      <CKEditor
        editor={ClassicEditor}
        data={reply}
        onChange={(event, editor) => {
          const data = editor.getData();
          setReply(data);
        }}
      />

      <button
        type="submit"
        className="btn btn-success "
        onClick={handleFormSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default SuggestionReplyForm;
