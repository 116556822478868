import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import styles from "../../singleProject/globals/sidebar.module.css";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import BugReportIcon from "@mui/icons-material/BugReport";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Divider } from "@mui/material";

const DesktopSideBar = ({ tab, setTab }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Sidebar
      backgroundColor="#fff"
      collapsed={collapsed}
      className={styles.side_bar_container}
      style={{
        height: "100vh",
      }}
    >
      <div className={styles.sidebar_top_bar}>
        <div className={styles.close_icon}>
          {collapsed ? (
            <ArrowCircleRightIcon
              color="secondary"
              fontSize="large"
              onClick={() => setCollapsed(false)}
            />
          ) : (
            <ArrowCircleLeftIcon
              color="secondary"
              fontSize="large"
              onClick={() => setCollapsed(true)}
            />
          )}
        </div>
        <div className={styles.project_creator}>
          {collapsed ? <h5> Open menu </h5> : <h5> Close menu </h5>}
        </div>
      </div>
      <Divider light={true} className={styles.side_bar_divider} />
      <Menu>
        <MenuItem
          icon={<CheckCircleIcon />}
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            "&:active": {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          onClick={() => setTab({ name: "Completed Updates" })}
          active={tab === "" || tab === "Completed Updates" ? true : false}
          className={
            tab === "" || tab === "Completed Updates"
              ? `${styles.active_tab}`
              : null
          }
        >
          {" "}
          Completed Updates{" "}
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<UpcomingIcon />}
          onClick={() => setTab({ name: "Upcoming Updates" })}
          active={tab === "Upcoming Updates" ? true : false}
          className={tab === "Upcoming Updates" ? `${styles.active_tab}` : null}
        >
          {" "}
          Upcoming Updates
        </MenuItem>
        <MenuItem
          rootStyles={{
            "&:hover": {
              backgroundColor: "#309897",
              color: "#000",
            },
            color: "#000",
            fontFamily: "Roboto, sans-serif",
            fontSize: "18px",
            fontWeight: "300",
          }}
          icon={<BugReportIcon />}
          onClick={() => setTab({ name: "Bug Tracker" })}
          active={tab === "Bug Tracker" ? true : false}
          className={tab === "Bug Tracker" ? `${styles.active_tab}` : null}
        >
          {" "}
          Bug Tracker{" "}
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};

export default DesktopSideBar;
