import React from 'react'

const NetworkContactCard = ({obj}) => {

  return (
    <div className="network-contact-card shadow-lg">
      <div className="p-3">
        <img className="network-contact-card-avatar" 
        src="https://www.pngitem.com/pimgs/m/627-6275734_profile-icon-contacts-hd-png-download.png" alt="prof-avatar" />
      </div>
      <div className="network-contact-card-details p-3">
        <h4>
          <i className="fa fa-id-card text-info mt-1" aria-hidden="true"></i>&nbsp;&nbsp;
          {obj.name}
        </h4>
        <h4>
          <i className="fa fa-user text-info mt-1" aria-hidden="true"></i>&nbsp;&nbsp;
          {obj.role}
        </h4>
        <h4>
          <i className="fa fa-envelope text-info mt-1" aria-hidden="true"></i>&nbsp;&nbsp;
          {obj.email}
        </h4>
        <h4>
          <i className="fas fa-globe text-info mt-1" aria-hidden="true"></i>&nbsp;&nbsp;
          <a href={obj.website} target="_blank" rel="noreferrer">{obj.website}</a>
        </h4>
      </div>
    </div>
  )
}

export default NetworkContactCard
