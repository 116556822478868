/* eslint-disable */
import React, { useState, useEffect } from "react";
import _ from "lodash";
import parse from "html-react-parser";
import TagBadge from "../reusablecomponents/TagBadge";
import idea from "../../images/idea.png";
import noImage from "../../images/no-image.jpeg";
import nolike from "../../images/nolike.png";
import like from "../../images/like.png";
import comment from "../../images/comment.png";
import SuggestionComments from "./SuggestionComments";
import SuggestionTrending from "./SuggestionTrending";
import SuggestionNavigation from "./SuggestionNavigation";
import SuggestionBoxBanner from "./SuggestionBoxBanner";
import SuggestionEditForm from "./SuggestionEditForm";
import { createVote } from "../../services/votes/voteService";
import "../../stylesheets/suggestion.css";
import { fetchSuggestion, fetchSuggestionCategories } from "../../redux";
import { connect } from "react-redux";
import Navbar from "../navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import { useQuery } from "react-query";
// === MATERIAL UI ===
import AvatarGroup from "@mui/material/AvatarGroup";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddLinkIcon from "@mui/icons-material/AddLink";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import ArticleIcon from "@mui/icons-material/Article";
import { displayDate, displayTime } from "../../helpers/timeModule";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import SuggestionShowMobileMenu from "./SuggestionShowMobileMenu";
import SuggestionTrendingDrawer from "./SuggestionTrendingDrawer";
import NewCommentMobileDrawer from "./NewCommentMobileDrawer";
import UpvotesDisplayModal from "./UpvotesDisplayModal";
import SuggestionRightDrawer from "./SuggestionRightDrawer";
import DisplayLinkedProjects from "./DisplayLinkedProjects";

const SuggestionShow = ({
  match,
  fetchSuggestion,
  fetchSuggestionCategories,
}) => {
  const route = match.params.id;
  const [imageArr, setImageArr] = useState([]);
  const [numberOfComments, setNumberOfcomments] = useState(null);
  const [show, setShow] = useState(false);
  const [suggestion, setSuggestion] = useState({});
  const [userLiked, setUserLiked] = useState(false);
  const [votes, setVotes] = useState(0);
  const [viewTrending, setViewTrending] = useState(false);
  const [newComment, setNewComment] = useState(false);
  const [suggestionCategories, setSuggestionCategories] = useState([]);
  const [votersToggleDrawer, setVotersToggleDrawer] = useState(false);
  const [upvoteProfiles, setVotersDrawerProfiles] = useState([]);
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [drawerProfiles, setDrawerProfiles] = useState([]);
  const [listSpecificSuggestionProjects, setlistSpecificSuggestionProjects] = useState({
    selectedID: null,
    suggestionProjects: []
  })
  const [displayLinkedProjects, setDisplayLinkedProjects] = useState(false);

  const { isLoading, error, data } = useQuery("suggestion", () =>
    fetchSuggestion(route)
  );

  useEffect(() => {
    if (data) {
      const { result } = data.payload;
      setImageArr([
        result.sug_img_one.url,
        result.sug_img_two.url,
        result.sug_img_three.url,
        result.sug_img_four.url,
      ]);
      setUserLiked(result.user_likes);
      setVotes(result.votes);
      setSuggestion({ ...result });
    }
  }, [data, route]);

  // ===== LETS FETCH THE SUGGESTION CATEGORIES ====
  useEffect(() => {
    fetchSuggestionCategories()
      .then((res) => setSuggestionCategories(res.payload.result))
      .catch((err) => console.log("Internal Application Error" + err));
  }, []);

  const showProjectModal = () => {
    setOpenProjectModal(!openProjectMoal);
  };

  const sendVote = async (data) => {
    try {
      const res = await createVote(data);
      res.message == "Thanks for your vote"
        ? toast.success(res.message)
        : toast.error(res.message);
    } catch (error) {
      console.error(error);
    }
  };

  const voteEntry = () => {
    const xData = {
      likeable_type: "Suggestion",
      likeable_id: suggestion.id,
    };
    sendVote(xData);
    setUserLiked(!userLiked);
    if (userLiked) {
      setVotes((prev) => prev - 1);
    } else {
      setVotes((prev) => prev + 1);
    }
  };

  const getNumberOfComments = (number) => {
    setNumberOfcomments(number);
  };

  if (isLoading) return "Loading...";
  if (error) return "An error has occurred: " + error.message;

  const displayCreatorAvator = () => {
    if (_.isEmpty(suggestion)) return null;

    if (suggestion.created_by.image === null)
      return <Avatar src="/broken-image.jpg" />;

    return (
      <Avatar
        alt={`${suggestion.created_by.first_name} ${suggestion.created_by.last_name}`}
        src={`${suggestion.created_by.image.url}`}
      />
    );
  };

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    if (_.size(text) < 250) return <div className="text">{parse(text)}</div>;

    return (
      <>
        <div className="text">
          {isReadMore ? parse(text.slice(0, 250)) : parse(text)}
        </div>
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : "show less"}
        </span>
      </>
    );
  };

  const displaySharedProfiles = () => {
    const result = suggestion.shared_Profiles.map((profile, idx) => {
      return (
        <Avatar
          key={idx}
          alt="profile avatar"
          src={`${profile.profile_avatar.url}`}
          className="sb-shared-icons-avatars"
        />
      );
    });

    return result;
  };

  const displayVotersProfiles = () => {
    const result = suggestion.users_liked.map((profile, idx) => {
      return (
        <Avatar
          key={idx}
          alt="profile avatar"
          src={`${profile.profile_avatar.url}`}
          className="sb-shared-icons-avatars"
        />
      );
    });

    return result;
  };

  const handleOpenLinkedProjects = (id, projects) => {
    setlistSpecificSuggestionProjects({
      selectedID: id,
      suggestionProjects: projects.length > 0 ? [...projects] : [],
    });
    setDisplayLinkedProjects(true);
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <SuggestionTrendingDrawer
        setViewTrending={setViewTrending}
        viewTrending={viewTrending}
      />
      <NewCommentMobileDrawer
        newComment={newComment}
        setNewComment={setNewComment}
        numberOfComments={numberOfComments}
        suggestion={suggestion}
        sugID={route}
      />
      <UpvotesDisplayModal
        votersToggleDrawer={votersToggleDrawer}
        upvoteProfiles={upvoteProfiles}
        setVotersToggleDrawer={setVotersToggleDrawer}
      />

      <SuggestionRightDrawer
        setToggleDrawer={setToggleDrawer}
        stateToggleDrawer={toggleDrawer}
        drawerProfiles={drawerProfiles}
      />
        <DisplayLinkedProjects 
       displayLinkedProjects={displayLinkedProjects}
       setDisplayLinkedProjects={setDisplayLinkedProjects}
       listSpecificSuggestionProjects={listSpecificSuggestionProjects}
       setlistSpecificSuggestionProjects={setlistSpecificSuggestionProjects}
      />

      <br />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-3 show-dektop-nav">
            <div className="sticky-sec">
              <SuggestionNavigation />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-11 col-lg-8 col-xl-6">
            {show ? (
              <SuggestionEditForm
                sug={suggestion}
                setShow={setShow}
                suggestionCategories={suggestionCategories}
              />
            ) : null}

            <div className="card tt-suggestion-card">
              <div className="card-body container-fluid">
                <div className="tt-suggestion-card-title row">
                  {/* === DISPLAY THE IDEA === */}
                  <div className="col-md-12 col-sm-12 col-12 suggest-idea">
                    {suggestion.idea === null ? null : (
                      <div className="suggestion-idea-title">
                        <img src={idea} alt="thumbnail" /> <span> IDEA</span>
                      </div>
                    )}

                    <div className="suggestion-purpose">
                      {" "}
                      {suggestion.idea !== null ? (
                        <ReadMore>{`${suggestion.idea}`}</ReadMore>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* === DISPLAY THE PURPOSE === */}
                <div className="suggest-idea">
                  {" "}
                  {suggestion.purpose !== "" ? (
                    <div className="suggestion-idea-title">
                      <FlagCircleIcon /> <span> PURPOSE</span>
                    </div>
                  ) : null}
                  {suggestion.purpose !== null ? (
                    <ReadMore>{`${suggestion.purpose}`}</ReadMore>
                  ) : null}{" "}
                </div>

                {/* === DETAILS === */}

                <div className="suggest-idea">
                  {" "}
                  {suggestion.details !== "" ? (
                    <div className="suggestion-idea-title">
                      <ArticleIcon /> <span> DETAILS</span>
                    </div>
                  ) : null}
                  {suggestion.details !== null ? (
                    <ReadMore>{`${suggestion.details}`}</ReadMore>
                  ) : null}
                </div>

                {/* ==== DISPLAY THE IMAGES ==== */}
                <div className="suggestion_images container-fluid">
                  {imageArr.map((img, idx) => {
                    if (img === null) {
                      return null;
                    } else {
                      return (
                        <img
                          key={`${img}+${idx}`}
                          src={img ? img : noImage}
                          alt={`sb+${idx}`}
                          className="img-thumbnail"
                        />
                      );
                    }
                  })}
                </div>

                {/* === DISPLAY THE TAGS === */}
                <div className="tt-tags-display">
                  {_.isEmpty(suggestion.tags) ? null : (
                    <>
                      {suggestion.tags.map((tag, idx) => (
                        <TagBadge key={idx} tag={`#${tag.name}`} />
                      ))}
                    </>
                  )}
                </div>

                {!_.isEmpty(suggestion) ? (
                  <>
                    <div className="show-like-share-container likes-cont-ex">
                      {suggestion.users_liked.length < 1 ? null : (
                        <div className="liked-by-container">
                          <div className="suggestions-shared-profiles">
                            <Chip
                              label="Upvoted by:"
                              color="success"
                              variant="outlined"
                              className="shared-with-chip"
                            />

                            <AvatarGroup
                              max={3}
                              onClick={() => {
                                setVotersToggleDrawer(true);
                                setVotersDrawerProfiles([
                                  ...suggestion.users_liked,
                                ]);
                              }}
                            >
                              {displayVotersProfiles()}
                            </AvatarGroup>
                          </div>
                        </div>
                      )}

                      {suggestion.shared_Profiles.length < 1 ? null : (
                        <div className="shared-with-container">
                          <div className="suggestions-shared-profiles">
                            <Chip
                              label="Shared with:"
                              color="success"
                              variant="outlined"
                              className="shared-with-chip"
                            />

                            <AvatarGroup
                              max={3}
                              onClick={() => {
                                setToggleDrawer(true);
                                setDrawerProfiles([
                                  ...suggestion.shared_Profiles,
                                ]);
                              }}
                            >
                              {displaySharedProfiles()}
                            </AvatarGroup>
                          </div>
                        </div>
                      )}
                    </div>

                    <Divider />
                  </>
                ) : null}

                {/* === THE BUTTONS === */}
                <div className="sb-like-comment-btn">
                  <button type="button" onClick={voteEntry}>
                    {userLiked ? (
                      <img src={like} alt="sb-like-btn" />
                    ) : (
                      <img src={nolike} alt="sb-like-btn" />
                    )}{" "}
                    <span>Upvote ({votes})</span>
                  </button>
                  {/*  === IMPLEMENT THE SHARE AND THE REMIX BUTTON === */}
                  <IconButton aria-label="Edit" onClick={() => setShow(!show)}>
                    <EditIcon /> <span> EDIT</span>
                  </IconButton>
                </div>
                {/* === DISPLAY THE AVATOR FOR THE CREATOR === */}
                <Divider />
                <div className="suggestion-creator-avatar">
                  <Stack direction="row" spacing={3}>
                    {displayCreatorAvator()}
                    <h6>
                      {_.isEmpty(suggestion)
                        ? null
                        : `${suggestion.created_by.first_name} ${suggestion.created_by.last_name}`}
                    </h6>
                  </Stack>
                  <h6 className="date-of-suggestion-creation">
                    suggested: {displayDate(suggestion.created_at).value}{" "}
                    {displayTime(
                      displayDate(suggestion.created_at).unit,
                      displayDate(suggestion.created_at).value
                    )}{" "}
                    {suggestion.is_remix ? "♻️" : null}
                  </h6>
                </div>
                <Divider />
                {/* ==== LINK THE PROJECT ==== */}
                {!_.isEmpty(suggestion) ? (
                  <>
                    <div className="project-link-div">
                      <div
                        className="project-link-title"
                        onClick={() => {
                          handleOpenLinkedProjects(
                            suggestion.id,
                            suggestion.project
                          );
                        }}
                      >
                        <Badge
                          badgeContent={
                            suggestion.project.length >= 1
                              ? suggestion.project.length
                              : "0"
                          }
                          color="primary"
                        >
                          <i className="fas fa-project-diagram text-info mt-1"></i>
                          &nbsp;&nbsp;
                          <span>Projects</span>&nbsp;&nbsp;
                        </Badge>
                      </div>
                      <div>
                        {/* <>
                          <Stack spacing={2} direction="row">
                            <Button
                              variant="contained"
                              startIcon={<AddBoxIcon />}
                              onClick={showProjectModal}
                            >
                              New
                            </Button>
                            <Button
                              variant="contained"
                              startIcon={<AddLinkIcon />}
                              onClick={() => {
                                filterProjectsToLink();
                                handleClickLinkOpen(true);
                                setSelectedSuggestionID(sug.id);
                              }}
                            >
                              link
                            </Button>
                          </Stack>
                        </> */}
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            <SuggestionComments
              sugID={route}
              getNumberOfComments={getNumberOfComments}
            />
          </div>
          <div className="col-xl-3 col-lg-4 computer-view showpage-computer-view">
            <div className="sticky-sec">
              <SuggestionBoxBanner />
              <SuggestionTrending />
            </div>
          </div>
          <div className="showpage-mobile-navigation">
            <SuggestionShowMobileMenu
              numberOfComments={numberOfComments}
              setViewTrending={setViewTrending}
              setNewComment={setNewComment}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchSuggestion: (id) => dispatch(fetchSuggestion(id)),
  fetchSuggestionCategories: () => dispatch(fetchSuggestionCategories()),
});

export default connect(null, mapDispatchToProps)(SuggestionShow);
/* eslint-enable eqeqeq */
