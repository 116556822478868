import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { createUser, checkLoggedInStatus } from "../redux";
import { Link } from "react-router-dom";
import LoggedInComp from "../components/LoggedInComp";
import ConfirmEmailModal from "../components/ConfirmEmailModal";
import { toast, ToastContainer } from "react-toastify";
import AppSignUpForm from "../components/auth/AppSignUpForm";
import AppAuthScreen from "../components/auth/AppAuthScreen";

const SignUp = ({ status, history, isLoggedIn, createUser }) => {
  useEffect(() => {
    isLoggedIn();
  }, [isLoggedIn]);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setButtonLoader] = useState(false);
  const [disable, setDisableButton] = useState(false);

  const buttonLoader = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  const registerUser = ({
    firstName,
    lastName,
    userName,
    email,
    password,
    passwordConfirmation,
  }) => {
    setButtonLoader(true);
    setDisableButton(true);
    const userData = {
      user: {
        first_name: firstName,
        last_name: lastName,
        username: userName,
        email: email,
        password: password,
        password_confirmation: passwordConfirmation,
        user_type_id: 2,
      },
    };
    createUser(userData)
      .then((response) => {
        const result = response.payload;
        if (result.post) {
          setTitle(result.post.title);
          setMessage(result.post.message);
          setOpen(true);
        }
        if (result.response.status && result.response.status === 422) {
          toast.error("Email or username already taken , please try again");
          setButtonLoader(false);
          setDisableButton(false);
        }
      })
      .catch((errors) => console.log(errors));
  };

  const closeModal = () => {
    setOpen(false);
    history.push("/signin");
  };

  const showData = () => {
    if (!_.isEmpty(status.data)) {
      return (
        <>
          <LoggedInComp username={status.data.user.username} />
        </>
      );
    }
    if (_.isEmpty(status.data) && status.errorMsg) {
      return (
        <>
          <div className="row">
            <AppAuthScreen />

            <div className="col-md-7">
              <ToastContainer />
              <div className="register-content-form p-3">
                <h1 className="text-center mt-5">Create Account</h1>

                <div className="sign-in-register">
                  <span className="account-quiz">Already have an account?</span>{" "}
                  &#160;
                  <span className="account-signin-register">
                    <Link to={"/signin"}>SIGN IN</Link>
                  </span>
                </div>

                <AppSignUpForm
                  registerUser={registerUser}
                  disable={disable}
                  loader={loader}
                  buttonLoader={buttonLoader}
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      <ConfirmEmailModal
        isOpen={open}
        closeModal={closeModal}
        title={title}
        message={message}
      />
      <div className="container-fluid">{showData()}</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(createUser(data)),
  isLoggedIn: () => dispatch(checkLoggedInStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
