import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MobileSearch from "./MobileSearch";

const MobileSearchDialog = ({ searchButton, setSearchButton,  setMobileSearchSuggestion, suggestions}) => {
  const handleClose = () => {
     setSearchButton(false)
  };
  return (
    <div>
      <Dialog open={searchButton} onClose={handleClose}>
        <DialogTitle>Search Suggestion</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
           <MobileSearch  setMobileSearchSuggestion = { setMobileSearchSuggestion} suggestions={suggestions} setSearchButton={setSearchButton} />
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default MobileSearchDialog;
