import React, { useEffect } from "react";
import "../stylesheets/categories.css";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchCategories } from "../redux";
import _ from "lodash";
import CategoryCard from "../components/categories/CategoryCard";
import LoadingSpinner from "../components/LoadingSpinner";
import Navbar from "../components/navbar/Navbar";

const CategoriesContainer = ({ categories, fetchCategories }) => {
  const history = useHistory();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const showCategories = () => {
    if (!_.isEmpty(categories.data.result) && categories.data.loggedIn) {
      return (
        <>
          {categories.data.result.map((cat) => (
            <CategoryCard
              key={cat.id}
              catId={cat.id}
              name={cat.name}
              imgUrl={cat.image_url}
            />
          ))}
        </>
      );
    } else if (_.isEmpty(categories.data) && categories.errorMsg) {
      return (
        <>
          <h1 className="text-danger text-center p-3 notification_log">
            Please log in to continue
          </h1>
          <h5 className="text-primary text-center">
            <Link className="no-deco" to="/signin">
              <u>Go Home</u>
            </Link>
          </h5>
          {setTimeout(() => {
            history.push("/signin");
          }, 500)}
        </>
      );
    }
    if (categories.loading) {
      return <LoadingSpinner />;
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Navbar />
        <h1 className="category-header">Categories</h1>
        <div className="category-main">{showCategories()}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCategories: () => dispatch(fetchCategories()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesContainer);
