import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar/Navbar";
import UserRoleTable from "../components/userRoles/UserRoleTable";
import {
  fetchUserRoles,
  updateUserRole,
  fetchUsers,
} from "../services/userRoles/userRoleService";
import "../stylesheets/roles.css";
import LoadingSpinner from "../components/LoadingSpinner";
import UserRoleModal from "../components/userRoles/UserRoleModal";
import { toast, ToastContainer } from "react-toastify";

const UserRolesContainer = () => {
  const [userRoles, setUserRoles] = useState(null);
  const [users, setUsers] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [open, setOpen] = useState(false);

  const getUserRoles = async () => {
    try {
      const result = await fetchUserRoles();
      setUserRoles(result);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const result = await fetchUsers();
      setUsers(result);
    } catch (error) {
      console.log(error);
    }
  };

  const sendUpdate = async (id, data) => {
    try {
      await updateUserRole(id, data);
      getUserRoles();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserRoles();
    getUsers();
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const dropRole = (id,data) => {
    sendUpdate(id, data);
  }

  const updateRole = (data) => {
    sendUpdate(roleId, data);
    toast.success("Role assigned sucessfully");
  };

  const showData = () => {
    if (userRoles == null) {
      return (
        <>
          <div className="container-fluid">
            <LoadingSpinner />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="container-fluid user-role-container">
            {Object.keys(userRoles.result).map((role) => {
              return (
                <UserRoleTable
                  key={role}
                  title={role}
                  data={userRoles.result[role]}
                  authId={userRoles.user_type.id}
                  openModal={openModal}
                  setRoleId={setRoleId}
                  dropRole={dropRole}
                />
              );
            })}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      {users == null ? null : (
        <UserRoleModal
          users={users}
          open={open}
          closeModal={closeModal}
          updateRole={updateRole}
        />
      )}
      <div className="top-ten-cont">
        <h2 className="p-3">
          {" "}
          <i className="fas fa-sitemap"></i>&nbsp;&nbsp;TUC 1/10
        </h2>
        {showData()}
      </div>
      <br />
      <br />
    </>
  );
};

export default UserRolesContainer;
