import React, { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import Modal from "react-bootstrap/Modal";

const ProjectCollabModal = ({isOpen,closeModal,users,handleSubmit}) =>  {

  const [user, setUser] = useState(null)

  const handleFormSubmit = (e) =>{
    e.preventDefault()
    const data ={
      user_id: user
    }
    if(user == null){
      toast.warn("Please select a collaborator or cancel operation");
    }else{
      handleSubmit(data);
      closeModal();
    }
  }

  return (
    <>
      <ToastContainer />
       <Modal show={isOpen} onHide={closeModal}>
        <Modal.Header className="text-white" style={{ backgroundColor: "#047C7C" }} closeButton>
          <Modal.Title>Add Collaborator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="users">Select Collaborator</label>
              <select className="form-control" name="users" onChange={(e) => setUser(e.target.value)}>
                <option value="">None</option>
                {
                  users.map(u => {
                    return (
                      <option key={u.id} value={u.id}>{`${u.first_name} ${u.last_name}`}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="form-group float-right">
              <button className="add-btn rounded text-white" type="submit">
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <h5>TUC SYSTEM</h5>
        </Modal.Footer>
       </Modal>
    </>
  );
  
}
export default ProjectCollabModal;
