/*eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchMasterNeeds,
  createMasterNeed,
  updateMasterNeed,
} from "../services/masterNeeds/masterNeedsService";
import "../stylesheets/masterNeeds.css";

import Navbar from "../components/navbar/Navbar";
import NeedModal from "../components/masterNeeds/NeedModal";
import { fetchProjects } from "../services/projects/projectsService";
import NeedCard from "../components/masterNeeds/NeedCard";
import _ from "lodash";

const MasterNeedsContainer = ({ match }) => {
  const needId = match.params.id;

  const [needs, setNeeds] = useState([]);
  const [projects, setProjects] = useState([]);
  const [category, setCategory] = useState("");
  const [open, setOpen] = useState(false);

  const getMasterNeeds = async (id) => {
    try {
      const data = await fetchMasterNeeds(id);
      setNeeds(data.result.open_needs);
      setCategory(data.result.category);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjects = async () => {
    try {
      const data = await fetchProjects();
      setProjects(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const createNeed = async (data) => {
    try {
      await createMasterNeed(data);
      getMasterNeeds(needId);
      toast.success("Need created successfully");
    } catch (error) {
      console.log(error);
    }
  };

  const updateNeed = async (id,data) => {
      try {
        await updateMasterNeed(id,data);
        getMasterNeeds(needId);
        if(data.need.is_procured) {
          toast.warning("Need Closed");
        }else {
          toast.warning("Need updated successfully");
        }
      } catch (error) {
        console.log(error);
      }
  }

  useEffect(() => {
    getMasterNeeds(needId);
    getProjects();
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleCreateNeed = (data) => {
    createNeed(data);
  };

  const handleUpdateNeed = (id,data) => {
    updateNeed(id,data)
  }

  const showNeeds = () => {
    if (_.isEmpty(needs)) {
      return (
        <h4 className="text-danger text-center">
          <i className="fa fa-frown-o" aria-hidden="true"></i>
          &nbsp; Sorry No Needs Available
        </h4>
      );
    } else {
      return needs.map((need, idx) => (
        <NeedCard
          key={need.id}
          idx={idx}
          data={need}
          master={true}
          projects={projects}
          handleSubmit={handleUpdateNeed}
        />
      ));
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <NeedModal
        category={needId}
        projects={projects}
        isOpen={open}
        closeModal={closeModal}
        handleSubmit={handleCreateNeed}
        proId={""}
      />
      <div className="master-need-container">
        <div className="master-needs-header">
          <h2 className="app-head-title ml-1">
            &nbsp;<Link to={"/needs"}>Categories</Link> {">"} {category}
          </h2>
          <button type="button" onClick={openModal}>
            Add New
          </button>
        </div>
        <div className="master-need-card-container">{showNeeds()}</div>
      </div>
    </>
  );
};

export default MasterNeedsContainer;
