import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import _ from "lodash";
import Stack from "@mui/material/Stack";

const MobileSearch = ({ setMobileSearchSuggestion, suggestions, setSearchButton }) => {
  const [searchableSuggestions, setSearchableSuggestions] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(suggestions)) {
      const { result } = suggestions.data;
      setSearchableSuggestions([...result]);
    }
  }, [suggestions]);

  return (
    <Stack spacing={2} sx={{ width: 300 }}>
      <Autocomplete
       freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={searchableSuggestions}
        getOptionLabel={(option)=>(option.idea ? option.idea:'')}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.idea}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search using suggestion idea"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setMobileSearchSuggestion({
              idea: newValue,
            });
            setSearchButton(false)
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setMobileSearchSuggestion({
              idea: newValue.inputValue,
            });
            setSearchButton(false)
          } else {
            setMobileSearchSuggestion(newValue);
            setSearchButton(false)
          }
        }}
        selectOnFocus
        clearOnBlur
      />
    </Stack>
  );
};

export default MobileSearch;
