import { BASEURL } from "../../API/apiData";

const createProjectCategory = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(`${BASEURL}project_categories`, requestOptions);
  const results = await response.json();
  return results;
};

export { createProjectCategory };
