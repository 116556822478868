import { useContext } from "react";
import projectSpeedDialContext from "../../context/projectSpeedDialContext";


const useSpeedDialButtons = () => {
  const speedDial = useContext(projectSpeedDialContext);
  return speedDial
};

export default useSpeedDialButtons;
