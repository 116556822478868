import { FETCH_COMMENT_REQUEST, FETCH_COMMENT_SUCCESS, FETCH_COMMENT_FAILURE } from "./commentTypes";


const initialState = {
    loading: true,
    data: [],
    errorMsg: '',
  };
  
  const commentFetchReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMMENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_COMMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          errorMsg: '',
        };
      case FETCH_COMMENT_FAILURE:
        return {
          ...state,
          loading: false,
          data: [],
          errorMsg: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default commentFetchReducer 