import * as yup from "yup";

const registrationSchema = yup.object().shape({
  firstName: yup
    .string()
    .strict(true)
    .required()
    .min(3)
    .trim("There should be no spaces")
    .label("First Name"),
  lastName: yup
    .string()
    .strict(true)
    .required()
    .min(3)
    .trim("There should be no spaces")
    .label("Last Name"),
  userName: yup
    .string()
    .strict(true)
    .required()
    .min(3)
    .trim("There should be no spaces")
    .label("UserName"),
  email: yup
    .string()
    .email()
    .strict(true)
    .required()
    .trim("There should be no spaces")
    .label("Email"),
  password: yup
    .string()
    .strict(true)
    .min(6)
    .required()
    .trim("There should be no spaces")
    .label("Password"),
  passwordConfirmation: yup
    .string()
    .strict(true)
    .min(6)
    .required()
    .trim("There should be no spaces")
    .oneOf(
      [yup.ref("password"), null],
      "Passwords must match. Kindly double check your password and password confirmation"
    )
    .label("Password Confirmation"),
});

const signInSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .strict(true)
    .required()
    .trim("There should be no spaces")
    .label("Email"),
  password: yup
    .string()
    .strict(true)
    .min(6)
    .required()
    .trim("There should be no spaces")
    .label("Password"),
});

const passwordResetSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .strict(true)
    .required()
    .trim("There should be no spaces")
    .label("Email"),
});

export { registrationSchema, signInSchema, passwordResetSchema };
