import React from "react";
import { useFormikContext } from "formik";
import AppFormError from "./AppFormError";

function AppInputField({ classname, type, name, value, placeholder }) {
  const { handleChange, setFieldTouched, touched, errors } = useFormikContext();
  return (
    <>
      <input
        className={classname}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={() => setFieldTouched(name)}
        required
        placeholder={placeholder}
      />
      {touched[name] && <AppFormError>{errors[name]}</AppFormError>}
    </>
  );
}

export default AppInputField;
