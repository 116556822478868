import axios from "axios";
import { BASEURL } from "../../API/apiData";
import {
  FETCH_PROJECTTOPTENENTRIES_SUCCESS,
  FETCH_PROJECTTOPTENENTRIES_REQUEST,
  FETCH_PROJECTTOPTENENTRIES_FAILURE,
  CREATE_PROJECTTOPTENENTRY_REQUEST,
  CREATE_PROJECTTOPTENENTRY_SUCCESS,
  CREATE_PROJECTTOPTENENTRY_FAILURE,
  UPDATE_PROJECTTOPTENENTRY_REQUEST,
  UPDATE_PROJECTTOPTENENTRY_SUCCESS,
  UPDATE_PROJECTTOPTENENTRY_FAILURE
} from "./sProjectTopTenTypes";

export const fetchProjectTopTenEntriesRequest = () => ({
  type: FETCH_PROJECTTOPTENENTRIES_REQUEST,
});

export const fetchProjectTopTenEntriesSuccess = (response) => ({
  type: FETCH_PROJECTTOPTENENTRIES_SUCCESS,
  payload: response,
});

export const fetchProjectTopTenEntriesFailure = (error) => ({
  type: FETCH_PROJECTTOPTENENTRIES_FAILURE,
  payload: error,
});

export const createProjectTopTenEntryRequest = () => ({
  type: CREATE_PROJECTTOPTENENTRY_REQUEST,
});

export const createProjectTopTenEntrySuccess = (response) => ({
  type: CREATE_PROJECTTOPTENENTRY_SUCCESS,
  payload: response,
});

export const createProjectTopTenEntryFailure = (error) => ({
  type: CREATE_PROJECTTOPTENENTRY_FAILURE,
  payload: error,
});

export const updateProjectTopTenEntryRequest = () => ({
  type: UPDATE_PROJECTTOPTENENTRY_REQUEST,
});

export const updateProjectTopTenEntrySuccess = (response) => ({
  type: UPDATE_PROJECTTOPTENENTRY_SUCCESS,
  payload: response,
});

export const updateProjectTopTenEntryFailure = (error) => ({
  type: UPDATE_PROJECTTOPTENENTRY_FAILURE,
  payload: error,
});

export const fetchProjectTopTenEntries = (pId, pttId) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchProjectTopTenEntriesRequest());
  return axios
    .get(
      `${BASEURL}projects/${pId}/project_top_tens/${pttId}/project_top_ten_entries`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return dispatch(fetchProjectTopTenEntriesSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchProjectTopTenEntriesFailure(error));
    });
};

export const createProjectTopTenEntry = (pId, pttId, data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(createProjectTopTenEntryRequest());
  return axios
    .post(
      `${BASEURL}projects/${pId}/project_top_tens/${pttId}/project_top_ten_entries`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return dispatch(createProjectTopTenEntrySuccess(response.data));
    })
    .catch((error) => {
      return dispatch(createProjectTopTenEntryFailure(error));
    });
};

export const updateProjectTopTenEntry = (pId, pttId,ptteId ,data) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(updateProjectTopTenEntryRequest());
  return axios
    .put(
      `${BASEURL}projects/${pId}/project_top_tens/${pttId}/project_top_ten_entries/${ptteId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return dispatch(updateProjectTopTenEntrySuccess(response.data));
    })
    .catch((error) => {
      return dispatch(updateProjectTopTenEntryFailure(error));
    });
};
