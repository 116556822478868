import React, { useState } from "react";
import "../../stylesheets/suggestion.css";

import { connect } from "react-redux";
import { createComment } from "../../redux/comments/commentActions";

function TopTenComments({ createComment, categoryID, topTenID, id }) {
  const [commentState, setCommentState] = useState("");
  const [ideaState, setIdeaState] = useState("");
  const [purposeState, setPurposeState] = useState("");

  const handleCommentChange = (event) => {
    setCommentState({ [event.target.name]: event.target.value });
  };

  const handleIdeaChange = (event) => {
    setIdeaState({ [event.target.name]: event.target.value });
  };

  const handlePurposeChange = (event) => {
    setPurposeState({ [event.target.name]: event.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const top_ten_entry_comment = {
      comment_text: commentState.comment,
      idea: ideaState.idea,
      purpose: purposeState.Purpose,
    };
    createComment(categoryID, topTenID, id, top_ten_entry_comment);
    window.location.reload();
  };

  return (
    <div className="container comment_input_field">
      <div className="row">
        <div className="col-md-12">
          <form onSubmit={(event) => handleSubmit(event)}>
            <input
              type="text"
              onChange={(event) => handleIdeaChange(event)}
              placeholder="IDEA"
              name="idea"
            />
            <input
              type="text"
              onChange={(event) => handlePurposeChange(event)}
              placeholder="PURPOSE"
              name="Purpose"
            />

            <textarea
              placeholder="COMMENT"
              onChange={(event) => handleCommentChange(event)}
              required
              name="comment"
            />
            <input type="submit" value="Submit" className="submit-tt-comment" />
          </form>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  createComment: (categoryID, topTenID, id, data) =>
    dispatch(createComment(categoryID, topTenID, id, data)),
});

export default connect(null, mapDispatchToProps)(TopTenComments);
