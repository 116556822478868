import React, {useState} from 'react'
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Paper from "@mui/material/Paper";
import Badge from '@mui/material/Badge';
import { useHistory } from "react-router-dom";

const SuggestionShowMobileMenu = ({setNewComment, setViewTrending, numberOfComments}) => {
    const history = useHistory();
    const [value, setValue] = useState(null);

    const handleChange = (newValue) => {
      setValue(newValue);

      if (newValue === "back")  return history.push("/suggestion");

      if(newValue === "newComment") return setNewComment(true);

      if( newValue === "viewTrending") return setViewTrending(true);
      
    };
  
    return (
      <div className="suggestion-mobile-menu-container">
        <Box sx={{ width: "100%" }}>
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                handleChange(newValue);
              }}
            >
              <BottomNavigationAction
                label="Back"
                icon={<ArrowBackTwoToneIcon />}
                value="back"
              />
              <BottomNavigationAction
                label="comments"
                icon={
                <Badge badgeContent={numberOfComments} color="success">
                   <ForumTwoToneIcon />
              </Badge>
            
            }
                value="newComment"
              />
              <BottomNavigationAction
                label="Trending"
                icon={<TrendingUpIcon />}
                value="viewTrending"
              />
            </BottomNavigation>
          </Paper>
        </Box>
      </div>
    );
}

export default SuggestionShowMobileMenu