import { BASEURL } from "../../API/apiData";

const createNewProjectOpenItem = async (data) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${BASEURL}project_tasks`, requestOptions);
  const results = await response.json();
  return results;
};

const getAllProjectOpenItems = async (project_id) => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projectopenitems/${project_id}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

export { createNewProjectOpenItem, getAllProjectOpenItems };
