import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { NotificationManager } from "react-notifications";
import ClassEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

function CreateDiscussion({
  isOpen,
  closeModal,
  projectID,
  setProjectDiscussion,
  projectDiscussion,
  createProjectDiscussion,
}) {
  // === MAKE THE FORM CONTROLLED ===
  const [formState, setFormState] = useState({
    discussion: "",
    idea: "",
    purpose: "",
  });
  // === LOADING COMPONENT STATE ===
  const [loading, setLoadingComponent] = useState(false);

  // === SAVE THE DISCUSSION AND MANAGE ERRORS, THEN REFRESH THE STATE ===
  const saveDiscussion = async (data) => {
    const savingData = {
      discussionData: { ...data },
    };
    try {
      const { payload } = await createProjectDiscussion(projectID, savingData);
      setProjectDiscussion([payload, ...projectDiscussion]);
      setFormState({
        discussion: "",
        idea: "",
        purpose: "",
      });
      closeModal();
      setLoadingComponent(false);
      NotificationManager.info("Discussion created successfully");
    } catch (error) {
      NotificationManager.error(
        "Something went wrong. Kindly check your internet connection and try again"
      );
      setLoadingComponent(false);
    }
    setFormState({
      discussion: "",
      idea: "",
      purpose: "",
    });
  };
  // === SUBMIT THE FORM ===
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoadingComponent(true);
    saveDiscussion(formState);
  };
  // === LOADING COMPONENTS ===
  const loadingComponent = () => {
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
  };

  return (
    <Modal show={isOpen} onHide={closeModal}>
      <Modal.Header
        className="text-white"
        style={{ backgroundColor: "#047C7C" }}
        closeButton
      >
        <Modal.Title>Add Discussion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="need">Title of discussion</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.discussion}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, discussion: data });
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="idea">Idea</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.idea}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, idea: data });
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="purpose">Purpose</label>
            <CKEditor
              className="form-control"
              editor={ClassEditor}
              data={formState.purpose}
              onChange={(event, editor) => {
                const data = editor.getData();
                setFormState({ ...formState, purpose: data });
              }}
            />
          </div>

          <div className="form-group float-right">
            <button
              className="add-btn rounded text-white"
              type="submit"
              disabled={loading}
            >
              {loading ? loadingComponent() : "Submit"}
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <h5>TUC SYSTEM</h5>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateDiscussion;
