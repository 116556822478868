import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import _ from "lodash";

function InvitesTable({ invitationState, handleDeleteInvite, loader }) {
  const [deletingId, setDeletingId] = useState(null);

  if (_.isEmpty(invitationState))
    return <Alert severity="info">There is no any invitations</Alert>;

  const handleDelete = (inviteID) => {
    setDeletingId(inviteID);
    handleDeleteInvite(inviteID);
  };

  const result = invitationState.map((invite, index) => {
    return (
      <tr key={invite.id}>
        <th scope="row">{index + 1}</th>
        <td>{invite.email}</td>
        <td>{invite.link}</td>
        <td>{invite.expired ? "YES" : "No"}</td>
        <td>{invite.expired ? "YES" : "No"}</td>
        <td>
          {" "}
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(invite.id)}
          >
            {deletingId === invite.id && loader ? "Deleting..." : "Remove"}
          </Button>{" "}
        </td>
      </tr>
    );
  });

  return (
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Email</th>
          <th scope="col">Invite Link</th>
          <th scope="col">Is the invite link used?</th>
          <th scope="col">Is the invite link expired?</th>
          <th scope="col">action</th>
        </tr>
      </thead>
      <tbody>{result}</tbody>
    </table>
  );
}

export default InvitesTable;
