import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// === MATERIAL UI ===
import { editSuggestionCommentReply } from "../../services/suggestions/suggestionCommentReplies";

function SuggestionCommentReplyEdit({
  commentReplyContent,
  replyComment,
  suggestionID,
  suggestionResponseID,
  setCommentReplyEditForm,
  fetchSuggestionComments,
}) {
  const { id } = replyComment;
  //    === APP STATE ===
  const [reply, setReply] = useState("");
  //  === useEffect ===
  useEffect(() => {
    setReply(commentReplyContent);
  }, [commentReplyContent]);
  // === CREATING EDIT COMMENT REPLY
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Edit the content
    try {
      await editSuggestionCommentReply(
        suggestionID,
        suggestionResponseID,
        id,
        reply
      );
      toast.success("Edited successfully");
      fetchSuggestionComments();

      setCommentReplyEditForm(false);
    } catch (error) {
      toast.error("Something went wrong please try again");
      setCommentReplyEditForm(false);
    }
  };
  return (
    <div>
      <div className="container comment-reply-form">
        <CKEditor
          editor={ClassicEditor}
          data={commentReplyContent}
          onChange={(event, editor) => {
            const data = editor.getData();
            setReply(data);
          }}
        />

        <button
          type="submit"
          className="btn btn-success"
          onClick={(e) => handleFormSubmit(e)}
        >
          Edit
        </button>
      </div>
    </div>
  );
}

export default SuggestionCommentReplyEdit;
