import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Box } from "@mui/material";

function TaskCalender({ selectedTabItems }) {
  const [currentEvents, setCurrentEvents] = useState([...selectedTabItems()]);
  const [, setSelectionApi] = useState({
    selected: null,
    calenderApi: null,
  });

  useEffect(() => {
    setCurrentEvents([...selectedTabItems()]);
  }, [selectedTabItems]);

  // ==== ADD EVENT TO THE CALENDER ====
  const handleDateClick = (selected) => {
    const calenderApi = selected.view.calendar;
    setSelectionApi({
      selected,
      calenderApi,
    });
  };

  // ==== WHEN YOU CLICK THE EVENT ON THE CALENDER ====
  const handleEventClick = (selected) => {
    console.log(selected);
  };

  return (
    <Box m="5px">
      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="5px">
          <FullCalendar
            height="75vh"
            themeSystem="bootstrap"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            // eventsSet={(events) => setCurrentEvents(events)}
            initialEvents={[...currentEvents]}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default TaskCalender;
