import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import CommentIcon from "@mui/icons-material/Comment";
import { fetchSuggestionEngagements } from "../../services/engagement/engagementService";

const SuggestionTrendingDrawer = ({ viewTrending, setViewTrending }) => {
  const history = useHistory();
  const [state, setState] = useState({ right: false });
  const [trendingSuggestion, setTrendingSuggestion] = useState([]);

  useEffect(() => {
    if (viewTrending === true) {
      setState({ right: true });
    }
    return () => {
      setViewTrending(false);
    };
  }, [viewTrending, setViewTrending]);

  useEffect(() => {
    getTrendingSuggestion();
  }, []);

  const getTrendingSuggestion = async () => {
    try {
      const results = await fetchSuggestionEngagements();
      setTrendingSuggestion([...results]);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToTrendingSuggestion = (tSuggestion) => {
    const { suggestion_id } = tSuggestion;
    history.push(`/suggestion/${suggestion_id}`);

    window.location.reload();
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = (anchor) => {
    toggleDrawer(anchor, false);
    setViewTrending(false);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => handleClick(anchor)}
      onKeyDown={() => handleClick(anchor)}
    >
      <List
        sx={{ bgcolor: "background.paper" }}
        subheader={
          <>
            <ListSubheader component="div" id="nested-list-subheader">
              Most Engaged Suggestions
            </ListSubheader>
            <Divider />
          </>
        }
      >
        {trendingSuggestion.map((suggestion) => (
          <Fragment key={suggestion.id}>
            <ListItem
              key={suggestion.id}
              disablePadding
              onClick={() => navigateToTrendingSuggestion(suggestion)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Badge badgeContent={suggestion.count} color="primary">
                    <CommentIcon />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary={suggestion.name} />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};

export default SuggestionTrendingDrawer;
