import React from 'react'
import '../../stylesheets/search.css'
function SearchComponent(props) {
  return (
    <div className='suggestion-search-container'>
      <div className="search-box">
        <button className="btn-search"><i className="fas fa-search"></i></button>
        <input
          className="input-search"
          type="search"
          placeholder={props.placeholder}
          aria-label="Search"
          onChange={props.getsearch}
        />
      </div>
    </div>
  )
}

export default SearchComponent
