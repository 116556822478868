import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { fetchUser } from "../../services/users/userService";


const UserToggleNav = ({ handleLogout, showUserToggleNav }) => {

  const[isAdmin, setIsAdmin] = useState(false)

  const history = useHistory();

  useEffect(() => {
    getUser()
  }, [])
  

  const getUser = async () => {
    const result = await fetchUser();
    if (result.user.user_type_id === 1){
      setIsAdmin(true);
    }else {
      setIsAdmin(false);
    }
  };
  
  const navigateBadges = () => {
    history.push({
      pathname: `/profile`,
      state: { tabID: 4 },
    });
  };

  const handleUserToggle = () => {
    showUserToggleNav()
    navigateBadges()
  }


  return (
    <div className="user-toggle-nav shadow-lg">
      <button type="button" className="btn-wrapper" onClick={showUserToggleNav}>
        <Link to={"/profile"}>
          <i className="fas fa-user-circle"></i>&nbsp;&nbsp;My Profile
        </Link>
      </button>
      {isAdmin ? (
        <>
          <button
            type="button"
            className="btn-wrapper"
            onClick={showUserToggleNav}
          >
            <Link to={"/admin"}>
              <i className="fa fa-lock"></i>&nbsp;&nbsp;Admin
            </Link>
          </button>
        </>
      ) : null}
      <button type="button" className="btn-wrapper" onClick={handleUserToggle}>
        <div className="bagdes-div">
          <i className="fas fa-award"></i>&nbsp;&nbsp;Badges
        </div>
      </button>
      <button type="button" className="btn-wrapper" onClick={showUserToggleNav}>
        <Link to={"/under_construction"}>
          <i className="fas fa-envelope"></i>&nbsp;&nbsp;Messages
        </Link>
      </button>
      <button className="nav-btn-logout mt-3" onClick={handleLogout}>
        <i className="fas fa-power-off"></i>&nbsp;&nbsp;Logout
      </button>
    </div>
  );
};

export default UserToggleNav;
