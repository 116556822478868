// ==== THIS FILES HANDLES QUERIES FOR ALL PROJECTS CONTAINER ====
import { useQuery } from "react-query";
import getAllDashBoardContents from "../../services/DashBoard";

// --- THIS FETCHES ALL THE DASHBOARD CONTENTS ----
const UseFetchAllDashBoardContents = () => {
  const fetchDashBoardContent = async () => {
    const { result } = await getAllDashBoardContents();
    return result;
  };
  return useQuery({
    queryKey: ["allDashBoardContents"],
    queryFn: fetchDashBoardContent,
  });
};

export { UseFetchAllDashBoardContents };
