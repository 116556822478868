import React from "react";
import parse from "html-react-parser";
import { Button } from "@mui/material";

const ProjectIPMCard = ({
  icon,
  title,
  details,
  projectSdgs,
  sdgsEditButton,
  handleSDG,
}) => {
  const displayProjectSdgs = () => {
    let results = projectSdgs.map((value) => {
      return (
        <div key={value.sdg_name}>
          <img src={value.sdg_image} alt={value.sdg_name} />
        </div>
      );
    });
    return results;
  };
  return (
    <>
      <div className="s-project-ipm-card-icon-container">
        {icon && <img src={icon} alt="icon" />}
        {sdgsEditButton ? (
          <div className="sdg-container">
            <h5 className="s-project-ipm-card-details-title">{title}</h5>
            <Button
              onClick={(e) => handleSDG(e)}
              variant="contained"
              color="secondary"
            >
              +Add
            </Button>
          </div>
        ) : (
          <h5 className="s-project-ipm-card-details-title">{title}</h5>
        )}
      </div>
      <div className="s-project-ipm-card">
        <div className="s-project-ipm-card-details">
          <div>{details && parse(details)}</div>
          <div className="display-sdgs">
            {projectSdgs && displayProjectSdgs()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectIPMCard;
