/*eslint-disable */
import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import avatar from "../../images/avatar.jpeg";
import Tooltip from "@material-ui/core/Tooltip";
import RemixModal from "../RemixModal";
import EditModal from "../EditModal";
import { createRemix } from "../../redux";
import ProjectModal from "../project/ProjectModal";

let editID;
let editObject;
let remixObject;
let remixID;

const TopTenEntryCard = ({
  idx,
  data,
  catId,
  ttId,
  createRemix,
  handleVote,
  handleCreateProject,
}) => {
  const history = useHistory();
  const [details, showDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProjectMoal, setOpenProjectModal] = useState(false);
  const [openRemix, setOpenRemix] = useState(false);
  const [userLiked, setUserLiked] = useState(data.user_liked);
  const [votes, setVotes] = useState(data.votes);

  const showProjectModal = () => {
    setOpenProjectModal(!openProjectMoal);
  };

  const openEditModal = (id, data) => {
    setOpen(true);
    editID = id;
    editObject = data;
  };

  const closeEditModal = () => {
    setOpen(false);
  };

  const openRemixModal = (id, data) => {
    setOpenRemix(true);
    remixID = id;
    remixObject = data;
  };

  const closeRemixModal = () => {
    setOpenRemix(false);
  };

  const handleShowPage = (id, data, creator, time) => {
    history.push({
      pathname: `/categories/${catId}/topten/${ttId}/entries/${id}`,
      state: {
        object: data,
        creator: creator,
        time: time,
      },
    });
  };

  const handleCreateRemix = (data = remixObject) => {
    createRemix(catId, ttId, remixID, data);
    setTimeout(() => {
      window.location.reload(true);
    }, 500);
  };

  const voteEntry = () => {
    const xData = {
      likeable_type: "TopTenEntry",
      likeable_id: data.id,
    };
    handleVote(xData);
    setUserLiked(!userLiked);
    if (userLiked) {
      setVotes((prev) => prev - 1);
    } else {
      setVotes((prev) => prev + 1);
    }
  };

  return (
    <>
      {openProjectMoal ? (
        <ProjectModal
          sugId={""}
          ttId={data.id}
          categories={data.project_categories}
          closeModal={showProjectModal}
          createProject={handleCreateProject}
        />
      ) : null}
      <EditModal
        id={editID}
        closeModal={closeEditModal}
        isOpen={open}
        object={editObject}
        catID={catId}
        ttID={ttId}
      />
      <RemixModal
        id={remixID}
        closeModal={closeRemixModal}
        isOpen={openRemix}
        object={remixObject}
        handleSubmit={handleCreateRemix}
      />
      <div className="project-tt-entry-card mt-2 shadow">
        <div className="project-tt-entry-card-header">
          <div>
            <span className="project-tt-entry-card-header-idx">{idx + 1}</span>
            {data.is_remix && (
              <span>
                <i
                  className="fa fa-registered text-warning"
                  aria-hidden="true"
                ></i>
              </span>
            )}
            &nbsp;&nbsp;
            <span>{data.entry["Name"]}</span>
          </div>
          <div>
            <button
              className="p-ttdet-btn"
              onClick={() => showDetails(!details)}
            >
              <i className="fa fa-chevron-down mt-2"></i>
            </button>
          </div>
        </div>
        {details ? (
          <div className="project-tt-entry-card-details extend-c">
            <div className="project-tt-entry-card-details-info extend">
              <div className="project-link-div">
                <div className="project-link-title">
                  {data.project !== null ? (
                    <>
                      <i className="fas fa-project-diagram text-info"></i>
                      &nbsp;&nbsp;
                      <span>Project</span>&nbsp;&nbsp;
                      <i className="fa fa-chevron-right text-info"></i>
                      &nbsp;&nbsp;
                      <Link to={`/projects/${data.project.id}`}>
                        <span className="text-success">
                          {data.project.name}
                        </span>
                      </Link>
                    </>
                  ) : null}
                </div>
                <div>
                  {data.project === null && (
                    <button type="button" onClick={showProjectModal}>
                      Create Project
                    </button>
                  )}
                </div>
              </div>
              <div className="project-tt-entry-card-btns">
                <button type="button" onClick={voteEntry}>
                  {userLiked ? (
                    <i className="fas fa-heart text-danger"></i>
                  ) : (
                    <i className="fas fa-heart"></i>
                  )}
                  &nbsp;&nbsp;{votes}
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleShowPage(
                      data.id,
                      data.entry,
                      data.created_by,
                      data.created_at
                    )
                  }
                >
                  <i className="fa fa-commenting text-success"></i>&nbsp;&nbsp;
                  {data.comments}
                </button>
                <button
                  type="button"
                  onClick={() => openRemixModal(data.id, data.entry)}
                >
                  <i className="fas fa-recycle text-success"></i>&nbsp;&nbsp;
                  {data.remixes}
                </button>
                <button
                  type="button"
                  onClick={() => openEditModal(data.id, data.entry)}
                >
                  <i className="fas fa-edit text-primary"></i>
                </button>
              </div>
              <div className="ptt-e-creator">
                <h6>Created by: {data.created_by}</h6>
                <div className="pp-t-users">
                  {data.users_engaged.map((u, i) => {
                    return (
                      <div key={i}>
                        <Link to={`/profile/${u.id}`}>
                          <Tooltip
                            interactive
                            className="toolTip"
                            title={`${u.name}`}
                          >
                            <img
                              className="profile-avatar-thumb-sm"
                              src={u.avatar !== null ? u.avatar : avatar}
                              alt={`${u.name}`}
                            />
                          </Tooltip>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createRemix: (catId, tId, tEId, data) =>
    dispatch(createRemix(catId, tId, tEId, data)),
});

export default connect(null, mapDispatchToProps)(TopTenEntryCard);
