import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import styles from "./css/main.module.css";
import InvitationForm from "./InvitationForm";
import CircularProgress from "@mui/material/CircularProgress";
import { NotificationContainer } from "react-notifications";
import { NotificationManager } from "react-notifications";

import InvitesTable from "./InvitesTable";
import {
  destroyAnInvite,
  getAllInvites,
  sendAnInvite,
} from "../../services/InviteSystem/inviteSystemService";

function Invitation() {
  const [invitationState, setInvitationState] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [email, setEmail] = useState("");

  //   === FETCH ALL INVITES

  const fetchAllInvitations = async () => {
    setLoader(true);
    try {
      const { result } = await getAllInvites();
      setInvitationState([...result]);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchAllInvitations();
  }, []);

  //   create a new invite
  const handleCreateInvitation = async () => {
    setLoader(true);
    const data = {
      invitation: {
        email: email,
      },
    };

    try {
      const { message } = await sendAnInvite(data);
      if (message === "User already exists in the platform") {
        NotificationManager.error(`${message}`);
        setLoader(false);
        setEmail("");
        return;
      }
      if (message === "An invitation has already been sent to this user") {
        NotificationManager.error(`${message}`);
        setLoader(false);
        setEmail("");
        return;
      }
      NotificationManager.success(`${message}`);
      setLoader(false);
      setEmail("");
      fetchAllInvitations();
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  //   delete an invite

  const handleDeleteInvite = async (inviteId) => {
    setDeleteLoader(true);
    try {
      const { message } = await destroyAnInvite(inviteId);
      if (message === "Invitation deleted") {
        NotificationManager.success(`${message}`);
        setDeleteLoader(false);
        fetchAllInvitations();
      }
      setDeleteLoader(false);
    } catch (error) {
      console.log(error);
      setDeleteLoader(false);
    }
  };
  return (
    <>
      <Navbar />
      <NotificationContainer />
      <div className={styles.main_container}>
        <div className={`container-fluid ${styles.invitation_container}`}>
          <h3 className={styles.header_text}>
            Invite a new user to TUC platform.
          </h3>
          <h6 className={styles.header_small_text}>
            Send out an invite by adding an email of the user you want to
            invite.
          </h6>
        </div>
        <div className={styles.form_container}>
          <InvitationForm
            setEmail={setEmail}
            email={email}
            handleCreateInvitation={handleCreateInvitation}
            loader={loader}
          />
        </div>
        <hr />
        <div className={`container-fluid ${styles.invite_table_container}`}>
          {loader ? (
            <CircularProgress color="secondary" />
          ) : (
            <InvitesTable
              invitationState={invitationState}
              handleDeleteInvite={handleDeleteInvite}
              loader={deleteLoader}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Invitation;
