import React from 'react'
import { Link } from 'react-router-dom';
import avatar from "../../images/avatar.jpeg";

const EngagementCard = ({idx,data}) => {
  return (
    <Link
      className="text-info text-decoration-none"
      to={`${data.route}/${data.id}`}
    >
      <div className="engagement-user-card">
        <div className="d-flex justify-content-start">
          <h6 className="badge badge-info">{idx + 1}</h6>
          &nbsp;
          <img
            className="profile-avatar-thumb-xs"
            src={data.profile_avatar ? data.profile_avatar : avatar || null}
            alt="avatar"
          />
          &nbsp;&nbsp;
          <h6>{data.name}</h6>
        </div>
        <div>
          <h6 className="p-2 text-info">
            {data.count} engagements &nbsp;
            <i className="fa fa-area-chart" aria-hidden="true"></i>
          </h6>
        </div>
      </div>
    </Link>
  );
}

export default EngagementCard