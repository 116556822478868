import { BASEURL } from "../../API/apiData";
import {bmcListsRoutes, bmcMainRoutes} from "./routes"

const getAllKeyActivities = async (projectID , bmcID) => {
  const token = localStorage.getItem("token");

    const requestOptions = {
        method: "GET",
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        },
    };
    const response = await fetch(
        `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.activities}`,
        requestOptions
      );
      const results = await response.json();
      return results;
}

const createNewKeyActivities = async (projectID, projectName, bmcID) => {
  const token = localStorage.getItem("token");

    const data = {
      key_activity_name: `${projectName} Key activites`
    }
    const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      };
    const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.activities}`,
    requestOptions
    );
    const results = await response.json();
    return results;
} 


// === LIST ITEMS ===
const createNewKeyActivitiesList = async (projectID, bmcID, channelID, name ) => {
  const token = localStorage.getItem("token");

  const data = {
    key_activity_item: {
      item_name: name
    }
  }

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.activities}/${channelID}/${bmcListsRoutes.activitiesItems}`,
    requestOptions
    );

const results = await response.json();
return results;

}

const editNewKeyActivitiesList = async (projectID, bmcID, channelID, name, ID ) => {
  const token = localStorage.getItem("token");

const data = {
  key_activity_item: {
    item_name: name
  }
}

const requestOptions = {
  method: "PUT",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
  body: JSON.stringify(data),
};

const response = await fetch(
  `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.activities}/${channelID}/${bmcListsRoutes.activitiesItems}/${ID}`,
  requestOptions
  );

const results = await response.json();
return results;

}

export { getAllKeyActivities , createNewKeyActivities, createNewKeyActivitiesList, editNewKeyActivitiesList }