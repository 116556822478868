/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { fetchAllProfile } from "../redux";
import UserCard from "../components/badges/UserCard";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/admin.css";
import _ from "lodash";
import AwardModal from "../components/badges/AwardModal";
import { assignBadge, fetchBadges } from "../services/badges/badgeService";

const BadgeManagerContainer = ({ getUsers }) => {
  const [users, setUsers] = useState([]);
  const [showAwardModal, setShowAwardModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [badges, setBadges] = useState([]);

  const getBadges = async () => {
    const response = await fetchBadges();
    setBadges(response.result);
  };

  const assignUserBadge = async (data) => {
    try {
      await assignBadge(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBadges();
  }, []);

  useEffect(() => {
    getUsers()
      .then((response) => {
        setUsers(response.payload);
      })
      .catch((err) => console.log("Internal Server Error"));
  }, [getUsers]);

  const showModal = () => {
    setShowAwardModal(!showAwardModal);
  };

  const showUsers = () => {
    if (_.isEmpty(users)) {
      return null;
    } else if (!_.isEmpty(users)) {
      return (
        <>
          <div className="user-card-container">
            {users.map((user) => (
              <UserCard
                key={user.id}
                data={user}
                showAwardModal={showModal}
                setUserId={setUserId}
              />
            ))}
          </div>
        </>
      );
    }
  };

  const handleAssignBadge = (data) => {
    assignUserBadge(data);
  };

  return (
    <>
      <Navbar />
      <AwardModal
        isOpen={showAwardModal}
        closeModal={setShowAwardModal}
        data={badges}
        profileId={userId}
        handleAssignBadge={handleAssignBadge}
      />
      <ToastContainer />
      <div className="admin-header">
        <span>
          <i className="fa fa-lock" aria-hidden="true"></i>&nbsp;TUC Admin
          Panel/ Badge Manager
        </span>
      </div>
      <i className="fa fa-search search-icon" aria-hidden="true"></i>
      <input
        type="text"
        className="admin-user-search"
        name="search"
        placeholder="Search User"
      />
      {showUsers()}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => dispatch(fetchAllProfile()),
});

export default connect(null, mapDispatchToProps)(BadgeManagerContainer);
