import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// --- react query -----
import { useMutation, useQueryClient } from "react-query";
//
import Navbar from "../components/navbar/Navbar";
import MainDisplay from "../components/Networks/MainDisplay";
import CreateNewNetwork from "../components/Networks/reusableComponents/CreateNewNetwork";
import AppTopBar from "../components/Networks/reusableComponents/AppTopBar";
import {
  UseFetchAllNetworks,
  UseFetchAllProjectsForNetwork,
} from "../hooks/Network";
import _ from "lodash";
import AppLoadingSkeleton from "../components/reusablecomponents/AppLoadingSkeleton";
// ---- MATERIAL UI ----
import Alert from "@mui/material/Alert";
import {
  addProjectToNetwork,
  createNewNetwork,
} from "../services/NetworksService";
import AddProjectsToNetworkModal from "../components/Networks/reusableComponents/AddProjectsToNetworkModal";

const Networks = () => {
  const history = useHistory();
  // -- CREATE NEW NETWORK MODAL ----
  const [createNetworkModal, setCreateNetworkModal] = useState(false);
  // --- INPUT ELEMENTS ---
  const [networkDataElements, setNetworkDataElements] = useState({
    name: "",
    idea: "",
    purpose: "",
    mission: "",
    vision: "",
  });
  const [searchValue, setSearchValue] = useState(null);
  // --- ADD PROJECTS TO NETWORK MODAL ----
  const [addProjectsToNetworkModal, setAddProjectsToNetworkModal] =
    useState(false);
  const [networkID, setNetworkID] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);

  // --- REACT QUERY ----
  const queryClient = useQueryClient();

  const {
    data: networkData,
    error: networkError,
    isLoading: networkIsLoading,
  } = UseFetchAllNetworks();

  const {
    data: networkProjects,
    error: networkProjectsError,
    isLoading: networkProjectsIsLoading,
  } = UseFetchAllProjectsForNetwork(networkID);

  const createNewNetworkMutation = useMutation({
    mutationFn: async (networkData) => createNewNetwork(networkData),
    onSuccess: () => {
      setNetworkDataElements({
        name: "",
        idea: "",
        purpose: "",
        mission: "",
        vision: "",
      });
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
      setCreateNetworkModal(false);
    },
  });

  const addProjectToNetworkMutation = useMutation({
    mutationFn: async (networkProjectData) =>
      addProjectToNetwork(networkProjectData),
    onSuccess: () => {
      setSelectedProjects([]);
      queryClient.invalidateQueries({
        queryKey: ["networks"],
      });
      setAddProjectsToNetworkModal(false);
    },
  });

  const newNetwork = async (networkData) => {
    createNewNetworkMutation.mutate(networkData);
  };

  const newNetworkProject = async () => {
    let selectedProjectID = _.uniq(selectedProjects);

    if (_.isEmpty(selectedProjectID)) return;

    selectedProjectID.forEach((item) => {
      let data = {
        project_network: {
          project_id: item,
          network_id: networkID,
        },
      };

      addProjectToNetworkMutation.mutate(data);
    });
  };

  // ----Navigation ---

  const naviageToDashBoard = () => {
    history.push("/dashboard");
  };

  const navigateToNetworks = () => {
    history.push("/networks");
  };

  if (networkIsLoading)
    return (
      <>
        <Navbar />
        <AppLoadingSkeleton />
      </>
    );

  if (networkError)
    return (
      <>
        <Navbar />
        <div className="container mt-4 text-center">
          <Alert variant="filled" severity="error">
            Something went wrong while fetching networks ....
          </Alert>
        </div>
      </>
    );

  return (
    <>
      <CreateNewNetwork
        open={createNetworkModal}
        setOpen={setCreateNetworkModal}
        newNetwork={newNetwork}
        createNewNetworkMutation={createNewNetworkMutation}
        networkData={networkDataElements}
        setNetworkData={setNetworkDataElements}
      />
      <AddProjectsToNetworkModal
        open={addProjectsToNetworkModal}
        setOpen={setAddProjectsToNetworkModal}
        networkProjects={networkProjects}
        networkProjectsIsLoading={networkProjectsIsLoading}
        networkProjectsError={networkProjectsError}
        setSelectedProjects={setSelectedProjects}
        selectedProjects={selectedProjects}
        newNetworkProject={newNetworkProject}
        addProjectToNetworkMutation={addProjectToNetworkMutation}
      />
      <Navbar />
      <AppTopBar
        naviageToDashBoard={naviageToDashBoard}
        navigateToNetworks={navigateToNetworks}
      />
      <MainDisplay
        setCreateNetworkModal={setCreateNetworkModal}
        networkData={networkData}
        setNetworkID={setNetworkID}
        setAddProjectsToNetworkModal={setAddProjectsToNetworkModal}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </>
  );
};

export default Networks;
