/* eslint-disable no-empty-pattern */
import React from "react";
import { Formik } from "formik";

import ViewPasswordBtn from "../ViewPasswordBtn";
import AppInputField from "./AppInputField";
import AppLabel from "./AppLabel";
import { registrationSchema } from "./schema";

function AppSignUpForm({ registerUser, disable, loader, buttonLoader }) {
  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          userName: "",
          email: "",
          password: "",
          passwordConfirmation: "",
        }}
        onSubmit={(value) => registerUser(value)}
        validationSchema={registrationSchema}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <AppLabel>FirstName</AppLabel>
                <AppInputField
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  classname="form-control"
                />
              </div>
              <div className="form-group">
                <AppLabel>LastName</AppLabel>
                <AppInputField
                  type="text"
                  name="lastName"
                  placeholder="Enter Last name"
                  classname="form-control"
                />
              </div>
              <div className="form-group">
                <AppLabel>UserName</AppLabel>
                <AppInputField
                  type="text"
                  name="userName"
                  placeholder="Username should have no spaces"
                  classname="form-control"
                />
              </div>
              <div className="form-group">
                <AppLabel>Email</AppLabel>
                <AppInputField
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  classname="form-control"
                />
              </div>
              <div className="form-group">
                <AppLabel>Password</AppLabel>
                <AppInputField
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  classname="form-control pwd"
                />
                <div className="w-100 text-right">
                  <ViewPasswordBtn />
                </div>
                <small
                  id="passwordHelpBlock"
                  className="form-text text-secondary"
                >
                  Your password must be 6-20 characters long, contain letters
                  and numbers, and must not contain spaces, special characters,
                  or emoji.
                </small>
              </div>
              <div className="form-group">
                <AppLabel> Password Confirmation </AppLabel>
                <AppInputField
                  type="password"
                  name="passwordConfirmation"
                  placeholder="Enter password confirmation"
                  classname="form-control pwd"
                />
                <div className="w-100 text-right">
                  <ViewPasswordBtn />
                </div>
              </div>
              <div className="text-center m-3">
                <button
                  className="auth-btn-one mt-3"
                  type="submit"
                  disabled={disable}
                  onClick={handleSubmit}
                >
                  {" "}
                  {loader === true ? buttonLoader() : `SIGN UP`}{" "}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default AppSignUpForm;
