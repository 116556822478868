/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  fetchSuggestionCategories,
  fetchSuggestions,
  fetchProjects,
} from "../redux";
import { createVote } from "../services/votes/voteService";
import _ from "lodash";
import "../stylesheets/category.css";
import LoadingSpinner from "../components/LoadingSpinner";
import { Link, useHistory } from "react-router-dom";
import SuggestionForm from "../components/suggestionbox/suggestionForm";
import SuggestionBody from "../components/suggestionbox/SuggestionBody";
import "../stylesheets/suggestion.css";
import SuggestionFilterBar from "../components/suggestionbox/SuggestionFilterBar";
import SuggestionNavigation from "../components/suggestionbox/SuggestionNavigation";
import SuggestionCategories from "../components/suggestionbox/SuggestionCategories";
import SuggestionSearches from "../components/suggestionbox/SuggestionSearches";
import SuggestionTrending from "../components/suggestionbox/SuggestionTrending";
import SuggestionBoxBanner from "../components/suggestionbox/SuggestionBoxBanner";
import Navbar from "../components/navbar/Navbar";
import LinkProjectModal from "../components/suggestionbox/LinkProjectModal";
import SuggestionMobileMenu from "../components/suggestionbox/SuggestionMobileMenu";
import SuggestionBoxTrendingMobile from "../components/suggestionbox/SuggestionBoxTrendingMobile";
import MobileSearchDialog from "../components/suggestionbox/MobileSearchDialog";
import AppFullDialog from "../components/reusablecomponents/AppFullDialog";
import SuggestionTrendingDrawer from "../components/suggestionbox/SuggestionTrendingDrawer";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SuggestionBoxPlusButton from "../components/suggestionbox/SuggestionBoxPlusButton";
import NewSuggestionModal from "../components/suggestionbox/NewSuggestionModal";
import DisplayLinkedProjects from "../components/suggestionbox/DisplayLinkedProjects";

const SuggestionsContainer = ({
  suggestions,
  fetchSuggestion,
  fetchSuggestionCategories,
  createProject,
  fetchProjects,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [suggestionCategories, setSuggestionCategories] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [suggestion, setSuggestion] = useState(null);
  const [action, setAction] = useState("create");
  const [projects, setAllProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSuggestionID, setSelectedSuggestionID] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  //partition . filtering . sorting
  const [items, setItems] = useState([]);
  const [dataSet, setDataSet] = useState({ page_size: 10, page_number: 1 });
  const [pageCount, setPageCount] = useState(0);
  const [filterItems, setFilterItems] = useState([]);

  // === MOBILE MENU STATE ===
  const [searchButton, setSearchButton] = useState(false);
  const [mobileSuggestionForm, setMobileSuggestionForm] = useState(false);
  const [viewTrending, setViewTrending] = useState(false);
  // === MOBILE SEARCH STATE IMPLEMENTATION ===
  const [mobileSearchSuggestion, setMobileSearchSuggestion] = useState(null);
  // === DESKTOP PLUS BUTTON STATE IMPLEMENTATION ===
  const [desktopPlusBtnState, setDesktopPlusBtnState] = useState({
    search: false,
    addSuggestion: false,
  });
  // ===== THIS IS THE STATE RESPONSIBLE FOR LINKED PROJECTS LOGIC ====
  const [displayLinkedProjects, setDisplayLinkedProjects] = useState(false);
  const [listSpecificSuggestionProjects, setlistSpecificSuggestionProjects] =
    useState({
      selectedID: null,
      suggestionProjects: [],
    });
  // ===== LINKING PROJECTS ====
  const [projectsToLink, setProjectsToLink] = useState([]);

  // ======================= END OF STATES ===================================
  console.log(suggestions);
  // FETCH SUGGESTIONS AND SUGGETIONS CATEGORY
  useEffect(() => {
    fetchSuggestion();
    fetchSuggestionCategories()
      .then((res) => setSuggestionCategories(res.payload.result))
      .catch((err) => console.log("Internal Application Error"));
  }, [fetchSuggestion, fetchSuggestionCategories]);

  // ==== FETCH ALL PROJECTS ====
  const getAllProjects = async () => {
    try {
      const { payload } = await fetchProjects();
      setAllProjects(payload.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  // ==== PAGE PAGINATION ====
  useEffect(() => {
    let pages = pageCount;
    if (suggestions.data.result) {
      pages = Math.ceil(suggestions.data.result.length / dataSet["page_size"]);
      setPageCount(pages); //total number of pages
      setFilterItems(suggestions.data.result); //set the collection to work with after filtered
      setItems(getPage(suggestions.data.result, 1)); //set the collection to display in page
    }
  }, [suggestions]);

  const getPage = (set, currentPage) => {
    const firstRec = (currentPage - 1) * dataSet["page_size"];
    const page_to_show = set.slice(firstRec, firstRec + dataSet["page_size"]);
    return page_to_show;
  };

  const sendVote = async (data) => {
    try {
      const res = await createVote(data);
      res.message == "Thanks for your vote"
        ? toast.success(res.message)
        : toast.error(res.message);
    } catch (error) {
      console.log(error);
    }
  };

  const handleVote = (data) => {
    sendVote(data);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const chosenMobileCategory = (category) => {
    setSelectedCategory(category);
  };
  // ==== DISPLAY RECENT SEARCH ====
  const recentSearch = () => {
    return (
      <Stack
        sx={{ width: "100%" }}
        spacing={2}
        className="recent-search-mobile"
      >
        <Alert
          severity="info"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => setMobileSearchSuggestion(null)}
            >
              <DeleteIcon /> clear
            </Button>
          }
        >
          <AlertTitle>Recent Search</AlertTitle>
          <strong>
            {" "}
            {mobileSearchSuggestion !== null &&
              mobileSearchSuggestion.idea}{" "}
          </strong>
        </Alert>
      </Stack>
    );
  };

  // === THIS IS THE FUNCTIONS THAT HANLES THE DISPLAY OF SUGGESTIONS ===
  const handleSuggestionsDisplay = () => {
    if (mobileSearchSuggestion !== null && selectedCategory === null) {
      // ==== This handles the search for mobile
      return (
        <div className="suggestion-mobile-search-results">
          <SuggestionBody
            desktopPlusBtnState={desktopPlusBtnState}
            setDesktopPlusBtnState={setDesktopPlusBtnState}
            setSuggestion={setSuggestion}
            setAction={setAction}
            sug={mobileSearchSuggestion}
            categories={suggestionCategories}
            handleVote={handleVote}
            handleClickOpen={handleClickOpen}
            setSelectedSuggestionID={setSelectedSuggestionID}
            setDisplayLinkedProjects={setDisplayLinkedProjects}
            displayLinkedProjects={displayLinkedProjects}
            setlistSpecificSuggestionProjects={
              setlistSpecificSuggestionProjects
            }
            listSpecificSuggestionProjects={listSpecificSuggestionProjects}
            projects={projects}
            setProjectsToLink={setProjectsToLink}
          />
        </div>
      );
    }

    // ==== IF THERE IS NO CATEGORY CHOSEN ====
    if (selectedCategory === null)
      return items.map((sug) => {
        return (
          <SuggestionBody
            desktopPlusBtnState={desktopPlusBtnState}
            setDesktopPlusBtnState={setDesktopPlusBtnState}
            key={sug.id}
            sug={sug}
            categories={suggestionCategories}
            handleVote={handleVote}
            handleClickLinkOpen={handleClickOpen}
            setSelectedSuggestionID={setSelectedSuggestionID}
            setAction={setAction}
            setSuggestion={setSuggestion}
            setDisplayLinkedProjects={setDisplayLinkedProjects}
            displayLinkedProjects={displayLinkedProjects}
            setlistSpecificSuggestionProjects={
              setlistSpecificSuggestionProjects
            }
            listSpecificSuggestionProjects={listSpecificSuggestionProjects}
            projects={projects}
            setProjectsToLink={setProjectsToLink}
          />
        );
      });

    // ==== This handles the filter of suggestions in both mobile and desktop
    if (selectedCategory !== null) {
      // ==== IF THERE IS A CATEGORY CHOSEN ====
      const { data } = suggestions;
      const { result } = data;

      const filtredByCategory = result.filter((value) => {
        if (value.category_id == selectedCategory) return value;
      });

      const displayFilteredItems = filtredByCategory.map((sug) => {
        return (
          <SuggestionBody
            desktopPlusBtnState={desktopPlusBtnState}
            key={sug.id}
            sug={sug}
            categories={suggestionCategories}
            handleVote={handleVote}
            handleClickLinkOpen={handleClickOpen}
            setSelectedSuggestionID={setSelectedSuggestionID}
            setDesktopPlusBtnState={setDesktopPlusBtnState}
            setAction={setAction}
            setSuggestion={setSuggestion}
            setDisplayLinkedProjects={setDisplayLinkedProjects}
            displayLinkedProjects={displayLinkedProjects}
            setlistSpecificSuggestionProjects={
              setlistSpecificSuggestionProjects
            }
            listSpecificSuggestionProjects={listSpecificSuggestionProjects}
            projects={projects}
            setProjectsToLink={setProjectsToLink}
          />
        );
      });
      return displayFilteredItems;
    }

    // ==== This handles the condition for search bar in desktop display ====
    if (searchInput.length > 1) {
      return items.map((sug) => {
        return (
          <SuggestionBody
            desktopPlusBtnState={desktopPlusBtnState}
            setDesktopPlusBtnState={setDesktopPlusBtnState}
            setSuggestion={setSuggestion}
            setAction={setAction}
            key={sug.id}
            sug={sug}
            categories={suggestionCategories}
            handleVote={handleVote}
            handleClickOpen={handleClickOpen}
            setSelectedSuggestionID={setSelectedSuggestionID}
            setDisplayLinkedProjects={setDisplayLinkedProjects}
            displayLinkedProjects={displayLinkedProjects}
            setlistSpecificSuggestionProjects={
              setlistSpecificSuggestionProjects
            }
            listSpecificSuggestionProjects={listSpecificSuggestionProjects}
            projects={projects}
            setProjectsToLink={setProjectsToLink}
          />
        );
      });
    }
  };

  const showData = () => {
    if (!_.isEmpty(suggestions.data.result) && suggestions.data.loggedIn) {
      const fields = Object.keys(suggestions.data.result[0]);

      return (
        <>
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 tablet-view col-xl-3">
                  <div className="sticky-sec">
                    <SuggestionCategories
                      suggestionCategories={suggestionCategories}
                      setSelectedCategory={setSelectedCategory}
                    />
                    <SuggestionSearches
                      recentSearch={recentSearch}
                      mobileSearchSuggestion={mobileSearchSuggestion}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-xl-6">
                  <div className="position-well-container">
                    {handleSuggestionsDisplay()}
                  </div>
                </div>
                <div className="col-xl-3 computer-view">
                  <div className="sticky-sec">
                    <SuggestionBoxBanner />
                    <SuggestionTrending />
                  </div>
                </div>
              </div>
            </div>
          </>
        </>
      );
    }
    if (_.isEmpty(suggestions.data.result) && suggestions.data.loggedIn) {
      return (
        <>
          <div className="suggest-btn-group container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6 suggestion-header">
                <h3 className="suggestion-main-title">Suggestions</h3>
              </div>

              <div className="col-md-6 col-sm-6 col-6">
                <button
                  onClick={() => setShow(!show)}
                  className="suggest-btn mt-3"
                >
                  Add New Suggestion
                </button>
              </div>
            </div>
          </div>
          <SuggestionFilterBar />
          {show ? (
            <SuggestionForm
              setShow={setShow}
              suggestionCategories={suggestionCategories}
            />
          ) : null}
          <h1 className="text-success text-center mt-2">
            No suggestions available, add some!!!
          </h1>
        </>
      );
    }
    if (suggestions.loading) {
      return (
        <>
          <div className="suggest-btn-group container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-6 suggestion-header">
                <h3 className="suggestion-main-title">Suggestions</h3>
              </div>

              <div className="col-md-6 col-sm-6 col-6">
                <button
                  onClick={() => setShow(!show)}
                  className="suggest-btn mt-3"
                >
                  Add New Suggestion
                </button>
              </div>
            </div>
          </div>

          <LoadingSpinner />
        </>
      );
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      <DisplayLinkedProjects
        displayLinkedProjects={displayLinkedProjects}
        setDisplayLinkedProjects={setDisplayLinkedProjects}
        listSpecificSuggestionProjects={listSpecificSuggestionProjects}
        setlistSpecificSuggestionProjects={setlistSpecificSuggestionProjects}
      />
      <LinkProjectModal
        open={open}
        handleClose={handleClose}
        projects={projectsToLink}
        selectedSuggestionID={selectedSuggestionID}
        fetchSuggestion={fetchSuggestion}
      />
      <MobileSearchDialog
        searchButton={searchButton}
        setSearchButton={setSearchButton}
        setMobileSearchSuggestion={setMobileSearchSuggestion}
        suggestions={suggestions}
      />
      <AppFullDialog
        setOpen={setMobileSuggestionForm}
        open={mobileSuggestionForm}
        details={"Add Suggestion"}
        suggestion={suggestion}
        action={action}
        setShow={setShow}
        suggestionCategories={suggestionCategories}
      />

      <SuggestionTrendingDrawer
        viewTrending={viewTrending}
        setViewTrending={setViewTrending}
      />
      <NewSuggestionModal
        suggestion={suggestion}
        action={action}
        setShow={setShow}
        suggestionCategories={suggestionCategories}
        desktopPlusBtnState={desktopPlusBtnState}
        setDesktopPlusBtnState={setDesktopPlusBtnState}
      />

      <div className="sug-container container-fluid">
        <div className="suggestion-box-trending-mobile container-fluid">
          {mobileSearchSuggestion === null ? (
            <SuggestionBoxTrendingMobile
              suggestionCategories={suggestionCategories}
              chosenMobileCategory={chosenMobileCategory}
            />
          ) : (
            recentSearch()
          )}
        </div>
        {showData()}
      </div>
      {/* ==== SUGGESTION PLUS BUTTON DISPLAY ==== */}
      <div className="suggetion-plus-button-container">
        <SuggestionBoxPlusButton
          setDesktopPlusBtnState={setDesktopPlusBtnState}
          desktopPlusBtnState={desktopPlusBtnState}
          setSearchButton={setSearchButton}
        />
      </div>

      <SuggestionMobileMenu
        setSearchButton={setSearchButton}
        setMobileSuggestionForm={setMobileSuggestionForm}
        setViewTrending={setViewTrending}
        setSelectedCategory={setSelectedCategory}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  suggestions: state.suggestions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSuggestion: () => dispatch(fetchSuggestions()),
  fetchSuggestionCategories: () => dispatch(fetchSuggestionCategories()),
  fetchProjects: () => dispatch(fetchProjects()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionsContainer);
