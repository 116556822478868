import axios from 'axios';
import { BASEURL } from '../../API/apiData';
import {FETCH_PROJECTSUGGESTIONS_SUCCESS, FETCH_PROJECTSUGGESTIONS_REQUEST, 
  FETCH_PROJECTSUGGESTIONS_FAILURE, CREATE_PROJECTSUGGESTION_REQUEST, 
  CREATE_PROJECTSUGGESTION_SUCCESS, CREATE_PROJECTSUGGESTION_FAILURE, CREATE_PROJECTSUGGESTIONUPDATE_REQUEST,
  CREATE_PROJECTSUGGESTIONUPDATE_SUCCESS,CREATE_PROJECTSUGGESTIONUPDATE_FAILURE} from './projectSuggestionTypes'

export const fetchProjectSuggestionsRequest = () =>({
  type: FETCH_PROJECTSUGGESTIONS_REQUEST,
})

export const fetchProjectSuggestionsSuccess = (response) =>({
  type: FETCH_PROJECTSUGGESTIONS_SUCCESS,
  payload: response
})

export const fetchProjectSuggestionsFailure = (error) =>({
  type: FETCH_PROJECTSUGGESTIONS_FAILURE,
  payload: error
})

export const createProjectSuggestionRequest = () =>({
  type: CREATE_PROJECTSUGGESTION_REQUEST,
})

export const createProjectSuggestionSuccess = (response) =>({
  type: CREATE_PROJECTSUGGESTION_SUCCESS,
  payload: response
})

export const createProjectSuggestionFailure = (error) =>({
  type: CREATE_PROJECTSUGGESTION_FAILURE,
  payload: error
})

export const createProjectSuggestionUpdateRequest = () =>({
  type: CREATE_PROJECTSUGGESTIONUPDATE_REQUEST,
})

export const createProjectSuggestionUpdateSuccess = (response) =>({
  type: CREATE_PROJECTSUGGESTIONUPDATE_SUCCESS,
  payload: response
})

export const createProjectSuggestionUpdateFailure = (error) =>({
  type: CREATE_PROJECTSUGGESTIONUPDATE_FAILURE,
  payload: error
})

export const fetchProjectSuggestions = pId => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(fetchProjectSuggestionsRequest());
  return axios.get(`${BASEURL}projects/${pId}/project_suggestions`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  }).then(response => {
      return dispatch(fetchProjectSuggestionsSuccess(response.data));
    }).catch(error => {
      return dispatch(fetchProjectSuggestionsFailure(error));
    });
};

export const createProjectSuggestion = (pId, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createProjectSuggestionRequest());
  return axios.post(`${BASEURL}projects/${pId}/project_suggestions`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createProjectSuggestionSuccess(response.data));
    }).catch(error => {
      return dispatch(createProjectSuggestionFailure(error));
    });
};

export const updateProjectSuggestion = (pId,psId, data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(createProjectSuggestionUpdateRequest());
  return axios.put(`${BASEURL}projects/${pId}/project_suggestions/${psId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(createProjectSuggestionUpdateSuccess(response.data));
    }).catch(error => {
      return dispatch(createProjectSuggestionUpdateFailure(error));
    });
};
