import React from "react";
import DesktopDisplay from "./DesktopDisplay";
import MobileDisplay from "./MobileDisplay";

const MainDisplay = (props) => {
  return (
    <>
      <DesktopDisplay props={props} />
      <MobileDisplay props={props} />
    </>
  );
};

export default MainDisplay;
