export const CREATE_PROJECTDISCUSSION_REQUEST =
  "CREATE_PROJECTDISCUSSION_REQUEST";
export const CREATE_PROJECTDISCUSSION_SUCCESS =
  "CREATE_PROJECTDISCUSSION_SUCCESS";
export const CREATE_PROJECTDISCUSSION_FAILURE =
  "CREATE_PROJECTDISCUSSION_FAILURE";

export const FETCH_PROJECTDISCUSSION_REQUEST =
  "FETCH_PROJECTDISCUSSION_REQUEST";
export const FETCH_PROJECTDISCUSSION_SUCCESS =
  "FETCH_PROJECTDISCUSSION_SUCCESS";
export const FETCH_PROJECTDISCUSSION_FAILURE =
  "FETCH_PROJECTDISCUSSION_FAILURE";
