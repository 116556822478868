import { BASEURL } from "../../API/apiData";
import { bmcListsRoutes, bmcMainRoutes } from "./routes";

const getAllCostStructure = async (projectID, bmcID) => {
  const token = localStorage.getItem("token");

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.cost}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};

const createNewCostStructure = async (projectID, projectName, bmcID) => {
  const token = localStorage.getItem("token");

  const data = {
    cost_structure__name: `${projectName} cost structure`,
  };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.cost}`,
    requestOptions
  );
  const results = await response.json();
  return results;
};
// === LIST ITEMS ===
const createNewCostStructureList = async (
  projectID,
  bmcID,
  channelID,
  name
) => {
  const token = localStorage.getItem("token");

  const data = {
    cost_structure_item: {
      item_name: name,
    },
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.cost}/${channelID}/${bmcListsRoutes.costItems}`,
    requestOptions
  );

  const results = await response.json();
  return results;
};

const editNewCostStructureList = async (
  projectID,
  bmcID,
  channelID,
  name,
  ID
) => {
  const token = localStorage.getItem("token");

  const data = {
    cost_structure_item: {
      item_name: name,
    },
  };

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `${BASEURL}projects/${projectID}/business_model_canvas/${bmcID}/${bmcMainRoutes.cost}/${channelID}/${bmcListsRoutes.costItems}/${ID}`,
    requestOptions
  );

  const results = await response.json();
  return results;
};

export {
  getAllCostStructure,
  createNewCostStructure,
  createNewCostStructureList,
  editNewCostStructureList,
};
