/*eslint-disable */
import React, { useState, useEffect } from "react";
import SuggestionReplyBody from "./suggestionReplyBody";
import SuggestionReplyForm from "./suggestionReplyForm";
import Button from "@mui/material/Button";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";


const SuggestionComments = ({ sugID, getNumberOfComments }) => {
  const [createFunctions, setCreateFunctions] = useState({});
  const [numberOfComments, setNumberOfcomments] = useState(null);
  const [openCommentForm, setOpenCommentForm] = useState(false);

  useEffect(() => {
    getNumberOfComments(numberOfComments);
  }, [numberOfComments]);

  const getfetchCreateMethods = (
    fetchResponse,
    createResponse,
    setResponses,
    responses
  ) => {
    setCreateFunctions({
      createComment: createResponse,
      fetchAllComments: fetchResponse,
      setResponses: setResponses,
      responses: responses,
    });
  };
  const { createComment, fetchAllComments, setResponses } = createFunctions;
  const createNewComment = (data) => {
    createComment(sugID, data)
      .then(() => {
        fetchAllComments(sugID)
          .then((results) => {
            const { result } = results.payload;
            setResponses(result);
            setNumberOfcomments(result.length);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="suggestion-comments-container">
      <div className="card">
        <div className="card-body">
          <div className="suggestion-comments-counter">
            <h3>{numberOfComments} Comments</h3>
            <div className="suggetion-comments-sort text-right">
              <Button
                variant="outlined"
                startIcon={<QuestionAnswerIcon />}
                onClick={() => setOpenCommentForm(!openCommentForm)}
              >
                {openCommentForm ? "close form" : "New Comment"}
              </Button>
            </div>
          </div>
          <div className="suggestion-comment-field">
            {openCommentForm && (
              <SuggestionReplyForm
                createNewComment={createNewComment}
                sugID={sugID}
              />
            )}
          </div>
          <div className="suggestions-comments-body">
            <SuggestionReplyBody
              setNumberOfcomments={setNumberOfcomments}
              getfetchCreateMethods={getfetchCreateMethods}
              sugID={sugID}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionComments;
