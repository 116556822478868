/*eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createProjectTopTen, fetchProjectTopTens } from "../../../redux";
import { ToastContainer, toast } from "react-toastify";
import "../../../stylesheets/projectTop10.css";
import ProjectTop10All from "./ProjectTop10All";
import ProjectTop10Single from "./ProjectTop10Single";
import { Button } from "@mui/material";
import AppProjectSearch from "../globals/AppProjectSearch";

const ProjectTop10 = ({
  id,
  fetchProjectTopTens,
  setTopTens,
  topTens,
  speedDialState,
  filteredList,
  setFilteredList,
}) => {
  const { toggleProjectSpeedDialMenu } = speedDialState;
  const [viewNo, setViewNo] = useState(1);
  const [topTenId, setTopTenId] = useState(null);
  const [filterValue, setFilterValue] = useState("");

  const [open, setOpen] = useState(false);
  useEffect(() => {
    fetchProjectTopTens(id)
      .then((res) => {
        setTopTens(res.payload.result);
        setFilteredList(res.payload.result);
      })
      .catch((error) => console.log("Internal Server Error"));
  }, [fetchProjectTopTens]);

  useEffect(() => {
    setFilteredList(handlefilterBySearch());

    return () => {
      setFilteredList(topTens);
    };
  }, [filterValue]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const setId = (val) => {
    setViewNo(2);
    setTopTenId(val);
  };

  const handlefilterBySearch = () => {
    // ==== If the filter is empty ====
    if (filterValue === "") return topTens;
    if (filterValue === null) {
      return topTens;
    }
    // ==== If we have a list to filter ====
    let filteredList = topTens.filter((value) => {
      if (value.title === filterValue.title) {
        return value;
      }
    });
    return filteredList;
  };

  const showView = () => {
    if (viewNo == 1) {
      return (
        <>
          <div className="container">
            {/* <AppProjectSearch
              data={topTens && topTens.length > 1 ? [...topTens] : []}
              moduleType={"title"}
              value={filterValue}
              setValue={setFilterValue}
            /> */}
          </div>
          <ProjectTop10All
            pId={id}
            topTens={filteredList}
            setId={setId}
            setTopTens={setTopTens}
            setFilteredList={setFilteredList}
          />
        </>
      );
    } else if (viewNo == 2) {
      return (
        <ProjectTop10Single pId={id} ttId={topTenId} setViewNo={setViewNo} />
      );
    }
  };

  return (
    <>
      <div className="container-fluid">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => toggleProjectSpeedDialMenu("top10", true)}
          startIcon={<i className="fas fa-plus-circle"></i>}
          className="add_item_button"
        >
          Add Top10
        </Button>
      </div>
      <ToastContainer />
      <div className="profile-core-values">
        <div className="w-100 p-2">{showView()}</div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchProjectTopTens: (id) => dispatch(fetchProjectTopTens(id)),
  createProjectTopTen: (id, data) => dispatch(createProjectTopTen(id, data)),
});

export default connect(null, mapDispatchToProps)(ProjectTop10);
