import axios from "axios";
import { BASEURL } from "../../API/apiData";
import {
  FETCH_SINGLEPROJECT_FAILURE,
  FETCH_SINGLEPROJECT_SUCCESS,
  FETCH_SINGLEPROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST, UPDATE_PROJECT_SUCCESS, UPDATE_PROJECT_FAILURE
} from "./singleProjectTypes";

export const fetchSingleProjectRequest = () => ({
  type: FETCH_SINGLEPROJECT_REQUEST,
});

export const fetchSingleProjectSuccess = (response) => ({
  type: FETCH_SINGLEPROJECT_SUCCESS,
  payload: response,
});

export const fetchSingleProjectFailure = (error) => ({
  type: FETCH_SINGLEPROJECT_FAILURE,
  payload: error,
});

// ==== Update Project ====
export const updateProjectsRequest = () => ({
  type: UPDATE_PROJECT_REQUEST,
});

export const updateProjectsSuccess = (response) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload: response,
});

export const updateProjectsFailure = (error) => ({
  type: UPDATE_PROJECT_FAILURE,
  payload: error,
});

export const fetchSingleProject = (projectID) => (dispatch) => {
  const token = localStorage.getItem("token");
  dispatch(fetchSingleProjectRequest());
  return axios
    .get(`${BASEURL}projects/${projectID}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return dispatch(fetchSingleProjectSuccess(response.data));
    })
    .catch((error) => {
      return dispatch(fetchSingleProjectFailure(error));
    });
};

export const updateProject = (projectID,data) => dispatch => {
  const token = localStorage.getItem('token')
  dispatch(updateProjectsRequest());
  return axios.put(`${BASEURL}/projects/${projectID}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    }
  } )
    .then(response => {
      return dispatch(updateProjectsSuccess(response.data));
    }).catch(error => {
      return dispatch(updateProjectsFailure(error));
    });
};
