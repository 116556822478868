import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/navbar/Navbar";
import "../stylesheets/masterNeeds.css";
import { createMasterNeedsCategory, fetchMasterNeedsCategories } from "../services/masterNeeds/masterNeedsService";
import NeedCategoryCard from "../components/masterNeeds/NeedCategoryCard";
import _ from "lodash";
import NeedCategoryModal from "../components/masterNeeds/NeedCategoryModal";

const MasterNeedsCategoryContainer = () => {
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false)

  const getCategories = async () => {
    try {
      const data = await fetchMasterNeedsCategories();
      setCategories(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const createCategory = async (data) => {
    try {
       await createMasterNeedsCategory(data);
       toast.success("Category added successfully");
       getCategories();
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCategories();
  }, []);

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
      setOpen(false);
  };

  const handleCreateCategory = (data) => {
    createCategory(data)
  }

  const displayCategories = () => {
    if (!_.isEmpty(categories)) {
      return (
        <>
          {categories.map((cat) => (
            <NeedCategoryCard key={cat.id} category={cat} />
          ))}
        </>
      );
    } else {
      return (
        <h2 className="text-center text-danger">No Categories Available</h2>
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <NeedCategoryModal isOpen={open} closeModal={closeModal} handleSubmit={handleCreateCategory}/>
      <div className="master-need-container">
        <div className="master-needs-header">
          <h2 className="app-head-title ml-1">
            &nbsp;Categories
          </h2>
          <button type="button" onClick={openModal}>Add New Category</button>
        </div>
        <div className="master-need-categories">{displayCategories()}</div>
      </div>
    </>
  );
};

export default MasterNeedsCategoryContainer;
