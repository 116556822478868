import React from 'react'
import { useHistory } from 'react-router-dom'


const ProfileHeadDetails = ({avatar,fName, lName, address, role, openModal = null,profileUserId,userId}) => {
  const history = useHistory()
  return (
    <div className="user-details">
      <div className="user-details-info">
        <img
          className="profile-avatar shadow-lg"
          src={
            avatar ||
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          }
          alt="profile-avatar"
        />
      </div>
      <div className="user-details-info">
        <h3>
          {fName} {lName}
        </h3>
        <h3>{role}</h3>
        <h3>{address || "TBD"}</h3>
        {profileUserId === userId && openModal == null ? (
          <h6>
            <button
              className="mobile-edit"
              onClick={() => history.push("/profile")}
            >
              <i className="fas fa-pen"></i>&nbsp;Edit Mode
            </button>
          </h6>
        ) : null }
        {openModal != null ?  <h6>
            <button
              className="mobile-edit"
              onClick={openModal}
            >
              <i className="fas fa-pen"></i>&nbsp;Edit Profile
            </button>
          </h6> : null}
        <div className="head-social-links p-2">
          <i className="fab fa-facebook-square fp-2 text-primary"></i>
          <i className="fab fa-twitter p-2 text-info"></i>
          <i className="fab fa-linkedin p-2 text-primary"></i>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeadDetails
