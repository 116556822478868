/*eslint-disable */
import _ from "lodash";
import React, {useState, useEffect} from "react";
import nocores from '../../assets/nocorevalues.png'
import { fetchUserBadges } from "../../services/badges/badgeService";
import UserBadge from "../badges/UserBadge";

const ProfileBadges = ({userId}) => {

  const [badges, setBadges] = useState([])

  const getUserBadges = async (data) => {
    const response = await fetchUserBadges(data);
    setBadges(response.result);
  };

  useEffect(() => {
    getUserBadges({user_id: userId})
  }, [])

  const showBadges = () => {
    if (!_.isEmpty(badges)){
      return (
        <>
          <div className="user-badge-container">
            {
              badges.map(badge => <UserBadge key={badge.id} badge={badge}/>)
            }
          </div>
        </>
      );
    }else if (_.isEmpty(badges)) {
      return (
        <>
          <div className="w-100 text-center">
          <img className="no-cores-thumbnail"src={nocores} alt="no-cv" />
          <br />
          <h4 className="text-dark">No badges have been awarded yet</h4>
        </div>
        </>
      )
    }
  }
  

  return (
    <>
      <div className="profile-core-values">
        <h1>Badges</h1>
        {
          showBadges()
        }
      </div>
    </>
  );
};

export default ProfileBadges;
