import { BASEURL } from "../../API/apiData";

const getAllSdgs = async () => {
  const token = localStorage.getItem("token");
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await fetch(`${BASEURL}sdgs`, requestOptions);
  const results = await response.json();
  return results;
};

export default getAllSdgs;
