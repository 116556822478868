import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/navbar/Navbar";
import UpdateMain from "../components/updates/UpdateMain";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  createUpdateNotice,
  fetchUpdateNotices,
  editUpdateNotice,
} from "../services/updates/updateNoticeService";
import "../stylesheets/updates.css";
import { useLocation } from "react-router-dom";

const UpdateNoticeContainer = () => {
  const [updates, setUpdates] = useState({});

  const location = useLocation();

  const getUpdates = async () => {
    const result = await fetchUpdateNotices();
    setUpdates(result);
  };

  useEffect(() => {
    getUpdates();
  }, []);

  const createUpdate = async (data) => {
    try {
      await createUpdateNotice(data);
      toast.success("Update added successfully");
      getUpdates();
    } catch (error) {
      console.log(error);
    }
  };

  const editUpdate = async (id, data) => {
    try {
      await editUpdateNotice(id, data);
      toast.success("Update edited successfully");
      getUpdates();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateUpdate = (data) => {
    createUpdate(data);
  };

  const handleUpdateNotice = (id, data) => {
    editUpdate(id, data);
  };

  const showData = () => {
    if (updates != null) {
      return (
        <UpdateMain
          updates={updates.result}
          handleEdit={handleUpdateNotice}
          location={location}
          handleCreateUpdate={handleCreateUpdate}
          handleUpdateNotice={handleUpdateNotice}
        />
      );
    } else {
      return <LoadingSpinner />;
    }
  };

  return (
    <>
      <ToastContainer />
      <Navbar />
      <div className="update-notice-container">{showData()}</div>
      <br />
    </>
  );
};

export default UpdateNoticeContainer;
